import React, { useCallback } from 'react';
import { ITeam } from '../../../../domainTypes/teams';
import { useCurrentUserScopes } from '../../../../services/currentUser';
import { useSnackbar } from '../../../../hooks/useSnackbar';
import { useMixpanel } from '../../../../services/mixpanel';
import { useDialogState } from '../../../../hooks/useDialogState';
import { removeTeam, saveTeam } from '../../service/teams';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { Trash2 } from 'react-feather';
import { css } from '../../../../emotion';
import Typography from '@material-ui/core/Typography';
import { ButtonWithPromise } from '../../../../components/ButtonWithPromise';
import { TeamCard } from './TeamCard';
import Tooltip from '@material-ui/core/Tooltip';
import { useManualCampaigns } from '../../service';
import { useMappedLoadingValue } from '../../../../services/db';

const TeamDeleteButton: React.FC<{ team: ITeam }> = ({ team }) => {
  const { enqueueSnackbar } = useSnackbar();
  const mixpanel = useMixpanel();
  const { dialogOpen, openDialog, closeDialog } = useDialogState();
  const [hasCampaigns = true] = useMappedLoadingValue(
    useManualCampaigns(),
    (campaigns) => campaigns.some((c) => c.team === team.teamId)
  );

  const deleteTeam = useCallback(async () => {
    try {
      mixpanel.track('teams_delete', {
        teamId: team.teamId,
        spaceId: team.spaceId
      });
      await removeTeam(team.spaceId, team.teamId);
      enqueueSnackbar('Team deleted', { variant: 'success' });
    } catch (e) {
      console.error('Failed to delete team', e);
      enqueueSnackbar('Failed to delete team', { variant: 'error' });
    }
  }, [enqueueSnackbar, mixpanel, team.spaceId, team.teamId]);

  return (
    <>
      <Tooltip
        title={
          hasCampaigns
            ? "Please reassign this team's past campaigns before deleting."
            : ''
        }
      >
        <span>
          <Button
            color="default"
            onClick={openDialog}
            style={{ opacity: 0.5 }}
            disabled={hasCampaigns}
          >
            <Trash2 size={16} /> &nbsp; Delete team ...
          </Button>
        </span>
      </Tooltip>
      <Dialog open={dialogOpen} onClose={closeDialog} scroll="body">
        <DialogTitle
          className={css(() => ({
            paddingBottom: 0
          }))}
        >
          Permanently delete this team?
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" paragraph>
            Are you sure you want to delete the team{' '}
            <strong>"{team.name}"</strong>? You can't undo this action.
          </Typography>
          <DialogActions
            className={css((t) => ({ padding: 0, paddingTop: t.spacing(2) }))}
          >
            <Button onClick={closeDialog}>Cancel</Button>
            <ButtonWithPromise
              variant="contained"
              color="secondary"
              onClick={deleteTeam}
              pending="Deleting..."
            >
              Delete team
            </ButtonWithPromise>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};
export const Team: React.FC<{ team: ITeam }> = ({ team }) => {
  const scopes = useCurrentUserScopes();
  const { enqueueSnackbar } = useSnackbar();
  const mixpanel = useMixpanel();
  const canDelete = scopes.has('teams.delete');
  const canEdit = scopes.has('teams.edit');
  const saveTeamChanges = useCallback(
    async (team: ITeam) => {
      if (!canEdit) return;
      try {
        mixpanel.track('teams_edit', {
          teamId: team.teamId,
          spaceId: team.spaceId
        });
        await saveTeam(team);
        enqueueSnackbar('Team saved', {
          variant: 'success'
        });
      } catch (e) {
        console.error('Failed to save team', e);
        enqueueSnackbar('Failed to save team', {
          variant: 'error'
        });
      }
    },
    [canEdit, enqueueSnackbar, mixpanel]
  );
  const deleteButton = canDelete ? <TeamDeleteButton team={team} /> : null;
  return (
    <TeamCard
      team={team}
      deleteButton={deleteButton}
      showReset
      saveLabel="Save team"
      saveTeam={saveTeamChanges}
      canModify={canEdit}
    />
  );
};
