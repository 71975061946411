import { useMixpanel } from '../../../services/mixpanel';
import { useLocalJsonStorage } from '../../../services/localJsonStorage';
import { useCallback, useMemo, useState } from 'react';
import { useQueryParam } from '../../../routes';
import {
  FilterDefinition,
  FiltersDefinition,
  printFilters,
  readFilters
} from '../../../domainTypes/filters';

import { FilterableDimension } from './index';

export const useFiltersUrlParam = () => {
  return useQueryParam<FiltersDefinition>(
    'filter',
    (param: string | null | undefined) => (param ? readFilters(param) : []),
    printFilters
  );
};

export const useFilterDefinitions = () => {
  const [filters] = useFiltersUrlParam();
  return filters;
};

export interface DrawerOptions {
  initialState?: boolean;
  localStorageKey?: string;
}

export const useFilterDrawerState = ({
  initialState = false,
  localStorageKey = 'filter-drawer-state'
}: DrawerOptions = {}) => {
  const mixpanel = useMixpanel();
  const [isOpen, setOpen] = useLocalJsonStorage(localStorageKey, initialState);
  const toggle = useCallback(() => {
    if (isOpen) {
      mixpanel.track('filters_hide');
      setOpen(false);
    } else {
      mixpanel.track('filters_show');
      setOpen(true);
    }
  }, [isOpen, mixpanel, setOpen]);
  return {
    isOpen,
    toggle
  };
};

export const useFiltersDrawer = (
  filters: FilterDefinition[],
  setFilters: (filters: FilterDefinition[]) => void
) => {
  const mixpanel = useMixpanel();

  const [
    placeholderDimension,
    setPlaceholderDimension
  ] = useState<FilterableDimension | null>(null);

  const clearPlaceholder = useCallback(() => setPlaceholderDimension(null), []);

  const addFilter = useCallback(
    (def: FilterDefinition) => {
      setFilters([...filters, def]);
      mixpanel.track('filters_apply', { name: def.k, value: def.v });
      clearPlaceholder();
    },
    [clearPlaceholder, filters, mixpanel, setFilters]
  );

  const updateFilter = useCallback(
    (def: FilterDefinition) => {
      setFilters(
        filters.map((d) => {
          if (d.k === def.k) {
            return def;
          }
          return d;
        })
      );
      mixpanel.track('filters_apply', { name: def.k, value: def.v });
    },
    [filters, mixpanel, setFilters]
  );

  const removeFilter = useCallback(
    (dimension: FilterableDimension) => {
      setFilters(filters.filter((f) => f.k !== dimension));
      mixpanel.track('filters_remove', { name: dimension });
    },
    [filters, mixpanel, setFilters]
  );

  const clearFilters = useCallback(() => {
    setFilters([]);
    mixpanel.track('filters_clear');
  }, [mixpanel, setFilters]);

  return useMemo(
    () => ({
      placeholderDimension,
      setPlaceholderDimension,
      clearPlaceholder,
      addFilter,
      updateFilter,
      removeFilter,
      clearFilters
    }),
    [
      addFilter,
      clearFilters,
      clearPlaceholder,
      placeholderDimension,
      removeFilter,
      updateFilter
    ]
  );
};
