import Typography from '@material-ui/core/Typography';
import { css } from '../../../../../../emotion';
import {
  Collapse,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch
} from '@material-ui/core';
import { TagListCreator } from '../TagListCreator';
import { kebabCase } from 'lodash';
import { InternalId } from '../../../../../../components/ConnectionId';
import React from 'react';
import { LinkGeneratorOption } from '../../../../../../domainTypes/customDimensions';

interface LinkGeneratorSettingsProps {
  name: string;
  showInLinkGenerator: boolean;
  setShowInLinkGenerator: (show: boolean) => void;
  linkGeneratorMode: 'dictionary' | 'freeform';
  setLinkGeneratorMode: (mode: 'dictionary' | 'freeform') => void;
  options: LinkGeneratorOption[];
  setOptions: (options: LinkGeneratorOption[]) => void;
}

export const LinkGeneratorSettingsControl: React.FC<LinkGeneratorSettingsProps> = ({
  showInLinkGenerator,
  setShowInLinkGenerator,
  linkGeneratorMode,
  setLinkGeneratorMode,
  options,
  setOptions,
  name
}) => {
  return (
    <>
      <Typography
        variant="body1"
        className={css(() => ({ fontWeight: 'bold' }))}
      >
        Link generator settings
      </Typography>

      <FormControlLabel
        control={
          <Switch
            color="primary"
            checked={showInLinkGenerator}
            onChange={(e) => setShowInLinkGenerator(e.target.checked)}
          />
        }
        label={
          <Typography variant="body1">
            Show <span>{name}</span> in the Link Generator
          </Typography>
        }
      />
      {showInLinkGenerator && (
        <>
          <Typography variant="body1">
            How should people be able to define this dimension within links?
          </Typography>
          <FormControl>
            <RadioGroup
              name="link-generator-mode"
              value={linkGeneratorMode}
              onChange={(e) =>
                setLinkGeneratorMode(
                  e.target.value as 'dictionary' | 'freeform'
                )
              }
            >
              <FormControlLabel
                control={<Radio color="primary" />}
                value="dictionary"
                label="Pre-defined list of options. You'll be able to add more later."
              />
              <Collapse in={linkGeneratorMode === 'dictionary'}>
                <TagListCreator
                  label="Options"
                  placeholder="Type the option name and press Enter to add it."
                  value={options}
                  setValue={setOptions}
                  createTag={(name) => ({ id: kebabCase(name), name })}
                  tagId={(option) => option.id}
                  renderTag={(option) => (
                    <>
                      {option.name}
                      <InternalId>{option.id}</InternalId>
                    </>
                  )}
                  variant="outlined"
                  disabled={linkGeneratorMode !== 'dictionary'}
                />
              </Collapse>

              <FormControlLabel
                control={<Radio color="primary" />}
                value="freeform"
                label="Free input. People can type anything they want."
              />
            </RadioGroup>
          </FormControl>
        </>
      )}
    </>
  );
};
