import { useCallback, useMemo } from 'react';
import {
  PAYOUT_STATUSES,
  SALE_TYPES,
  TRANSACTION_STATUSES
} from '../../../../domainTypes/performance';
import { useCustomDimensions } from '../../../../services/customDimensions';
import { capitalize } from 'lodash';
import {
  CustomDimensionName,
  slotTitle
} from '../../../../domainTypes/customDimensions';
import { FilterableDimension } from '../index';

interface FiltersListOption {
  dimension: FilterableDimension;
  title: string;
  terms: string[];
}

interface FiltersListGroup {
  label: string;
  options: Array<FiltersListOption>;
}

export type FiltersList = Array<FiltersListGroup>;

export const productFiltersList: FiltersList = [
  {
    label: 'Product',
    options: [
      {
        dimension: 'product_catalog_brand',
        title: 'Brand',
        terms: []
      },
      {
        dimension: 'product_catalog_retailer',
        title: 'Retailer',
        terms: []
      },
      {
        dimension: 'product_catalog_manufacturer',
        title: 'Manufacturer',
        terms: []
      },
      {
        dimension: 'product_catalog_seller',
        title: 'Seller',
        terms: []
      },
      {
        dimension: 'product_catalog',
        title: 'Catalog',
        terms: []
      }
    ]
  }
];

export const useAnalyticsFiltersList = (): FiltersList => {
  const [customDimensions = {}] = useCustomDimensions();
  const customTitle = useCallback(
    (name: CustomDimensionName) =>
      capitalize(customDimensions[name]?.name ?? slotTitle(name)),
    [customDimensions]
  );
  return useMemo(() => {
    return [
      {
        label: 'Popular',
        options: [
          { dimension: 'site', title: 'Site', terms: ['website'] },
          {
            dimension: 'platform',
            title: 'Platform',
            terms: ['partner', 'network']
          },
          {
            dimension: 'channel',
            title: 'Site or Channel',
            terms: ['offsite', 'site', 'newsletter', 'social', 'media', 'email']
          },
          {
            dimension: 'advertiser',
            title: 'Advertiser',
            terms: ['advertisers', 'partners', 'affiliates']
          }
        ]
      },
      {
        label: 'Custom',
        options: [
          {
            dimension: 'tag',
            title: 'Tag',
            terms: ['author', 'authors', 'editor', 'writer']
          },
          {
            dimension: 'click_data_01',
            title: customTitle('click_data_01'),
            terms: ['click', 'dimension', 'custom', 'data']
          },
          {
            dimension: 'click_data_02',
            title: customTitle('click_data_02'),
            terms: ['click', 'dimension', 'custom', 'data']
          },
          {
            dimension: 'click_data_03',
            title: customTitle('click_data_03'),
            terms: ['click', 'dimension', 'custom', 'data']
          },
          {
            dimension: 'click_data_04',
            title: customTitle('click_data_04'),
            terms: ['click', 'dimension', 'custom', 'data']
          },
          {
            dimension: 'click_data_05',
            title: customTitle('click_data_05'),
            terms: ['click', 'dimension', 'custom', 'data']
          },
          {
            dimension: 'click_data_06',
            title: customTitle('click_data_06'),
            terms: ['click', 'dimension', 'custom', 'data']
          },
          {
            dimension: 'click_data_07',
            title: customTitle('click_data_07'),
            terms: ['click', 'dimension', 'custom', 'data']
          },
          {
            dimension: 'click_data_08',
            title: customTitle('click_data_08'),
            terms: ['click', 'dimension', 'custom', 'data']
          },
          {
            dimension: 'click_data_09',
            title: customTitle('click_data_09'),
            terms: ['click', 'dimension', 'custom', 'data']
          },
          {
            dimension: 'click_data_10',
            title: customTitle('click_data_10'),
            terms: ['click', 'dimension', 'custom', 'data']
          }
        ]
      },
      {
        label: 'Audience',
        options: [
          {
            dimension: 'device',
            title: 'Device',
            terms: ['desktop', 'mobile']
          },
          {
            dimension: 'country',
            title: 'Click country',
            terms: ['geography', 'location']
          }
        ]
      },
      {
        label: 'Campaigns',
        options: [
          {
            dimension: 'campaign_team',
            title: 'Campaign team',
            terms: ['campaign', 'team']
          },
          {
            dimension: 'campaign_manager',
            title: 'Campaign manager',
            terms: ['campaign', 'manager']
          },
          {
            dimension: 'campaign_advertiser',
            title: 'Advertiser',
            terms: ['campaign', 'advertiser']
          },
          {
            dimension: 'campaign_status',
            title: 'Status',
            terms: ['campaign', 'status']
          }
        ]
      },
      {
        label: 'Products',
        options: [
          {
            dimension: 'product_availability',
            title: 'Product availability',
            terms: ['product', 'availability', 'stock']
          }
        ]
      },
      {
        label: 'Traffic sources',
        options: [
          {
            dimension: 'referrer',
            title: 'Referrer',
            terms: ['domain', 'traffic']
          },
          { dimension: 'utm_campaign', terms: [], title: 'UTM campaign' },
          { dimension: 'utm_medium', terms: [], title: 'UTM medium' },
          { dimension: 'utm_source', terms: [], title: 'UTM source' },
          { dimension: 'utm_term', terms: [], title: 'UTM term' },
          { dimension: 'utm_content', terms: [], title: 'UTM content' }
        ]
      },
      {
        label: 'Other filters',
        options: [
          {
            dimension: 'payout_id',
            title: 'Payout ID',
            terms: ['invoices', 'payments', 'payouts']
          },
          {
            dimension: 'payout_status',
            title: 'Payout status',
            terms: PAYOUT_STATUSES
          },
          {
            dimension: 'transaction_status',
            title: 'Transaction status',
            terms: [...TRANSACTION_STATUSES, 'sale']
          },
          {
            dimension: 'transaction_type',
            title: 'Transaction type',
            terms: [...SALE_TYPES, 'sale']
          },
          {
            dimension: 'integration_id',
            title: 'Integration ID',
            terms: ['integration', 'api', 'import']
          },
          {
            dimension: 'l_advertiser_domain',
            title: 'Deeplink domain',
            terms: ['advertiser', 'link']
          }
        ]
      }
    ];
  }, [customTitle]);
};

const matches = ({ title, terms }: FiltersListOption, search: string) => {
  if (search === '') return true;
  const normalizedSearch = search.toLocaleLowerCase();
  return (
    title.toLocaleLowerCase().includes(normalizedSearch) ||
    terms.some((term) => term.toLocaleLowerCase().includes(normalizedSearch))
  );
};

export const filterDimensionsTree = (
  tree: FiltersList,
  availableDimensions: FilterableDimension[],
  search: string
): FiltersList => {
  return tree
    .map((group: FiltersListGroup) => ({
      ...group,
      options: group.options.filter(
        (def) =>
          availableDimensions.includes(def.dimension) && matches(def, search)
      )
    }))
    .filter((group) => group.options.length > 0);
};
