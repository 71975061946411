import { generateToDocFn } from '../../domainTypes/document';
import { IAmazonApiKeys } from '../../domainTypes/productCatalog';
import { store } from '../../services/db';
import {
  CollectionListener,
  createCollectionListenerStore,
  useCollectionListener
} from '../../services/firecache/collectionListener';
import { FS } from '../../versions';

const toAmazonApiKeys = generateToDocFn<IAmazonApiKeys>();

const apiConfigsStore = createCollectionListenerStore(
  (spaceId) =>
    new CollectionListener(
      store()
        .collection(FS.amazonApiKeysPublic)
        .where('spaceId', '==', spaceId),
      toAmazonApiKeys
    )
);

export const useApiConfigs = (spaceId: string) =>
  useCollectionListener(apiConfigsStore(spaceId));
