import { useTeam } from '../../service/teams';
import { useCurrentUser } from '../../../../services/currentUser';
import React from 'react';
import { WithShape } from '../../../../components/Charts/Util';
import { getStableRandomColor } from '../../../../services/color';
import { Skeleton } from '@material-ui/lab';
import { Truncated } from '../../../../components/Truncated';
import { Dash } from '../../../../components/Table/CountCell';

export const TeamWithColor: React.FC<{ id: string; name: string }> = ({
  id,
  name
}) => {
  return (
    <Truncated title={name}>
      <WithShape large color={getStableRandomColor(id)}>
        {name}
      </WithShape>
    </Truncated>
  );
};

export const TeamWithColorLazy: React.FC<{ teamId: string }> = ({ teamId }) => {
  const { space } = useCurrentUser();
  const [team, loading] = useTeam(space.id, teamId);
  if (loading) {
    return <Skeleton />;
  }
  if (!team) {
    return <Dash size={12} />;
  }
  return <TeamWithColor id={team.data.teamId} name={team.data.name} />;
};
