import React from 'react';
import { useHasCurrentUserRequiredScopes } from '../../../../../services/currentUser';
import { Helmet } from 'react-helmet';
import { NoPermissions } from '../../../../../components/NoPermissions';
import { ContentPageBody } from '../../../components/ContentPageBody';
import { useTrackMixpanelView } from '../../../../../services/mixpanel';
import { CustomDimensionsSlot } from './CustomDimensionsSlot';
import { ClickDimensionName } from '../../../../../domainTypes/customDimensions';
import { useCustomDimensionTitle } from '../../../../../services/customDimensions';

export const PageCustomDimensionsSlot = ({
  slot
}: {
  slot: ClickDimensionName;
}) => {
  useTrackMixpanelView('view_custom_dimensions');
  const [canView] = useHasCurrentUserRequiredScopes([
    'reports.custom_dimensions.view'
  ]);
  const title = useCustomDimensionTitle(slot);

  return (
    <>
      <Helmet>
        <title>{title} | Affilimate</title>
      </Helmet>
      <ContentPageBody>
        {canView ? <CustomDimensionsSlot slot={slot} /> : <NoPermissions />}
      </ContentPageBody>
    </>
  );
};
