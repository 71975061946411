import {
  Card,
  CardContent,
  CircularProgress,
  Typography
} from '@material-ui/core';
import firebase from 'firebase/app';
import React, { useState } from 'react';
import { ButtonWithPromise } from '../../components/ButtonWithPromise';
import { styled } from '../../emotion';
import { FlexContainerVertical } from '../../layout/Flex';
import { withoutTrailingSlashAtAnyPosition } from '../../services/url';
const Wrapper = styled('div')`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f9;

  > img {
    margin-bottom: ${(p) => p.theme.spacing()}px;
    margin-right: ${(p) => p.theme.spacing()}px;
  }
`;

const Loader = styled(CircularProgress)`
  width: 24px !important;
  height: 24px !important;
  margin-right: ${(p) => p.theme.spacing(1)}px;
  display: block;

  svg {
    color: ${(p) => p.theme.palette.primary.main};
  }
`;

export const InitializingAccount = () => {
  return (
    <Wrapper>
      <Loader />
      <br />
      <Typography component="div" color="textSecondary">
        Preparing your account, hang tight
      </Typography>
    </Wrapper>
  );
};

export const CreatingNewAccount = () => {
  return (
    <Wrapper>
      <img src="/favicon.png" width="30px" alt="" />
      <Typography color="textSecondary">
        Creating a new account for you...
      </Typography>
    </Wrapper>
  );
};

export const SigningOut = () => {
  return (
    <Wrapper>
      <Loader />
      <br />
      <Typography component="div" color="textSecondary">
        Signing out...
      </Typography>
    </Wrapper>
  );
};

export const EmailNotVerified = ({
  authUser,
  displayName,
  continueUrl
}: {
  authUser: firebase.User;
  displayName: string;
  continueUrl: string; // used to redirect to the app after verification
}) => {
  const [emailSent, setEmailSent] = useState(false);

  const sendVerificationEmail = async () => {
    await authUser.sendEmailVerification({
      url: withoutTrailingSlashAtAnyPosition(continueUrl)
    });
    setEmailSent(true);
  };

  return (
    <FlexContainerVertical
      marginTop={15}
      fullWidth
      alignItems="center"
      spacing={2}
    >
      <Typography variant="h6">Welcome {displayName}!</Typography>

      <Card>
        <CardContent>
          {emailSent ? (
            <FlexContainerVertical
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <FlexContainerVertical
                spacing={1}
                fullWidth
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="body1" align="center">
                  Check your email for a verification link.
                </Typography>
                <Typography variant="body1" align="center">
                  After clicking the link, refresh this page to continue.
                </Typography>
              </FlexContainerVertical>
              <Typography variant="body2" color="textSecondary" align="center">
                Please check your spam folder if you don't see the email in your
                inbox.
              </Typography>
            </FlexContainerVertical>
          ) : (
            <FlexContainerVertical
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="body1" align="center">
                Please verify your email address to continue.
              </Typography>
              <ButtonWithPromise
                variant="contained"
                color="primary"
                onClick={sendVerificationEmail}
                pending={'Sending email...'}
              >
                Send verification email
              </ButtonWithPromise>
            </FlexContainerVertical>
          )}
        </CardContent>
      </Card>
    </FlexContainerVertical>
  );
};
