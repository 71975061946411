import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Skeleton } from '@material-ui/lab';
import React, { useMemo } from 'react';
import { ArrowRight, Link, PlusCircle, ShoppingBag } from 'react-feather';
import { RetailerName } from '../../../../../components/ProductCatalogRetailer';
import { FilterDefinition } from '../../../../../domainTypes/filters';
import { css, styled } from '../../../../../emotion';
import { usePromise } from '../../../../../hooks/usePromise';
import { Centered } from '../../../../../layout/Centered';
import {
  FlexContainer,
  FlexContainerVertical
} from '../../../../../layout/Flex';
import { useCurrentUser } from '../../../../../services/currentUser';
import { callFirebaseFunction } from '../../../../../services/firebaseFunctions';
import { useMarketplaceAdvertiserProfilesPublic } from '../../../../../services/marketplaceAdvertisers';

const ShoppingBagWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 8px;
  background-color: ${(p) => p.theme.palette.primary.main};
  color: white;
`;

const TargetAdvertiserWrapper = styled('img')`
  width: 50px;
  height: 50px;
  border-radius: 8px;
`;

const FilterWrapper = styled('span')`
  display: inline-block;
  align-items: center;
  color: ${(p) => p.theme.palette.grey[600]};
  margin-right: 4px;
`;

const FilterIconWrapper = styled('span')`
  display: inline-block;
  position: relative;
  top: 2px;
  margin-right: 4px;
  margin-left: 8px;
`;

export const SegmentDialogEmptyState: React.FC<{
  maId: string;
  addFilter: (def: FilterDefinition) => void;
}> = ({ maId, addFilter }) => {
  const { space } = useCurrentUser();
  const [profiles] = useMarketplaceAdvertiserProfilesPublic(space.id);
  const advertiser = useMemo(() => {
    if (!profiles) return undefined;
    return profiles.find((a) => a.id === maId);
  }, [profiles, maId]);

  const [retailers = [], loadingRetailers] = usePromise<string[]>(async () => {
    const data = await callFirebaseFunction<{ retailer: string[] }>(
      'productCatalog-publisher_getAggregatedDataFromMatches',
      { spaceId: space.id, maId, fieldsToAggregate: ['retailer'] }
    );
    return data.retailer;
  }, [space.id, maId]);

  return (
    <Centered
      height={500}
      className={css((t) => ({
        borderRadius: t.spacing(1),
        border: `1px solid ${t.palette.divider}`,
        textAlign: 'center'
      }))}
    >
      <FlexContainerVertical
        alignItems="center"
        className={css(() => ({
          maxWidth: 500
        }))}
      >
        <FlexContainer justifyContent="space-around" spacing={2}>
          <ShoppingBagWrapper>
            <ShoppingBag size={30} />
          </ShoppingBagWrapper>
          <ArrowRight size={18} color="#AAA" />
          <Link size={18} />
          <ArrowRight size={18} color="#AAA" />
          <TargetAdvertiserWrapper
            src={advertiser?.logoUrl}
            alt={advertiser?.name}
          />
        </FlexContainer>
        <br />
        <Typography variant="h6">
          <strong>Choose product links to route to {advertiser?.name}</strong>
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          Click{' '}
          <FilterWrapper>
            <FilterIconWrapper>
              <PlusCircle size={16} />
            </FilterIconWrapper>
            <span>Add a filter</span>
          </FilterWrapper>{' '}
          above to choose which product links to offer as part of this campaign.
          You’ll only see options that match in-stock products{' '}
          {advertiser?.name} offers.
        </Typography>
        <Typography variant="body1" paragraph>
          Or, source from one of these suggested retailers:
        </Typography>
        <FlexContainer alignItems="center">
          {loadingRetailers ? (
            <>
              <Skeleton variant="text" width={120} height={36} />
              <Skeleton variant="text" width={120} height={36} />
            </>
          ) : (
            retailers.map((r) => (
              <Button
                key={r}
                variant="outlined"
                color="primary"
                onClick={() =>
                  addFilter({ k: 'product_catalog_retailer', v: [r] })
                }
              >
                <RetailerName retailer={r} />
              </Button>
            ))
          )}
        </FlexContainer>
      </FlexContainerVertical>
    </Centered>
  );
};
