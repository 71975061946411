import { FilterUI } from '../FilterUI';
import { FilterDefinition } from '../../../../domainTypes/filters';
import React from 'react';

interface FilterMenuBodyProps {
  filterUI: FilterUI;
  initialDefinition: FilterDefinition;
  onSave: (value: FilterDefinition) => void;
  isFirst: boolean;
}

export const FilterMenuBody: React.FC<FilterMenuBodyProps> = ({
  filterUI,
  initialDefinition,
  onSave,
  isFirst
}) => {
  const Menu = filterUI.menu;
  return (
    <Menu definition={initialDefinition} onSave={onSave} isFirst={isFirst} />
  );
};
