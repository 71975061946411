import { Button, Tooltip } from '@material-ui/core';
import React from 'react';
import { BarChart } from 'react-feather';
import { styled } from '../../../emotion';

import { LineChartIcon } from './LineChartIcon';

export type ChartMode = 'barChart' | 'lineChart';

const ICON_SIZE = 16;

export const ChartModeButton = styled<typeof Button, { selected: boolean }>(
  Button
)`
  margin-right: 8px !important;
  min-width: auto !important;
  padding: 4px !important;
  border-radius: 4px !important;

  ${({ selected }) => !selected && `opacity: 0.4;`}
`;

export const StyledButtonGroup = styled('div')`
  margin-left: ${({ theme }) => theme.spacing(1)}px;
`;

export const ChartModeSelector = ({
  value,
  onChange
}: {
  value: ChartMode;
  onChange: (nextValue: ChartMode) => void;
}) => {
  const handleClick = (name: ChartMode) => {
    onChange(name);
  };
  return (
    <StyledButtonGroup>
      <Tooltip placement="top" title="View as bar chart">
        <ChartModeButton
          color="default"
          variant={value === 'barChart' ? 'contained' : 'text'}
          size="small"
          onClick={() => handleClick('barChart')}
          selected={value === 'barChart'}
        >
          <BarChart size={ICON_SIZE} />
        </ChartModeButton>
      </Tooltip>
      <Tooltip placement="top" title="View as line chart">
        <ChartModeButton
          color="default"
          variant={value === 'lineChart' ? 'contained' : 'text'}
          size="small"
          onClick={() => handleClick('lineChart')}
          selected={value === 'lineChart'}
        >
          <LineChartIcon size={ICON_SIZE} />
        </ChartModeButton>
      </Tooltip>
    </StyledButtonGroup>
  );
};
