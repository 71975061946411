import React, { useCallback, useMemo } from 'react';
import { css } from '../../../emotion';
import { ArrowRight, X } from 'react-feather';
import { useDialogState } from '../../../hooks/useDialogState';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton
} from '@material-ui/core';
import {
  AnalyticsColumnTransformer,
  AnalyticsField,
  AnalyticsFilter,
  AnalyticsOrderBy,
  AnalyticsQuery,
  AnalyticsResponseRowWithComparison,
  ISOTimeRange
} from '../../../domainTypes/analytics_v2';
import { IColumn } from '../../../components/Table/Column';
import { Metric } from '../../../services/analyticsV2/metrics';
import {
  AnalyticsColumnDefinitions,
  AnalyticsTableMetadata,
  useAnalyticsTable
} from '../../../components/analytics_v2/Table';
import { useCurrentUser } from '../../../services/currentUser';
import { compact, max } from 'lodash';
import { useAnalyticsQueryV2 } from '../../../services/analyticsV2/query';
import { Centered } from '../../../layout/Centered';
import { Loader } from '../../../components/Loader';
import { Message } from '../../../components/Message';
import { RowsRenderer } from '../../../components/GroupableList';
import { EMPTY_ARR } from '../../../domainTypes/emptyConstants';
import { useMappedLoadingValue } from '../../../services/db';
import { CustomPagination } from '../../../components/CustomPagination';
import { FlexContainer } from '../../../layout/Flex';
import { useRoutes } from '../../../routes';

const PAGE_SIZE = 10;
const PAGINATION_PARAM_NAME = 'ftpage';
const SORT_PARAM_NAME = 'ftsort';

type ColumnName = Metric | 'name';
type Column = IColumn<
  AnalyticsResponseRowWithComparison,
  ColumnName,
  AnalyticsTableMetadata & { maxVal: number }
>;

interface FullTableProps {
  groupField: AnalyticsField;
  metrics: readonly Metric[];
  metric: Metric;
  filters: AnalyticsFilter[];
  range: ISOTimeRange;
  compareRange?: ISOTimeRange;
  columnTransformers?: AnalyticsColumnTransformer[];
  nameColumn: Column;
}

const useTableCount = (
  range: ISOTimeRange,
  groupField: AnalyticsField,
  filters: AnalyticsFilter[],
  metric: Metric,
  columnTransformers: AnalyticsColumnTransformer[]
) => {
  const { space } = useCurrentUser();
  const query = useMemo<AnalyticsQuery>(() => {
    return {
      range,
      select: [metric],
      groupBy: [groupField],
      columnTransformers,
      filters: compact([
        ...filters,
        groupField !== 'device' && {
          field: groupField,
          condition: 'not in',
          values: ['']
        }
      ])
    };
  }, [columnTransformers, filters, groupField, metric, range]);

  return useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    (data) => data?.rows.length ?? 0
  );
};

const useTableData = (
  range: ISOTimeRange,
  groupField: AnalyticsField,
  metrics: readonly Metric[],
  filters: AnalyticsFilter[],
  orderBy: AnalyticsOrderBy,
  paginate: AnalyticsQuery['paginate'],
  columnTransformers: AnalyticsColumnTransformer[],
  compareRange?: ISOTimeRange
) => {
  const { space } = useCurrentUser();
  const query = useMemo<AnalyticsQuery>(
    () => ({
      ...fullTableQuery(
        range,
        groupField,
        metrics,
        filters,
        orderBy,
        columnTransformers,
        compareRange
      ),
      paginate
    }),
    [
      columnTransformers,
      compareRange,
      filters,
      groupField,
      metrics,
      orderBy,
      paginate,
      range
    ]
  );

  return useAnalyticsQueryV2(space.id, query);
};

export const fullTableQuery = (
  range: ISOTimeRange,
  groupField: AnalyticsField,
  metrics: readonly Metric[],
  filters: AnalyticsFilter[],
  orderBy: AnalyticsOrderBy,
  columnTransformers: AnalyticsColumnTransformer[],
  compareRange?: ISOTimeRange
): AnalyticsQuery => {
  const compare = compareRange ? { range: compareRange } : undefined;
  return {
    range,
    compare,
    select: [...metrics],
    orderBy: [orderBy],
    groupBy: [groupField],
    columnTransformers,
    filters: compact([
      ...filters,
      // TODO: maybe additional filter?
      //
      // Device is an enum - there are no empties here, so we can ignore this
      // THis will also be required for others fields.
      // Arguably the backend could also be smart about it
      // TODO: 'includeUnknown' prop?
      groupField !== 'device' && {
        field: groupField,
        condition: 'not in',
        values: ['']
      }
    ])
  };
};

const FullTable: React.FC<FullTableProps> = ({
  groupField,
  metrics,
  metric,
  filters,
  range,
  compareRange,
  columnTransformers = EMPTY_ARR,
  nameColumn
}) => {
  const availableColumns = useMemo<ColumnName[]>(() => {
    return ['name', ...metrics];
  }, [metrics]);

  const columnDefinitions = useMemo(() => {
    return {
      name: {
        column: {
          key: 'name',
          head: nameColumn.head,
          cell: nameColumn.cell,
          align: 'left',
          sortable: false,
          width: 200,
          flexGrow: 4
        },
        sorter: {
          key: 'name',
          items: {
            sort: (p: AnalyticsResponseRowWithComparison) =>
              p.group[groupField],
            dir: 'asc'
          }
        }
      }
    };
  }, [groupField, nameColumn]);

  const {
    tableProps,
    paginationSelectorProps,
    pagination,
    orderBy,
    metrics: tableMetrics
  } = useAnalyticsTable(
    availableColumns,
    // TODO: type assertion is needed because name column need additional "otherProps" prop "maxValue"
    columnDefinitions as AnalyticsColumnDefinitions<'name'>,
    {
      pageSize: PAGE_SIZE,
      defaultSortColumn: 'gmv_sum_net',
      paginationParamName: PAGINATION_PARAM_NAME,
      sortQueryParamName: SORT_PARAM_NAME,
      showComparison: false
    }
  );

  const [count = 0] = useTableCount(
    range,
    groupField,
    filters,
    orderBy.field as Metric,
    columnTransformers
  );

  const [data, loading] = useTableData(
    range,
    groupField,
    tableMetrics,
    filters,
    orderBy,
    pagination,
    columnTransformers,
    compareRange
  );

  const otherProps = useMemo(
    () => ({
      ...tableProps.otherProps,
      maxVal:
        (data && max(data.rows.map((r) => r.data[metric]?.curr || 0))) || 0
    }),
    [data, metric, tableProps.otherProps]
  );

  if (!data && loading) {
    return (
      <Centered>
        <Loader size={16} />
      </Centered>
    );
  }
  if (!data) {
    // should never happen
    return null;
  }

  if (data.rows.length === 0) {
    return (
      <Centered>
        <Message message="No data found" />
      </Centered>
    );
  }

  return (
    <>
      <RowsRenderer
        {...tableProps}
        rows={data.rows}
        otherProps={otherProps}
        renderHead={true}
        rowToKey={(d) => d.group[groupField]}
        variant="plain"
      />
      <FlexContainer justifyContent="flex-end">
        <CustomPagination
          {...paginationSelectorProps}
          style={{ marginLeft: 'auto' }}
          count={Math.ceil(count / PAGE_SIZE)}
          siblingCount={0}
        />
      </FlexContainer>
    </>
  );
};

type FullTableFooterProps = {
  title: string;
} & FullTableProps;

export const FullTableFooter: React.FC<FullTableFooterProps> = ({
  title,
  ...tableProps
}) => {
  const { changeQuery } = useRoutes();
  const { dialogOpen, openDialog, closeDialog } = useDialogState(false);
  const onClose = useCallback(() => {
    closeDialog();
    changeQuery({
      [PAGINATION_PARAM_NAME]: undefined,
      [SORT_PARAM_NAME]: undefined
    });
  }, [changeQuery, closeDialog]);
  return (
    <>
      <div
        className={css((t) => ({
          padding: t.spacing(2),
          color: t.palette.text.secondary,
          textAlign: 'center'
        }))}
      >
        <span
          className={css(() => ({
            cursor: 'pointer'
          }))}
          onClick={openDialog}
        >
          View all{' '}
          <ArrowRight size={14} style={{ position: 'relative', top: '2px' }} />
        </span>
      </div>
      <Dialog
        open={dialogOpen}
        onClose={onClose}
        fullWidth
        maxWidth="xl"
        scroll="body"
      >
        <DialogTitle>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 100px'
            }}
          >
            {title}
            <div style={{ textAlign: 'right' }}>
              <IconButton onClick={onClose}>
                <X size={24} />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <FullTable {...tableProps} />
        </DialogContent>
      </Dialog>
    </>
  );
};
