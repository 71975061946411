import React from 'react';
import { Chip } from '@material-ui/core';
import { FlexContainer } from '../../../../layout/Flex';
import Typography from '@material-ui/core/Typography';

export const BaseChip: React.FC<{
  onClick: () => void;
  onDelete: () => void;
}> = ({ onClick, onDelete, children }) => {
  return (
    <Chip
      onClick={onClick}
      onDelete={onDelete}
      variant="outlined"
      label={<FlexContainer spacing={0.5}>{children}</FlexContainer>}
    />
  );
};

export const PlaceholderChip: React.FC = ({ children }) => {
  return (
    <Chip
      disabled
      variant="outlined"
      label={
        <Typography variant="body2" style={{ fontStyle: 'italic' }}>
          {children} ...
        </Typography>
      }
    />
  );
};
