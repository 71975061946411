import { useFeatureEnabled } from '../../../services/features';
import { useMemo } from 'react';
import { ALL_UTM_FILTER_UIS } from '../../../components/analytics_v2/Filters/FilterUI/analytics/UTMFilterUI';
import { referrerFilterUIDef } from '../../../components/analytics_v2/Filters/FilterUI/analytics/ReferrerFilterUI';
import { channelFilterUIDef } from '../../../components/analytics_v2/Filters/FilterUI/analytics/ChannelFilterUI';
import { platformFilterUIDef } from '../../../components/analytics_v2/Filters/FilterUI/analytics/PlatformFilterUI';
import { deviceFilterUIDef } from '../../../components/analytics_v2/Filters/FilterUI/analytics/DeviceFilterUI';
import { tagFilterUIDef } from '../../../components/analytics_v2/Filters/FilterUI/analytics/TagFilterUI';
import { compact } from 'lodash';
import { AnalyticsFilterUI } from '../../../components/analytics_v2/Filters/FilterUI';
import { linkAdvertiserDomainFilterUIDef } from '../../../components/analytics_v2/Filters/FilterUI/analytics/LinkAdvertiserDomainFilterUI';
import { countryFilterUIDef } from '../../../components/analytics_v2/Filters/FilterUI/analytics/CountryFilterUI';

export const useContentPagesFilterUIs = () => {
  const showTrafficSources = useFeatureEnabled('REFERRER_REPORTS_V1');
  // TODO: include page custom dimensions when we add filters for them
  return useMemo<AnalyticsFilterUI[]>(
    () =>
      compact([
        ...(showTrafficSources ? ALL_UTM_FILTER_UIS : []),
        showTrafficSources ? referrerFilterUIDef : null,
        deviceFilterUIDef,
        countryFilterUIDef,
        tagFilterUIDef,
        channelFilterUIDef,
        platformFilterUIDef,
        linkAdvertiserDomainFilterUIDef
      ]),
    [showTrafficSources]
  );
};
