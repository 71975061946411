import {
  campaignQueryBase,
  ReadyManualCampaign
} from '../../service/manual-campaign';
import {
  AnalyticsField,
  AnalyticsInterval,
  AnalyticsQuery
} from '../../../../domainTypes/analytics_v2';
import {
  Metric,
  metricName,
  metricTitle
} from '../../../../services/analyticsV2/metrics';
import { useCurrentUser } from '../../../../services/currentUser';
import React, { useCallback, useMemo, useState } from 'react';
import { useMappedLoadingValue } from '../../../../services/db';
import { useAnalyticsQueryV2 } from '../../../../services/analyticsV2/query';
import { CampaignMetric } from '../report/CampaignMetricSelector';
import { useQueryParam } from '../../../../routes';
import { useIntervalSelectorState } from '../../../../components/IntervalSelector';
import { useAnalyticsInterval } from '../../../../hooks/timeframe';
import { getCampaignGoalForMetric } from '../../service/goals-and-incentives';
import { getKnownPartnerForKey } from '../../../../services/partner';
import { COLORS } from '../../../../domainTypes/colors';
import { COLOR_UNKNOWN } from '../../../../services/color';
import { Legend } from '../../../../components/Charts/Util';
import { CampaignTimeseries, ChartMode } from './CampaignTimeseries';
import { aggregateRows } from '../../service/aggregate';

const campaignQuery = (
  campaign: ReadyManualCampaign,
  interval: AnalyticsInterval,
  groupBy: AnalyticsField | undefined,
  metric: Metric
): AnalyticsQuery => ({
  ...campaignQueryBase(campaign),
  select: [metric],
  groupBy: groupBy ? [groupBy] : [],
  interval,
  orderBy: [{ field: 'interval', direction: 'DESC' }]
});

const useCampaignTimeseries = (
  campaign: ReadyManualCampaign,
  interval: AnalyticsInterval,
  groupBy: AnalyticsField | undefined,
  metric: Metric,
  mode: ChartMode
) => {
  const { space } = useCurrentUser();
  const query = useMemo<AnalyticsQuery>(
    () => campaignQuery(campaign, interval, groupBy, metric),
    [campaign, groupBy, interval, metric]
  );
  const mapper = useCallback(
    (response) => aggregateRows(response.rows, mode, groupBy, metric),
    [groupBy, metric, mode]
  );
  return useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    mapper,
    true
  );
};

export const ManualCampaignTimeseries: React.FC<{
  campaign: ReadyManualCampaign;
  metric: CampaignMetric;
  summary: React.ReactNode;
}> = ({ campaign, metric, summary }) => {
  const [mode, setMode] = useState<ChartMode>('aggregated');
  const [split, setSplit] = useQueryParam<'pk' | 'none'>(
    'split',
    (split) => (split === 'pk' ? 'pk' : 'none'),
    (split) => split
  );
  const field = useMemo<AnalyticsField | undefined>(
    () => (split === 'none' ? undefined : split),
    [split]
  );
  const { interval, setInterval, options } = useIntervalSelectorState();
  const analyticsInterval = useAnalyticsInterval(interval);
  const series = useCampaignTimeseries(
    campaign,
    analyticsInterval,
    field,
    metric,
    mode
  );

  const goal = getCampaignGoalForMetric(campaign, metric);

  const seriesName = useCallback(
    (fieldValue) =>
      split === 'none'
        ? metricTitle(metric)
        : getKnownPartnerForKey(fieldValue)?.name ?? fieldValue,
    [metric, split]
  );

  const seriesColor = useCallback(
    (fieldValue) =>
      split === 'none'
        ? COLORS.blue.blue3
        : getKnownPartnerForKey(fieldValue)?.color ?? COLOR_UNKNOWN,
    [split]
  );

  return (
    <CampaignTimeseries
      campaign={campaign}
      metric={metric}
      field={field}
      mode={mode}
      goal={goal}
      series={series}
      interval={interval}
      setInterval={setInterval}
      intervalOptions={options}
      seriesName={seriesName}
      seriesColor={seriesColor}
      footer={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Legend
            items={[
              {
                shape: 'circle',
                color: '#444',
                label: 'Cumulatively',
                active: mode === 'aggregated',
                onClick: () => setMode('aggregated')
              },
              {
                shape: 'circle',
                color: '#444',
                label: `Per ${interval.unit}`,
                active: mode === 'raw',
                onClick: () => setMode('raw')
              }
            ]}
          />
          <Legend
            items={[
              {
                shape: 'circle',
                color: '#444',
                label: `Total ${metricName(metric)}`,
                active: split === 'none',
                onClick: () => setSplit('none')
              },
              {
                shape: 'circle',
                color: '#444',
                label: `${metricTitle(metric)} by platform`,
                active: split === 'pk',
                onClick: () => setSplit('pk')
              }
            ]}
          />
        </div>
      }
      summary={summary}
      exportQuery={campaignQuery(campaign, analyticsInterval, field, metric)}
    />
  );
};
