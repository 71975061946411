import { ScheduledSegmentCampaign } from '../service/segment-campaign';
import React from 'react';
import { Centered } from '../../../layout/Centered';
import { Paper } from '@material-ui/core';
import { prettifyDateRange } from '../../../services/time';
import { useCurrentUser } from '../../../services/currentUser';
import Typography from '@material-ui/core/Typography';
import { Moment } from 'moment-timezone';
import { useRoutes } from '../../../routes';
import { Link } from 'react-router-dom';
import { FlexContainerVertical } from '../../../layout/Flex';

interface SegmentCampaignScheduledProps {
  campaign: ScheduledSegmentCampaign;
}

const formatTimeframe = (
  timeframe: {
    start: Moment;
    end: Moment;
  } | null,
  tz: string
) => {
  if (!timeframe) return null; // Shouldn't happen
  return prettifyDateRange(timeframe.start, timeframe.end, tz);
};

export const SegmentCampaignScheduled: React.FC<SegmentCampaignScheduledProps> = ({
  campaign
}) => {
  const { tz } = useCurrentUser();
  const { ROUTES } = useRoutes();
  return (
    <Paper style={{ width: '80%', margin: '0 auto' }}>
      <Centered height={300}>
        <FlexContainerVertical alignItems="center">
          <Typography variant="body1" color="textSecondary">
            Campaign <strong>{campaign.name}</strong> is scheduled for{' '}
            {formatTimeframe(campaign.timeframe, tz)}
          </Typography>
          <Link to={ROUTES.campaigns.segment.report.url(campaign.id)}>
            <Typography variant="body2" color="primary">
              View campaign report
            </Typography>
          </Link>
        </FlexContainerVertical>
      </Centered>
    </Paper>
  );
};
