import React, { useMemo, useState } from 'react';
import { css } from '../../../../emotion';
import { Button, ButtonBase, TextField } from '@material-ui/core';
import { FlexContainer, FlexContainerVertical } from '../../../../layout/Flex';
import Avatar from '@material-ui/core/Avatar';
import { ButtonWithPromise } from '../../../../components/ButtonWithPromise';
import { useDialogState } from '../../../../hooks/useDialogState';
import { isEqual, times } from 'lodash';
import { updateSpace } from '../../../../admin/services/space';
import { ISpace } from '../../../../domainTypes/space';
import { useSnackbar } from 'notistack';
import { storeFileAndGetDownloadUrl } from '../../../../services/storage';
import shortid from 'shortid';
import { ImageUploadDialog } from '../../../../components/ImageUpload';

interface SpaceProfileProps {
  space: ISpace;
}

export const SpaceProfileForm: React.FC<SpaceProfileProps> = ({ space }) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    dialogOpen: isUploadDialogOpen,
    openDialog: openUploadDialog,
    closeDialog: closeUploadDialog
  } = useDialogState();

  const initialSpaceProfile = useMemo(
    () => ({
      name: space.profile.name,
      description: space.profile.description,
      logoUrl: space.profile.logoUrl
    }),
    [space.profile.description, space.profile.logoUrl, space.profile.name]
  );

  const [spaceProfile, setSpaceProfile] = useState(initialSpaceProfile);

  const hasChanges = useMemo(
    () => !isEqual(initialSpaceProfile, spaceProfile),
    [initialSpaceProfile, spaceProfile]
  );

  const revertSpaceSettings = () => setSpaceProfile(initialSpaceProfile);
  const saveSpaceSettings = async () => {
    try {
      await updateSpace(space.id, {
        profile: spaceProfile
      });
      enqueueSnackbar('Space settings updated!', { variant: 'success' });
    } catch (err) {
      console.error('Space settings update failed', err);
      enqueueSnackbar('Failed to update space settings', { variant: 'error' });
    }
  };

  return (
    <>
      <div
        className={css((t) => ({
          display: 'grid',
          gridTemplateColumns: 'min-content 1fr',
          columnGap: t.spacing(4),
          alignItems: 'center',
          padding: t.spacing(4)
        }))}
      >
        <ButtonBase onClick={openUploadDialog}>
          <FlexContainerVertical alignItems="center" justifyContent="center">
            <Avatar
              variant="rounded"
              className={css((t) => ({
                backgroundColor: 'white',
                width: 100,
                height: 100,
                border: `1px solid ${t.palette.grey[300]}`,
                color: t.palette.grey[300]
              }))}
              src={spaceProfile.logoUrl}
            />
            <div
              className={css((t) => ({
                color: t.palette.grey[500],
                fontSize: t.custom.fontSize.s,
                width: 100,
                textAlign: 'center'
              }))}
            >
              Click to change your space logo
            </div>
          </FlexContainerVertical>
        </ButtonBase>
        <FlexContainerVertical fullWidth spacing={2}>
          <TextField
            value={spaceProfile.name}
            onChange={(e) =>
              setSpaceProfile((settings) => ({
                ...settings,
                name: e.target.value
              }))
            }
            label="Space name"
            variant="outlined"
            fullWidth
          />
          <TextField
            value={spaceProfile.description}
            onChange={(e) =>
              setSpaceProfile((settings) => ({
                ...settings,
                description: e.target.value
              }))
            }
            label="Space description"
            variant="outlined"
            fullWidth
          />
          <FlexContainer fullWidth>
            <ButtonWithPromise
              variant="contained"
              color="primary"
              disabled={!hasChanges}
              onClick={saveSpaceSettings}
              pending="Saving..."
            >
              Save changes
            </ButtonWithPromise>
            <Button onClick={revertSpaceSettings}>Revert changes</Button>
          </FlexContainer>
        </FlexContainerVertical>
      </div>
      <ImageUploadDialog
        title="Upload space logo"
        open={isUploadDialogOpen}
        onClose={closeUploadDialog}
        onUpload={async (blob, ext) => {
          const url = await storeFileAndGetDownloadUrl({
            name: `space-logos/${space.id}/${times(4)
              .map(() => shortid())
              .join()}.${ext}`,
            data: blob,
            contentType: blob.type
          });

          setSpaceProfile((settings) => ({
            ...settings,
            logoUrl: url
          }));
        }}
      />
    </>
  );
};
