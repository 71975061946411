import { SegmentCampaign } from '../service/segment-campaign';
import { LoadingValue } from '../../../services/db';
import React from 'react';
import { UserLevelScope } from '../../../domainTypes/permissions';
import { useCurrentUserScopes } from '../../../services/currentUser';
import { CampaignsPageBody } from './CampaignsPageBody';
import { NoPermissions } from '../../../components/NoPermissions';
import { Loader } from '../../../components/Loader';
import { Paper } from '@material-ui/core';
import { Message } from '../../../components/Message';
import { Centered } from '../../../layout/Centered';

export const SegmentCampaignPage: React.FC<{
  campaign: LoadingValue<SegmentCampaign>;
  scope: UserLevelScope;
  children: ({ campaign }: { campaign: SegmentCampaign }) => React.ReactNode;
}> = ({ campaign, scope, children }) => {
  const scopes = useCurrentUserScopes();
  const hasScope = scopes.has(scope);
  const [campaignData, loading, error] = campaign;

  return (
    <CampaignsPageBody>
      {!hasScope ? (
        <NoPermissions />
      ) : error ? (
        <Message message="Couldn't load campaign." />
      ) : loading || !campaignData ? (
        <Paper style={{ width: '80%', margin: '0 auto' }}>
          <Centered height={300}>
            <Loader size={36} height={300} />
          </Centered>
        </Paper>
      ) : (
        children({ campaign: campaignData })
      )}
    </CampaignsPageBody>
  );
};
