import {
  FilterDefinition,
  ProductCatalogAvailabilityFilterDef
} from '../../../../../../domainTypes/filters';
import { ProductCatalogAvailabilityMenu } from './ProductCatalogAvailabilityMenu';
import React from 'react';
import { capitalize, isEmpty } from 'lodash';
import {
  ProductCatalogFilterMenuProps,
  ProductCatalogFilterUI
} from '../../../FilterUI';
import { WithShape } from '../../../../../Charts/Util';
import { ProductCatalogAvailability } from '../../../../../../domainTypes/productCatalog';
import { ProductCatalogFilter } from '../../../../../../domainTypes/productCatalog';
import { ChipContent } from '../../common';
import { AVAILABILITY_COLORS } from '../../../../../../features/Links/pages/Overview/components/ProductIssue';

interface ProductAvailabilityLabelsProps {
  statuses: ProductCatalogAvailability[];
}

const ProductAvailabilityLabels: React.FC<ProductAvailabilityLabelsProps> = ({
  statuses
}) => {
  return (
    <>
      {statuses.map((status) => {
        const color = AVAILABILITY_COLORS[status].backgroundColor;
        return (
          <WithShape key={status} shape="square" color={color}>
            <strong>{capitalize(status.replaceAll('_', ' '))}</strong>
          </WithShape>
        );
      })}
    </>
  );
};

export const productCatalogAvailabilityFilterUI: ProductCatalogFilterUI = {
  type: 'product_catalog',
  dimension: 'product_catalog_availability',
  chip: ({ definition }) => {
    if (
      !definition ||
      definition.k !== 'product_catalog_availability' ||
      isEmpty(definition.v)
    ) {
      return null;
    }

    return (
      <ChipContent title="Product Availability is">
        <ProductAvailabilityLabels
          statuses={definition.v as ProductCatalogAvailability[]}
        />
      </ChipContent>
    );
  },
  menu: function ProductCatalogAvailabilityFilterUIMenu({
    definition,
    onSave,
    isFirst,
    context
  }: ProductCatalogFilterMenuProps) {
    return (
      <ProductCatalogAvailabilityMenu
        definition={definition as ProductCatalogAvailabilityFilterDef}
        onSave={onSave}
        isFirst={isFirst}
        context={context}
      />
    );
  },
  init: () => ({
    k: 'product_catalog_availability',
    v: []
  }),
  toClauses: (definition: FilterDefinition): ProductCatalogFilter[] => {
    if (
      !definition ||
      definition.k !== 'product_catalog_availability' ||
      isEmpty(definition.v)
    ) {
      return [];
    }

    return [
      {
        field: 'availability',
        condition: 'in',
        values: definition.v
      }
    ];
  }
};
