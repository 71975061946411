import { AnalyticsFilterUI } from '../../../FilterUI';
import React, { useCallback } from 'react';
import {
  Channel,
  channelLabel,
  useSpaceChannels
} from '../../../../../../services/channels/channels';
import { compact } from 'lodash';
import {
  OTHERS_CHANNEL_ID,
  UNKNOWN_CHANNEL_ID
} from '../../../../../../domainTypes/channels';
import { useMappedLoadingValue } from '../../../../../../services/db';
import { Skeleton } from '@material-ui/lab';
import { ChannelMenu } from './ChannelMenu';
import { ChannelFilterDef } from '../../../../../../domainTypes/filters';
import { ChipContent, formatList } from '../../common';

const ChannelListChip: React.FC<{ definition: ChannelFilterDef }> = ({
  definition
}) => {
  const mapFn = useCallback(
    (channels: Channel[]) =>
      compact([
        ...channels
          .filter((c) => definition.v.includes(c.id))
          .map(channelLabel),
        definition.v.includes(UNKNOWN_CHANNEL_ID) && 'Unknown',
        definition.v.includes(OTHERS_CHANNEL_ID) && 'Other'
      ]),
    [definition.v]
  );
  const [channels] = useMappedLoadingValue(useSpaceChannels(), mapFn, true);

  return (
    <ChipContent title="Channel is">
      {channels ? formatList(channels) : <Skeleton variant="text" width={80} />}
    </ChipContent>
  );
};

export const channelFilterUIDef: AnalyticsFilterUI = {
  type: 'analytics',
  dimension: 'channel',
  chip: function ChannelChip({ definition }) {
    if (!definition) return <>Channel</>;
    if (definition.k !== 'channel') {
      throw new Error('Invalid definition');
    }
    return <ChannelListChip definition={definition} />;
  },
  menu: function ChannelUIMenu({ onSave, definition, context, isFirst }) {
    return (
      <ChannelMenu
        onSave={onSave}
        definition={definition as ChannelFilterDef}
        context={context}
        isFirst={isFirst}
      />
    );
  },
  init: () => ({
    k: 'channel',
    v: []
  }),
  toClauses: (definition) => {
    if (definition.k !== 'channel') return [];
    return [
      {
        field: 'channel_id',
        condition: 'in',
        values: definition.v
      }
    ];
  }
};
