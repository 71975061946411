import {
  Button,
  ButtonBase,
  InputAdornment,
  Paper,
  Popover,
  TextField
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { isEmpty, isNil } from 'lodash';
import { Moment } from 'moment-timezone';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  DateInput,
  DateRangePicker as AriaDateRangePicker,
  DateSegment,
  Group,
  I18nProvider
} from 'react-aria-components';
import { Calendar, X, XCircle } from 'react-feather';
import { css, styled } from '../../emotion';
import { useDialogState } from '../../hooks/useDialogState';
import { FlexContainer } from '../../layout/Flex';
import { useCurrentUser } from '../../services/currentUser';
import { prettifyDateRange } from '../../services/time';
import { DateRangePicker as CalendarDateRangePicker } from '../TimeframePicker/DateRangePicker';
import {
  momentsToRange,
  rangeToMoments,
  toEndOfDay,
  toStartOfDay,
  toZonedDateTime
} from '../TimeframePicker/service/conversion';
import {
  CalendarDateRange,
  DateTimeRange
} from '../TimeframePicker/service/range';

const Picker: React.FC<{
  value: MomentsRange | null;
  onSelect: (range: MomentsRange | null) => void;
  cancel: () => void;
}> = ({ value, onSelect, cancel }) => {
  const { tz } = useCurrentUser();
  const [range, setRange] = useState(() => value && momentsToRange(value));
  const apply = useCallback(
    (range: CalendarDateRange | null) => {
      onSelect(
        range
          ? rangeToMoments(
              {
                start: toStartOfDay(range.start, tz),
                end: toEndOfDay(range.end, tz)
              },
              tz
            )
          : null
      );
    },
    [onSelect, tz]
  );

  return (
    <Paper
      className={css((t) => ({
        padding: t.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        gap: t.spacing(2)
      }))}
    >
      <CalendarDateRangePicker value={range} onChange={setRange} />
      <FlexContainer
        className={css(() => ({
          alignSelf: 'flex-end'
        }))}
      >
        <Button onClick={cancel}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => apply(range)}
        >
          Apply timeframe
        </Button>
      </FlexContainer>
    </Paper>
  );
};

interface MomentsRange {
  start: Moment;
  end: Moment;
}

type DateRangePickerProps = {
  value: MomentsRange | null;
  onChange: (value: MomentsRange | null) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  fullWidth?: boolean;
  hasError?: boolean;
};

const AriaStylesContainer = styled('div')`
  .react-aria-DateRangePicker {
    color: ${(p) => p.theme.palette.text.primary};
    font-size: 1rem;
    line-height: normal;

    .react-aria-Group {
      display: flex;
      gap: ${(p) => p.theme.spacing(1)}px;
      width: 100%;
      align-items: center;
      box-sizing: border-box;
      overflow: auto;
      position: relative;
      padding: 17px 14px;
      border: 1px solid rgb(0 0 0 / 6%);
      border-radius: 8px;
      background: ${(p) => p.theme.palette.common.white};
      white-space: nowrap;

      &[data-pressed] {
        box-shadow: none;
        background: ${(p) => p.theme.palette.grey.A100};
      }

      &[data-invalid] {
        // YUCK!
        outline: 2px solid ${(p) => p.theme.palette.error.main} !important;
        outline-offset: -1px;
      }

      &[data-focus-within]:not([data-readonly='true']) {
        outline: 2px solid ${(p) => p.theme.palette.primary.main};
        outline-offset: -1px;
      }
    }

    .react-aria-DateInput {
      display: flex;
      width: unset;
      min-width: unset;
      padding: unset;
      border: unset;
      outline: unset;

      [data-focused]:not([aria-readonly='true']) {
        background-color: ${(p) => p.theme.custom.colors.primary.main};
        outline: 1px solid ${(p) => p.theme.custom.colors.primary.main};
        color: ${(p) => p.theme.custom.colors.primary.light};
        border-radius: 4px;
      }
    }
  }
`;

export const DateTimeRangePicker: React.FC<DateRangePickerProps> = ({
  value,
  onChange,
  onFocus,
  onBlur
}) => {
  const { tz } = useCurrentUser();
  const { dialogOpen, closeDialog, openDialog } = useDialogState(false);
  const ref = useRef(null);
  const range = useMemo<DateTimeRange | null>(() => {
    return (
      value && {
        start: toZonedDateTime(value.start),
        end: toZonedDateTime(value.end)
      }
    );
  }, [value]);
  const setRange = useCallback(
    (range: DateTimeRange | null) => {
      onChange(range ? rangeToMoments(range, tz) : null);
    },
    [onChange, tz]
  );

  const onSave = useCallback(
    (range: MomentsRange | null) => {
      onChange(range);
      closeDialog();
    },
    [closeDialog, onChange]
  );

  return (
    <I18nProvider locale="en-US">
      <AriaStylesContainer>
        <AriaDateRangePicker
          ref={ref}
          value={range}
          onChange={setRange}
          onBlur={onBlur}
          onFocus={onFocus}
          granularity="minute"
          hideTimeZone
        >
          <Group>
            <DateInput slot="start">
              {(segment) => <DateSegment segment={segment} />}
            </DateInput>
            <span aria-hidden>&ndash;</span>
            <DateInput slot="end">
              {(segment) => <DateSegment segment={segment} />}
            </DateInput>
            <Tooltip title="Pick date">
              <ButtonBase
                onClick={openDialog}
                className={css(() => ({ marginLeft: 'auto' }))}
              >
                <Calendar size={14} />
              </ButtonBase>
            </Tooltip>
            <Tooltip title="Clear date">
              <ButtonBase onClick={() => onChange(null)}>
                <XCircle size={14} />
              </ButtonBase>
            </Tooltip>
          </Group>
        </AriaDateRangePicker>
        <Popover
          open={dialogOpen}
          onClose={closeDialog}
          anchorEl={ref.current}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          style={{
            marginTop: 4,
            zIndex: 99998
          }}
        >
          <Picker value={value} onSelect={onSave} cancel={closeDialog} />
        </Popover>
      </AriaStylesContainer>
    </I18nProvider>
  );
};

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  value,
  onChange,
  onBlur,
  onFocus,
  fullWidth,
  hasError
}) => {
  const { tz } = useCurrentUser();
  const { dialogOpen, closeDialog, openDialog } = useDialogState(false);
  const ref = useRef(null);
  const label = useMemo(() => {
    if (isNil(value) || isEmpty(value)) {
      return 'Not set';
    }
    return prettifyDateRange(value.start, value.end, tz);
  }, [tz, value]);

  const onSave = useCallback(
    (range: MomentsRange | null) => {
      onChange(range);
      closeDialog();
    },
    [closeDialog, onChange]
  );

  return (
    <I18nProvider locale="en-US">
      <TextField
        ref={ref}
        value={label}
        onClick={openDialog}
        variant="outlined"
        onFocus={onFocus}
        onBlur={onBlur}
        fullWidth={fullWidth}
        error={hasError}
        InputProps={{
          readOnly: true,
          className: css((t) => ({
            color: !value ? t.palette.text.secondary : t.palette.text.primary
          })),

          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                className={css((t) => ({
                  cursor: 'pointer',
                  color: t.palette.text.secondary
                }))}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onChange(null);
                }}
              >
                <X
                  size={16}
                  className={css((t) => ({
                    cursor: 'pointer',
                    color: t.palette.text.secondary
                  }))}
                />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <Popover
        open={dialogOpen}
        onClose={closeDialog}
        anchorEl={ref.current}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        style={{
          marginTop: 4,
          zIndex: 99998
        }}
      >
        <Picker value={value} onSelect={onSave} cancel={closeDialog} />
      </Popover>
    </I18nProvider>
  );
};
