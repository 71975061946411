import { AnalyticsFilterUI } from '../../../FilterUI';
import {
  FilterDefinition,
  PlatformFilterDef
} from '../../../../../../domainTypes/filters';
import { ChipContent, filterModeSuffix, formatList } from '../../common';
import { PlatformMenu, PlatformMenuForTransactions } from './PlatformMenu';
import { compact, isEmpty } from 'lodash';
import { getKnownPartnerForKey } from '../../../../../../services/partner';
import { modeToCondition } from '../../../useAnalyticsFilters';
import { AnalyticsFilter } from '../../../../../../domainTypes/analytics_v2';

const toPlatformAnalyticsFilter = (
  definition: FilterDefinition
): AnalyticsFilter[] => {
  if (definition.k !== 'platform') {
    return [];
  }
  return [
    {
      field: 'pk',
      condition: modeToCondition(definition.mode),
      values: definition.v
    }
  ];
};

const initPlatformFilter = (): FilterDefinition => ({
  k: 'platform',
  v: []
});

const formatPk = (pk: string) =>
  isEmpty(pk) ? 'Unknown' : getKnownPartnerForKey(pk)?.name;

const PlatformChip = ({ definition }: { definition?: FilterDefinition }) => {
  if (!definition) return <>Platform</>;
  if (definition.k !== 'platform') {
    throw new Error('Invalid definition');
  }
  const platforms = compact(definition.v.map(formatPk));
  return (
    <ChipContent title={`Platform ${filterModeSuffix(definition.mode)}`}>
      {formatList(platforms)}
    </ChipContent>
  );
};

export const platformFilterUIDef: AnalyticsFilterUI = {
  type: 'analytics',
  dimension: 'platform',
  chip: PlatformChip,
  menu: function PlatformFilterUIMenu({ definition, ...props }) {
    return (
      <PlatformMenu definition={definition as PlatformFilterDef} {...props} />
    );
  },
  init: initPlatformFilter,
  toClauses: toPlatformAnalyticsFilter
};

export const platformFilterForTransactionsUIDef: AnalyticsFilterUI = {
  type: 'analytics',
  dimension: 'platform',
  chip: PlatformChip,
  menu: function PlatformFilterUIMenu({ definition, ...props }) {
    return (
      <PlatformMenuForTransactions
        definition={definition as PlatformFilterDef}
        {...props}
      />
    );
  },
  init: initPlatformFilter,
  toClauses: toPlatformAnalyticsFilter
};
