import {
  DrawerOptions,
  useFilterDrawerState,
  useFiltersDrawer,
  useFiltersUrlParam
} from './hooks';
import { ComponentProps, useMemo } from 'react';
import {
  FilterUI,
  FilterUIMenuProps,
  ProductCatalogFilterUI,
  ProductCatalogFilterUIContext
} from './FilterUI';
import { ProductCatalogFilter } from '../../../domainTypes/productCatalog';
import { FiltersDrawerWithDefaultTree } from './Drawer/FiltersDrawer';
import { FiltersToggleButton } from './Toggle';
import { FiltersDefinition } from '../../../domainTypes/filters';

interface ProductCatalogFiltersProps {
  filters: ProductCatalogFilter[];
  drawerProps: ComponentProps<typeof FiltersDrawerWithDefaultTree>;
  toggleProps: ComponentProps<typeof FiltersToggleButton>;
}

interface QueryParts {
  filters: Array<ProductCatalogFilter>;
}

const useProductCatalogContext = (
  partial: Partial<QueryParts> = {}
): ProductCatalogFilterUIContext => {
  return useMemo(
    () => ({
      baseQuery: {
        filters: partial.filters ?? []
      }
    }),
    [partial]
  );
};

// TODO: better name
export function useProductCatalogFiltersInner(
  filters: FiltersDefinition,
  setFilters: (filters: FiltersDefinition) => void,
  uis: Array<ProductCatalogFilterUI>,
  queryParts: Partial<QueryParts> = {},
  drawerOptions?: DrawerOptions
): ProductCatalogFiltersProps {
  const { isOpen, toggle } = useFilterDrawerState(drawerOptions);
  const filtersDrawerProps = useFiltersDrawer(filters, setFilters);

  const baseContext = useProductCatalogContext(queryParts);

  const filterClausesWithDimensions = useMemo(() => {
    return filters.flatMap((definition) => {
      const dimension = definition.k;
      const ui = uis.find((ui) => ui.dimension === dimension);
      if (!ui) return [];
      return {
        dimension,
        clauses: ui.toClauses(definition)
      };
    });
  }, [filters, uis]);

  const filterClauses = useMemo(() => {
    return filterClausesWithDimensions.flatMap(({ clauses }) => clauses);
  }, [filterClausesWithDimensions]);

  const filterUIs = useMemo<FilterUI[]>(() => {
    return uis.map((ui: ProductCatalogFilterUI) => {
      const Menu = ui.menu;
      const otherFilterClauses = filterClausesWithDimensions
        .filter(({ dimension }) => dimension !== ui.dimension)
        .flatMap(({ clauses }) => clauses);
      const filters = [...baseContext.baseQuery.filters, ...otherFilterClauses];
      const context = {
        ...baseContext,
        baseQuery: { ...baseContext.baseQuery, filters }
      };
      return {
        ...ui,
        menu: (props: FilterUIMenuProps) => (
          <Menu {...props} context={context} />
        )
      };
    });
  }, [baseContext, filterClausesWithDimensions, uis]);

  return useMemo(
    () => ({
      filters: filterClauses,
      drawerProps: {
        ...filtersDrawerProps,
        uis: filterUIs,
        filters,
        isOpen
      },
      toggleProps: { isOpen, toggle, filters }
    }),
    [filterClauses, filterUIs, filters, filtersDrawerProps, isOpen, toggle]
  );
}

export function useProductCatalogFilters(
  uis: Array<ProductCatalogFilterUI>,
  queryParts: Partial<QueryParts> = {},
  drawerOptions?: DrawerOptions
): ProductCatalogFiltersProps {
  const [filters, setFilters] = useFiltersUrlParam();
  return useProductCatalogFiltersInner(
    filters,
    setFilters,
    uis,
    queryParts,
    drawerOptions
  );
}
