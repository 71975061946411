import { css, styled } from '../../../../emotion';
import { Badge, Button } from '@material-ui/core';
import React from 'react';
import { Filter } from 'react-feather';
import Typography from '@material-ui/core/Typography';
import { FilterDefinition } from '../../../../domainTypes/filters';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

interface Props {
  isOpen: boolean;
  toggle: () => void;
  filters: FilterDefinition[];
}

const SmallBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    padding: '0 4px',
    right: -12,
    height: 16,
    minWidth: 16
  }
}));

const ToggleButton = styled(Button)(() => ({
  paddingRight: '0'
}));

export const FiltersToggleButton: React.FC<Props> = ({
  isOpen,
  toggle,
  filters
}) => (
  <ToggleButton
    startIcon={<Filter size={16} color="rgba(0, 0, 0, 0.54)" />}
    onClick={toggle}
    className={css((t) => ({
      paddingRight:
        filters.length > 0 ? `${t.spacing(3)}px` : `${t.spacing(1)}px`
    }))}
  >
    <Typography variant="body2" color="textSecondary">
      {isOpen ? 'Hide filters' : 'Filters'}
    </Typography>
    <SmallBadge
      badgeContent={filters.length}
      color="error"
      invisible={filters.length === 0}
    />
  </ToggleButton>
);

export const FiltersToggleIconButton: React.FC<Props> = ({
  isOpen,
  toggle
}) => (
  <Tooltip title={isOpen ? 'Hide filters' : 'Filters'} placement="right">
    <IconButton onClick={toggle}>
      <Filter size={16} color="rgba(0, 0, 0, 0.54)" />
    </IconButton>
  </Tooltip>
);
