import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { TableCellProps } from '@material-ui/core/TableCell';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { AdvertiserWithColor } from '../../../../components/AdvertiserWithColor';
import { formatChartCurrency } from '../../../../components/Charts/Util';
import { Loader } from '../../../../components/Loader';
import { Truncated } from '../../../../components/Truncated';
import { UserAvatarGroup } from '../../../../components/UserAvatars';
import { WithHoverIndicator } from '../../../../components/WithHoverIndicator';
import { css, styled } from '../../../../emotion';
import { useRoutes, useStringQueryParam } from '../../../../routes';
import { useMappedLoadingValue } from '../../../../services/db';
import { useTrackMixpanelView } from '../../../../services/mixpanel';
import { useSpaceCurrency } from '../../../../services/useSpaceCurrency';
import { CampaignsPageBody } from '../../components/CampaignsPageBody';
import { useManualCampaigns } from '../../service';
import { SearchInput } from '../../../../components/SearchInput';
import { FlexContainer } from '../../../../layout/Flex';
import { useCurrentUserScopes } from '../../../../services/currentUser';
import { NoPermissions } from '../../../../components/NoPermissions';
import {
  ManualCampaign,
  CompletedManualCampaign
} from '../../service/manual-campaign';
import React from 'react';
import {
  calculateEarningsGoal,
  getFlatSpendAmount
} from '../../service/goals-and-incentives';

const isCompleted = (
  campaign: ManualCampaign
): campaign is CompletedManualCampaign => campaign.status === 'completed';

const matchesSearch = (c: CompletedManualCampaign, search: string) => {
  if (!search) return true;
  return (
    c.name.toLowerCase().includes(search) ||
    c.advertisers.some((a) => a.name.toLowerCase().includes(search))
  );
};

const RowCell: React.FC<TableCellProps> = ({ children, ...props }) => (
  <TableCell
    {...props}
    className={css(() => ({
      borderBottom: 'none'
    }))}
  >
    {children}
  </TableCell>
);

const HeaderCell: React.FC<TableCellProps> = ({ children, ...props }) => (
  <TableCell
    {...props}
    className={css((t) => ({
      borderBottom: 'none',
      fontSize: t.typography.caption.fontSize,
      color: t.palette.text.secondary
    }))}
  >
    {children}
  </TableCell>
);

const PaddedPaper = styled(Paper)`
  padding: ${({ theme }) => theme.spacing(1)}px
    ${({ theme }) => theme.spacing(0)}px;
`;

const Body = ({ campaigns }: { campaigns: CompletedManualCampaign[] }) => {
  const { ROUTES } = useRoutes();
  const currency = useSpaceCurrency();
  return (
    <PaddedPaper>
      <Table size="small">
        <TableHead>
          <TableRow>
            <HeaderCell>Name</HeaderCell>
            <HeaderCell>Advertisers</HeaderCell>
            <HeaderCell>Flat spend</HeaderCell>
            <HeaderCell>Goal</HeaderCell>
            <HeaderCell>Timeframe</HeaderCell>
            <HeaderCell>Managers</HeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {campaigns.map((campaign) => {
            const goal = calculateEarningsGoal(campaign);
            const flatSpend = getFlatSpendAmount(campaign);
            return (
              <TableRow key={campaign.id}>
                <RowCell>
                  <div className={css(() => ({ width: 180 }))}>
                    <WithHoverIndicator>
                      <Link
                        to={ROUTES.campaigns.manual.report.url(campaign.id)}
                      >
                        <Truncated title={campaign.name} />
                      </Link>
                    </WithHoverIndicator>
                  </div>
                </RowCell>
                <RowCell>
                  {campaign.advertisers.map((advertiser) => (
                    <AdvertiserWithColor
                      key={advertiser.advertiserId}
                      advertiserName={advertiser.name}
                      partnerKey={advertiser.partnerKey}
                    />
                  ))}
                </RowCell>
                <RowCell>
                  {formatChartCurrency(flatSpend, currency, {
                    excludeCents: true
                  })}
                </RowCell>
                <RowCell>
                  {formatChartCurrency(goal ?? 0, currency, {
                    excludeCents: true
                  })}
                </RowCell>
                <RowCell>
                  {campaign.timeframe.start.format('MMM DD')} -{' '}
                  {campaign.timeframe.end.format('MMM DD, YYYY')}
                </RowCell>
                <RowCell>
                  <UserAvatarGroup
                    spaceId={campaign.spaceId}
                    userIds={campaign.managers}
                    max={3}
                  />
                </RowCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </PaddedPaper>
  );
};

export const CampaignsComplete: React.FC = () => {
  const scopes = useCurrentUserScopes();
  const canView = scopes.has('campaigns.view');
  useTrackMixpanelView('view_campaigns_completed');
  const [search, setSearch] = useStringQueryParam('q');
  const [campaigns, loading] = useMappedLoadingValue(
    useManualCampaigns(),
    (campaigns) =>
      campaigns.filter(
        // This isn't as tidy as before but probably inevitable
        // as we will need some filtering no matter what?
        (c): c is CompletedManualCampaign =>
          isCompleted(c) && matchesSearch(c, search)
      )
  );

  return (
    <>
      <Helmet>
        <title>Completed campaigns | Affilimate</title>
      </Helmet>
      <CampaignsPageBody>
        <FlexContainer alignItems="center" marginBottom={2} marginTop={3}>
          <Typography component="h1" variant="h6">
            <strong>Completed campaigns</strong>
          </Typography>
          <SearchInput
            value={search}
            onChange={setSearch}
            placeholder="Search campaigns"
            width={300}
          />
        </FlexContainer>
        {!canView ? (
          <NoPermissions />
        ) : loading || !campaigns ? (
          <Loader size={36} height={500} />
        ) : (
          <Body campaigns={campaigns} />
        )}
      </CampaignsPageBody>
    </>
  );
};
