import {
  Bar,
  Cell,
  ComposedChart,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import {
  entries,
  fromPairs,
  groupBy,
  keys,
  mapValues,
  sum,
  values
} from 'lodash';
import moment, { Moment } from 'moment-timezone';
import { css } from '../../../../../emotion';
import React, { useMemo } from 'react';
import { Card } from '@material-ui/core';
import { useSpaceCurrency } from '../../../../../services/useSpaceCurrency';
import { formatChartCurrency } from '../../../../../components/Charts/Util';
import { ISOTimeRange } from '../../../../../domainTypes/analytics_v2';
import { useTypedStringQueryParam } from '../../../../../routes';
import { FlexContainer } from '../../../../../layout/Flex';
import Typography from '@material-ui/core/Typography';
import { getStableRandomColor } from '../../../../../services/color';

import { TeamWithCampaigns } from '../../../service/teams-report';
import { CampaignReportGranularity } from '../../../service/reports';
import {
  CampaignReportAggregation,
  CampaignReportAggregationModeSelector
} from './CampaignReportAggregationModeSelector';
import { TeamsReportTooltip } from './TeamsReportTooltip';
import { CustomDot, CustomDotProps } from './CustomDot';
import { IForecast } from '../../../../../domainTypes/teams';
import { generateRangeOfMoments } from '../../../../../services/time';

interface BaseData {
  timestamp: string;
  totalForecast: number | null;
  byTeam: Record<
    string,
    {
      flatSpend: number;
      forecast: number;
    }
  >;
}

function groupByUniq<T, K extends keyof T>(
  collection: Array<T>,
  key: K
): Record<string, T> {
  return mapValues(groupBy(collection, key), (t) => t[0]);
}

function createDict<T>(
  keys: string[],
  fn: (key: string) => T
): Record<string, T> {
  return fromPairs(keys.map((k) => [k, fn(k)]));
}

const aggregate = (
  baseData: Array<BaseData>,
  aggregation: CampaignReportAggregation,
  granularity: CampaignReportGranularity,
  teamIds: string[]
): Array<BaseData> => {
  switch (aggregation) {
    case 'cum':
      return cumulativeSumsOfFlatSpendAndForecasts(
        baseData,
        teamIds,
        granularity
      );
    case 'none':
      return baseData;
  }
};

const cumulativeSumsOfFlatSpendAndForecasts = (
  baseData: Array<BaseData>,
  teamIds: string[],
  granularity: CampaignReportGranularity
): Array<BaseData> => {
  let forecastSum = 0;
  const flatSpendSumsByTeam = createDict(teamIds, () => 0);
  const forecastSumsByTeam = createDict(teamIds, () => 0);

  return baseData.map(({ timestamp, totalForecast, byTeam }) => {
    const newTotalForecast = totalForecast
      ? (forecastSum += totalForecast)
      : totalForecast;
    const shouldIncreaseForecast = moment(timestamp)
      .startOf(granularity)
      .isSame(moment(timestamp).startOf('quarter'));

    const newByTeam = createDict(teamIds, (k) => {
      const flatSpend = byTeam[k]?.flatSpend ?? 0;
      flatSpendSumsByTeam[k] += flatSpend;
      const forecast = byTeam[k]?.forecast ?? 0;
      forecastSumsByTeam[k] += shouldIncreaseForecast ? forecast : 0;
      return {
        flatSpend: flatSpendSumsByTeam[k],
        forecast: forecastSumsByTeam[k]
      };
    });

    return {
      timestamp,
      totalForecast: newTotalForecast,
      byTeam: newByTeam
    };
  });
};

type Quarter = keyof IForecast['amounts'];

const roundToQuarter = (ts: Moment, granularity: CampaignReportGranularity) => {
  return ts.clone().endOf('quarter').startOf(granularity).toISOString();
};

const toQuarterTimestamp = (
  year: number,
  quarter: Quarter,
  granularity: CampaignReportGranularity
) => {
  const quarterNumber = parseInt(quarter.match(/q(\d)/)?.[1] as string, 10);
  let quarterMoment = moment().year(year).quarter(quarterNumber);
  return roundToQuarter(quarterMoment, granularity);
};

const sumValues = (data: Record<string, number>) => sum(values(data));

type DataPoint = {
  timestamp: string;
  amount: number | null;
  teamId: string;
};

const groupByTimestampByTeam = (
  data: Array<DataPoint>
): Record<string, Record<string, number>> => {
  const byTimestamp = groupBy(data, 'timestamp');

  return mapValues(byTimestamp, (timestampData) => {
    const timestampDataByTeam = groupByUniq(timestampData, 'teamId');
    return mapValues(timestampDataByTeam, (data) => data.amount ?? 0);
  });
};

const getForecastsByTimestampByTeam = (
  data: TeamWithCampaigns[],
  timeframe: ISOTimeRange,
  granularity: CampaignReportGranularity
): Record<string, Record<string, number>> => {
  const year = moment(timeframe.start).year();

  const forecasts = data.flatMap((d) => {
    const forecast = d.team.forecasts.find((f) => f.year === year);
    if (!forecast) return [];
    const teamId = d.team.teamId;
    return entries(forecast.amounts).map(([quarter, amount]) => {
      const timestamp = toQuarterTimestamp(
        year,
        quarter as Quarter,
        granularity
      );
      return {
        timestamp,
        amount,
        teamId
      };
    });
  });

  return groupByTimestampByTeam(forecasts);
};

const getPerformanceByTimestampByTeam = (
  data: TeamWithCampaigns[]
): Record<string, Record<string, number>> => {
  const rawPerformanceSeries = data.flatMap((d) =>
    d.campaigns.flatMap((c) =>
      c.series.map((s) => ({ ...s, teamId: d.team.teamId }))
    )
  );

  return groupByTimestampByTeam(rawPerformanceSeries);
};

const getTotalForecast = (
  forecastsByTsByTeam: Record<string, Record<string, number>>,
  timestamp: string,
  isFirst: boolean
) => {
  if (isFirst) return 0;
  const timestampForecasts = forecastsByTsByTeam[timestamp];
  if (!timestampForecasts) return null;
  return sumValues(timestampForecasts);
};

const formatChartData = (
  timeframe: ISOTimeRange,
  granularity: 'month',
  data: Array<TeamWithCampaigns>,
  teamIds: string[],
  aggregation: 'none' | 'cum'
): Array<BaseData> => {
  const timestamps = generateRangeOfMoments(
    moment(timeframe.start),
    moment(timeframe.end),
    granularity
  );

  const forecastsByTsByTeam = getForecastsByTimestampByTeam(
    data,
    timeframe,
    granularity
  );
  const performanceByTsByTeam = getPerformanceByTimestampByTeam(data);

  const baseData = timestamps.map((ts, index) => {
    const isFirst = index === 0;
    const timestamp = ts.toISOString();

    const quarterTimestamp = roundToQuarter(ts, granularity);

    const byTeam = createDict(teamIds, (teamId) => {
      const flatSpend = performanceByTsByTeam[timestamp]?.[teamId] ?? 0;
      const forecast = forecastsByTsByTeam[quarterTimestamp]?.[teamId] ?? 0;
      return { flatSpend, forecast };
    });

    const totalForecast = getTotalForecast(
      forecastsByTsByTeam,
      timestamp,
      isFirst
    );
    return {
      timestamp,
      byTeam,
      totalForecast
    };
  });

  return aggregate(baseData, aggregation, granularity, teamIds);
};

export const CampaignTeamsTimeseries: React.FC<{
  timeframe: ISOTimeRange;
  data: Array<TeamWithCampaigns>;
  granularity: CampaignReportGranularity;
}> = ({ data, timeframe, granularity }) => {
  const [aggregation, setAggregation] = useTypedStringQueryParam<
    CampaignReportAggregation
  >('agg', 'cum');
  const currency = useSpaceCurrency();

  const teamsById = useMemo(() => {
    const teams = data.map((d) => d.team);
    return groupByUniq(teams, 'teamId');
  }, [data]);

  const teamIds = useMemo(() => keys(teamsById), [teamsById]);

  const chartData = useMemo(
    () => formatChartData(timeframe, granularity, data, teamIds, aggregation),
    [aggregation, data, granularity, teamIds, timeframe]
  );

  return (
    <Card
      className={css((t) => ({
        paddingTop: t.spacing(4)
      }))}
    >
      <FlexContainer justifyContent="space-between">
        <div>
          <Typography
            variant="body1"
            className={css(() => ({
              fontWeight: 700
            }))}
          >
            Forecast vs. Actual,{' '}
            {aggregation === 'cum' ? 'cumulative' : 'monthly'}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            Team flat spend forecast vs. actual performance anchored to your
            campaign dates.
          </Typography>
        </div>
        <CampaignReportAggregationModeSelector
          value={aggregation}
          onChange={setAggregation}
        />
      </FlexContainer>
      <ResponsiveContainer width="99%" height={400}>
        <ComposedChart data={chartData} margin={{ top: 10 }}>
          <XAxis
            xAxisId="timestamp"
            dataKey="timestamp"
            tickFormatter={(ts) => moment(ts).format('MMM')}
            tick={{
              fill: '#9b9b9b',
              transform: 'translate(0, 8)',
              fontSize: 14
            }}
            tickSize={0}
            stroke="cwBBB"
          />
          <YAxis
            yAxisId="performance"
            width={100}
            orientation="right"
            tick={{
              fill: '#9b9b9b',
              fontSize: 14
            }}
            tickSize={0}
            stroke="cwBBB"
            tickFormatter={(v) =>
              formatChartCurrency(v, currency, { excludeCents: true })
            }
          />
          {teamIds.map((teamId) => (
            <Bar
              isAnimationActive={false}
              key={teamId}
              dataKey={(d) => d.byTeam[teamId]?.flatSpend ?? 0}
              name={teamId}
              yAxisId="performance"
              xAxisId="timestamp"
              stackId="a"
              fill={getStableRandomColor(teamsById[teamId].teamId)}
            >
              {chartData.map((d) => {
                const isInFuture = moment(d.timestamp).isAfter(
                  moment().startOf(granularity)
                );
                return (
                  <Cell key={d.timestamp} opacity={isInFuture ? 0.7 : 1} />
                );
              })}
            </Bar>
          ))}
          <Line
            isAnimationActive={false}
            type="monotone"
            yAxisId="performance"
            xAxisId="timestamp"
            dataKey="totalForecast"
            stroke="#000"
            strokeWidth={1.4}
            strokeDasharray="2, 5"
            connectNulls
            activeDot={false}
            dot={(props: CustomDotProps) => (
              <CustomDot {...props} granularity={granularity} />
            )}
          />
          <ReferenceLine
            y={0}
            yAxisId="performance"
            xAxisId="timestamp"
            stroke="#bbb"
          />
          <Tooltip
            cursor={false}
            separator=": "
            content={<TeamsReportTooltip teamsById={teamsById} />}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Card>
  );
};
