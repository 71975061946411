import { Typography } from '@material-ui/core';
import { compact, uniq } from 'lodash';
import React, { useMemo } from 'react';
import { ArrowDown, ChevronDown, Grid, Search } from 'react-feather';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { ChartMode } from '../../../../../../components/Charts/EarningsChartCard/ChartModeSelector';
import { EarningsChartCardWithoutDataV2 } from '../../../../../../components/Charts/EarningsChartCardV2';
import { PlatformWithColor } from '../../../../../../components/PlatformWithColor';
import { SearchInput } from '../../../../../../components/SearchInput';
import {
  SingleSelector,
  SingleSelectorOption
} from '../../../../../../components/SingleSelector';
import {
  AnalyticsFilter,
  AnalyticsQuery,
  ISOTimeRange
} from '../../../../../../domainTypes/analytics_v2';
import { CurrencyCode } from '../../../../../../domainTypes/currency';
import { EMPTY_ARR } from '../../../../../../domainTypes/emptyConstants';
import { ISpace } from '../../../../../../domainTypes/space';
import { styled } from '../../../../../../emotion';
import {
  DEFAULT_OFFSET,
  PageToolbar,
  PageToolbarOtherRow,
  PageToolbarSection
} from '../../../../../../layout/PageToolbar';
import { Section } from '../../../../../../layout/Section';
import {
  useNullableStringSetQueryParam,
  useRoutes,
  useStringQueryParam,
  useTypedStringQueryParam
} from '../../../../../../routes';
import { useAnalyticsQueryV2 } from '../../../../../../services/analyticsV2/query';
import { useCurrentUser } from '../../../../../../services/currentUser';
import { useMappedLoadingValue } from '../../../../../../services/db';
import { getKnownPartnerForKeyUnsafe } from '../../../../../../services/partner';
import {
  AdvertiserGrouper,
  productGrouperToEnglish,
  productGrouperToEnglishHeading
} from '../../../../services/advertisers';
import { AdvertiserSummaryCardsV2 } from './components/AdvertiserSummaryCard';
import { TopAdvertiserGrouperChartCardV2 } from './components/TopAdvertiserGrouperChartCardV2';
import { TopAdvertiserGrouperSection } from './components/TopAdvertiserGrouperSection';
import { TopPagesSection } from './components/TopPagesSection';
import { SortByKey } from './types';
import {
  TimeframePicker,
  useTimeframe
} from '../../../../../../components/analytics_v2/Timeframe';
import { toClickhouseOrderByField } from './service';
import { useIntervalSelectorState } from '../../../../../../components/IntervalSelector';
import { FiltersToggleButton } from '../../../../../../components/analytics_v2/Filters/Toggle';
import { FiltersDrawerWithDefaultTree } from '../../../../../../components/analytics_v2/Filters/Drawer/FiltersDrawer';
import { useAnalyticsFilters } from '../../../../../../components/analytics_v2/Filters/useAnalyticsFilters';
import { tagFilterUIDef } from '../../../../../../components/analytics_v2/Filters/FilterUI/analytics/TagFilterUI';
import { channelFilterUIDef } from '../../../../../../components/analytics_v2/Filters/FilterUI/analytics/ChannelFilterUI';
import { integrationIDFilterUIDef } from '../../../../../../components/analytics_v2/Filters/FilterUI/analytics/IntegrationIDFilterUI';
import { FILTER_STATE_LOCAL_STORAGE_KEYS } from '../../../../../../components/analytics_v2/Filters/Drawer/keys';

const SearchInfo = styled('div')`
  background-color: #e5e5e5;
  color: ${({ theme }) => theme.palette.grey[600]};
  padding: ${({ theme }) => theme.spacing(0.5)}px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  font-size: 12px;
  text-align: center;
`;

const TopGrid = styled('div')`
  display: grid;
  grid-template-columns: 1.5fr 2fr;
  min-height: 490px;
  grid-column-gap: ${({ theme }) => theme.spacing(2)}px;
  grid-row-gap: ${({ theme }) => theme.spacing(1)}px;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    grid-template-columns: 1fr;
  }
`;

const SearchAndPaginationWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)}px;
`;

const GROUPER_OPTIONS: {
  [key in AdvertiserGrouper]: SingleSelectorOption<AdvertiserGrouper>;
} = {
  partner_product_name: {
    value: 'partner_product_name',
    label: 'Product name',
    searchValue: 'Product name'
  },
  partner_product_id: {
    value: 'partner_product_id',
    label: 'Product ID',
    searchValue: 'Product ID'
  },
  partner_product_category: {
    value: 'partner_product_category',
    label: 'Category',
    searchValue: 'Category'
  },
  seller: {
    value: 'seller',
    label: 'Seller',
    searchValue: 'Seller'
  },
  brand: {
    value: 'brand',
    label: 'Brand',
    searchValue: 'Brand'
  }
};

export const Body = ({
  space,
  currency,
  partnerKeys,
  orderBy,
  setOrderBy,
  groupedBy,
  setGroupedBy,
  advertiserNames,
  search,
  range,
  filters: filterClauses,
  compareRange
}: {
  space: ISpace;
  currency: CurrencyCode;
  orderBy: SortByKey;
  setOrderBy: (orderBy: SortByKey) => void;
  groupedBy: AdvertiserGrouper;
  setGroupedBy: (groupBy: AdvertiserGrouper) => void;
  partnerKeys: Set<string>;
  advertiserNames: Set<string>;
  search: string;
  range: ISOTimeRange;
  filters: AnalyticsFilter[];
  compareRange: ISOTimeRange | null;
}) => {
  const spaceId = space.id;
  const stablePartnerKeys = useMemo(() => [...partnerKeys].sort(), [
    partnerKeys
  ]);
  const stableAdvertiserNames = useMemo(() => [...advertiserNames].sort(), [
    advertiserNames
  ]);

  const [chartMode, setChartMode] = useTypedStringQueryParam<ChartMode>(
    'chart_mode',
    'barChart'
  );

  const commonQ = useMemo<
    Pick<AnalyticsQuery, 'range' | 'filters' | 'search'>
  >(() => {
    const filters: AnalyticsFilter[] = [
      ...filterClauses,
      {
        field: 'advertiser_name',
        condition: 'in',
        values: stableAdvertiserNames
      },
      {
        field: 'pk',
        condition: 'in',
        values: stablePartnerKeys
      }
    ];
    return {
      range,
      filters,
      search: search
        ? [
            {
              pattern: `%${search}%`,
              in: compact([
                'partner_product_name',
                groupedBy === 'partner_product_id' && 'partner_product_id',
                groupedBy === 'partner_product_category' &&
                  'partner_product_category',
                groupedBy === 'seller' && 'seller',
                groupedBy === 'brand' && 'brand'
              ])
            }
          ]
        : undefined
    };
  }, [
    filterClauses,
    stableAdvertiserNames,
    stablePartnerKeys,
    range,
    search,
    groupedBy
  ]);
  const totalsQ = useMemo<AnalyticsQuery>(() => {
    return {
      ...commonQ,
      select: [
        'commission_sum_net',
        'order_count_net',
        'quantity_net',
        'gmv_sum_net'
      ]
    };
  }, [commonQ]);

  const oField = toClickhouseOrderByField(orderBy);

  const bestPageQ = useMemo<AnalyticsQuery>(() => {
    return {
      ...commonQ,
      select: uniq(['commission_sum_net', oField]),
      orderBy: [{ field: oField, direction: 'DESC' }],
      groupBy: ['page_url'],
      filters: [
        ...(commonQ.filters || []),
        {
          field: 'page_url',
          condition: 'not in',
          values: ['']
        }
      ],
      paginate: {
        limit: 1,
        page: 1
      }
    };
  }, [commonQ, oField]);

  const bestGroupQ = useMemo<AnalyticsQuery>(() => {
    return {
      ...commonQ,
      select: uniq(['commission_sum_net', oField]),
      orderBy: [{ field: oField, direction: 'DESC' }],
      columnTransformers: [
        {
          field: groupedBy,
          mapTo: 'Unknown',
          condition: 'in',
          values: ['']
        }
      ],
      groupBy: [groupedBy],
      paginate: {
        limit: 1,
        page: 1
      }
    };
  }, [commonQ, groupedBy, oField]);

  const totalsLv = useMappedLoadingValue(
    useAnalyticsQueryV2(spaceId, totalsQ),
    (r) => {
      const x = r.rows[0];
      return {
        commission_sum_net: x.data.commission_sum_net?.curr || 0,
        gmv_sum_net: x.data.gmv_sum_net?.curr || 0,
        order_count_net: x.data.order_count_net?.curr || 0,
        quantity_net: x.data.quantity_net?.curr || 0
      };
    }
  );
  const bestPageLv = useMappedLoadingValue(
    useAnalyticsQueryV2(spaceId, bestPageQ),
    (r) => {
      const x = r.rows[0];
      if (!x) {
        return null;
      }
      return {
        url: x.group['page_url'] || '',
        commission_sum_net: x.data.commission_sum_net?.curr || 0
      };
    }
  );
  const bestGroupLv = useMappedLoadingValue(
    useAnalyticsQueryV2(spaceId, bestGroupQ),
    (r) => {
      const x = r.rows[0];
      if (!x) {
        return null;
      }
      return {
        name: x.group[groupedBy] || '',
        commission_sum_net: x.data.commission_sum_net?.curr || 0
      };
    }
  );

  const intervalSelectorProps = useIntervalSelectorState();

  return (
    <>
      {search.length > 0 && (
        <SearchInfo>
          <Search size={12} /> These results apply your search term{' '}
          <strong>"{search}"</strong> to transactions with matching product
          names or the selected group.
        </SearchInfo>
      )}
      <Section>
        <AdvertiserSummaryCardsV2
          totalsLv={totalsLv}
          bestPageLv={bestPageLv}
          bestGroupLv={bestGroupLv}
          groupBy={groupedBy}
          orderBy={orderBy}
          currency={currency}
          advertiserNames={stableAdvertiserNames}
        />
        <PageToolbar>
          <SearchAndPaginationWrapper>
            <Typography variant="body1" component="h2">
              <strong>Product performance</strong>
            </Typography>
          </SearchAndPaginationWrapper>
        </PageToolbar>
        <TopGrid>
          <TopAdvertiserGrouperChartCardV2
            spaceId={spaceId}
            commonQ={commonQ}
            compareRange={compareRange}
            groupedBy={groupedBy}
            orderedBy={oField}
            currency={currency}
            limit={8}
          />

          <EarningsChartCardWithoutDataV2
            space={space}
            range={commonQ.range}
            interval={intervalSelectorProps.interval}
            setInterval={intervalSelectorProps.setInterval}
            intervalOptions={intervalSelectorProps.options}
            filters={commonQ.filters || EMPTY_ARR}
            search={commonQ.search || EMPTY_ARR}
            metric={
              oField === 'gmv_sum_net' ? 'gmv_sum_net' : 'commission_sum_net'
            }
            setMetric={(m) => {
              setOrderBy(m === 'gmv_sum_net' ? 'pt' : 'ct');
            }}
            chartMode={chartMode}
            setChartMode={setChartMode}
            currency={currency}
            graphMode={groupedBy}
            heading={productGrouperToEnglishHeading(groupedBy)}
            subHeading={
              oField === 'gmv_sum_net'
                ? 'Sales volume per product sold'
                : 'Earnings per product sold'
            }
          />
        </TopGrid>
      </Section>
      <TopAdvertiserGrouperSection
        spaceId={spaceId}
        commonQ={commonQ}
        compareRange={compareRange}
        groupedBy={groupedBy}
      />
      <PageToolbar>
        <div>
          <Typography variant="body1" component="h2">
            <strong>Content performance</strong>
          </Typography>
        </div>
      </PageToolbar>
      <TopPagesSection
        spaceId={spaceId}
        commonQ={commonQ}
        compareRange={compareRange}
      />
    </>
  );
};

const UIS = [tagFilterUIDef, channelFilterUIDef, integrationIDFilterUIDef];

export const PagePerformanceAdvertiserDetailsInner = () => {
  const [
    selectedPartners
    //setSelectedPartners,
    //toPartnerQueryParam
  ] = useNullableStringSetQueryParam('partners');
  const { ROUTES } = useRoutes();
  const [
    selectedAdvertisers
    //setSelectedAdvertisers,
    //toAdvertiserQueryParam
  ] = useNullableStringSetQueryParam('advertisers', '---');
  const { range, compare } = useTimeframe();
  const [search, setSearch] = useStringQueryParam('search');
  const [orderBy, setOrderBy] = useTypedStringQueryParam<SortByKey>(
    'orderBy',
    'ct'
  );
  const [groupedBy, setGroupedBy] = useTypedStringQueryParam<AdvertiserGrouper>(
    'productGroupBy',
    'partner_product_name'
  );
  const { space } = useCurrentUser();

  const orderByOptions = useMemo(() => {
    // Filter the options based the network
    const options: SingleSelectorOption<SortByKey>[] = compact([
      {
        value: 'ct',
        label: 'Earnings',
        searchValue: 'Earnings'
      },
      {
        value: 'pt',
        label: 'Sales volume',
        searchValue: 'Sales volume'
      },
      !selectedPartners?.has('amazon') && {
        value: 'oct',
        label: 'Order count',
        searchValue: 'Order count'
      },
      {
        value: 'qt',
        label: 'Quantity sold',
        searchValue: 'Quantity sold'
      }
    ]);
    return options;
  }, [selectedPartners]);

  const groupByOptions = useMemo(() => {
    const supportsCategory =
      selectedPartners &&
      ['impact', 'amazon', 'redventures'].some((x) => selectedPartners.has(x));
    const supportsSeller = selectedPartners && selectedPartners.has('amazon');
    const supportsBrand =
      selectedPartners &&
      ['rakuten', 'partnerize', 'cj'].some((x) => selectedPartners.has(x));
    return compact([
      GROUPER_OPTIONS.partner_product_name,
      GROUPER_OPTIONS.partner_product_id,
      supportsCategory && GROUPER_OPTIONS.partner_product_category,
      supportsSeller && GROUPER_OPTIONS.seller,
      supportsBrand && GROUPER_OPTIONS.brand
    ]);
  }, [selectedPartners]);

  const { filters, toggleProps, drawerProps } = useAnalyticsFilters(
    UIS,
    {
      orderBy: toClickhouseOrderByField(orderBy)
    },
    {
      localStorageKey: FILTER_STATE_LOCAL_STORAGE_KEYS.performance
    }
  );

  if (!selectedPartners || !selectedAdvertisers) {
    return <div>no partner selected</div>;
  }

  return (
    <>
      <Helmet>
        <title>{[...selectedAdvertisers].join(',')} | Affilimate</title>
      </Helmet>
      <PageToolbar sticky offset={DEFAULT_OFFSET} wrap>
        <PageToolbarSection flex={6} justifyContent="flex-start">
          <Typography
            variant="h6"
            component="h2"
            style={{ marginRight: '6px' }}
          >
            <strong>{[...selectedAdvertisers].join(',')}</strong>
          </Typography>
          {[...selectedPartners].map((partnerKey) => {
            const partner = getKnownPartnerForKeyUnsafe(partnerKey);
            return (
              <Link
                to={ROUTES.performanceNew.advertisers.overview.url({
                  filters: [
                    {
                      k: 'platform',
                      v: [partnerKey],
                      mode: 'in'
                    }
                  ]
                })}
              >
                <PlatformWithColor partner={partner} key={partnerKey} />
              </Link>
            );
          })}
          &nbsp;&nbsp;
          <SingleSelector
            value={groupedBy}
            onChange={setGroupedBy}
            legend="Group by"
            options={groupByOptions}
          >
            <Typography
              variant="body2"
              color="textSecondary"
              component="span"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '6px'
              }}
            >
              <Grid size={18} />{' '}
              {groupedBy ? (
                <>
                  Group <strong>{productGrouperToEnglish(groupedBy)}</strong>
                </>
              ) : (
                'No group'
              )}
              <ChevronDown size={18} />
            </Typography>
          </SingleSelector>
          <SingleSelector
            value={orderBy}
            onChange={setOrderBy}
            legend="Sort by"
            options={orderByOptions}
          >
            <Typography
              variant="body2"
              color="textSecondary"
              component="span"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '6px'
              }}
            >
              <ArrowDown size={18} /> Sort{' '}
              <strong>
                {orderByOptions.find((o) => o.value === orderBy)?.label}
              </strong>
              <ChevronDown size={18} />
            </Typography>
          </SingleSelector>
          <FiltersToggleButton {...toggleProps} />
        </PageToolbarSection>
        <PageToolbarSection flex={3} justifyContent="flex-end">
          <SearchInput
            value={search}
            onChange={setSearch}
            width={250}
            placeholder="Search transactions"
          />
          <TimeframePicker />
        </PageToolbarSection>
        <PageToolbarOtherRow>
          <FiltersDrawerWithDefaultTree marginTop={2} {...drawerProps} />
        </PageToolbarOtherRow>
      </PageToolbar>

      <Body
        space={space}
        range={range}
        compareRange={compare?.range ?? null}
        search={search}
        orderBy={orderBy}
        filters={filters}
        groupedBy={groupedBy}
        setOrderBy={setOrderBy}
        setGroupedBy={setGroupedBy}
        partnerKeys={selectedPartners}
        advertiserNames={selectedAdvertisers}
        currency={space.config.currency}
      />
    </>
  );
};
