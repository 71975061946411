import {
  Button,
  Collapse,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { groupBy, orderBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import {
  AlignLeft,
  Check,
  ChevronDown,
  ChevronUp,
  ExternalLink,
  Minus as Dash,
  Search as IconSearch,
  Table,
  X
} from 'react-feather';
import {
  AdvertiserRate,
  CachedAdvertiser
} from '../../../../../domainTypes/advertiser';
import { COLORS } from '../../../../../domainTypes/colors';
import { Doc } from '../../../../../domainTypes/document';
import { GeneratedLink } from '../../../../../domainTypes/linkGenerator';
import { PARTNERS } from '../../../../../domainTypes/partners';
import { PlatformWithColor } from '../components';
import {
  cookieDurationString,
  Currency,
  Number,
  styled,
  useCheckRates
} from '../service';

const CookieDuration = ({ seconds }: { seconds: number }) => {
  const str = cookieDurationString(seconds);
  return (
    <Typography variant="caption" color="textSecondary" component="span">
      &nbsp;&mdash; {str} cookie
    </Typography>
  );
};

const ConnectionId = styled('div')`
  color: #616161;
  background-color: #eee;
  font-size: 10px;
  line-height: 18px;
  padding: 0 5px;
  border-radius: 5px;
  overflow: hidden;
  white-space: nowrap;
  height: 19px;
  max-width: 80px;
  text-overflow: ellipsis;
  font-family: Menlo, Consolas, Courier;
`;

export const RateWrapper = styled('div')`
  color: #616161;
  background-color: #eee;
  font-size: 10px;
  line-height: 18px;
  margin-left: 10px;
  padding: 0 5px;
  border-radius: 5px;
  overflow: hidden;
  white-space: nowrap;
  height: 19px;
  max-width: 80px;
  text-overflow: ellipsis;
  font-family: Menlo, Consolas, Courier;
`;

const formatRateRange = (minRate: number, maxRate: number) => {
  if (minRate === maxRate) {
    return <Number n={minRate / 100} format="percent" />;
  }
  return (
    <>
      <Number n={minRate} />
      &ndash;
      <Number n={maxRate / 100} format="percent" />
    </>
  );
};

export const MiniRateSummary = ({
  advertiser
}: {
  advertiser: CachedAdvertiser;
}) => {
  const d = advertiser;
  if (d.maxRateFlat || d.maxRatePercent) {
    const showSlash = d.maxRateFlat && d.maxRatePercent;

    const [minRatePercent, maxRatePercent] = d.rates.reduce<[number, number]>(
      ([curMin, curMax], r) => {
        if (r.rateType === 'Percent' && r.actualRatePercent) {
          return [
            Math.min(curMin, r.actualRatePercent),
            Math.max(curMax, r.actualRatePercent)
          ];
        }
        return [curMin, curMax];
      },
      [Infinity, -Infinity]
    );

    return (
      <>
        {d.maxRatePercent !== null &&
          formatRateRange(minRatePercent, maxRatePercent)}
        {showSlash && ' / '}
        {d.maxRateFlat && d.maxRateCurrency !== null && (
          <Currency cents={d.maxRateFlat} currency={d.maxRateCurrency} />
        )}
      </>
    );
  }
  return null;
};

const RatesBoxWrapper = styled('div')`
  margin-bottom: ${(p) => p.theme.spacing(2)}px;
  max-height: 400px;
  overflow-y: auto;
`;

const RatesRow = styled('div')`
  display: grid;
  grid-template-columns: 2fr 24px 36px 50px 90px;
  margin: 0 auto;
  align-items: center;
  border-bottom: 1px solid ${(p) => p.theme.palette.grey[200]};
  padding: ${({ theme }) => theme.spacing(1.5)}px
    ${({ theme }) => theme.spacing(0.5)}px;
  grid-column-gap: ${({ theme }) => theme.spacing(1)}px;

  &:first-of-type {
    border-top: 1px solid ${(p) => p.theme.palette.grey[200]};
  }
`;

const RatesInnerRowWrapper = styled<'div', { maxHeight: number }>('div')`
  max-height: ${(p) => p.maxHeight}px;
  min-height: ${(p) => p.maxHeight}px;
  overflow-y: auto;
  background-color: ${(p) => p.theme.palette.grey[100]};
  padding: 0 ${(p) => p.theme.spacing(1)}px;
`;

const RatesInnerRow = styled('div')`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  font-size: 0.85rem;
  justify-content: space-between;
  grid-column-gap: ${(props) => props.theme.spacing(1)}px;
  padding: ${({ theme }) => theme.spacing(0.5)}px
    ${({ theme }) => theme.spacing(1)}px;
`;

const RatesInnerRowHeader = styled('div')`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-column-gap: ${(props) => props.theme.spacing(1)}px;
  background-color: ${(p) => p.theme.palette.grey[200]};
  font-size: 0.8em;
  color: ${(p) => p.theme.palette.grey[800]};
  border-bottom: 1px solid ${(p) => p.theme.palette.grey[300]};
  padding: ${({ theme }) => theme.spacing(1)}px
    ${({ theme }) => theme.spacing(2)}px;
  text-transform: uppercase;
  font-weight: 700;
`;

const ConnectionMenuItem = styled('div')`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)}px;
`;

const RateRowIndividual = ({ rate }: { rate: AdvertiserRate }) => {
  const r = rate;
  return (
    <RatesInnerRow>
      <div>{r.description || 'Your rate'}</div>
      <div style={{ textAlign: 'right' }}>
        {r.rateType === 'Percent' && r.actualRatePercent !== null && (
          <Number n={r.actualRatePercent / 100} format="percent" />
        )}
        {r.cookieDurationSeconds !== null && (
          <CookieDuration seconds={r.cookieDurationSeconds} />
        )}
      </div>
      <div style={{ textAlign: 'right' }}>{r.payoutType}</div>
    </RatesInnerRow>
  );
};

export const RatesBox = ({
  links,
  onSelect
}: {
  links: GeneratedLink[];
  onSelect: ({
    connectionId,
    advertiserId
  }: {
    connectionId: string;
    advertiserId: string;
  }) => void;
}) => {
  const [
    selectedAdvertiser,
    setSelectedAdvertiser
  ] = useState<CachedAdvertiser | null>(null);
  const [search, setSearch] = useState('');

  const visibleRates = useMemo(() => {
    if (!selectedAdvertiser) {
      return [];
    }
    return orderBy(
      selectedAdvertiser.rates.filter((r) => {
        return search.length === 0
          ? true
          : (r.description || '').toLowerCase().includes(search.toLowerCase());
      }),
      (r) => r.actualRatePercent,
      'desc'
    );
  }, [selectedAdvertiser, search]);

  return (
    <div>
      <RatesBoxWrapper>
        {links.map((l) => {
          const partner = PARTNERS.find((p) => p.key === l.partnerKey);

          if (!partner) {
            return null;
          }

          return (
            <>
              <RatesRow key={l.link}>
                <div>
                  <ConnectionMenuItem>
                    <PlatformWithColor partner={partner} key={l.partnerKey} />
                    {l.connectionNickname && (
                      <ConnectionId>{l.connectionNickname}</ConnectionId>
                    )}
                  </ConnectionMenuItem>
                </div>
                <div>
                  <Tooltip
                    title={`${l.advertiser} (${l.advertiserId})`}
                    placement="top"
                  >
                    <div
                      style={{
                        color: 'gray',
                        position: 'relative',
                        display: 'block',
                        top: '3px'
                      }}
                    >
                      <AlignLeft size={16} />
                    </div>
                  </Tooltip>
                </div>
                <div style={{ textAlign: 'right', color: 'gray' }}>
                  {l.advertiserDetails ? (
                    <>{l.advertiserDetails.rates.length}x</>
                  ) : (
                    <Dash />
                  )}
                </div>
                <div style={{ textAlign: 'right' }}>
                  {l.advertiserDetails ? (
                    <strong>
                      <MiniRateSummary advertiser={l.advertiserDetails} />
                    </strong>
                  ) : (
                    <Dash />
                  )}
                </div>
                <div style={{ textAlign: 'right' }}>
                  {l.advertiserDetails && (
                    <Button
                      size="small"
                      variant="text"
                      color="primary"
                      style={{ padding: 0 }}
                      onClick={() => {
                        onSelect({
                          connectionId: l.connectionId,
                          advertiserId: l.advertiserId
                        });
                      }}
                    >
                      Select
                    </Button>
                  )}
                  <IconButton
                    style={{ padding: `6px` }}
                    onClick={() => {
                      if (selectedAdvertiser?.advertiserId === l.advertiserId) {
                        setSelectedAdvertiser(null);
                      } else {
                        setSelectedAdvertiser(l.advertiserDetails);
                        setSearch('');
                      }
                    }}
                  >
                    {selectedAdvertiser === l.advertiserDetails ? (
                      <ChevronUp size={14} />
                    ) : (
                      <ChevronDown size={14} />
                    )}
                  </IconButton>
                </div>
              </RatesRow>
              <Collapse in={selectedAdvertiser === l.advertiserDetails}>
                <RatesInnerRowHeader>
                  <div>
                    <TextField
                      variant="standard"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      InputProps={{
                        fullWidth: true,
                        style: { height: '16px', fontSize: 14 },
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconSearch size={12} />
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                  <div style={{ textAlign: 'right' }}>
                    Rate <span style={{ color: '#999' }}>▼</span>
                  </div>
                  <div style={{ textAlign: 'right' }}>Payout Type</div>
                </RatesInnerRowHeader>
                <RatesInnerRowWrapper
                  maxHeight={
                    selectedAdvertiser?.partnerKey === 'impact' ? 164 : 120
                  }
                >
                  {visibleRates.map((r, i) => (
                    <RateRowIndividual key={i} rate={r} />
                  ))}
                </RatesInnerRowWrapper>
              </Collapse>
            </>
          );
        })}
      </RatesBoxWrapper>
    </div>
  );
};

const LinkConfiguration = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  grid-column-gap: ${(p) => p.theme.spacing(1)}px;
  grid-row-gap: ${(p) => p.theme.spacing(1)}px;
  margin-bottom: ${(p) => p.theme.spacing(2)}px;
`;

const RatesPreviewBoxWrapper = styled('div')`
  margin-bottom: ${(p) => p.theme.spacing(2)}px;
  max-height: 300px;
  overflow-y: auto;
`;

const RatesPreviewRow = styled('div')`
  display: grid;
  grid-template-columns: 1fr 24px 36px 50px 36px;
  margin: 0 auto;
  align-items: center;
  border-bottom: 1px solid ${(p) => p.theme.palette.grey[200]};
  padding: ${({ theme }) => theme.spacing(1.5)}px
    ${({ theme }) => theme.spacing(0.5)}px;
  grid-column-gap: ${({ theme }) => theme.spacing(1)}px;

  &:first-of-type {
    border-top: 1px solid ${(p) => p.theme.palette.grey[200]};
  }
`;

export const RatesPreview = ({
  prefilledUrl,
  spaceId
}: {
  prefilledUrl: string;
  spaceId: string;
}) => {
  const checkRates = useCheckRates();
  const [search, setSearch] = useState('');
  const [advertisers, setAdvertisers] = useState<Doc<CachedAdvertiser>[]>([]);
  const [
    selectedAdvertiser,
    setSelectedAdvertiser
  ] = useState<CachedAdvertiser | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showRates, setShowRates] = useState(false);

  useEffect(() => {
    setLoading(true);
    checkRates(prefilledUrl)
      .then((rates) => {
        setAdvertisers(rates);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [prefilledUrl, checkRates]);

  const visibleRates = useMemo(() => {
    if (!selectedAdvertiser) {
      return [];
    }
    return orderBy(
      selectedAdvertiser?.rates.filter((r) => {
        return search.length === 0
          ? true
          : (r.description || '').toLowerCase().includes(search.toLowerCase());
      }),
      (r) => r.actualRatePercent,
      'desc'
    );
  }, [search, selectedAdvertiser]);

  const FIXED_HEIGHT = 42;

  if (
    !prefilledUrl ||
    prefilledUrl.length < 5 ||
    !prefilledUrl.includes('.') ||
    !prefilledUrl.startsWith('https://')
  ) {
    const offset = 16;
    // This is to avoid layout shift when the url is not prefilled
    return <LinkConfiguration style={{ height: FIXED_HEIGHT + offset }} />;
  }

  if (loading) {
    const textHeight = 12;
    const offset = 8; // Helps align loader with actual text
    return (
      <div
        style={{
          height: FIXED_HEIGHT - offset,
          marginTop: 16 + offset,
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Skeleton variant="rect" height={textHeight} width={150} />
        <Skeleton variant="rect" height={textHeight} width={100} />
      </div>
    );
  }

  if (error) {
    return <LinkConfiguration>Error: {error}</LinkConfiguration>;
  }

  const ratesByPartnerKey = groupBy(advertisers, 'data.partnerKey');
  const partnerKeys = Object.keys(ratesByPartnerKey);

  if (partnerKeys.length < 1) {
    return (
      <LinkConfiguration style={{ height: FIXED_HEIGHT }}>
        <Typography
          variant="body2"
          component="p"
          gutterBottom
          color="textSecondary"
          style={{
            marginTop: 16,
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <span style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <X size={18} style={{ marginRight: 4, color: COLORS.red.red4 }} />
            No active partners found for domain
          </span>
          <Button
            size="small"
            variant="text"
            color="primary"
            style={{ padding: 0 }}
            href="https://app.affilimate.com/performance-new/rates?show_troubleshooting=true"
            rel="noopener noreferrer"
            target="_blank"
          >
            Check why <ExternalLink size={14} style={{ marginLeft: 4 }} />
          </Button>
        </Typography>
      </LinkConfiguration>
    );
  }

  return (
    <LinkConfiguration>
      <div style={{ height: FIXED_HEIGHT }}>
        <Typography
          variant="body2"
          component="p"
          gutterBottom
          color="textSecondary"
          style={{
            marginTop: 16,
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <span style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Check
              size={18}
              style={{ marginRight: 4, color: COLORS.green.green8 }}
            />
            Active on <strong>{partnerKeys.length}</strong> platforms
          </span>
          <Button
            size="small"
            variant="text"
            style={{ padding: 0, opacity: 0.6 }}
            onClick={() => {
              setShowRates(!showRates);
            }}
          >
            <Table size={14} style={{ marginRight: '6px' }} />{' '}
            {showRates ? 'Hide rates' : 'Preview rates'}
          </Button>
        </Typography>
      </div>
      <Collapse in={showRates}>
        <RatesPreviewBoxWrapper>
          {advertisers.map((l) => {
            const partner = PARTNERS.find((p) => p.key === l.data.partnerKey);

            if (!partner) {
              return null;
            }

            return (
              <React.Fragment key={l.id}>
                <RatesPreviewRow>
                  <div>
                    <ConnectionMenuItem>
                      <PlatformWithColor
                        partner={partner}
                        key={l.data.partnerKey}
                      />
                    </ConnectionMenuItem>
                  </div>
                  <div>
                    <Tooltip
                      title={`${l.data.name} (${l.data.advertiserId})`}
                      placement="top"
                    >
                      <div
                        style={{
                          color: 'gray',
                          position: 'relative',
                          display: 'block',
                          top: '3px'
                        }}
                      >
                        <AlignLeft size={16} />
                      </div>
                    </Tooltip>
                  </div>
                  <div style={{ textAlign: 'right', color: 'gray' }}>
                    {l.data.rates ? <>{l.data.rates.length}x</> : <Dash />}
                  </div>
                  <div style={{ textAlign: 'right' }}>
                    {l.data.rates ? (
                      <strong>
                        <MiniRateSummary advertiser={l.data} />
                      </strong>
                    ) : (
                      <Dash />
                    )}
                  </div>
                  <div style={{ textAlign: 'right' }}>
                    <IconButton
                      style={{ padding: `6px` }}
                      onClick={() => {
                        if (
                          selectedAdvertiser?.advertiserId ===
                          l.data.advertiserId
                        ) {
                          setSelectedAdvertiser(null);
                        } else {
                          setSelectedAdvertiser(l.data);
                          setSearch('');
                        }
                      }}
                    >
                      {selectedAdvertiser === l.data ? (
                        <ChevronUp size={14} />
                      ) : (
                        <ChevronDown size={14} />
                      )}
                    </IconButton>
                  </div>
                </RatesPreviewRow>
                <Collapse in={selectedAdvertiser === l.data}>
                  <RatesInnerRowHeader>
                    <div>
                      <TextField
                        variant="standard"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        InputProps={{
                          fullWidth: true,
                          style: { height: '16px', fontSize: 14 },
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconSearch size={12} />
                            </InputAdornment>
                          )
                        }}
                      />
                    </div>
                    <div style={{ textAlign: 'right' }}>
                      Rate <span style={{ color: '#999' }}>▼</span>
                    </div>
                    <div style={{ textAlign: 'right' }}>Payout Type</div>
                  </RatesInnerRowHeader>
                  <RatesInnerRowWrapper
                    maxHeight={
                      selectedAdvertiser?.partnerKey === 'impact' ? 164 : 120
                    }
                  >
                    {visibleRates.map((r, i) => (
                      <RateRowIndividual key={i} rate={r} />
                    ))}
                  </RatesInnerRowWrapper>
                </Collapse>
              </React.Fragment>
            );
          })}
        </RatesPreviewBoxWrapper>
      </Collapse>
    </LinkConfiguration>
  );
};
