import { capitalize, Card, CardContent, Typography } from '@material-ui/core';
import React from 'react';
import { AlertBox } from '../../../../../components/AlertBox';
import { ICurrentUser } from '../../../../../domainTypes/user';
import { CanvasBar } from '../../../../../layout/Canvas';
import { Section } from '../../../../../layout/Section';

export const UserProfileAuthenticationProvidersSection = <
  X extends Pick<ICurrentUser, 'authUser'>
>({
  currentUser
}: {
  currentUser: X;
}) => {
  const hasPasswordLogin = !!currentUser.authUser?.providerData.find(
    (provider: any) => provider?.providerId === 'password'
  );

  const hasOnlyPasswordLogin =
    hasPasswordLogin && currentUser.authUser?.providerData.length === 1;

  if (!currentUser.authUser || hasOnlyPasswordLogin) {
    return null;
  }

  return (
    <Section>
      <CanvasBar>Authentication providers</CanvasBar>
      <Card>
        <CardContent>
          {!hasPasswordLogin && (
            <AlertBox variant="pending">
              You're currently logging in to Affilimate with a third-party
              provider like Google or Facebook. If you'd like to enable to
              email-based login, just logout and click "Reset Password" to do
              so.
            </AlertBox>
          )}

          <Typography variant="body2">
            With Affilimate, you have authenticated at least once using:
          </Typography>
          <ul>
            {currentUser.authUser?.providerData.map(
              (provider: any) =>
                provider && (
                  <li key={provider.providerId}>
                    {capitalize(provider.providerId)}
                  </li>
                )
            )}
          </ul>
          <Typography variant="body2">
            To change passwords in any service besides Affilimate (such as
            Google or Facebook), please change your password in that service
            directly.
          </Typography>
        </CardContent>
      </Card>
    </Section>
  );
};
