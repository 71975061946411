import { AnalyticsFilterUI } from '../../../FilterUI';
import { ChipContent, formatList } from '../../common';
import { CountryFilterDef } from '../../../../../../domainTypes/filters';
import React from 'react';
import { CountryMenu } from './CountryMenu';
import { FlagWithLabelForFilterChip } from '../../../../../Flag';

export const countryFilterUIDef: AnalyticsFilterUI = {
  type: 'analytics',
  dimension: 'country',
  chip: ({ definition }) => {
    if (!definition) return <>Click country</>;
    if (definition.k !== 'country') {
      throw new Error('Invalid definition');
    }
    const flags = definition.v.map((country) => (
      <FlagWithLabelForFilterChip key={country} code={country} />
    ));
    return (
      <ChipContent title="Click country is"> {formatList(flags)}</ChipContent>
    );
  },
  menu: function CountryFilterUIMenu({ definition, ...props }) {
    return (
      <CountryMenu definition={definition as CountryFilterDef} {...props} />
    );
  },
  init: () => ({
    k: 'country',
    v: []
  }),
  toClauses: (definition) => {
    if (definition.k !== 'country') {
      return [];
    }
    return [
      {
        field: 'country',
        condition: 'in',
        values: definition.v
      }
    ];
  }
};
