import { PaginationProps } from '@material-ui/lab';
import { compact } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { CurrencyCode } from '../../domainTypes/currency';
import { MixpanelEventName } from '../../domainTypes/mixpanel';
import {
  ProductCatalogAvailability,
  ProductCatalogField,
  ProductCatalogLimit,
  ProductCatalogOrderBy,
  ProductCatalogQueryResultRow
} from '../../domainTypes/productCatalog';
import {
  AVAILABILITY_COLORS,
  AVAILABILITY_TEXTS
} from '../../features/Links/pages/Overview/components/ProductIssue';
import { ProductCellWithoutLink } from '../../features/Links/pages/Overview/components/ProductLinkCell';
import { SortDirection } from '../../hooks/useSort';
import { useNumberQueryParam } from '../../routes';
import { useMixpanel } from '../../services/mixpanel';
import {
  useColumnsQueryParam,
  useSortQueryParam
} from '../analytics_v2/Table/hooks';
import { WithShape } from '../Charts/Util';
import { ItemSorter, RowsRendererProps } from '../GroupableList';
import { Price } from '../Number';
import { RetailerName } from '../ProductCatalogRetailer';
import { IColumn } from '../Table/Column';
import { ColumnSelectorProps } from '../Table/ColumnSelector';
import { Dash } from '../Table/CountCell';
import { Truncated } from '../Truncated';

const ALL_PRODUCT_CATALOG_COLUMNS: Record<
  ProductCatalogTableField,
  ProductCatalogColumnDefinition<ProductCatalogTableField>
> = {
  brand: {
    sorter: {
      key: 'brand',
      items: {
        sort: (a) => {
          return a.brand ?? '';
        },
        dir: 'asc'
      }
    },
    column: {
      key: 'brand',
      head: () => 'Brand',
      width: 200,
      align: 'left',
      sortable: true,
      cell: (row) =>
        row.brand ? (
          <Truncated title={row.brand}>{row.brand}</Truncated>
        ) : (
          <Dash />
        )
    },
    fields: ['brand'],
    orderByField: 'brand'
  },
  manufacturer: {
    sorter: {
      key: 'manufacturer',
      items: {
        sort: (a) => {
          return a.manufacturer ?? '';
        },
        dir: 'asc'
      }
    },
    column: {
      key: 'manufacturer',
      head: () => 'Manufacturer',
      width: 100,
      align: 'left',
      sortable: true,
      cell: (row) =>
        row.manufacturer ? (
          <Truncated title={row.manufacturer}>{row.manufacturer}</Truncated>
        ) : (
          <Dash />
        )
    },
    fields: ['manufacturer'],
    orderByField: 'manufacturer'
  },
  retailer: {
    sorter: {
      key: 'retailer',
      items: {
        sort: (a) => {
          return a.retailer ?? '';
        },
        dir: 'asc'
      }
    },
    column: {
      key: 'retailer',
      head: () => 'Retailer',
      width: 150,
      align: 'left',
      sortable: true,
      cell: (row) =>
        row.retailer ? <RetailerName retailer={row.retailer} /> : <Dash />
    },
    fields: ['retailer'],
    orderByField: 'retailer'
  },
  seller: {
    sorter: {
      key: 'seller',
      items: {
        sort: (a) => {
          return a.seller ?? '';
        },
        dir: 'asc'
      }
    },
    column: {
      key: 'seller',
      head: () => 'Seller',
      width: 100,
      align: 'left',
      sortable: true,
      cell: (row) =>
        row.seller ? (
          <Truncated title={row.seller}>{row.seller}</Truncated>
        ) : (
          <Dash />
        )
    },
    fields: ['seller'],
    orderByField: 'seller'
  },
  catalog: {
    sorter: {
      key: 'catalog',
      items: {
        sort: (a) => {
          return a.catalog ?? '';
        },
        dir: 'asc'
      }
    },
    column: {
      key: 'catalog',
      head: () => 'Catalog',
      width: 100,
      align: 'left',
      sortable: true,
      cell: (row) =>
        row.catalog ? (
          <Truncated title={row.catalog}>{row.catalog}</Truncated>
        ) : (
          <Dash />
        )
    },
    fields: ['catalog'],
    orderByField: 'catalog'
  },
  price: {
    sorter: {
      key: 'price',
      items: {
        sort: (a) => {
          return a.price ?? 0;
        },
        dir: 'asc'
      }
    },
    column: {
      key: 'price',
      head: () => 'Price',
      width: 100,
      align: 'right',
      sortable: true,
      // Add here and in 'fields' prop currency
      cell: (row) =>
        row.price ? (
          <Price
            price={row.price}
            orig_price={row.price}
            currency={'USD' as CurrencyCode}
          />
        ) : (
          <Dash />
        )
    },
    fields: ['price'],
    orderByField: 'price'
  },
  availability: {
    sorter: {
      key: 'availability',
      items: {
        sort: (a) => {
          return a.availability ?? '';
        },
        dir: 'asc'
      }
    },
    column: {
      key: 'availability',
      head: () => 'Availability',
      width: 130,
      align: 'left',
      sortable: true,
      cell: (row) => {
        const availability =
          row.availability ?? ('unknown' as ProductCatalogAvailability);
        return (
          <WithShape
            shape="square"
            color={AVAILABILITY_COLORS[availability].backgroundColor}
          >
            {AVAILABILITY_TEXTS[availability].label}
          </WithShape>
        );
      }
    },
    fields: ['availability'],
    orderByField: 'availability'
  },
  product: {
    sorter: {
      key: 'product',
      items: {
        sort: (a) => {
          return a.title ?? '';
        },
        dir: 'asc'
      }
    },
    column: {
      key: 'product',
      head: () => 'Product',
      width: 200,
      align: 'left',
      sortable: true,
      flexGrow: 2,
      cell: (row) => (
        <ProductCellWithoutLink
          p_title={row.title!}
          p_image_url={row.image_link!}
          p_link_url={row.link!}
        />
      )
    },
    fields: ['uid', 'title', 'image_link', 'link'],
    orderByField: 'title'
  }
};

interface ProductCatalogTableMetadata {
  showComparison: boolean;
}

export interface ProductCatalogTableSettings<ColumnName extends string> {
  tableProps: Pick<
    RowsRendererProps<
      ProductCatalogQueryResultRow,
      ColumnName,
      ProductCatalogTableMetadata
    >,
    'columns' | 'sorter' | 'sortDirection' | 'onHeadClick' | 'otherProps'
  >;
  columnSelectorProps: Pick<
    ColumnSelectorProps<ColumnName>,
    'columns' | 'value' | 'onChange'
  >;
  paginationSelectorProps: Pick<PaginationProps, 'page' | 'onChange'>;
  pagination: NonNullable<ProductCatalogLimit>;
  orderBy: ProductCatalogOrderBy;
  fields: ProductCatalogField[];
}

export type ProductCatalogTableField =
  | 'product'
  | 'brand'
  | 'manufacturer'
  | 'seller'
  | 'catalog'
  | 'retailer'
  | 'price'
  | 'availability';

interface Options<ColumnName> {
  defaultVisibleColumns?: Array<ColumnName>;
  defaultSortColumn: ColumnName;
  defaultSortDirection?: SortDirection;
  sortQueryParamName?: string;
  columnSelectorParamName?: string;
  paginationParamName?: string;
  pageSize: number;
  showComparison?: boolean;
  trackingEventNames?: {
    onSortChange?: MixpanelEventName;
    onPageChange?: MixpanelEventName;
  };
}

interface ProductCatalogColumnDefinition<Column extends string> {
  sorter: ItemSorter<ProductCatalogQueryResultRow>;
  column: IColumn<
    ProductCatalogQueryResultRow,
    Column,
    ProductCatalogTableMetadata
  >;
  fields: ProductCatalogField[];
  orderByField: ProductCatalogField;
}

export type ProductCatalogColumnDefinitions<ColumnName extends string> = Record<
  ColumnName,
  ProductCatalogColumnDefinition<ColumnName>
>;

export const useProductCatalogTable = <
  CustomColumn extends string,
  FieldColumn extends ProductCatalogTableField
>(
  columns: Array<CustomColumn | FieldColumn>,
  customColumnsDefinitions: ProductCatalogColumnDefinitions<CustomColumn>,
  {
    defaultSortColumn,
    defaultSortDirection = 'desc',
    columnSelectorParamName = 'columns',
    sortQueryParamName = 'sort',
    paginationParamName = 'page',
    pageSize,
    showComparison = true,
    defaultVisibleColumns = columns,
    trackingEventNames
  }: Options<CustomColumn | FieldColumn>
): ProductCatalogTableSettings<CustomColumn | FieldColumn> => {
  const mixpanel = useMixpanel();
  const [[sortColumn, sortDirection], setSort] = useSortQueryParam(
    sortQueryParamName,
    columns,
    {
      column: defaultSortColumn,
      direction: defaultSortDirection
    }
  );
  const [selectedColumns, selectColumns] = useColumnsQueryParam(
    columnSelectorParamName,
    defaultVisibleColumns
  );

  const [page, setPage] = useNumberQueryParam(paginationParamName, 1);

  const getColumn = useCallback(
    (c: CustomColumn | FieldColumn) => {
      return (ALL_PRODUCT_CATALOG_COLUMNS[c as FieldColumn] ??
        customColumnsDefinitions[
          c as CustomColumn
        ]) as ProductCatalogColumnDefinition<CustomColumn | FieldColumn>;
    },
    [customColumnsDefinitions]
  );

  const sorter = useMemo(() => {
    const column = getColumn(sortColumn);
    return column.sorter;
  }, [getColumn, sortColumn]);

  const pagination = useMemo(
    () => ({
      page,
      limit: pageSize
    }),
    [page, pageSize]
  );

  const fields = useMemo<ProductCatalogField[]>(() => {
    return [...selectedColumns].flatMap((c) => getColumn(c).fields);
  }, [getColumn, selectedColumns]);

  const orderBy = useMemo<ProductCatalogOrderBy>(() => {
    const column = getColumn(sortColumn);
    return {
      field: column.orderByField,
      direction: sortDirection === 'asc' ? 'ASC' : 'DESC'
    };
  }, [getColumn, sortColumn, sortDirection]);

  const trackingNameOnSortChange = trackingEventNames?.onSortChange || null;
  const trackingNameOnPageChange = trackingEventNames?.onPageChange || null;
  return useMemo<
    ProductCatalogTableSettings<CustomColumn | FieldColumn>
  >(() => {
    return {
      tableProps: {
        columns: compact(
          columns
            .filter((c) => selectedColumns.has(c))
            .map((c) => getColumn(c).column)
        ),
        sorter,
        sortDirection,
        onHeadClick: (c, dir) => {
          const { sorter } = getColumn(c.key);
          if (c.sortable && sorter) {
            const nextDir = dir ?? defaultSortDirection;
            setSort([c.key, nextDir]);
            if (trackingNameOnSortChange) {
              mixpanel.track(trackingNameOnSortChange, {
                column: c.key,
                direction: dir
              });
            }
          }
        },
        otherProps: {
          showComparison
        }
      },
      columnSelectorProps: {
        columns: compact(
          columns.map((c) => getColumn(c)?.column)
        ).sort((a, b) => a.key.localeCompare(b.key)),
        value: selectedColumns,
        onChange: selectColumns
      },
      paginationSelectorProps: {
        page,
        onChange: (_, nextPage) => {
          setPage(nextPage);
          if (trackingNameOnPageChange) {
            mixpanel.track(trackingNameOnPageChange, {
              page: nextPage
            });
          }
        }
      },
      orderBy,
      pagination,
      fields
    };
  }, [
    columns,
    defaultSortDirection,
    fields,
    getColumn,
    mixpanel,
    orderBy,
    page,
    pagination,
    selectColumns,
    selectedColumns,
    setPage,
    setSort,
    showComparison,
    sortDirection,
    sorter,
    trackingNameOnPageChange,
    trackingNameOnSortChange
  ]);
};
