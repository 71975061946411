import { Tooltip, Typography } from '@material-ui/core';
import { compact, truncate } from 'lodash';
import React, { useMemo } from 'react';
import {
  AnalyticsColumnDefinition,
  useAnalyticsTable
} from '../../../../../../../components/analytics_v2/Table';
import { WithShape } from '../../../../../../../components/Charts/Util';
import { CustomPagination } from '../../../../../../../components/CustomPagination';
import { FlagWithLabel } from '../../../../../../../components/Flag';
import { RowsRenderer } from '../../../../../../../components/GroupableList';
import { PaperLoader } from '../../../../../../../components/Loader';
import {
  AnalyticsQuery,
  AnalyticsResponseRowWithComparison,
  ISOTimeRange
} from '../../../../../../../domainTypes/analytics_v2';
import { CanvasBar } from '../../../../../../../layout/Canvas';
import { Section } from '../../../../../../../layout/Section';
import { Metric } from '../../../../../../../services/analyticsV2/metrics';
import { useAnalyticsQueryV2 } from '../../../../../../../services/analyticsV2/query';
import { getStableRandomColor } from '../../../../../../../services/color';
import {
  AdvertiserGrouper,
  productGrouperToEnglish,
  productNameFieldToEnglish
} from '../../../../../services/advertisers';
import {
  AVERAGE_COMMISSION_COLUMN,
  AVERAGE_PRICE_COLUMN
} from './customColumns';
import { ExportQueryButton } from '../../../../../../../components/ExportQuery';
import { FlexContainer } from '../../../../../../../layout/Flex';

type CustomColumns = AdvertiserGrouper | 'average_price' | 'average_commission';

const PAGE_SIZE = 15;

export const TopAdvertiserGrouperSection = ({
  spaceId,
  commonQ,
  compareRange,
  groupedBy
}: {
  spaceId: string;
  commonQ: Pick<AnalyticsQuery, 'range' | 'filters' | 'search'>;
  compareRange: ISOTimeRange | null;
  groupedBy: AdvertiserGrouper;
}) => {
  const availableColumns = useMemo<(Metric | CustomColumns)[]>(() => {
    return compact([
      groupedBy,
      'order_count_net',
      'quantity_net',
      'average_price',
      'average_commission',
      'avg_rate_net',
      'commission_sum_net',
      'gmv_sum_net'
    ]);
  }, [groupedBy]);
  const customColumns = useMemo<
    Record<CustomColumns, AnalyticsColumnDefinition<CustomColumns>>
  >(() => {
    return {
      country: {
        column: {
          key: 'country',
          head: () => 'Country',
          cell: (p: AnalyticsResponseRowWithComparison) => {
            const code = p.group.country;
            if (!code) {
              return (
                <WithShape color={getStableRandomColor('Unknown')} large>
                  Unknown
                </WithShape>
              );
            }
            return <FlagWithLabel code={code} />;
          },
          align: 'left',
          sortable: true,
          defaultDirection: 'asc',
          width: 300,
          flexGrow: 2
        },
        sorter: {
          key: 'country',
          items: {
            sort: (p: AnalyticsResponseRowWithComparison) => p.group.country,
            dir: 'asc'
          }
        }
      },
      seller: {
        column: {
          key: 'seller',
          head: () => 'Seller',
          cell: (p: AnalyticsResponseRowWithComparison) => {
            const rawName = p.group.seller || 'Unknown';
            const nameLabel = productNameFieldToEnglish(rawName, groupedBy);
            return (
              <Tooltip
                title={rawName.length > nameLabel.length ? rawName : nameLabel}
                placement="top"
              >
                <div>
                  <WithShape color={getStableRandomColor(rawName)} large>
                    {truncate(nameLabel, { length: 100 })}
                  </WithShape>
                </div>
              </Tooltip>
            );
          },
          align: 'left',
          sortable: true,
          defaultDirection: 'asc',
          width: 300,
          flexGrow: 2
        },
        sorter: {
          key: 'seller',
          items: {
            sort: (p: AnalyticsResponseRowWithComparison) => p.group.seller,
            dir: 'asc'
          }
        }
      },
      partner_product_category: {
        column: {
          key: 'partner_product_category',
          head: () => 'Category',
          cell: (p: AnalyticsResponseRowWithComparison) => {
            const rawName = p.group.partner_product_category || 'Unknown';
            const nameLabel = productNameFieldToEnglish(rawName, groupedBy);
            return (
              <Tooltip
                title={rawName.length > nameLabel.length ? rawName : nameLabel}
                placement="top"
              >
                <div>
                  <WithShape color={getStableRandomColor(rawName)} large>
                    {truncate(nameLabel, { length: 100 })}
                  </WithShape>
                </div>
              </Tooltip>
            );
          },
          align: 'left',
          sortable: true,
          defaultDirection: 'asc',
          width: 300,
          flexGrow: 2
        },
        sorter: {
          key: 'partner_product_category',
          items: {
            sort: (p: AnalyticsResponseRowWithComparison) =>
              p.group.partner_product_category,
            dir: 'asc'
          }
        }
      },
      partner_product_name: {
        column: {
          key: 'partner_product_name',
          head: () => 'Product name',
          cell: (p: AnalyticsResponseRowWithComparison) => {
            const rawName = p.group.partner_product_name || 'Unknown';
            const nameLabel = productNameFieldToEnglish(rawName, groupedBy);
            return (
              <Tooltip
                title={rawName.length > nameLabel.length ? rawName : nameLabel}
                placement="top"
              >
                <div>
                  <WithShape color={getStableRandomColor(rawName)} large>
                    {truncate(nameLabel, { length: 100 })}
                  </WithShape>
                </div>
              </Tooltip>
            );
          },
          align: 'left',
          sortable: true,
          defaultDirection: 'asc',
          width: 300,
          flexGrow: 2
        },
        sorter: {
          key: 'partner_product_name',
          items: {
            sort: (p: AnalyticsResponseRowWithComparison) =>
              p.group.partner_product_name,
            dir: 'asc'
          }
        }
      },
      partner_product_id: {
        column: {
          key: 'partner_product_id',
          head: () => 'Product ID',
          cell: (p: AnalyticsResponseRowWithComparison) => {
            const label = p.group.partner_product_id || 'Unknown';
            return (
              <Tooltip title={label} placement="top">
                <div>
                  <WithShape color={getStableRandomColor(label)} large>
                    {truncate(label, { length: 100 })}
                  </WithShape>
                </div>
              </Tooltip>
            );
          },
          align: 'left',
          sortable: true,
          defaultDirection: 'asc',
          width: 400,
          flexGrow: 8
        },
        sorter: {
          key: 'partner_product_id',
          items: {
            sort: (p: AnalyticsResponseRowWithComparison) =>
              p.group.partner_product_id,
            dir: 'asc'
          }
        }
      },
      average_price: AVERAGE_PRICE_COLUMN,
      average_commission: AVERAGE_COMMISSION_COLUMN
    };
  }, [groupedBy]);
  const {
    pagination,
    orderBy,
    tableProps,
    paginationSelectorProps
  } = useAnalyticsTable(availableColumns, customColumns, {
    pageSize: PAGE_SIZE,
    defaultSortColumn: 'commission_sum_net',
    sortQueryParamName: 'productsSort',
    paginationParamName: 'productsPage',
    showComparison: !!compareRange
  });

  const baseQ = useMemo<AnalyticsQuery>(
    () => ({
      ...commonQ,
      groupBy: [groupedBy],
      // TODO unknown transformers
      orderBy: [orderBy],
      select: [
        'quantity_net',
        'commission_sum_net',
        'avg_rate_net',
        'gmv_sum_net',
        'order_count_net'

        // avg price - don't have that yet --> current table divides total by quantity
        // avg commision --> current table divides total by quantity
        // avg rate
      ]
    }),
    [commonQ, groupedBy, orderBy]
  );

  const q = useMemo<AnalyticsQuery>(() => {
    return {
      ...baseQ,
      compare: compareRange ? { range: compareRange } : undefined,
      paginate: pagination
    };
  }, [baseQ, compareRange, pagination]);
  const [ds] = useAnalyticsQueryV2(spaceId, q);
  const currentRowsCount = ds ? ds.rows.length : 0;
  return (
    <Section>
      <CanvasBar>
        <div>
          <Typography variant="body1" component="h2" color="textPrimary">
            <strong>
              Performance by {productGrouperToEnglish(groupedBy).toLowerCase()}
            </strong>
          </Typography>
          <Typography variant="caption" component="p" color="textSecondary">
            Update this list and the charts above using the{' '}
            <strong>Group by</strong> option in the report header.
          </Typography>
        </div>
        <FlexContainer>
          <CustomPagination
            {...paginationSelectorProps}
            siblingCount={0}
            count={
              (paginationSelectorProps.page || 1) +
              (currentRowsCount < PAGE_SIZE ? 0 : 1)
            }
          />
          <ExportQueryButton
            title="Export products"
            reportType="advertisers"
            query={baseQ}
          />
        </FlexContainer>
      </CanvasBar>
      {!ds && <PaperLoader height={300} />}
      {ds && (
        <RowsRenderer
          {...tableProps}
          renderHead
          rows={ds.rows}
          rowToKey={(d) =>
            d.group.partner_product_name || d.group.partner_product_id
          }
        />
      )}
    </Section>
  );
};
