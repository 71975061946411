import { LoadingValue, useMappedLoadingValue } from '../../../services/db';
import { useCurrentUser } from '../../../services/currentUser';
import { useCallback, useMemo } from 'react';
import {
  AnalyticsQuery,
  AnalyticsResponse
} from '../../../domainTypes/analytics_v2';
import { useAnalyticsQueryV2 } from '../../../services/analyticsV2/query';
import { campaignQueryBase, ReadyManualCampaign } from './manual-campaign';
import {
  calculateEarningsGoal,
  getClicksGoal,
  getCPCIncentive,
  getFlatSpendAmount,
  getPageviewsGoal
} from './goals-and-incentives';
import { BaseCampaign } from '../../../domainTypes/campaigns';

export type CampaignMetricWithGoal = {
  value: number;
  goal?: {
    value: number;
    percentage: number;
  };
};

export const hasGoal = (
  metric: CampaignMetricWithGoal
): metric is Required<CampaignMetricWithGoal> => {
  return !!metric.goal;
};

export type CampaignTotalWithGoal = 'c' | 'p' | 'gmv' | 'estimatedCPC';
export type CampaignTotal =
  | 'v'
  | 'commission'
  | 'flatSpend'
  | 'aov'
  | 'orderCount'
  | 'budget'
  | 'cpc';

export type CampaignTotalsObject = {
  [key in CampaignTotalWithGoal]: CampaignMetricWithGoal;
} &
  {
    [key in CampaignTotal]: number;
  };

export type CampaignTotals = LoadingValue<CampaignTotalsObject>;

export const getCampaignTotals = (
  campaign: Pick<BaseCampaign, 'goals' | 'incentives'>,
  response: AnalyticsResponse
): CampaignTotalsObject => {
  const data = response.rows[0].data;
  const gmvGoal = calculateEarningsGoal(campaign);
  const clickGoal = getClicksGoal(campaign);
  const pvGoal = getPageviewsGoal(campaign);
  const gmv = data.gmv_sum_net?.curr ?? 0;
  const clicks = data.c?.curr ?? 0;
  const pageviews = data.p?.curr ?? 0;
  const flatSpend = getFlatSpendAmount(campaign);
  const cpc = getCPCIncentive(campaign);
  const budget = cpc?.limit ?? 0;
  const estimatedCPC = (cpc?.amount ?? 0) * clicks;
  return {
    commission: data.commission_sum_net?.curr ?? 0,
    gmv: {
      value: gmv,
      goal: gmvGoal
        ? {
            value: gmvGoal,
            percentage: gmv / gmvGoal
          }
        : undefined
    },
    flatSpend,
    cpc: cpc?.amount ?? 0,
    budget,
    estimatedCPC: {
      value: estimatedCPC,
      goal: {
        value: budget,
        percentage: estimatedCPC / budget
      }
    },
    aov: data.aov_net?.curr ?? 0,
    orderCount: data.order_count_net?.curr ?? 0,
    c: {
      value: clicks,
      goal: clickGoal
        ? {
            value: clickGoal,
            percentage: clicks / clickGoal
          }
        : undefined
    },
    p: {
      value: pageviews,
      goal: pvGoal
        ? {
            value: pvGoal,
            percentage: pageviews / pvGoal
          }
        : undefined
    },
    v: data.v?.curr ?? 0
  };
};

export const useCampaignTotals = (
  campaign: ReadyManualCampaign
): CampaignTotals => {
  const { space } = useCurrentUser();
  const query = useMemo<AnalyticsQuery>(() => {
    return {
      ...campaignQueryBase(campaign),
      select: [
        'c',
        'p',
        'v',
        'commission_sum_net',
        'gmv_sum_net',
        'aov_net',
        'order_count_net'
      ]
    };
  }, [campaign]);
  const mapper = useCallback(
    (response) => getCampaignTotals(campaign, response),
    [campaign]
  );
  return useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    mapper,
    true
  );
};
