import { BASIC_MODES, FilterMenu } from '../../Menus/FilterMenu';
import {
  AnalyticsFieldSelectorMenu,
  useCollectionFilterState
} from '../../Menus/Selector';
import React, { useState } from 'react';
import pluralize from 'pluralize';
import {
  ClickDataFilterDefinition,
  ClickDataSlot
} from '../../../../../../domainTypes/filters';
import { CustomDimensionOptionLabel } from '../../../../../CustomDimensionOptionLabel';
import { AnalyticsFilterMenuComponentProps } from '../../../FilterUI';

type ClickDataMenuProps = AnalyticsFilterMenuComponentProps<
  ClickDataFilterDefinition
> & {
  slot: ClickDataSlot;
  name: string;
  title: string;
  description: string;
};

export const ClickDataMenu: React.FC<ClickDataMenuProps> = ({
  slot,
  definition,
  onSave,
  context,
  isFirst,
  name,
  title,
  description
}) => {
  const [mode, setMode] = useState(BASIC_MODES[0].value);
  const {
    values,
    isSaveEnabled,
    handleSave,
    handleToggle,
    handleFocus
  } = useCollectionFilterState<string, ClickDataFilterDefinition>(
    definition,
    onSave
  );

  return (
    <FilterMenu>
      <FilterMenu.Header name={name} isFirst={isFirst}>
        <FilterMenu.ModeSelector
          modes={BASIC_MODES}
          mode={mode}
          setMode={setMode}
        />
      </FilterMenu.Header>
      <FilterMenu.Body>
        <AnalyticsFieldSelectorMenu
          label={name}
          selectedValues={values}
          onToggle={handleToggle}
          onFocus={handleFocus}
          queryFilters={context.baseQuery.filters}
          analyticsField={slot}
          orderBy={context.baseQuery.orderBy}
          range={context.baseQuery.range}
          renderOption={(value) => (
            <CustomDimensionOptionLabel slot={slot} value={value} large />
          )}
        />
      </FilterMenu.Body>
      <FilterMenu.Footer
        description={
          <>
            <strong>{title}</strong>: {description}
          </>
        }
      >
        <FilterMenu.SaveButton
          disabled={!isSaveEnabled}
          onSave={handleSave}
          label={`Filter by ${pluralize('value', values.length, true)}`}
        />
      </FilterMenu.Footer>
    </FilterMenu>
  );
};
