import { CampaignsPageBody } from '../../components/CampaignsPageBody';
import React, { useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import {
  useMixpanel,
  useTrackMixpanelView
} from '../../../../services/mixpanel';
import { useManualCampaignWithDefault } from '../../service';
import { CampaignWizard } from '../../components/manual-campaign-form/CampaignWizard';
import { enqueueSnackbar } from 'notistack';
import { useRoutes, useStringQueryParam } from '../../../../routes';
import { useCurrentUserScopes } from '../../../../services/currentUser';
import { NoPermissions } from '../../../../components/NoPermissions';

export const CampaignDetails: React.FC<{
  campaignId: string;
}> = ({ campaignId }) => {
  const scopes = useCurrentUserScopes();
  const canEdit = scopes.has('campaigns.edit');
  useTrackMixpanelView('view_campaigns_detail', { id: campaignId });
  const { ROUTES, goTo } = useRoutes();
  const { track } = useMixpanel();
  const campaign = useManualCampaignWithDefault(campaignId); // Use something like `useCampaignData`
  const [campaignData] = campaign;
  // TODO: use url to navigate after Preview step!
  const [back] = useStringQueryParam('back', 'overview');
  const url = useMemo(() => {
    return back === 'overview'
      ? ROUTES.campaigns.manual.active.url()
      : ROUTES.campaigns.manual.report.url(campaignId);
  }, [
    ROUTES.campaigns.manual.active,
    ROUTES.campaigns.manual.report,
    back,
    campaignId
  ]);
  const onSuccess = useCallback(() => {
    // NOTE: It's really 'campaign edit complete' event
    track('edit_campaign');
    if (campaignData) {
      enqueueSnackbar(`Campaign ${campaignData.name} edited`, {
        variant: 'success'
      });
    }
    goTo(url);
  }, [campaignData, goTo, track, url]);

  return (
    <>
      <Helmet>
        <title>Campaign Details | Affilimate</title>
      </Helmet>
      <CampaignsPageBody>
        {canEdit ? (
          <CampaignWizard campaign={campaign} onSuccess={onSuccess} />
        ) : (
          <NoPermissions />
        )}
      </CampaignsPageBody>
    </>
  );
};
