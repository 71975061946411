import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography
} from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { ArrowUpRight, Copy, Download, Eye, EyeOff, Lock } from 'react-feather';
import { ButtonWithPromise } from '../../../../../components/ButtonWithPromise';
import { CopyButton } from '../../../../../components/CopyButton';
import { styled } from '../../../../../emotion';
import { useSnackbar } from '../../../../../hooks/useSnackbar';
import {
  DISTANCE_TO_TOP_BAR,
  PageBody,
  SideNavProps
} from '../../../../../layout/PageBody';
import { useBooleanQueryParam, useRoutes } from '../../../../../routes';
import { useAdminOrImpersonatorClaim } from '../../../../../services/auth';
import { useCurrentUser } from '../../../../../services/currentUser';
import { callFirebaseFunction } from '../../../../../services/firebaseFunctions';
import { CF } from '../../../../../versions';
import { HelpScoutArticlePreview } from '../service';
import { ArticleList } from './ArticleList';

type Props = {
  noTopPadding?: boolean;
  relatedArticles?: HelpScoutArticlePreview[];
  selectedArticle?: HelpScoutArticlePreview;
};

const ConfidentialWrapper = styled('div')`
  margin-top: ${(p) => p.theme.spacing(2)}px;
  background-color: ${(p) => p.theme.palette.grey[200]};
  color: ${(p) => p.theme.palette.grey[500]};
  padding: ${(p) => p.theme.spacing(2)}px ${(p) => p.theme.spacing(2)}px;
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
`;

const ConfidentialText = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const RightNavWrapper = styled('div')`
  top: ${(p) => p.theme.spacing(7 + DISTANCE_TO_TOP_BAR)}px;
  position: fixed;
  padding-left: ${(p) => p.theme.spacing(2)}px;
  padding-right: ${(p) => p.theme.spacing(2)}px;
`;

const ArticleActions = styled('div')`
  display: grid;
`;

const downloadArticlePdf = async (articleId: string, spaceId: string) => {
  const response = await callFirebaseFunction<{
    data: string;
    filename: string;
    contentType: string;
  }>(CF.helpCenter.downloadArticlePdf, {
    articleId,
    spaceId
  });
  return response;
};

const RightNav = ({
  selectedArticle,
  relatedArticles
}: {
  selectedArticle: HelpScoutArticlePreview;
  relatedArticles?: HelpScoutArticlePreview[];
}) => {
  const { space } = useCurrentUser();
  const [showEditorOptions] = useAdminOrImpersonatorClaim();
  const [includeDrafts, setIncludeDrafts] = useBooleanQueryParam(
    'drafts',
    false
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const downloadArticle = async () => {
    setDownloading(true);
    try {
      const response = await downloadArticlePdf(selectedArticle.id, space.id);
      enqueueSnackbar('PDF generated successfully! Now downloading...', {
        variant: 'success'
      });
      const { data: pdfBase64, filename, contentType } = response;
      const binaryString = atob(pdfBase64);
      const bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      const blob = new Blob([bytes], { type: contentType });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        setOpenDialog(false);
      }, 100);
    } catch (error) {
      console.error('Error downloading PDF:', error);
      enqueueSnackbar(
        'Error downloading PDF. Please try again or contact Support.',
        {
          variant: 'error'
        }
      );
    } finally {
      setDownloading(false);
    }
  };

  return (
    <div>
      <RightNavWrapper>
        {selectedArticle && (
          <ArticleActions>
            <h3>Available actions</h3>
            <CopyButton
              variant="outlined"
              size="small"
              color="primary"
              style={{ marginBottom: '6px' }}
              notificationMessage="Link to docs copied"
              label={
                <>
                  <Copy size={14} /> &nbsp; Copy link to doc
                </>
              }
              text={window.location.origin + window.location.pathname}
            />
            {showEditorOptions && selectedArticle.hasDraft && !includeDrafts && (
              <Button
                variant="outlined"
                size="small"
                style={{ marginBottom: '6px' }}
                color="secondary"
                onClick={() => {
                  setIncludeDrafts(true);
                }}
              >
                <Eye size={14} /> &nbsp; View draft
              </Button>
            )}
            {showEditorOptions && includeDrafts && (
              <Button
                variant="outlined"
                size="small"
                style={{ marginBottom: '6px' }}
                onClick={() => {
                  setIncludeDrafts(false);
                }}
              >
                <EyeOff size={14} /> &nbsp; Hide draft
              </Button>
            )}
            {showEditorOptions && (
              <Button
                variant="outlined"
                size="small"
                style={{ marginBottom: '6px' }}
                onClick={() => {
                  setOpenDialog(true);
                }}
              >
                <Download size={14} /> &nbsp; Download as PDF
              </Button>
            )}
            {showEditorOptions && (
              <Button
                variant="outlined"
                size="small"
                href={`https://secure.helpscout.net/docs/${selectedArticle.collectionId}/article/${selectedArticle.id}/`}
                target="_blank"
                style={{ marginBottom: '6px' }}
              >
                Edit article &nbsp; <ArrowUpRight size={14} />
              </Button>
            )}
          </ArticleActions>
        )}
        {relatedArticles && (
          <div>
            <h3>Read related articles</h3>
            <ArticleList columns={1} articles={relatedArticles} />
          </div>
        )}
        <ConfidentialWrapper>
          <ConfidentialText style={{ marginBottom: '6px' }}>
            <Lock size={14} />{' '}
            <Typography variant="body2">
              <strong>Confidential</strong>
            </Typography>
          </ConfidentialText>
          <ConfidentialText variant="caption">
            Affilimate’s documentation and implementation details are
            proprietary information. Sharing outside your organization is not
            permitted.
          </ConfidentialText>
        </ConfidentialWrapper>
      </RightNavWrapper>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Download as PDF</DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <ButtonWithPromise
            color="primary"
            variant="contained"
            onClick={downloadArticle}
            disabled={downloading}
            pending={downloading ? 'Generating PDF...' : 'Download PDF'}
          >
            <Download size={14} /> &nbsp; Download PDF
          </ButtonWithPromise>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const KnowledgeBasePageBody: React.FC<Props> = ({
  noTopPadding,
  relatedArticles,
  selectedArticle,
  children
}) => {
  const { ROUTES } = useRoutes();
  const sideNav: SideNavProps = useMemo(() => {
    return {
      groups: [
        {
          label: 'Knowledge base',
          items: [
            {
              label: 'Getting Started',
              path: ROUTES.docs.knowledgeBase.url('getting-started')
            },
            {
              label: 'Affiliate Dashboard',
              path: ROUTES.docs.knowledgeBase.url('affiliate-dashboard')
            },
            {
              label: 'Integrations',
              path: ROUTES.docs.knowledgeBase.url('integrations')
            },
            {
              label: 'Link Management',
              path: ROUTES.docs.knowledgeBase.url('link-management')
            },
            {
              label: 'Content Analytics',
              path: ROUTES.docs.knowledgeBase.url('content-analytics')
            },
            {
              label: 'Offsite Analytics',
              path: ROUTES.docs.knowledgeBase.url('offsite-analytics')
            },
            {
              label: 'Tools',
              path: ROUTES.docs.knowledgeBase.url('tools')
            },
            {
              label: 'API',
              path: ROUTES.docs.knowledgeBase.url('api')
            },
            {
              label: 'Account',
              path: ROUTES.docs.knowledgeBase.url('account')
            }
          ]
        },
        {
          label: 'Resources',
          items: [
            {
              label: 'Trust Center',
              path: ROUTES.docs.knowledgeBase.url('trust-center')
            }
          ]
        },
        {
          label: 'API',
          items: [
            {
              label: 'Overview',
              path: ROUTES.docs.knowledgeBase.url('api-overview')
            },
            {
              label: 'Client API',
              path: ROUTES.docs.knowledgeBase.url('client-api')
            },
            {
              label: 'Publisher API',
              path: ROUTES.docs.api.url()
            }
          ]
        }
      ]
    };
  }, [ROUTES.docs.knowledgeBase, ROUTES.docs.api]);

  return (
    <>
      <PageBody
        sideNav={sideNav}
        noTopPadding={noTopPadding}
        rightNav={
          selectedArticle ? (
            <RightNav
              selectedArticle={selectedArticle}
              relatedArticles={relatedArticles}
            />
          ) : window.location.pathname.startsWith('/api') ? null : (
            <div />
          )
        }
      >
        {children}
      </PageBody>
    </>
  );
};
