import {
  CircularProgress,
  FormControl,
  FormHelperText,
  MenuItem,
  Select
} from '@material-ui/core';
import React, { useCallback } from 'react';
import { ProfileLabel } from '../../../../components/ProfileLabel';
import { CampaignPartner } from '../../../../domainTypes/campaigns';
import { css } from '../../../../emotion';
import { useCurrentUser } from '../../../../services/currentUser';
import { useMappedLoadingValue } from '../../../../services/db';
import { useMarketplaceAdvertiserProfilesPublic } from '../../../../services/marketplaceAdvertisers';

type CampaignPartnerSelectorProps = {
  value: CampaignPartner | null;
  onChange: (value: CampaignPartner | null) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  error?: boolean;
  helperText?: React.ReactNode;
};

export const CampaignPartnerSelector: React.FC<CampaignPartnerSelectorProps> = ({
  value,
  onChange,
  onFocus,
  onBlur,
  error,
  helperText
}) => {
  const { space } = useCurrentUser();
  const [options, loading] = useMappedLoadingValue(
    useMarketplaceAdvertiserProfilesPublic(space.id),
    (partners) =>
      partners.map((partner) => ({
        id: partner.id,
        label: <ProfileLabel profile={partner} />
      }))
  );

  const handleChange = useCallback(
    ({ target }: React.ChangeEvent<{ value: unknown }>) => {
      onChange({
        type: 'partner',
        id: target.value as string
      });
    },
    [onChange]
  );

  return (
    <>
      <FormControl required error={error} variant="outlined">
        <Select
          value={value?.id}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={'Select a partner'}
          fullWidth
          endAdornment={
            loading ? (
              <CircularProgress
                size={24}
                className={css((t) => ({
                  marginRight: t.spacing(2)
                }))}
              />
            ) : null
          }
          disabled={!options || loading}
        >
          {!loading && options
            ? options.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.label}
                </MenuItem>
              ))
            : null}
        </Select>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </>
  );
};
