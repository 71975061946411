import {
  Button,
  ButtonBase,
  Menu,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { PlusCircle, XCircle } from 'react-feather';
import { MoneyInput } from '../../../../components/MoneyInput';
import { RateInput } from '../../../../components/RateInput';
import {
  CampaignCPCIncentive,
  CampaignFlatSpendIncentive,
  CampaignIncentive,
  CampaignIncentiveType,
  CampaignRateIncreaseIncentive,
  defaultIncentive
} from '../../../../domainTypes/campaigns';
import { symbolForCurrencyCode } from '../../../../domainTypes/currency';
import { FlexContainer, FlexContainerVertical } from '../../../../layout/Flex';
import { useSpaceCurrency } from '../../../../services/useSpaceCurrency';
import { Header, Label, Stack } from './form-components';

const allIncentiveOptions: Array<{
  type: CampaignIncentiveType;
  label: string;
}> = [
  { type: 'flatSpend', label: 'Flat spend' },
  { type: 'rateIncrease', label: 'Rate increase' },
  { type: 'cpc', label: 'CPC' }
];

interface RateIncreaseInputProps {
  value: CampaignRateIncreaseIncentive;
  onChange: (value: CampaignIncentive) => void;
}

const RateIncreaseInput: React.FC<RateIncreaseInputProps> = ({
  value,
  onChange
}) => {
  const changeFrom = useCallback(
    (from: number) => {
      onChange({ ...value, from });
    },
    [onChange, value]
  );

  const changeTo = useCallback(
    (to: number) => {
      onChange({ ...value, to });
    },
    [onChange, value]
  );

  const changeDescription = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChange({ ...value, description: event.target.value });
    },
    [onChange, value]
  );

  return (
    <Stack>
      <Label>Rate increase</Label>
      <FlexContainer>
        <RateInput
          label="From"
          value={value.from}
          variant="outlined"
          onChange={changeFrom}
        />
        <RateInput
          label="To"
          value={value.to}
          variant="outlined"
          onChange={changeTo}
        />
        <TextField
          label="Description"
          value={value.description}
          onChange={changeDescription}
          variant="outlined"
        />
      </FlexContainer>
    </Stack>
  );
};

const CPCInput: React.FC<{
  onChange: (newGoal: CampaignCPCIncentive) => void;
  value: CampaignCPCIncentive;
}> = ({ value, onChange }) => {
  const currency = useSpaceCurrency();
  const changeAmount = useCallback(
    (amount: number) => {
      onChange({ ...value, amount: amount * 100 });
    },
    [onChange, value]
  );

  const changeLimit = useCallback(
    (limit: number) => {
      onChange({ ...value, limit: limit * 100 });
    },
    [onChange, value]
  );

  return (
    <FlexContainer>
      <MoneyInput
        label="CPC"
        onChange={changeAmount}
        value={value.amount / 100}
        variant="outlined"
        numericFormatProps={{
          prefix: symbolForCurrencyCode(currency)
        }}
      />
      <MoneyInput
        label="Up to a limit of"
        onChange={changeLimit}
        value={value.limit / 100}
        variant="outlined"
        numericFormatProps={{
          prefix: symbolForCurrencyCode(currency)
        }}
      />
    </FlexContainer>
  );
};

interface FlatSpendInputProps {
  value: CampaignFlatSpendIncentive;
  onChange: (value: CampaignIncentive) => void;
}

const FlatSpendInput: React.FC<FlatSpendInputProps> = ({ value, onChange }) => {
  const currency = useSpaceCurrency();
  const changeAmount = useCallback(
    (amount: number) => {
      onChange({ ...value, amount: amount * 100 });
    },
    [onChange, value]
  );

  const changeDescription = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChange({ ...value, description: event.target.value });
    },
    [onChange, value]
  );
  return (
    <FlexContainer>
      <MoneyInput
        label="Flat spend"
        onChange={changeAmount}
        value={value.amount / 100}
        variant="outlined"
        numericFormatProps={{
          prefix: symbolForCurrencyCode(currency)
        }}
      />
      <TextField
        label="Description"
        value={value.description}
        onChange={changeDescription}
        variant="outlined"
      />
    </FlexContainer>
  );
};

interface CampaignIncentivesSelectorProps {
  value: CampaignIncentive[];
  onChange: (value: CampaignIncentive[]) => void;
  options?: CampaignIncentiveType[];
}

const CampaignIncentiveInput = ({
  onChange,
  value
}: {
  onChange: (newGoal: CampaignIncentive) => void;
  value: CampaignIncentive;
}) => {
  switch (value.type) {
    case 'rateIncrease':
      return <RateIncreaseInput value={value} onChange={onChange} />;
    case 'flatSpend':
      return <FlatSpendInput value={value} onChange={onChange} />;
    case 'cpc':
      return <CPCInput value={value} onChange={onChange} />;
  }
};

export const CampaignIncentivesSelector: React.FC<CampaignIncentivesSelectorProps> = ({
  value,
  onChange,
  options = allIncentiveOptions.map(({ type }) => type)
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuAnchor = useRef<HTMLButtonElement | null>(null);
  const availableOptions = useMemo(() => {
    return allIncentiveOptions
      .filter((option) => options.includes(option.type))
      .filter(
        (option) => !value.some((incentive) => incentive.type === option.type)
      );
  }, [options, value]);
  const addIncentive = useCallback(
    (type: CampaignIncentiveType) => {
      onChange([...value, defaultIncentive(type)]);
    },
    [onChange, value]
  );
  const changeIncentive = useCallback(
    (newGoal: CampaignIncentive) => {
      onChange(
        value.map((goal) => (goal.type === newGoal.type ? newGoal : goal))
      );
    },
    [onChange, value]
  );

  return (
    <Stack>
      <Header>
        INCENTIVES
        <Button
          ref={menuAnchor}
          color="primary"
          style={{ marginLeft: 12 }}
          onClick={() => setMenuOpen(true)}
          startIcon={<PlusCircle size={16} />}
          disabled={availableOptions.length === 0}
        >
          Add incentive
        </Button>
        <Menu
          open={menuOpen}
          anchorEl={menuAnchor.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          onClose={() => setMenuOpen(false)}
        >
          {availableOptions.map((option) => (
            <MenuItem
              key={option.type}
              onClick={() => {
                addIncentive(option.type);
                setMenuOpen(false);
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
        <FlexContainerVertical spacing={2}>
          {value.map((incentive) => (
            <FlexContainer>
              <CampaignIncentiveInput
                key={incentive.type}
                value={incentive}
                onChange={changeIncentive}
              />
              <ButtonBase
                onClick={() =>
                  onChange(value.filter((v) => v.type !== incentive.type))
                }
              >
                <XCircle size={16} />
              </ButtonBase>
            </FlexContainer>
          ))}
          {value.length === 0 && (
            <Typography variant="body2" color="textPrimary">
              Propose an incentive structure, for example with a CPC or flat
              spend for rerouted link clicks.
            </Typography>
          )}
        </FlexContainerVertical>
      </Header>
    </Stack>
  );
};
