import Typography from '@material-ui/core/Typography';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import {
  TimeframePickerWithRevisions,
  useTimeframeWithRevisions
} from '../../../../components/analytics_v2/Timeframe/revisions';
import { NoPermissions } from '../../../../components/NoPermissions';
import { css } from '../../../../emotion';
import { PageBody } from '../../../../layout/PageBody';
import {
  PageToolbar,
  PageToolbarOtherRow,
  PageToolbarSection,
  PageToolbarTitle
} from '../../../../layout/PageToolbar';
import {
  useCurrentUser,
  useCurrentUserScopes
} from '../../../../services/currentUser';
import { useTrackMixpanelView } from '../../../../services/mixpanel';
import { usePage } from '../../../../services/page';
import { getPathname } from '../../../../services/url';
import { DetailsPageTitle } from '../../components/DetailsPageTitle';
import { useSideNavProps } from '../../services/detailsSideNav';
import { CountriesTable } from './CountriesTable';
import { DevicesTable } from './DevicesTable';
import { FlexContainer } from '../../../../layout/Flex';
import { AnalyticsFilter } from '../../../../domainTypes/analytics_v2';
import { useContentPagesFilterUIs } from '../../services/filters';
import { FILTER_STATE_LOCAL_STORAGE_KEYS } from '../../../../components/analytics_v2/Filters/Drawer/keys';
import { useAnalyticsFilters } from '../../../../components/analytics_v2/Filters/useAnalyticsFilters';
import { FiltersToggleButton } from '../../../../components/analytics_v2/Filters/Toggle';
import { FiltersDrawerWithDefaultTree } from '../../../../components/analytics_v2/Filters/Drawer/FiltersDrawer';

const TableSection: React.FC<{ header: string }> = ({ header, children }) => (
  <div
    className={css((t) => ({
      marginBottom: t.spacing(8)
    }))}
  >
    <Typography
      variant="body2"
      component="p"
      className={css((t) => ({
        fontWeight: 'bold',
        marginBottom: t.spacing(2)
      }))}
    >
      {header}
    </Typography>
    {children}
  </div>
);

const DetailsAudienceBody = ({ url }: { url: string }) => {
  const sideNav = useSideNavProps(url);
  useTrackMixpanelView('view_content_details_audience', { url });

  const { space } = useCurrentUser();
  const [pageMetadata] = usePage(space.id, url);
  const { ranges, pickerProps } = useTimeframeWithRevisions(
    pageMetadata ? pageMetadata.data.revisions : []
  );

  const baseFilters = useMemo<AnalyticsFilter[]>(
    () => [{ field: 'page_url', condition: 'in', values: [url] }],
    [url]
  );

  const filterUIs = useContentPagesFilterUIs();
  const { filters, toggleProps, drawerProps } = useAnalyticsFilters(
    filterUIs,
    {
      filters: baseFilters
    },
    {
      localStorageKey: FILTER_STATE_LOCAL_STORAGE_KEYS.content
    }
  );

  const allFilters = useMemo<AnalyticsFilter[]>(() => {
    return [...baseFilters, ...filters];
  }, [baseFilters, filters]);

  return (
    <PageBody sideNav={sideNav} noTopPadding>
      <Helmet>
        <title>{getPathname(url)} | Affilimate</title>
      </Helmet>
      <PageToolbar wrap>
        <FlexContainer>
          <PageToolbarTitle flex={2}>
            <DetailsPageTitle url={url} />
          </PageToolbarTitle>
          <FiltersToggleButton {...toggleProps} />
        </FlexContainer>

        <PageToolbarSection flex={2} justifyContent="flex-end">
          <TimeframePickerWithRevisions {...pickerProps} />
        </PageToolbarSection>
        <PageToolbarOtherRow>
          <FiltersDrawerWithDefaultTree {...drawerProps} marginTop={2} />
        </PageToolbarOtherRow>
      </PageToolbar>
      <TableSection header="Top geographies">
        <CountriesTable
          filters={allFilters}
          range={ranges.range}
          compare={ranges.compare}
        />
      </TableSection>
      <TableSection header="Top devices">
        <DevicesTable
          filters={allFilters}
          range={ranges.range}
          compare={ranges.compare}
        />
      </TableSection>
    </PageBody>
  );
};

export const PageContentDetailsAudience = ({ url }: { url: string }) => {
  const scopes = useCurrentUserScopes();
  const canViewContentReports = scopes.has('reports.content.view');

  if (!canViewContentReports) {
    return <NoPermissions />;
  }

  return <DetailsAudienceBody url={url} />;
};
