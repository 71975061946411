import {
  ItemSorter,
  RowsRenderer
} from '../../../../../components/GroupableList';
import { ProductCatalogMatchQueryRow } from '../../../../../domainTypes/productCatalog';
import React from 'react';
import { css } from '../../../../../emotion';
import { IColumn } from '../../../../../components/Table/Column';
import { ProductCellWithoutLink } from '../../../../Links/pages/Overview/components/ProductLinkCell';
import { CountCell, Dash } from '../../../../../components/Table/CountCell';
import { Currency } from '../../../../../components/Number';
import { WithShape } from '../../../../../components/Charts/Util';
import {
  AVAILABILITY_COLORS,
  AVAILABILITY_TEXTS
} from '../../../../Links/pages/Overview/components/ProductIssue';
import { Truncated } from '../../../../../components/Truncated';
import { metricTitle } from '../../../../../services/analyticsV2/metrics';

type Columns = 'product' | 'brand' | 'price' | 'availability' | 'clicks';

const columnDefinitions: Array<IColumn<
  ProductCatalogMatchQueryRow,
  Columns
>> = [
  {
    key: 'product',
    head: () => 'Linked product',
    cell: (r) => {
      return (
        <ProductCellWithoutLink
          p_title={r.source?.title ?? ''}
          p_image_url={r.source?.image_link ?? ''}
          p_link_url={r.source?.link ?? ''}
        />
      );
    },
    align: 'left',
    sortable: false,
    width: 400,
    flexGrow: 3
  },
  {
    key: 'price',
    head: () => 'Price',
    cell: (r) => {
      const cents = r.source?.price;
      return !cents ? <Dash /> : <Currency cents={cents} currency="USD" />;
    },
    align: 'right',
    width: 72,
    flexGrow: 1
  },
  {
    key: 'availability',
    head: () => 'Stock',
    cell: (r) => {
      const availability = r.target?.availability ?? 'unknown';
      return (
        <WithShape
          shape="square"
          color={AVAILABILITY_COLORS[availability].backgroundColor}
        >
          {AVAILABILITY_TEXTS[availability].label}
        </WithShape>
      );
    },
    align: 'center',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'brand',
    head: () => 'Brand',
    cell: (r) => (
      <Truncated title={r.source?.brand ?? ''}>
        {r.source?.brand ?? ''}
      </Truncated>
    ),
    align: 'left',
    width: 120,
    flexGrow: 1
  },
  {
    key: 'clicks',
    head: () => metricTitle('c'),
    cell: (r) => (
      <CountCell
        before={0}
        after={r.source?.analytics?.c?.curr ?? 0}
        compare={false}
        dashWhenAllZero
      />
    ),
    align: 'right',
    sortable: true,
    width: 70,
    flexGrow: 1
  }
];

const CLICKS_SORTER: ItemSorter<ProductCatalogMatchQueryRow> = {
  key: 'clicks',
  items: {
    sort: (r) => r.source?.analytics?.c?.curr ?? 0,
    dir: 'desc'
  }
};

export const SegmentTopProducts: React.FC<{
  rows: ProductCatalogMatchQueryRow[];
}> = ({ rows }) => {
  return (
    <div
      className={css(() => ({
        overflowX: 'auto',
        maxHeight: 400,
        margin: '0 -24px'
      }))}
    >
      <RowsRenderer
        rows={rows}
        columns={columnDefinitions}
        sorter={CLICKS_SORTER}
        otherProps={undefined}
        renderHead
        rowToKey={(r) => r.source?.uid ?? ''}
      />
    </div>
  );
};
