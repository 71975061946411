import {
  BaseCampaign,
  CampaignCPCIncentive,
  CampaignFlatSpendIncentive,
  CampaignGoal,
  CampaignGoalType,
  CampaignIncentive,
  CampaignRateIncreaseIncentive
} from '../../../domainTypes/campaigns';
import { CampaignMetric } from '../components/report/CampaignMetricSelector';

type Campaign = Pick<BaseCampaign, 'goals' | 'incentives'>;

const getCampaignGoal = (
  campaign: Campaign,
  type: CampaignGoalType
): CampaignGoal | undefined => {
  return campaign.goals.find((g) => g.type === type);
};

export const hasROASGoal = (campaign: Campaign) => {
  return !!getCampaignGoal(campaign, 'roas');
};

export const hasGMVGoal = (campaign: Campaign) => {
  return !!getCampaignGoal(campaign, 'gmv');
};

export const getCampaignGoalForMetric = (
  campaign: Campaign,
  metric: CampaignMetric
) => {
  switch (metric) {
    case 'gmv_sum_net':
      return calculateEarningsGoal(campaign);
    case 'c':
      return getClicksGoal(campaign);
    case 'p':
      return getPageviewsGoal(campaign);
    case 'commission_sum_net':
      return getCampaignBudget(campaign);
  }
};

export const getClicksGoal = (campaign: Campaign): number | null => {
  const clicks = getCampaignGoal(campaign, 'clicks');
  return clicks ? clicks.amount : null;
};

export const getPageviewsGoal = (campaign: Campaign): number | null => {
  const pageviews = getCampaignGoal(campaign, 'pageviews');
  return pageviews ? pageviews.amount : null;
};

const isFlatSpend = (
  incentive: CampaignIncentive
): incentive is CampaignFlatSpendIncentive => incentive.type === 'flatSpend';

export const isRateIncreaseCampaignIncentive = (
  incentive: CampaignIncentive
): incentive is CampaignRateIncreaseIncentive =>
  incentive.type === 'rateIncrease';

export const getFlatSpend = (campaign: Campaign) =>
  campaign.incentives.find(isFlatSpend);

export const getFlatSpendAmount = (campaign: Campaign) =>
  getFlatSpend(campaign)?.amount ?? 0;

const getCampaignBudget = (campaign: Campaign) =>
  getCPCIncentive(campaign)?.limit ?? 0;

const isCPCIncentive = (
  incentive: CampaignIncentive
): incentive is CampaignCPCIncentive => incentive.type === 'cpc';

export const getCPCIncentive = (campaign: Campaign) =>
  campaign.incentives.find(isCPCIncentive);

export const calculateEarningsGoal = (campaign: Campaign): number | null => {
  const roas = getCampaignGoal(campaign, 'roas');
  if (roas) {
    const flatSpend = getFlatSpendAmount(campaign);
    return roas.amount * flatSpend;
  }
  const gmv = getCampaignGoal(campaign, 'gmv');
  if (gmv) {
    return gmv.amount;
  }
  return null;
};
