import { RunningSegmentCampaign } from '../service/segment-campaign';
import React from 'react';
import { Centered } from '../../../layout/Centered';
import { Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useRoutes } from '../../../routes';
import { Link } from 'react-router-dom';
import { FlexContainerVertical } from '../../../layout/Flex';

interface SegmentCampaignRunningProps {
  campaign: RunningSegmentCampaign;
}

export const SegmentCampaignRunning: React.FC<SegmentCampaignRunningProps> = ({
  campaign
}) => {
  const { ROUTES } = useRoutes();
  return (
    <Paper style={{ width: '80%', margin: '0 auto' }}>
      <Centered height={300}>
        <FlexContainerVertical alignItems="center">
          <Typography variant="body1" color="textSecondary">
            Campaign <strong>{campaign.name}</strong> is running
          </Typography>
          <Link to={ROUTES.campaigns.segment.report.url(campaign.id)}>
            <Typography variant="body2" color="primary">
              View campaign report
            </Typography>
          </Link>
        </FlexContainerVertical>
      </Centered>
    </Paper>
  );
};
