import {
  DimensionSettings,
  useCustomDimensions
} from '../../../../../../services/customDimensions';
import { capitalize, isNil } from 'lodash';
import {
  AnalyticsFilterMenuComponentProps,
  AnalyticsFilterUI
} from '../../../FilterUI';
import { slotTitle } from '../../../../../../domainTypes/customDimensions';
import {
  ClickDataFilterDefinition,
  ClickDataSlot,
  FilterDefinition
} from '../../../../../../domainTypes/filters';
import { ChipContent, formatList } from '../../common';
import { ClickDataMenu } from './ClickDataMenu';
import { useMemo } from 'react';
import { useCustomDimensionOptionLabel } from '../../../../../CustomDimensionOptionLabel';
import { EMPTY_ARR } from '../../../../../../domainTypes/emptyConstants';
import { useMappedLoadingValue } from '../../../../../../services/db';

const ChipLabel = ({ slot, value }: { slot: ClickDataSlot; value: string }) => {
  const label = useCustomDimensionOptionLabel(slot, value);
  return <>{label}</>;
};

const createCustomDimensionUI = (
  slot: ClickDataSlot,
  dimension: DimensionSettings
): AnalyticsFilterUI => {
  const description = dimension?.description ?? '';
  const name = dimension?.name ?? slotTitle(slot);
  const title = capitalize(name);
  return {
    type: 'analytics',
    dimension: slot,
    chip: ({ definition }) => {
      if (!definition) return <>{title}</>;
      if (definition.k !== slot) {
        throw new Error('Invalid definition');
      }
      return (
        <ChipContent title={`${title} is`}>
          {formatList(
            definition.v.map((v) => <ChipLabel key={v} slot={slot} value={v} />)
          )}
        </ChipContent>
      );
    },
    menu: ({ definition, ...props }: AnalyticsFilterMenuComponentProps) => (
      <ClickDataMenu
        {...props}
        definition={definition as ClickDataFilterDefinition}
        slot={slot}
        name={name}
        title={title}
        description={description}
      />
    ),
    init: () => ({
      k: slot,
      v: []
    }),
    toClauses: (definition: FilterDefinition) => {
      if (definition.k !== slot) {
        return [];
      }
      return [
        {
          field: slot,
          condition: 'in',
          values: definition.v
        }
      ];
    }
  };
};

export const useCustomDimensionsFilterUI = () => {
  const [dimensions = EMPTY_ARR] = useMappedLoadingValue(
    useCustomDimensions(),
    (dimensions) =>
      Object.entries(dimensions).filter(
        ([, settings]) => !isNil(settings)
      ) as Array<[ClickDataSlot, DimensionSettings]>
  );
  return useMemo<AnalyticsFilterUI[]>(
    () =>
      dimensions.map(([slot, settings]) =>
        createCustomDimensionUI(slot, settings)
      ),
    [dimensions]
  );
};
