import { useAllPagesQuery } from '../service';
import { AnalyticsFilter } from '../../../../../domainTypes/analytics_v2';

export const FetchAllPages = ({
  children,
  filters
}: {
  children: (props: { pages: string[] }) => JSX.Element;
  filters: AnalyticsFilter[];
}) => {
  const [pages = []] = useAllPagesQuery(filters);
  return children({ pages });
};
