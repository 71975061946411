import { CampaignsPageBody } from '../components/CampaignsPageBody';
import { InventoryOverview } from '../components/inventory/InventoryOverview';
import { Helmet } from 'react-helmet';

export const InventoryOverviewPage = () => {
  return (
    <>
      <Helmet>
        <title>Inventory | Affilimate</title>
      </Helmet>
      <CampaignsPageBody>
        <InventoryOverview />
      </CampaignsPageBody>
    </>
  );
};
