import React, { ReactNode } from 'react';
import Typography from '@material-ui/core/Typography';
import { take } from 'lodash';
import { coerceFilterMode, FilterMode } from '../../../../domainTypes/filters';

export const filterModeSuffix = (mode?: FilterMode) => {
  switch (coerceFilterMode(mode)) {
    case 'in':
      return 'is';
    case 'not_in':
      return 'is not';
  }
};

export const Segment: React.FC = ({ children }) => (
  <Typography variant="body2" component="span" style={{ fontWeight: 'bold' }}>
    {children}
  </Typography>
);

export const Or = () => (
  <Typography variant="body2" color="textSecondary" component="span">
    or
  </Typography>
);

export const formatList = (names: ReactNode[]): ReactNode[] => {
  const labels =
    names.length <= 4 ? names : [...take(names, 3), `${names.length - 3} more`];
  return labels.flatMap((segment, i) => {
    const isFirst = i === 0;
    return isFirst
      ? [<Segment key={i}>{segment}</Segment>]
      : [<Or />, <Segment key={i}>{segment}</Segment>];
  });
};

export const ChipContent: React.FC<{ title: string }> = ({
  children,
  title
}) => (
  <>
    <Typography variant="body2" component="span">
      {title}
    </Typography>
    {children}
  </>
);
