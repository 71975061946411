import React, { RefObject, useCallback, useRef, useState } from 'react';
import { And } from './And';
import { ButtonBase, Popover } from '@material-ui/core';
import {
  FilterDefinition,
  FiltersDefinition
} from '../../../../domainTypes/filters';
import { FilterUI, getFilterUI } from '../FilterUI';
import { css } from '../../../../emotion';
import { BaseChip, PlaceholderChip } from './Chip';
import { FilterMenuBody } from './FilterMenuBody';
import { FilterableDimension } from '../index';

interface FilterPlaceholderProps {
  anchorRef: RefObject<HTMLDivElement>;
  addFilter: (d: FilterDefinition) => void;
  filterUI: FilterUI;
  filters: Array<FilterDefinition>;
  clear: () => void;
}

const FilterPlaceholder: React.FC<FilterPlaceholderProps> = ({
  filterUI,
  addFilter,
  clear,
  anchorRef,
  filters
}) => {
  const isFirst = filters.length === 0;
  const definition = filterUI.init();
  const Chip = filterUI.chip;
  return (
    <>
      {!isFirst ? <And /> : null}
      <ButtonBase>
        <PlaceholderChip>
          <Chip />
        </PlaceholderChip>
      </ButtonBase>
      <Popover
        open={true}
        onClose={clear}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        style={{ marginTop: 10 }}
      >
        <FilterMenuBody
          filterUI={filterUI}
          initialDefinition={definition}
          onSave={addFilter}
          isFirst={isFirst}
        />
      </Popover>
    </>
  );
};

interface FilterChipProps {
  definition: FilterDefinition;
  filterUI: FilterUI;
  onChange: (definition: FilterDefinition) => void;
  onDelete: (dimension: FilterableDimension) => void;
  filters: Array<FilterDefinition>;
}

const FilterChip: React.FC<FilterChipProps> = ({
  onDelete,
  onChange,
  definition,
  filterUI,
  filters
}) => {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);
  const toggle = useCallback(() => setOpen((x) => !x), []);
  const close = useCallback(() => setOpen(false), []);
  const onSave = useCallback(
    (definition: FilterDefinition) => {
      onChange(definition);
      close();
    },
    [close, onChange]
  );
  const isFirst = filters.findIndex((f) => f.k === definition.k) === 0;
  const { chip: Chip } = filterUI;

  return (
    <>
      <Popover
        open={isOpen}
        onClose={close}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        style={{ marginTop: 10 }}
      >
        <FilterMenuBody
          initialDefinition={definition}
          onSave={onSave}
          filterUI={filterUI}
          isFirst={isFirst}
        />
      </Popover>
      <ButtonBase ref={ref}>
        <BaseChip onClick={toggle} onDelete={() => onDelete(definition.k)}>
          <Chip definition={definition} />
        </BaseChip>
      </ButtonBase>
    </>
  );
};

interface Props {
  filters: FiltersDefinition;
  placeHolderDimension: FilterableDimension | null;
  onChange: (def: FilterDefinition) => void;
  onDelete: (dimension: FilterableDimension) => void;
  addFilter: (d: FilterDefinition) => void;
  clearPlaceholder: () => void;
  uis: Array<FilterUI>;
}

export function FilterChipsList({
  filters,
  placeHolderDimension,
  onDelete,
  onChange,
  clearPlaceholder,
  addFilter,
  uis
}: Props) {
  const placeHolderRef = useRef<HTMLDivElement>(null);
  const placeholderUI =
    placeHolderDimension && getFilterUI(uis, placeHolderDimension);
  return (
    <>
      {filters.map((definition, i) => {
        const filterUI = getFilterUI(uis, definition.k);
        if (!filterUI) return null;
        const isFirst = i === 0;
        // Convert fragment into div if we want to avoid orphans
        return (
          <React.Fragment key={definition.k}>
            {isFirst ? null : <And />}
            <FilterChip
              // key={definition.k} NOTE: we need a key that changes with whole definition, JSON.stringify?
              definition={definition}
              onChange={onChange}
              onDelete={onDelete}
              filterUI={filterUI}
              filters={filters}
            />
          </React.Fragment>
        );
      })}
      <div
        ref={placeHolderRef}
        className={css((t) => ({
          alignItems: 'center',
          display: 'flex',
          columnGap: t.spacing(2)
        }))}
      >
        {placeholderUI && (
          <FilterPlaceholder
            filters={filters}
            anchorRef={placeHolderRef}
            addFilter={addFilter}
            clear={clearPlaceholder}
            filterUI={placeholderUI}
          />
        )}
      </div>
    </>
  );
}
