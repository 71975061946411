import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { compact, concat, isEmpty, isEqual, uniq, without } from 'lodash';
import pluralize from 'pluralize';
import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import {
  AnalyticsField,
  AnalyticsFilter,
  AnalyticsQuery,
  ISOTimeRange,
  SelectableField
} from '../../../../../domainTypes/analytics_v2';
import {
  coerceFilterMode,
  FilterMode
} from '../../../../../domainTypes/filters';
import {
  ProductCatalogField,
  ProductCatalogFilter,
  ProductCatalogQuery
} from '../../../../../domainTypes/productCatalog';
import { css, styled } from '../../../../../emotion';
import { useProductCatalogQueryForPublisherApp } from '../../../../../features/ProductCatalog/service';
import { FlexContainer } from '../../../../../layout/Flex';
import {
  Metric,
  metricName
} from '../../../../../services/analyticsV2/metrics';
import { useAnalyticsQueryV2 } from '../../../../../services/analyticsV2/query';
import { useCurrentUser } from '../../../../../services/currentUser';
import { useMappedLoadingValue } from '../../../../../services/db';
import { useSpaceId } from '../../../../../services/use-space-id';
import { Loader } from '../../../../Loader';
import { SearchInput, toSearchRegexp } from '../../../../SearchInput';
import { Truncated } from '../../../../Truncated';
import { FilterableDimension } from '../../index';

export function toggle<T>(collection: Array<T>, value: T): Array<T> {
  if (collection.includes(value)) {
    return without(collection, value);
  }
  return concat(collection, value);
}

export const useCollectionFilterStateWithMode = <
  Value,
  Definition extends { k: FilterableDimension; v: Value[]; mode?: FilterMode }
>(
  initialDefinition: Definition,
  onSave: (definition: Definition) => void,
  mode: FilterMode
): CollectionFilterState<Value> => {
  const [value, setValue] = useState(
    coerceFilterMode(initialDefinition.mode) === mode ? initialDefinition.v : []
  );

  const definition = useMemo<Definition>(
    () => ({ ...initialDefinition, v: value, mode }),
    [initialDefinition, value, mode]
  );

  const isSaveEnabled = useMemo(() => {
    return !isEqual(initialDefinition, definition) && !isEmpty(definition.v);
  }, [initialDefinition, definition]);

  const handleToggle = useCallback(
    (value: Value) => {
      setValue((prev) => toggle(prev, value));
    },
    [setValue]
  );

  const handleFocus = (value: Value) => {
    onSave({ ...initialDefinition, v: [value], mode });
  };

  return {
    values: definition.v,
    handleToggle,
    handleFocus,
    isSaveEnabled,
    handleSave: () => onSave(definition)
  };
};

interface CollectionFilterState<Value> {
  values: Value[];
  handleSave: () => void;
  handleToggle: (value: Value) => void;
  handleFocus: (value: Value) => void;
  isSaveEnabled: boolean;
}

export const useCollectionFilterState = <
  Value,
  Definition extends { k: FilterableDimension; v: Value[] }
>(
  initialDefinition: Definition,
  onSave: (definition: Definition) => void
): CollectionFilterState<Value> => {
  const [value, setValue] = useState(initialDefinition.v);

  const definition = useMemo<Definition>(
    () => ({ ...initialDefinition, v: value }),
    [initialDefinition, value]
  );

  const isSaveEnabled = useMemo(() => {
    return !isEqual(initialDefinition, definition) && !isEmpty(definition.v);
  }, [initialDefinition, definition]);

  const handleToggle = useCallback(
    (value: Value) => {
      setValue((prev) => toggle(prev, value));
    },
    [setValue]
  );

  const handleFocus = useCallback(
    (value: Value) => {
      onSave({ ...initialDefinition, v: [value] });
    },
    [initialDefinition, onSave]
  );

  const handleSave = useCallback(() => {
    onSave(definition);
  }, [onSave, definition]);

  return {
    values: definition.v,
    handleToggle,
    handleFocus,
    isSaveEnabled,
    handleSave
  };
};

export const useCollectionState = <T extends string>(
  initialValue: Array<T>
) => {
  const [value, setValue] = useState(initialValue);
  const toggleValue = useCallback(
    (key: T) => {
      setValue(toggle(value, key));
    },
    [setValue, value]
  );

  return [value, toggleValue] as const;
};

interface EntitySelectorMenuProps<T extends string> {
  label: string;
  toLabel?: (value: T) => ReactNode;
  options: Array<T>;
  selectedValues: Array<T>;
  onToggle: (value: T) => void;
  onFocus: (value: T) => void;
  queryFilters: AnalyticsFilter[];
  range: ISOTimeRange;
  analyticsField: AnalyticsField;
  metric?: SelectableField;
}

export function EnumSelectorMenu<T extends string>({
  label,
  toLabel = (str) => <Truncated title={str} />,
  options,
  selectedValues,
  onToggle,
  onFocus,
  analyticsField,
  queryFilters,
  range,
  metric = 'c'
}: EntitySelectorMenuProps<T>) {
  const { space } = useCurrentUser();
  const [search, setSearch] = useState('');
  const query = useMemo<AnalyticsQuery>(
    () => ({
      range,
      select: [metric],
      groupBy: [analyticsField],
      filters: queryFilters
    }),
    [range, metric, analyticsField, queryFilters]
  );

  const [fields = []] = useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    (r) => r.rows.map((r) => r.group[analyticsField] as string)
  );

  const expandedOptions = useMemo(() => {
    return options.map((o) => ({
      value: o,
      label: toLabel(o),
      disabled: !fields.includes(o)
    }));
  }, [fields, options, toLabel]);

  const filteredOptions = useMemo(() => {
    const searchRe = toSearchRegexp(search);
    return searchRe
      ? expandedOptions.filter((o) => o.value.match(searchRe))
      : expandedOptions;
  }, [expandedOptions, search]);

  return (
    <SelectorShell label={label} search={search} setSearch={setSearch}>
      <OptionsList
        options={filteredOptions}
        selectedValues={selectedValues}
        onToggle={onToggle}
        onFocus={onFocus}
      />
    </SelectorShell>
  );
}

interface ProductCatalogFieldSelectorMenuProps<T> {
  label: string;
  selectedValues: Array<T>;
  onToggle: (value: T) => void;
  onFocus: (value: T) => void;
  field: ProductCatalogField;
  filters: ProductCatalogFilter[];
  renderOption?: (value: T) => ReactNode;
  includeEmpty?: boolean;
}

export function ProductCatalogFieldSelectorMenu<T>({
  field,
  filters,
  selectedValues,
  onToggle,
  onFocus,
  label,
  includeEmpty = false,
  renderOption = _renderOption
}: ProductCatalogFieldSelectorMenuProps<T>) {
  const spaceId = useSpaceId();
  const [search, setSearch] = useState('');

  const query = useMemo<ProductCatalogQuery>(
    () => ({
      select: [field],
      groupBy: [field],
      limit: {
        page: 1,
        limit: TOP_N + 1
      },
      filters: compact([
        ...filters,
        !isEmpty(search) && {
          field,
          condition: 'ilike',
          pattern: `%${search}%`
        },
        !includeEmpty && {
          field,
          condition: 'not in',
          values: ['']
        }
      ]),
      orderBy: [
        {
          field,
          direction: 'ASC'
        }
      ]
    }),
    [field, filters, includeEmpty, search]
  );

  const rows = useProductCatalogQueryForPublisherApp(spaceId, query);

  const [hasMoreOptions = false] = useMappedLoadingValue(
    rows,
    (rows) => rows.length > TOP_N
  );

  const [fieldResults, loading] = useMappedLoadingValue(rows, (rows) =>
    rows.slice(0, TOP_N).map((r) => (r[field] as unknown) as T)
  );

  const options = useMemo(() => {
    if (!fieldResults) return fieldResults;
    const promoted = selectedValues.filter((v) => !fieldResults.includes(v));
    return [...promoted, ...fieldResults].map((v) => ({
      value: v,
      label: renderOption(v)
    }));
  }, [fieldResults, renderOption, selectedValues]);

  return (
    <SelectorShell label={label} search={search} setSearch={setSearch}>
      {!options || loading ? (
        <SelectorLoader />
      ) : (
        <OptionsList
          onFocus={onFocus}
          options={options}
          selectedValues={selectedValues}
          onToggle={onToggle}
          hasMore={hasMoreOptions}
        />
      )}
    </SelectorShell>
  );
}

interface AnalyticsFieldSelectorMenuProps<T> {
  label: string;
  selectedValues: Array<T>;
  onToggle: (value: T) => void;
  onFocus: (value: T) => void;
  queryFilters: AnalyticsFilter[];
  analyticsField: AnalyticsField;
  orderBy: Metric;
  range: ISOTimeRange;
  includeEmpty?: boolean;
  renderOption?: (value: T) => ReactNode;
}

export const TOP_N = 50;

const _renderOption = (v: unknown) => <Truncated title={String(v)} />;

export function AnalyticsFieldSelectorMenu<T>({
  analyticsField,
  queryFilters,
  orderBy,
  range,
  selectedValues,
  onToggle,
  onFocus,
  label,
  includeEmpty,
  renderOption = _renderOption
}: AnalyticsFieldSelectorMenuProps<T>) {
  const { space } = useCurrentUser();
  const [search, setSearch] = useState('');

  const query = useMemo<AnalyticsQuery>(
    () => ({
      range,
      // NOTE: we add 'c' to the select to ensure that we get the data for rows that don't have matching sales
      select: uniq([orderBy, 'c']),
      groupBy: [analyticsField],
      orderBy: [
        {
          field: orderBy,
          direction: 'DESC'
        }
      ],
      filters: compact([
        ...queryFilters,
        !includeEmpty && {
          field: analyticsField,
          condition: 'not in',
          values: ['']
        },
        search && {
          field: analyticsField,
          condition: 'ilike',
          pattern: `%${search}%`
        }
      ]),
      paginate: {
        page: 1,
        limit: TOP_N + 1
      }
    }),
    [range, orderBy, analyticsField, queryFilters, includeEmpty, search]
  );

  const rows = useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    (r) => r.rows
  );

  const [hasMoreOptions = false] = useMappedLoadingValue(
    rows,
    (rows) => rows.length > TOP_N
  );

  const [fieldResults, loading] = useMappedLoadingValue(rows, (rows) =>
    rows.slice(0, TOP_N).map(
      (r) =>
        // NOTE: T is opaque and a very underspecified type, there should be no harm in this assertion
        (r.group[analyticsField] as unknown) as T
    )
  );

  const options = useMemo(() => {
    if (!fieldResults) return fieldResults;
    const promoted = selectedValues.filter((v) => !fieldResults.includes(v));
    return [...promoted, ...fieldResults].map((v) => ({
      value: v,
      label: renderOption(v)
    }));
  }, [fieldResults, renderOption, selectedValues]);

  return (
    <SelectorShell label={label} search={search} setSearch={setSearch}>
      {!options || loading ? (
        <SelectorLoader />
      ) : (
        <OptionsList
          onFocus={onFocus}
          options={options}
          selectedValues={selectedValues}
          onToggle={onToggle}
          hasMore={hasMoreOptions}
          caption={`Top ${pluralize(label)} by ${metricName(orderBy)}`}
        />
      )}
    </SelectorShell>
  );
}

interface SelectorShellProps {
  label: string;
  search: string;
  setSearch: (search: string) => void;
  children: ReactNode;
}

export const SelectorShell = ({
  label,
  search,
  setSearch,
  children
}: SelectorShellProps) => (
  <FormControl
    component="fieldset"
    className={css(() => ({
      width: '100%'
    }))}
  >
    <div className={css((t) => ({ padding: t.spacing(1), width: '100%' }))}>
      <SearchInput
        autoFocus
        placeholder={`Search ${pluralize(label)}`}
        width={380 /* YIKES! */}
        fullWidth
        value={search}
        onChange={setSearch}
      />
    </div>
    <Divider variant="fullWidth" light />
    <div
      className={css(() => ({
        overflowY: 'scroll',
        maxHeight: 300,
        display: 'flex',
        flexDirection: 'column'
      }))}
    >
      {children}
    </div>
  </FormControl>
);

interface OptionsListProps<T> {
  options: Array<{
    value: T;
    disabled?: boolean;
    label: ReactNode;
  }>;
  selectedValues: Array<T>;
  onToggle: (value: T) => void;
  onFocus: (value: T) => void;
  hasMore?: boolean;
  caption?: string;
}

// NOTE: So div will take all available space but won't overflow: https://stackoverflow.com/a/66689926
const Label = styled('div')`
  min-width: 0;
`;

export function OptionsList<T>({
  options,
  selectedValues,
  onToggle,
  onFocus,
  hasMore,
  caption
}: OptionsListProps<T>) {
  if (isEmpty(options)) {
    return (
      <Typography
        variant="body2"
        color="textSecondary"
        className={css((t) => ({
          marginTop: t.spacing(2),
          padding: t.spacing(1),
          textAlign: 'center'
        }))}
      >
        Couldn't find any results. Try different filters.
      </Typography>
    );
  }

  return (
    <>
      {caption && (
        <Typography
          variant="caption"
          component="span"
          color="textSecondary"
          className={css((t) => ({
            marginLeft: t.spacing(1),
            marginTop: t.spacing(1)
          }))}
        >
          {caption}
        </Typography>
      )}
      {options.map((o) => {
        return (
          <FormControlLabel
            key={String(o.value)}
            control={
              <Checkbox
                checked={selectedValues.includes(o.value)}
                color="primary"
                value={o.value}
                onChange={() => onToggle(o.value)}
                disabled={o.disabled}
              />
            }
            classes={{
              label: css(() => ({
                minWidth: 0,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexGrow: 1,
                opacity: o.disabled ? 0.5 : 1
              })),
              root: css(() => ({
                marginLeft: '0 !important',
                marginRight: '0 !important'
              }))
            }}
            label={
              <>
                <Label>{o.label}</Label>
                <Button
                  size="small"
                  variant="text"
                  color="primary"
                  onClick={() => onFocus(o.value)}
                >
                  Only
                </Button>
              </>
            }
          />
        );
      })}
      {hasMore && (
        <Typography
          variant="caption"
          component="span"
          color="textSecondary"
          className={css((t) => ({
            textAlign: 'center',
            marginTop: t.spacing(1.5)
          }))}
          paragraph
        >
          Search to reveal more results
        </Typography>
      )}
    </>
  );
}

export type Item = { id: string; label: ReactNode };
export type Option = {
  expanded?: boolean;
  key: string;
  label: ReactNode;
  items: Array<Item>;
  hasMore?: boolean;
};

interface GroupedOptionListProps {
  options: Array<Option>;
  selectedValues: Array<string>;
  onToggle: (value: string) => void;
  onFocus: (value: string) => void;
  caption?: string;
}

export const GroupedOptionList: React.FC<GroupedOptionListProps> = ({
  options,
  onToggle,
  onFocus,
  selectedValues,
  caption
}) => {
  const [expanded, setExpanded] = useState(() => {
    const groups = options.filter(
      (group) =>
        group.expanded ||
        group.items.some((item) => selectedValues.includes(item.id))
    );
    return new Set(groups.map((group) => group.key));
  });

  const toggleExpanded = useCallback((key: string) => {
    setExpanded((prev) => {
      const next = new Set(prev);
      if (prev.has(key)) {
        next.delete(key);
      } else {
        next.add(key);
      }
      return next;
    });
  }, []);

  return (
    <>
      {caption && (
        <Typography
          variant="caption"
          component="span"
          color="textSecondary"
          className={css((t) => ({
            marginLeft: t.spacing(1),
            marginTop: t.spacing(1)
          }))}
        >
          {caption}
        </Typography>
      )}
      {options.map((group) => {
        return (
          <div key={group.key}>
            <FlexContainer
              role="button"
              justifyContent="space-between"
              onClick={() => toggleExpanded(group.key)}
              className={css((t) => ({
                padding: `0 ${t.spacing(2)}px`,
                borderBottom: t.custom.border.standard
              }))}
            >
              <Typography
                className={css(() => ({
                  cursor: 'pointer'
                }))}
              >
                {group.label}
              </Typography>
              <IconButton>
                {expanded.has(group.key) ? (
                  <ChevronUp size={16} />
                ) : (
                  <ChevronDown size={16} />
                )}
              </IconButton>
            </FlexContainer>
            {expanded.has(group.key) && (
              <FlexContainer
                className={css((t) => ({
                  backgroundColor: t.palette.grey[100],
                  borderBottom: t.custom.border.standard
                }))}
                direction="column"
                alignItems="flex-start"
              >
                {group.items.map((item) => {
                  return (
                    <>
                      <FormControlLabel
                        key={item.id}
                        classes={{
                          label: css(() => ({
                            minWidth: 0,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            flexGrow: 1
                          })),
                          root: css(() => ({
                            width: '100%',
                            marginLeft: '0 !important',
                            marginRight: '0 !important'
                          }))
                        }}
                        control={
                          <Checkbox
                            checked={selectedValues.includes(item.id)}
                            color="primary"
                            value={item.id}
                            onChange={() => onToggle(item.id)}
                          />
                        }
                        label={
                          <>
                            <Label>{item.label}</Label>
                            <Button
                              size="small"
                              variant="text"
                              color="primary"
                              onClick={() => onFocus(item.id)}
                            >
                              Only
                            </Button>
                          </>
                        }
                      />
                    </>
                  );
                })}
                {group.hasMore && (
                  <Typography
                    variant="caption"
                    component="span"
                    color="textSecondary"
                    className={css((t) => ({
                      width: '100%',
                      textAlign: 'center',
                      marginTop: t.spacing(1.5)
                    }))}
                    paragraph
                  >
                    Search to reveal more results
                  </Typography>
                )}
              </FlexContainer>
            )}
          </div>
        );
      })}
    </>
  );
};

export const SelectorLoader = () => <Loader height={80} size={24} />;
