import React, { useMemo } from 'react';
import { AnalyticsTableMetadata } from '../../../../../components/analytics_v2/Table';
import { FlagWithLabel } from '../../../../../components/Flag';
import {
  AnalyticsField,
  AnalyticsFilter,
  AnalyticsResponseRowWithComparison,
  ISOTimeRange
} from '../../../../../domainTypes/analytics_v2';
import {
  useRealtimeColumnTransformers,
  useRealtimeMetric,
  useSmallTableMetrics
} from '../service';
import {
  createNameColumn,
  TopPaper,
  TopTable,
  TopHeader
} from '../../../../../components/analytics_v2/Table/TopTable/TopTable';

const GROUP_FIELD: AnalyticsField = 'country';

const cell = (
  p: AnalyticsResponseRowWithComparison,
  o: AnalyticsTableMetadata
) => {
  const country = p.group[GROUP_FIELD];
  return <FlagWithLabel code={country} />;
};

export const TopCountriesTable = ({
  range,
  compare,
  color,
  filters
}: {
  range: ISOTimeRange;
  compare?: ISOTimeRange;
  color: string;
  filters: AnalyticsFilter[];
}) => {
  const [metric] = useRealtimeMetric();
  const metrics = useSmallTableMetrics();
  const columnTransformers = useRealtimeColumnTransformers(filters);
  const countryColumn = useMemo(
    () => createNameColumn(cell, 'Country', color, metric),
    [color, metric]
  );
  return (
    <TopPaper>
      <TopHeader
        title="Top countries"
        description="Engagement and performance by visitor country."
      ></TopHeader>
      <TopTable
        filters={filters}
        columnTransformers={columnTransformers}
        availableMetrics={metrics}
        range={range}
        compareRange={compare}
        metric={metric}
        groupField={GROUP_FIELD}
        nameColumn={countryColumn}
      />
    </TopPaper>
  );
};
