import { styled } from '../../emotion';
import { Truncated } from '../Truncated';

const retailerConfig = {
  Amazon: {
    name: 'Amazon',
    website: 'amazon.com'
  },
  'Best Buy': {
    name: 'Best Buy',
    website: 'bestbuy.com'
  },
  Bose: {
    name: 'Bose',
    website: 'bose.com'
  },
  Kiehls: {
    name: 'Kiehls',
    website: 'kiehls.com'
  },
  'Kiwi Co': {
    name: 'Kiwi Co',
    website: 'kiwico.com'
  },
  'LG Electronics': {
    name: 'LG Electronics',
    website: 'lg.com'
  },
  "Monica's Shop": {
    name: "Monica's Shop",
    website: 'affilimate.com'
  },
  'Office Depot and OfficeMax': {
    name: 'Office Depot and OfficeMax',
    website: 'officedepot.com'
  },
  "Victoria's Secret": {
    name: "Victoria's Secret",
    website: 'victoriassecret.com'
  },
  Revolve: {
    name: 'Revolve',
    website: 'revolve.com'
  },
  Withings: {
    name: 'Withings',
    website: 'withings.com'
  },
  'Yankee Candle': {
    name: 'Yankee Candle',
    website: 'yankeecandle.com'
  },
  Travelpro: {
    name: 'Travelpro',
    website: 'travelpro.com'
  }
};

const RetailerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const RetailerIcon = styled.img`
  width: 18px;
  height: 18px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
`;

export const RetailerName = ({ retailer }: { retailer: string }) => {
  if (retailerConfig[retailer as keyof typeof retailerConfig]) {
    const { name, website } = retailerConfig[
      retailer as keyof typeof retailerConfig
    ];
    return (
      <RetailerWrapper>
        <RetailerIcon src={`https://logo.clearbit.com/${website}`} alt={name} />
        <Truncated title={name}>{name}</Truncated>
      </RetailerWrapper>
    );
  }
  return <>{retailer}</>;
};
