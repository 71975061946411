import { compact, isEqual, sortBy } from 'lodash';
import pluralize from 'pluralize';
import React, { useCallback, useMemo, useState } from 'react';
import {
  AnalyticsFilter,
  AnalyticsQuery,
  ISOTimeRange
} from '../../../../../../domainTypes/analytics_v2';
import { LinkAdvertiserFilterDefinition } from '../../../../../../domainTypes/filters';
import { useAnalyticsQueryV2 } from '../../../../../../services/analyticsV2/query';
import { useCurrentUser } from '../../../../../../services/currentUser';
import { useMappedLoadingValue } from '../../../../../../services/db';
import { Loader } from '../../../../../Loader';
import { toSearchRegexp } from '../../../../../SearchInput';
import { BASIC_MODES, FilterMenu } from '../../Menus/FilterMenu';
import {
  OptionsList,
  SelectorLoader,
  SelectorShell,
  toggle
} from '../../Menus/Selector';
import { AnalyticsFilterMenuComponent } from '../../../FilterUI';

interface LinkAdvertiserDomainMenuBodyProps {
  onChange: (value: Array<string>) => void;
  onSave: (v: LinkAdvertiserFilterDefinition) => void;
  filters: AnalyticsFilter[];
  range: ISOTimeRange;
  value: Array<string>;
}

const NONE = 'None';
const LIMIT = 50;

const useLinkAdvertiserDomains = (
  filters: AnalyticsFilter[],
  range: ISOTimeRange,
  search: string
) => {
  const { space } = useCurrentUser();
  const query = useMemo<AnalyticsQuery>(() => {
    return {
      range,
      select: ['c'],
      groupBy: ['l_advertiser_domain'],
      filters: compact([
        ...filters,
        search && {
          field: 'l_advertiser_domain',
          condition: 'ilike',
          pattern: `%${search}%`
        }
      ]),
      orderBy: [{ field: 'c', direction: 'DESC' }],
      paginate: {
        page: 1,
        limit: LIMIT
      }
    };
  }, [filters, range, search]);

  return useMappedLoadingValue(useAnalyticsQueryV2(space.id, query), (res) => {
    return sortBy(
      res.rows.map((row) => {
        const advertiserDomain = row.group.l_advertiser_domain || NONE;
        return {
          label: advertiserDomain,
          value: advertiserDomain,
          searchValue: advertiserDomain.toLowerCase()
        };
      }),
      (x) => (x.value === NONE ? 'zzzzzzzzzzzzzz' : x.value)
    );
  });
};

const LinkAdvertiserDomainMenuBody: React.FC<LinkAdvertiserDomainMenuBodyProps> = ({
  range,
  onChange,
  filters,
  onSave,
  value
}) => {
  const [search, setSearch] = useState('');

  const [options, loading] = useLinkAdvertiserDomains(filters, range, search);

  const _toggle = useCallback(
    (platform: string) => onChange(toggle(value, platform)),
    [onChange, value]
  );

  const focus = useCallback(
    (platform: string) =>
      onSave({
        k: 'l_advertiser_domain',
        v: [platform]
      }),
    [onSave]
  );

  const filteredOptions = useMemo(() => {
    const searchRe = toSearchRegexp(search);
    if (!options) {
      return [];
    }
    if (!searchRe) {
      return options;
    }
    return options.filter((o) => o.searchValue.match(searchRe));
  }, [options, search]);

  if (!options || loading) {
    return <Loader size={24} />;
  }

  return (
    <SelectorShell
      label="Deeplink domain"
      search={search}
      setSearch={setSearch}
    >
      {!options || loading ? (
        <SelectorLoader />
      ) : (
        <OptionsList
          options={filteredOptions}
          selectedValues={value}
          onToggle={_toggle}
          onFocus={focus}
        />
      )}
    </SelectorShell>
  );
};

export const LinkAdvertiserDomainMenu: AnalyticsFilterMenuComponent<LinkAdvertiserFilterDefinition> = ({
  definition,
  onSave,
  context,
  isFirst
}) => {
  const [mode, setMode] = useState(BASIC_MODES[0].value);
  const [value, setValue] = useState(definition.v);
  const newDefinition = useMemo<LinkAdvertiserFilterDefinition>(
    () => ({
      k: 'l_advertiser_domain',
      v: value
    }),
    [value]
  );
  const enableSave =
    newDefinition.v.length > 0 && !isEqual(definition, newDefinition);

  return (
    <FilterMenu>
      <FilterMenu.Header name={'Deeplink domain'} isFirst={isFirst}>
        <FilterMenu.ModeSelector
          modes={BASIC_MODES}
          mode={mode}
          setMode={setMode}
        />
      </FilterMenu.Header>
      <FilterMenu.Body>
        <LinkAdvertiserDomainMenuBody
          filters={context.baseQuery.filters}
          range={context.baseQuery.range}
          onSave={onSave}
          onChange={setValue}
          value={value}
        />
      </FilterMenu.Body>
      <FilterMenu.Footer
        description={
          <>
            <strong>Deeplink domain</strong> is the domain of the site being
            deeplinked in an affiliate link.
          </>
        }
      >
        <FilterMenu.SaveButton
          disabled={!enableSave}
          onSave={() => onSave(newDefinition)}
          label={`Filter by ${pluralize(
            'Deeplink domain',
            value.length,
            true
          )}`}
        />
      </FilterMenu.Footer>
    </FilterMenu>
  );
};
