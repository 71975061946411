import { AnalyticsFilterUI } from '../../../FilterUI';
import React from 'react';
import { ChipContent, formatList } from '../../common';
import { PayoutIdMenu } from './PayoutIdMenu';
import { PayoutIdFilterDefinition } from '../../../../../../domainTypes/filters';

export const payoutIdFilterUIDef: AnalyticsFilterUI = {
  type: 'analytics',
  dimension: 'payout_id',
  chip: ({ definition }) => {
    if (!definition) return <>Payout ID</>;
    if (definition.k !== 'payout_id') {
      throw new Error('Invalid definition');
    }
    return (
      <ChipContent title="Payout ID is">{formatList(definition.v)}</ChipContent>
    );
  },
  menu: function PayoutIdFilterUIMenu({ definition, ...props }) {
    return (
      <PayoutIdMenu
        definition={definition as PayoutIdFilterDefinition}
        {...props}
      />
    );
  },
  init: () => ({
    k: 'payout_id',
    v: []
  }),
  toClauses: (definition) => {
    if (definition.k !== 'payout_id') {
      return [];
    }
    return [
      {
        field: 'payout_id',
        condition: 'in',
        values: definition.v
      }
    ];
  }
};
