import { FilterDefinition } from '../../../../domainTypes/filters';
import { Button } from '@material-ui/core';
import { css } from '../../../../emotion';
import Typography from '@material-ui/core/Typography';
import React from 'react';

export const ClearAll = ({
  clearFilters,
  filters
}: {
  clearFilters: () => void;
  filters: FilterDefinition[];
}) => {
  if (filters.length === 0) return null;
  return (
    <Button
      onClick={clearFilters}
      className={css(() => ({
        marginLeft: 'auto'
      }))}
    >
      <Typography variant="caption">Clear all</Typography>
    </Button>
  );
};
