import { Button, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { FilterMode } from '../../../../../domainTypes/filters';
import { css, styled } from '../../../../../emotion';
import { FlexContainer } from '../../../../../layout/Flex';
import pluralize from 'pluralize';
import { HelpIcon } from '../../../../HelpIcon';
import { ARTICLE_IDS, Beacon } from '../../../../../services/beacon';
import Typography from '@material-ui/core/Typography';

type FilterMenuCompoundComponent = React.FC & {
  Header: React.FC<HeaderProps>;
  ModeSelector: <T extends string>(
    props: ModeSelectorProps<T>
  ) => React.ReactElement;
  Body: React.FC;
  Footer: React.FC<FooterProps>;
  SaveButton: React.FC<SaveButtonProps>;
};

const FILTER_MENU_WIDTH = 400;

export const FilterMenu: FilterMenuCompoundComponent = ({ children }) => {
  return (
    <div className={css(() => ({ maxWidth: FILTER_MENU_WIDTH }))}>
      {children}
    </div>
  );
};

interface HeaderProps {
  name: string;
  isFirst: boolean;
}

const capitalizeFirst = (name: string) => {
  return `${name.charAt(0).toUpperCase()}${name.slice(1)}`;
};
const Header: React.FC<HeaderProps> = ({ name, isFirst, children }) => {
  const titleWithPrefix = isFirst ? capitalizeFirst(name) : `And ${name}`;
  return (
    <div className={css((t) => ({ padding: t.spacing(2) }))}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {titleWithPrefix}
        <span className={css((t) => ({ marginLeft: t.spacing(0.5) }))}>
          {children}
        </span>
      </Typography>
    </div>
  );
};

export const BASIC_MODES = [
  {
    value: 'oneOf',
    label: 'one of'
  }
];

export const ADVANCED_MODES: Array<{ label: string; value: FilterMode }> = [
  {
    value: 'in',
    label: 'one of'
  },
  {
    value: 'not_in',
    label: 'none of'
  }
];

export const saveButtonLabel = (
  noun: string,
  count: number,
  mode: FilterMode
) =>
  `${mode === 'in' ? 'Filter by' : 'Filter out'}  ${pluralize(
    noun,
    count,
    true
  )}`;

interface ModeSelectorProps<T extends string> {
  modes: Array<{ label: string; value: T }>;
  mode: T;
  setMode: (m: T) => void;
}

const ModeSelector = <T extends string>({
  modes,
  mode,
  setMode
}: ModeSelectorProps<T>) => (
  <Select
    value={mode}
    onChange={(e) => setMode(e.target.value as T)}
    variant="standard"
    disableUnderline
    className={css((t) => ({
      fontSize: t.typography.h6.fontSize
    }))}
  >
    {modes.map((m) => (
      <MenuItem key={m.value} value={m.value}>
        {m.label}
      </MenuItem>
    ))}
  </Select>
);

const Body: React.FC = ({ children }) => {
  return (
    <div>
      {children}
      <ReactResizeDetector
        handleHeight
        onResize={() => {
          window.dispatchEvent(new CustomEvent('resize'));
        }}
      />
    </div>
  );
};

const DimensionDescriptionBox = styled('div')`
  color: ${(t) => t.theme.palette.grey['700']};
`;

const LearnMore = () => (
  <HelpIcon
    style={{
      marginLeft: 5
    }}
    color="gray"
    onClick={() => {
      Beacon('article', ARTICLE_IDS.content.filters);
    }}
  >
    Learn more
  </HelpIcon>
);

interface FooterProps {
  description: React.ReactElement;
}

const Footer: React.FC<FooterProps> = ({ description, children }) => {
  return (
    <FlexContainer
      direction="column"
      spacing={2}
      className={css((t) => ({
        padding: t.spacing(2),
        backgroundColor: t.palette.grey['100']
      }))}
    >
      <DimensionDescriptionBox>
        {description}
        <LearnMore />
      </DimensionDescriptionBox>
      {children}
    </FlexContainer>
  );
};

interface SaveButtonProps {
  onSave: () => void;
  disabled: boolean;
  label: string;
}

const SaveButton: React.FC<SaveButtonProps> = ({ onSave, disabled, label }) => (
  <Button
    onClick={onSave}
    variant="contained"
    color="primary"
    disabled={disabled}
    fullWidth
  >
    {label}
  </Button>
);

FilterMenu.Footer = Footer;
FilterMenu.Body = Body;
FilterMenu.ModeSelector = ModeSelector;
FilterMenu.Header = Header;
FilterMenu.SaveButton = SaveButton;
