import { isNil } from 'lodash';
import { Target } from 'react-feather';
import React from 'react';
import { DotProps } from 'recharts';
import { CampaignReportGranularity } from '../../../service/reports';

export type CustomDotProps = DotProps & {
  value?: number;
  index: number;
  granularity: CampaignReportGranularity;
};

const isLast = (i: number, granularity: CampaignReportGranularity) => {
  switch (granularity) {
    case 'month':
      return i === 11;
  }
};

export const CustomDot = ({
  className,
  cx,
  cy,
  index,
  r,
  value,
  granularity
}: CustomDotProps) => {
  if (isNil(value) || index === 0) return null;
  if (isLast(index, granularity)) {
    const size = 22;
    return (
      <g
        transform={`translate(${(cx ?? 0) - size / 2}, ${
          (cy ?? 0) - size / 2
        })`}
      >
        <Target size={size} />
      </g>
    );
  }
  return (
    <circle
      r={r}
      stroke="#000"
      strokeWidth={1.4}
      fill="#fff"
      width="710"
      height="360"
      className={className}
      cx={cx}
      cy={cy}
    />
  );
};
