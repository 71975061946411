import { AnalyticsFilterUI } from '../../../FilterUI';
import { ChipContent, filterModeSuffix, formatList } from '../../common';
import React from 'react';
import { AdvertiserMenu } from './AdvertiserMenu';
import {
  AdvertiserFilterDefinition,
  FilterDefinition
} from '../../../../../../domainTypes/filters';
import { modeToCondition } from '../../../useAnalyticsFilters';

export const advertiserFilterUIDef: AnalyticsFilterUI = {
  type: 'analytics',
  dimension: 'advertiser',
  chip: ({ definition }) => {
    if (!definition) return <>Advertiser</>;
    if (definition.k !== 'advertiser') {
      throw new Error('Invalid definition');
    }
    return (
      <ChipContent title={`Advertiser ${filterModeSuffix(definition.mode)}`}>
        {formatList(definition.v)}
      </ChipContent>
    );
  },
  menu: function AdvertiserFilterUIMenu({ definition, ...props }) {
    return (
      <AdvertiserMenu
        definition={definition as AdvertiserFilterDefinition}
        {...props}
      />
    );
  },
  init: () => ({
    k: 'advertiser',
    v: []
  }),
  toClauses: (definition: FilterDefinition) => {
    if (definition.k !== 'advertiser') {
      return [];
    }
    return [
      {
        field: 'advertiser_name',
        condition: modeToCondition(definition.mode),
        values: definition.v
      }
    ];
  }
};
