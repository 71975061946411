import { AnalyticsFilterUI } from '../../../FilterUI';
import React, { ReactNode } from 'react';
import { take } from 'lodash';
import { InternalId } from '../../../../../ConnectionId';
import { ChipContent, Or, Segment } from '../../common';
import { IntegrationIdFilterDefinition } from '../../../../../../domainTypes/filters';
import { IntegrationIdMenu } from './IntegrationIdMenu';

const formatIntegrationIdList = (ids: string[]): ReactNode[] => {
  const labels =
    ids.length <= 4 ? ids : [...take(ids, 3), `${ids.length - 3} more`];
  return labels.flatMap((segment, i) => {
    const isFirst = i === 0;
    return isFirst
      ? [
          <Segment key={i}>
            <InternalId>{segment}</InternalId>
          </Segment>
        ]
      : [
          <Or />,
          <Segment key={i}>
            <InternalId>{segment}</InternalId>
          </Segment>
        ];
  });
};

export const integrationIDFilterUIDef: AnalyticsFilterUI = {
  type: 'analytics',
  dimension: 'integration_id',
  chip: ({ definition }) => {
    if (!definition) return <>Integration ID</>;
    if (definition.k !== 'integration_id') {
      throw new Error('Invalid definition');
    }
    return (
      <ChipContent title="Integration ID is">
        {formatIntegrationIdList(definition.v)}
      </ChipContent>
    );
  },
  menu: function IntegrationIDFilterUIMenu({ definition, ...props }) {
    return (
      <IntegrationIdMenu
        definition={definition as IntegrationIdFilterDefinition}
        {...props}
      />
    );
  },
  init: () => ({
    k: 'integration_id',
    v: []
  }),
  toClauses: (definition) => {
    if (definition.k !== 'integration_id') {
      return [];
    }
    return [
      {
        field: 'integration_id',
        condition: 'in',
        values: definition.v
      }
    ];
  }
};
