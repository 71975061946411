import React from 'react';
import { Helmet } from 'react-helmet';
import { DomainsContent } from './DomainsContent';

export const PageLinksDomains = () => {
  return (
    <>
      <Helmet>
        <title>Linked Domains | Affilimate</title>
      </Helmet>
      <DomainsContent />
    </>
  );
};
