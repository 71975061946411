import { CachedAdvertiser } from './advertiser';
import { ProductCatalogFilter } from './productCatalog';
import { ISpaceProfile } from './space';
import { Timestamp } from './time';

export type CampaignFlatSpendIncentive = {
  type: 'flatSpend';
  amount: number;
  description: string;
};

export type CampaignRateIncreaseIncentive = {
  type: 'rateIncrease';
  from: number;
  to: number;
  description: string;
};

export interface CampaignCPCIncentive {
  type: 'cpc';
  amount: number;
  limit: number;
}

export type CampaignIncentiveType = CampaignIncentive['type'];

export type CampaignIncentive =
  | CampaignCPCIncentive
  | CampaignFlatSpendIncentive
  | CampaignRateIncreaseIncentive;

export const defaultIncentive = (
  type: CampaignIncentiveType
): CampaignIncentive => {
  switch (type) {
    case 'cpc':
      return {
        type: 'cpc',
        amount: 0,
        limit: 0
      };
    case 'flatSpend':
      return {
        type: 'flatSpend',
        amount: 0,
        description: ''
      };
    case 'rateIncrease':
      return {
        type: 'rateIncrease',
        from: 0,
        to: 0,
        description: ''
      };
  }
};

export type CampaignGoal =
  | {
      type: 'roas';
      amount: number;
    }
  | {
      type: 'gmv';
      amount: number;
    }
  | {
      type: 'clicks';
      amount: number;
    }
  | {
      type: 'pageviews';
      amount: number;
    };

export type CampaignGoalType = CampaignGoal['type'];

export type CampaignGoals = Array<CampaignGoal>;

export type CampaignAdvertiser = Pick<
  CachedAdvertiser,
  'advertiserId' | 'name' | 'partnerKey' | 'integrationId'
>;

export const isOffsiteLink = (
  link: CampaignLink
): link is CampaignOffsiteLink => link.type === 'offsite';

export const isOnsiteLink = (link: CampaignLink): link is CampaignOnsiteLink =>
  link.type === 'onsite';

export type CampaignOffsiteLink = {
  type: 'offsite';
  id: string;
  channels: string[];
};

export type CampaignOnsiteLink = {
  type: 'onsite';
  id: string;
  pageUrls: string[];
};

export type CampaignLink = CampaignOnsiteLink | CampaignOffsiteLink;

export type CampaignType = 'manual' | 'segment';

export interface BaseCampaign {
  type: CampaignType;
  spaceId: string;
  name: string;
  id: string;
  team: string;
  managers: string[];
  incentives: CampaignIncentive[];
  goals: CampaignGoal[];
}

export interface IManualCampaign extends BaseCampaign {
  type: 'manual';
  advertisers: Array<CampaignAdvertiser>;
  pageUrls: string[];
  timeframe: {
    start: Timestamp;
    end: Timestamp;
  } | null;
  links: CampaignLink[];
}

interface BaseCampaignSegment {
  type: 'filterBased';
  id: string;
  maId: string;
  name: string;
  spaceId: string;
}

interface FiltersBasedSegment extends BaseCampaignSegment {
  filters: ProductCatalogFilter[];
  exclusions?: string[];
  remappings?: Record<string, string>;
}

export type CampaignSegment = FiltersBasedSegment;

export type CampaignPartner = {
  type: 'partner';
  id: string;
};
// | {
//     type: 'invited';
//     name: string;
//     email: string;
//   };

export const segmentCampaignStatuses = [
  'draft',
  'proposed',
  'approved',
  'scheduled',
  'running',
  'completed'
] as const;

export type SegmentCampaignStatus = typeof segmentCampaignStatuses[number];

export interface SegmentCampaignLogEntry {
  userId: string;
  timestamp: Timestamp;
  operation:
    | 'save-draft'
    | 'propose'
    | 'approve'
    | 'schedule'
    | 'adjust-proposal'
    | 'started'
    | 'limit-reached'
    | 'completed'
    | 'paused'
    | 'unpaused';
}

export interface ISegmentCampaign extends BaseCampaign {
  spaceProfile: ISpaceProfile;
  partner: CampaignPartner | null;
  status: SegmentCampaignStatus;
  segments: CampaignSegment[];
  pageConstraints: {
    include: string[];
    exclude: string[];
  };
  paused: boolean;
  timeframe: {
    start: Timestamp;
    end: Timestamp;
  } | null;
  log: SegmentCampaignLogEntry[];
}

export const CAMPAIGN_ANALYTICS_DEFAULT_LAST_X_DAYS = 30;
