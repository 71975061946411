import React from 'react';
import { CampaignReportAttribution } from '../service/reports';
import { SingleSelector } from '../../../components/SingleSelector';
import Typography from '@material-ui/core/Typography';
import { ChevronDown, Sidebar } from 'react-feather';

const label = (value: CampaignReportAttribution) => {
  switch (value) {
    case 'start':
      return 'Campaign start';
    case 'end':
      return 'Campaign end';
    case 'spread':
      return 'Spread evenly';
  }
};

const attributionValues = ['start', 'end', 'spread'] as const;

const options = attributionValues.map((value) => ({
  label: label(value),
  value,
  searchValue: label(value)
}));

export const CampaignReportAttributionSelector: React.FC<{
  value: CampaignReportAttribution;
  onChange: (nextValue: CampaignReportAttribution) => void;
}> = ({ value, onChange }) => {
  return (
    <SingleSelector
      value={value}
      onChange={onChange}
      options={options}
      noSearch
    >
      <Typography
        variant="body2"
        color="textSecondary"
        component="span"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '6px'
        }}
      >
        <Sidebar size={18} /> Attribution: <strong>{label(value)}</strong>
        <ChevronDown size={18} />
      </Typography>
    </SingleSelector>
  );
};
