import { Card, CardContent } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';
import { NoPermissions } from '../../../../components/NoPermissions';
import { CanvasBar } from '../../../../layout/Canvas';
import { LimitedWidth } from '../../../../layout/PageBody';
import { Section } from '../../../../layout/Section';
import {
  useCurrentUser,
  useCurrentUserScopes
} from '../../../../services/currentUser';
import { useTrackMixpanelView } from '../../../../services/mixpanel';
import { AmazonApiSettings } from '../../components/AmazonApiSettings';
import { PageBodyLinks } from '../../components/PageBodyLinks';

export const PageLinksAmazonSettings = () => {
  const { space } = useCurrentUser();
  const scopes = useCurrentUserScopes();
  const canViewAmazonLinkSettings = scopes.has('link_checker.settings.view');

  useTrackMixpanelView('view_amazon_settings');

  return (
    <PageBodyLinks>
      <Helmet>
        <title>Amazon Link Settings | Affilimate</title>
      </Helmet>
      {canViewAmazonLinkSettings ? (
        <LimitedWidth width={800}>
          <Section>
            <CanvasBar>Amazon Link Check Settings</CanvasBar>
            <Card>
              <CardContent>
                <AmazonApiSettings space={space} />
              </CardContent>
            </Card>
          </Section>
        </LimitedWidth>
      ) : (
        <NoPermissions />
      )}
    </PageBodyLinks>
  );
};
