import { Typography } from '@material-ui/core';
import { compact } from 'lodash';
import moment from 'moment-timezone';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Loader } from '../../../../components/Loader';
import { COLORS } from '../../../../domainTypes/colors';
import { ISOString } from '../../../../domainTypes/etl';
import { css, styled } from '../../../../emotion';
import { Centered } from '../../../../layout/Centered';
import { FlexContainer } from '../../../../layout/Flex';
import { PageBody } from '../../../../layout/PageBody';
import {
  DEFAULT_OFFSET,
  PageToolbar,
  PageToolbarOtherRow,
  PageToolbarSection
} from '../../../../layout/PageToolbar';
import { Section } from '../../../../layout/Section';
import {
  useCurrentUser,
  useCurrentUserScopes
} from '../../../../services/currentUser';
import { useFeatureEnabled } from '../../../../services/features';
import { useMixpanel } from '../../../../services/mixpanel';
import { useLastUpdate } from '../../../../services/realtime/monitors';
import { RealtimeMetricSelector } from './components/MetricSelector';
import { PageSearchBox, usePageSearch } from './components/PageSearchBox';
import { PeriodSelector, usePeriodRange } from './components/PeriodSelector';
import { RealtimeChart } from './components/RealtimeChart';
import { RealtimeMetricCards } from './components/RealtimeMetricCards';
import { RefreshButton } from './components/RefreshButton';
import { TopCountriesTable } from './components/TopCountriesTable';
import { TopDevicesTable } from './components/TopDevicesTable';
import { TopEarningsTable } from './components/TopEarningsTable';
import { TopLinksTable } from './components/TopLinksTable';
import { TopReferrerEntryOriginTable } from './components/TopReferrerEntryOrigin';
import { TopSourcesTable } from './components/TopSourcesTable';
import { UpdateCountDown } from './components/UpdateCountdown';
import { useRealtimeMetric } from './service';
import { useAnalyticsFilters } from '../../../../components/analytics_v2/Filters/useAnalyticsFilters';
import { FiltersToggleButton } from '../../../../components/analytics_v2/Filters/Toggle';
import { FiltersDrawerWithDefaultTree } from '../../../../components/analytics_v2/Filters/Drawer/FiltersDrawer';
import { ALL_UTM_FILTER_UIS } from '../../../../components/analytics_v2/Filters/FilterUI/analytics/UTMFilterUI';
import { referrerFilterUIDef } from '../../../../components/analytics_v2/Filters/FilterUI/analytics/ReferrerFilterUI';
import { tagFilterUIDef } from '../../../../components/analytics_v2/Filters/FilterUI/analytics/TagFilterUI';
import { channelFilterUIDef } from '../../../../components/analytics_v2/Filters/FilterUI/analytics/ChannelFilterUI';
import { FILTER_STATE_LOCAL_STORAGE_KEYS } from '../../../../components/analytics_v2/Filters/Drawer/keys';
import { deviceFilterUIDef } from '../../../../components/analytics_v2/Filters/FilterUI/analytics/DeviceFilterUI';
import { platformFilterUIDef } from '../../../../components/analytics_v2/Filters/FilterUI/analytics/PlatformFilterUI';

const TableGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.spacing(2)}px;
  min-height: 300px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-template-columns: 1fr;
  }
`;

const useMaximumRangeEndDate = (
  lastUpdate: ISOString
): [number, () => void] => {
  const [endDate, setEndDate] = useState(
    moment.utc(lastUpdate).startOf('minute').valueOf()
  );
  useEffect(() => {
    const nextMs = moment.utc(lastUpdate).startOf('minute').valueOf();
    setEndDate((prevDate) => Math.max(prevDate, nextMs));
  }, [lastUpdate]);
  const refresh = useCallback(
    () => setEndDate((prev) => Math.max(prev, Date.now())),
    []
  );
  return [endDate, refresh];
};

const useAvailableFilterUIs = () => {
  const scopes = useCurrentUserScopes();
  const canViewTrafficSources = scopes.has('reports.traffic_sources.view');
  const canViewUtms = scopes.has('reports.utms.view');

  return useMemo(
    () =>
      compact([
        ...(canViewUtms ? ALL_UTM_FILTER_UIS : []),
        canViewTrafficSources ? referrerFilterUIDef : null,
        deviceFilterUIDef,
        tagFilterUIDef,
        channelFilterUIDef,
        platformFilterUIDef
      ]),
    [canViewTrafficSources, canViewUtms]
  );
};

const DashboardRealtimeInner = ({ lastUpdate }: { lastUpdate: ISOString }) => {
  const mixpanel = useMixpanel();
  const [endDate, refreshEndDate] = useMaximumRangeEndDate(lastUpdate);
  const { range, compare } = usePeriodRange(endDate);
  const minuteInterval = useMemo(() => {
    const diffH = Math.abs(moment(range.start).diff(moment(range.end), 'h'));
    if (diffH === 1) {
      return 5;
    }
    if (diffH <= 12) {
      return 10;
    }
    if (diffH <= 24) {
      return 30;
    }
    return 60;
  }, [range.end, range.start]);
  const [orderBy] = useRealtimeMetric();
  const scopes = useCurrentUserScopes();
  const hasTrafficSourcesEnabled = useFeatureEnabled('REFERRER_REPORTS_V1');
  const canViewTrafficSources = scopes.has('reports.traffic_sources.view');
  const canViewUtms = scopes.has('reports.utms.view');

  const filterUIs = useAvailableFilterUIs();
  const { filters, toggleProps, drawerProps } = useAnalyticsFilters(
    filterUIs,
    {
      orderBy: orderBy,
      range: range
    },
    {
      localStorageKey: FILTER_STATE_LOCAL_STORAGE_KEYS.realtime
    }
  );
  const pageSearch = usePageSearch('page_url');
  const realtimeFilters = useMemo(() => {
    return compact([pageSearch, ...filters]);
  }, [filters, pageSearch]);

  return (
    <div>
      <PageToolbar sticky offset={DEFAULT_OFFSET} wrap>
        <PageToolbarSection flex={2}>
          <Typography
            variant="h6"
            component="h1"
            className={css((t) => ({
              marginRight: `${t.spacing(1)}px`,
              display: 'flex',
              gap: `${t.spacing(1)}px`,
              alignItems: 'center'
            }))}
          >
            <strong>Realtime</strong>
          </Typography>
          <PageSearchBox
            size="small"
            width={350}
            placeholder="Filter by slug, url, or part of the URL"
            autoFocus
            onSearch={(term) =>
              mixpanel.track('realtime_report_search', { term })
            }
          />
          <RealtimeMetricSelector />
          <FiltersToggleButton {...toggleProps} />
        </PageToolbarSection>
        <PageToolbarSection flex={1} justifyContent="flex-end" spacing="wide">
          <PeriodSelector />
          <UpdateCountDown />
          <RefreshButton
            onClick={() => {
              refreshEndDate();
              mixpanel.track('realtime_report_manual_refresh');
            }}
            lastEndDate={endDate}
          />
        </PageToolbarSection>

        <PageToolbarOtherRow>
          <FiltersDrawerWithDefaultTree
            {...drawerProps}
            title="Filter dashboard"
            marginTop={2}
          />
        </PageToolbarOtherRow>
      </PageToolbar>
      <Section>
        <RealtimeMetricCards
          range={range}
          compareRange={compare?.range}
          filters={realtimeFilters}
        />
        <br />
        <RealtimeChart
          range={range}
          compare={compare?.range}
          minuteInterval={minuteInterval}
          filters={realtimeFilters}
        />
      </Section>
      <Section>
        <TopEarningsTable
          range={range}
          compare={compare?.range}
          color={COLORS.gold.gold1}
          filters={realtimeFilters}
        />
      </Section>
      <Section>
        <TableGrid>
          {hasTrafficSourcesEnabled && canViewUtms && (
            <TopSourcesTable
              range={range}
              compare={compare?.range}
              color={COLORS.blue.blue1}
              filters={realtimeFilters}
            />
          )}
          <TopLinksTable
            range={range}
            compare={compare?.range}
            color={COLORS.red.red1}
            filters={realtimeFilters}
          />
          {hasTrafficSourcesEnabled && canViewTrafficSources && (
            <TopReferrerEntryOriginTable
              range={range}
              compare={compare?.range}
              color={COLORS.volcano.volcano1}
              filters={realtimeFilters}
            />
          )}
          <TopCountriesTable
            range={range}
            compare={compare?.range}
            color={COLORS.purple.purple1}
            filters={realtimeFilters}
          />
          <TopDevicesTable
            range={range}
            compare={compare?.range}
            color={COLORS.lime.lime1}
            filters={realtimeFilters}
          />
        </TableGrid>
      </Section>
    </div>
  );
};

export const DashboardRealtime = () => {
  const { space } = useCurrentUser();
  const [lastUpdate, loading, err] = useLastUpdate(space.id);

  if (loading) {
    return <Loader height={500} />;
  }
  if (!lastUpdate || err) {
    return (
      <Centered height={500}>
        <FlexContainer direction="column">
          <Typography variant="body1">
            Realtime Dashboard cannot be loaded at this moment.
          </Typography>
          <Typography variant="body1">Please try again later.</Typography>
        </FlexContainer>
      </Centered>
    );
  }

  return (
    <PageBody noTopPadding>
      <DashboardRealtimeInner lastUpdate={lastUpdate} />
    </PageBody>
  );
};
