import { ITeam } from '../../../domainTypes/teams';
import moment from 'moment-timezone';
import { sum, sumBy, values } from 'lodash';
import { CampaignsTeamsTableContext } from '../components/teams/report/CampaignsTeamsTable';
import { ManualCampaign } from './manual-campaign';

type PerformanceSeries = Array<{ timestamp: string; amount: number }>;

export type CampaignWithPerformance = ManualCampaign & {
  total: number;
  series: PerformanceSeries;
};

export interface TeamWithCampaigns {
  team: ITeam;
  campaigns: Array<CampaignWithPerformance>;
}

export const teamCampaignPerformance = (
  data: TeamWithCampaigns,
  ctx: CampaignsTeamsTableContext
) => {
  const yearForecast = data.team.forecasts.find(
    (f) => f.year === moment(ctx.timeframe.start).year()
  );
  const forecastAmount = yearForecast ? sum(values(yearForecast.amounts)) : 0;
  const flatSpends = sumBy(data.campaigns, (c) => c.total);

  return {
    forecast: forecastAmount,
    flatSpend: flatSpends
  };
};
