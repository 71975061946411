import React from 'react';
import { AnalyticsColumnDefinition } from '../../../../../../../components/analytics_v2/Table';
import { CountCell } from '../../../../../../../components/Table/CountCell';

export const AVERAGE_COMMISSION_COLUMN: AnalyticsColumnDefinition<'average_commission'> = {
  column: {
    key: 'average_commission',
    head: () => 'Average Commission',
    cell: (p, o) => {
      const before =
        (p.data.commission_sum_net?.prev || 0) /
        (p.data.quantity_net?.prev || 1);
      const after =
        (p.data.commission_sum_net?.curr || 0) /
        (p.data.quantity_net?.curr || 1);
      return (
        <CountCell
          before={before}
          after={after}
          compare={o.showComparison}
          currency={o.currency}
          format={'decimal'}
          digits={2}
        />
      );
    },
    align: 'right',
    sortable: true,
    defaultDirection: 'asc',
    width: 100,
    flexGrow: 2
  },
  sorter: {
    key: 'average_commission',
    items: {
      sort: (p) => {
        return (
          (p.data.commission_sum_net?.curr || 0) /
          (p.data.quantity_net?.curr || 1)
        );
      },
      dir: 'asc'
    }
  }
};

export const AVERAGE_PRICE_COLUMN: AnalyticsColumnDefinition<'average_price'> = {
  column: {
    key: 'average_price',
    head: () => 'Average Price',
    cell: (p, o) => {
      const before =
        (p.data.gmv_sum_net?.prev || 0) / (p.data.quantity_net?.prev || 1);
      const after =
        (p.data.gmv_sum_net?.curr || 0) / (p.data.quantity_net?.curr || 1);
      return (
        <CountCell
          before={before}
          after={after}
          compare={o.showComparison}
          currency={o.currency}
          format={'decimal'}
          digits={2}
        />
      );
    },
    align: 'right',
    sortable: true,
    defaultDirection: 'asc',
    width: 100,
    flexGrow: 2
  },
  sorter: {
    key: 'average_price',
    items: {
      sort: (p) => {
        return (
          (p.data.gmv_sum_net?.curr || 0) / (p.data.quantity_net?.curr || 1)
        );
      },
      dir: 'asc'
    }
  }
};
