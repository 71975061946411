import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormHelperText,
  InputLabel
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { isNil, sortBy } from 'lodash';
import { Moment } from 'moment-timezone';
import React, { useCallback, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { DateRangePicker } from '../../../../../components/DateRangePicker';
import { FieldContainer } from '../../../../../components/FieldContainer';
import { required } from '../../../../../components/Form/validators';
import { css } from '../../../../../emotion';
import { useSnackbar } from '../../../../../hooks/useSnackbar';
import { LimitedWidth } from '../../../../../layout/PageBody';
import { useCurrentUser } from '../../../../../services/currentUser';
import { momentRangeToIsoRange } from '../../../../../services/time';
import { SegmentCampaign } from '../../../service/segment-campaign';
import { scheduleSegmentCampaign } from '../../../service/segment-campaign-form';
import { SegmentSummaryInner } from '../Segment/CampaignSegmentsPicker';
import { usePromise } from '../../../../../hooks/usePromise';
import { getCampaignPages } from '../../../../../admin/features/Campaings/service';
import { Loader } from '../../../../../components/Loader';
import { Message } from '../../../../../components/Message';
import {
  FlexContainer,
  FlexContainerVertical
} from '../../../../../layout/Flex';
import { ExternalLink } from 'react-feather';
import { Centered } from '../../../../../layout/Centered';
import { SearchInput } from '../../../../../components/SearchInput';
import { CustomPagination } from '../../../../../components/CustomPagination';

interface ScheduleFormFields {
  timeframe: {
    start: Moment;
    end: Moment;
  } | null;
}

const PAGE_SIZE = 10;
const PreviewList: React.FC<{ pageUrls: string[] }> = ({ pageUrls }) => {
  const [search, _setSearch] = useState('');
  const [page, setPage] = useState(1);
  const setSearch = useCallback((str) => {
    setPage(1);
    _setSearch(str);
  }, []);
  const filteredUrls = useMemo(() => {
    return sortBy(pageUrls).filter((page) => page.includes(search));
  }, [pageUrls, search]);
  const urls = useMemo(() => {
    return filteredUrls.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE);
  }, [filteredUrls, page]);

  return (
    <FlexContainerVertical spacing={2}>
      <FlexContainer justifyContent="space-between" fullWidth>
        <SearchInput
          value={search}
          onChange={setSearch}
          placeholder="Search by URL"
          width={300}
        />
        <CustomPagination
          onChange={(_e, nextPage) => setPage(nextPage)}
          page={page}
          count={Math.ceil(filteredUrls.length / PAGE_SIZE)}
          siblingCount={0}
        />
      </FlexContainer>
      <div>
        {urls.map((url) => (
          <FlexContainer key={url} wrap="wrap">
            <Typography variant="body1">{url}</Typography>
            <a href={`${url}?am-preview=true`} target="_blank" rel="noreferrer">
              <Typography variant="body1" color="primary">
                <FlexContainer alignItems="center" spacing={0.5}>
                  Preview <ExternalLink size={18} />
                </FlexContainer>
              </Typography>
            </a>
          </FlexContainer>
        ))}
      </div>
    </FlexContainerVertical>
  );
};

const Preview: React.FC<{ campaign: SegmentCampaign }> = ({ campaign }) => {
  const { space } = useCurrentUser();
  const [data, loading, error] = usePromise(
    () => getCampaignPages(space.id, campaign.id),
    [space.id, campaign.id]
  );
  if (loading || !data) {
    return (
      <Centered height={60}>
        <Loader size={24} />
      </Centered>
    );
  }
  if (error) {
    return <Message message="Failed to load pages" />;
  }
  return <PreviewList pageUrls={data} />;
};

export const SegmentCampaignScheduleForm: React.FC<{
  campaign: SegmentCampaign;
}> = ({ campaign }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { space } = useCurrentUser();
  const scheduleCampaign = useCallback(
    async (fields: ScheduleFormFields) => {
      if (isNil(fields.timeframe)) return;
      try {
        await scheduleSegmentCampaign(
          space.id,
          campaign.id,
          momentRangeToIsoRange(fields.timeframe)
        );
        enqueueSnackbar('Campaign scheduled', { variant: 'success' });
      } catch (e) {
        enqueueSnackbar('Failed to schedule campaign', { variant: 'error' });
      }
    },
    [campaign.id, enqueueSnackbar, space.id]
  );
  return (
    <LimitedWidth width={1000}>
      <Card>
        <Form
          onSubmit={scheduleCampaign}
          initialValues={{
            timeframe: 'timeframe' in campaign ? campaign.timeframe : null
          }}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <CardContent>
                <div
                  className={css((t) => ({
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    columnGap: t.spacing(2),
                    rowGap: t.spacing(4)
                  }))}
                >
                  <Typography variant="h6">Preview and schedule</Typography>
                  <FieldContainer>
                    <Typography variant="body2" color="textSecondary">
                      The following matches have been approved by your partner.
                    </Typography>
                    {campaign.segments.map((segment) => (
                      <React.Fragment key={segment.id}>
                        <Typography variant="body1">{segment.name}</Typography>
                        <SegmentSummaryInner
                          segment={segment}
                          pageConstraints={campaign.pageConstraints}
                        />
                      </React.Fragment>
                    ))}
                  </FieldContainer>

                  <FieldContainer>
                    <Typography variant="body1">
                      Use the <strong>Preview</strong> option to test the
                      deployed campaign on your site, without recording any
                      clicks or pageviews.
                    </Typography>
                    <Preview campaign={campaign} />
                  </FieldContainer>

                  <FieldContainer>
                    <InputLabel>Timeframe</InputLabel>
                    <Field name="timeframe" validate={required}>
                      {({ input, meta }) => {
                        const hasError = meta.error && meta.touched;
                        const errorText = hasError
                          ? 'Please select a timeframe for your campaign'
                          : '';
                        return (
                          <FormControl
                            required
                            variant="outlined"
                            error={hasError}
                          >
                            <DateRangePicker {...input} hasError={hasError} />
                            <FormHelperText>{errorText}</FormHelperText>
                          </FormControl>
                        );
                      }}
                    </Field>
                  </FieldContainer>
                </div>
              </CardContent>
              <CardActions>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={submitting}
                >
                  Schedule campaign
                </Button>
              </CardActions>
            </form>
          )}
        />
      </Card>
    </LimitedWidth>
  );
};
