import {
  ProductCatalogFilterMenuComponent,
  ProductCatalogFilterUI
} from '../../../FilterUI';
import {
  FilterDefinition,
  ProductCatalogBrandFilterDef
} from '../../../../../../domainTypes/filters';
import { ChipContent, formatList } from '../../common';
import React, { useState } from 'react';
import { BASIC_MODES, FilterMenu } from '../../Menus/FilterMenu';
import {
  ProductCatalogFieldSelectorMenu,
  useCollectionFilterState
} from '../../Menus/Selector';
import pluralize from 'pluralize';

const BrandMenu: ProductCatalogFilterMenuComponent<ProductCatalogBrandFilterDef> = ({
  definition,
  onSave,
  isFirst,
  context
}) => {
  const [mode, setMode] = useState(BASIC_MODES[0].value);
  const {
    values,
    isSaveEnabled,
    handleSave,
    handleToggle,
    handleFocus
  } = useCollectionFilterState<string, ProductCatalogBrandFilterDef>(
    definition,
    onSave
  );

  return (
    <FilterMenu>
      <FilterMenu.Header name={'brand'} isFirst={isFirst}>
        <FilterMenu.ModeSelector
          modes={BASIC_MODES}
          mode={mode}
          setMode={setMode}
        />
      </FilterMenu.Header>
      <FilterMenu.Body>
        <ProductCatalogFieldSelectorMenu
          label={'brand'}
          selectedValues={values}
          filters={context.baseQuery.filters}
          onToggle={handleToggle}
          onFocus={handleFocus}
          field="brand"
        />
      </FilterMenu.Body>
      <FilterMenu.Footer
        description={
          <>
            <strong>Brand</strong> is the company that owns the product name and
            markets it.
          </>
        }
      >
        <FilterMenu.SaveButton
          disabled={!isSaveEnabled}
          onSave={handleSave}
          label={`Filter by ${pluralize('brand', values.length, true)}`}
        />
      </FilterMenu.Footer>
    </FilterMenu>
  );
};

export const brandFilterUI: ProductCatalogFilterUI = {
  type: 'product_catalog',
  chip: ({ definition }) => {
    if (!definition) return <>Brand</>;
    if (definition.k !== 'product_catalog_brand') {
      throw new Error('Invalid definition');
    }
    return (
      <ChipContent title={`Brand is`}>{formatList(definition.v)}</ChipContent>
    );
  },
  dimension: 'product_catalog_brand',
  init: () => ({
    k: 'product_catalog_brand',
    v: []
  }),
  menu: function BrandFilterUIMenu({ definition, ...props }) {
    return (
      <BrandMenu
        definition={definition as ProductCatalogBrandFilterDef}
        {...props}
      />
    );
  },
  toClauses: (definition: FilterDefinition) => {
    if (definition.k !== 'product_catalog_brand') {
      return [];
    }
    return [
      {
        field: 'brand',
        condition: 'in',
        values: definition.v
      }
    ];
  }
};
