import * as ENV from './env.json';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
//import LogRocket from 'logrocket';
import { start } from './stackdriverErrorReporter';
import { CUSTOMER_IO_INIT_SCRIPT } from './services/customerio';
import { loadScript } from './services/scriptLoader';

loadScript(CUSTOMER_IO_INIT_SCRIPT);

start({
  key: ENV.errorReporting.apiKey,
  projectId: ENV.errorReporting.projectId,
  service: ENV.errorReporting.service,
  version: process.env.GIT_SHA,
  disabled: process.env.NODE_ENV === 'development'
});

ReactDOM.render(<App />, document.getElementById('root'));

//LogRocket.init('mv10rs/affilimate-prod');

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
export { FILTER_STATE_LOCAL_STORAGE_KEYS } from './components/analytics_v2/Filters/Drawer/keys';
export { usePerformancePagesFilterUIs } from './features/PerformanceNew/services/filters';
