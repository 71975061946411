import isPropValid from '@emotion/is-prop-valid';
import { Card, Tooltip } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Skeleton } from '@material-ui/lab';
import { fromPairs, isNil } from 'lodash';
import React, { useMemo } from 'react';
import { Info } from 'react-feather';
import { formatNumber } from '../../../../../components/Number';
import { Trend } from '../../../../../components/Trend';
import { getTrend } from '../../../../../domainTypes/analytics';
import {
  AnalyticsFilter,
  AnalyticsQuery,
  AnalyticsResponseRowWithComparison,
  ISOTimeRange
} from '../../../../../domainTypes/analytics_v2';
import { css, styled } from '../../../../../emotion';
import { FlexContainer } from '../../../../../layout/Flex';
import {
  formatMetric,
  metricTitle
} from '../../../../../services/analyticsV2/metrics';
import { useAnalyticsQueryV2 } from '../../../../../services/analyticsV2/query';
import { useCurrentUser } from '../../../../../services/currentUser';
import {
  LoadingValue,
  useMappedLoadingValue
} from '../../../../../services/db';
import { useSpaceCurrency } from '../../../../../services/useSpaceCurrency';
import {
  EarningMetric,
  earningsMetrics,
  metricDescription,
  TrafficMetric,
  trafficMetrics,
  useRealtimeColumnTransformers
} from '../service';

const MetricCard = styled(Card, {
  shouldForwardProp: (props) => isPropValid(props)
})<{ isSelected: boolean }>`
  min-width: 170px;
  height: 90px;
  outline: ${(p) =>
    p.isSelected ? `1.5px solid ${p.theme.palette.primary.main}` : 'none'};
`;

const RealtimeMetricGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(1)}px;
`;

const useMetricTotals = (
  range: ISOTimeRange,
  compare: ISOTimeRange | undefined,
  filters: AnalyticsFilter[]
) => {
  const { space } = useCurrentUser();
  const columnTransformers = useRealtimeColumnTransformers(filters);
  const query = useMemo<AnalyticsQuery>(() => {
    return {
      select: [...trafficMetrics, ...earningsMetrics],
      range,
      compare: compare && { range: compare },
      filters,
      columnTransformers
    };
  }, [columnTransformers, compare, filters, range]);

  return useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    (d) =>
      d.rows[0]?.data ||
      fromPairs(
        [...trafficMetrics, ...earningsMetrics].map((m) => [
          m,
          { curr: 0, prev: 0 }
        ])
      )
  );
};

interface CardBodyProps {
  compare: boolean;
  metric: TrafficMetric | EarningMetric;
  data: LoadingValue<AnalyticsResponseRowWithComparison['data']>;
}

const CardBody: React.FC<CardBodyProps> = ({ metric, data, compare }) => {
  const currency = useSpaceCurrency();
  const [metrics, loading] = data;
  if (!metrics && loading) {
    return (
      <Skeleton className={css(() => ({ width: '60%', fontSize: '1.5rem' }))} />
    );
  }
  if (!metrics) {
    // should never happen at this point
    return null;
  }
  // TODO: handle empty case better?
  const { curr = 0, prev = 0 } = metrics[metric] ?? {};
  const currentString = formatMetric(curr, metric, currency);
  const percentString = formatNumber({
    n: getTrend(prev, curr),
    format: 'percent',
    digits: 1,
    plusMinus: true
  });
  return (
    <FlexContainer alignItems="baseline" spacing={0.5}>
      <Typography
        variant="h5"
        className={css((t) => ({
          marginRight: t.spacing(1.5)
        }))}
      >
        {currentString}{' '}
      </Typography>
      {compare ? (
        <>
          <Typography variant="body1" color="textSecondary">
            {percentString}
          </Typography>
          <Trend values={[prev, curr]} />
        </>
      ) : null}
    </FlexContainer>
  );
};

export const RealtimeMetricCards = ({
  filters,
  range,
  compareRange
}: {
  filters: AnalyticsFilter[];
  range: ISOTimeRange;
  compareRange?: ISOTimeRange;
}) => {
  const data = useMetricTotals(range, compareRange, filters);
  return (
    <RealtimeMetricGrid>
      {trafficMetrics.map((metric) => (
        <MetricCard isSelected={false} key={metric}>
          <FlexContainer>
            <Typography variant="body1">{metricTitle(metric)}</Typography>
            <Tooltip
              title={metricDescription(metric)}
              aria-label={metricDescription(metric)}
              placement="top"
            >
              <Info
                size={14}
                className={css((t) => ({
                  color: t.palette.grey[500],
                  flexShrink: 0
                }))}
              />
            </Tooltip>
          </FlexContainer>
          <CardBody
            metric={metric}
            data={data}
            compare={!isNil(compareRange)}
          />
        </MetricCard>
      ))}
      {earningsMetrics.map((metric) => (
        <MetricCard isSelected={false} key={metric}>
          <FlexContainer>
            <Typography variant="body1">{metricTitle(metric)}</Typography>
            <Tooltip
              title={metricDescription(metric)}
              aria-label={metricDescription(metric)}
              placement="top"
            >
              <Info
                size={14}
                className={css((t) => ({
                  color: t.palette.grey[500],
                  flexShrink: 0
                }))}
              />
            </Tooltip>
          </FlexContainer>
          <CardBody
            metric={metric}
            data={data}
            compare={!isNil(compareRange)}
          />
        </MetricCard>
      ))}
    </RealtimeMetricGrid>
  );
};
