import { compact } from 'lodash';
import { useMemo } from 'react';
import {
  AnalyticsFilter,
  AnalyticsSelectField
} from '../../../../../domainTypes/analytics_v2';
import {
  CampaignSegment,
  CAMPAIGN_ANALYTICS_DEFAULT_LAST_X_DAYS
} from '../../../../../domainTypes/campaigns';
import {
  ProductCatalogAvailability,
  ProductCatalogField,
  ProductCatalogFilter,
  ProductCatalogMatchQuery
} from '../../../../../domainTypes/productCatalog';
import { toTimeRanges } from '../../../../../hooks/timeframe';
import { useCurrentUser } from '../../../../../services/currentUser';
import { SegmentCampaign } from '../../../service/segment-campaign';

export interface SegmentQueryParts {
  orderBy?: ProductCatalogMatchQuery['orderBy'];
  paginate?: ProductCatalogMatchQuery['paginate'];
  sourceSelect?: ProductCatalogField[];
  targetSelect?: ProductCatalogField[];
  sourceAnalyticsSelect?: AnalyticsSelectField[];
  totalsSelect?: AnalyticsSelectField[];
}

const DEFAULTS_TOTALS_SELECT: AnalyticsSelectField[] = [
  'count_uniq_page_url',
  'count_uniq_link_id',
  'count_uniq_p_catalog_uid',
  'count_uniq_l_advertiser_id',
  'c',
  'gmv_sum_net',
  'commission_sum_net',
  'epc_net'
];

export const useSegmentQuery = (
  {
    filters,
    maId,
    exclusions
  }: Pick<CampaignSegment, 'filters' | 'maId' | 'exclusions'>,
  pageConstraints: SegmentCampaign['pageConstraints'],
  {
    orderBy,
    paginate,
    sourceSelect,
    sourceAnalyticsSelect,
    targetSelect,
    totalsSelect = DEFAULTS_TOTALS_SELECT
  }: SegmentQueryParts = {}
) => {
  const { space, tz } = useCurrentUser();
  const { range } = useMemo(
    () =>
      toTimeRanges(
        {
          range: {
            kind: 'period',
            value: {
              type: 'last',
              duration: `P${CAMPAIGN_ANALYTICS_DEFAULT_LAST_X_DAYS}D`
            }
          },
          comparison: { kind: 'disabled' }
        },
        tz
      ),
    [tz]
  );

  const pageContraintsFitlers = useMemo<AnalyticsFilter[]>(() => {
    return compact([
      pageConstraints.include.length && {
        field: 'page_url',
        condition: 'in',
        values: pageConstraints.include
      },
      pageConstraints.exclude.length && {
        field: 'page_url',
        condition: 'not in',
        values: pageConstraints.exclude
      }
    ]);
  }, [pageConstraints.exclude, pageConstraints.include]);

  return useMemo<ProductCatalogMatchQuery>(() => {
    const allFilters: ProductCatalogFilter[] = compact([
      ...filters,
      {
        field: 'uid',
        condition: 'not in',
        values: exclusions ?? []
      }
    ]);
    return {
      spaceId: space.id,
      maId,
      source: {
        select: sourceSelect,
        filters: allFilters,
        analytics: sourceAnalyticsSelect
          ? {
              range,
              select: sourceAnalyticsSelect,
              filters: pageContraintsFitlers
            }
          : undefined
      },
      target: {
        select: targetSelect,
        filters: [
          {
            field: 'availability',
            condition: 'in',
            values: [ProductCatalogAvailability.in_stock]
          }
        ]
      },
      totals: {
        analytics: {
          select: totalsSelect,
          range,
          filters: pageContraintsFitlers
        }
      },
      orderBy,
      paginate
    };
  }, [
    exclusions,
    filters,
    maId,
    orderBy,
    pageContraintsFitlers,
    paginate,
    range,
    sourceAnalyticsSelect,
    sourceSelect,
    space.id,
    targetSelect,
    totalsSelect
  ]);
};
