import React, { useCallback, useState } from 'react';
import { IconButton, TextField, TextFieldProps } from '@material-ui/core';
import { Plus, XCircle } from 'react-feather';
import { isEmpty } from 'lodash';
import { FlexContainer } from '../../../../../layout/Flex';

interface TagListCreatorProps<Tag> {
  value: Tag[];
  setValue: (ids: Tag[]) => void;
  createTag: (name: string) => Tag;
  renderTag: (tag: Tag) => React.ReactNode;
  tagId: (tag: Tag) => string;
  label?: string;
  placeholder?: string;
}

export function TagListCreator<Tag>({
  value,
  setValue,
  label = 'IDs',
  placeholder = 'abc-',
  variant,
  size,
  createTag,
  renderTag,
  tagId,
  disabled
}: TagListCreatorProps<Tag> &
  Pick<TextFieldProps, 'variant' | 'size' | 'disabled'>) {
  const [tagName, setTagName] = useState('');

  const addNewId = useCallback(
    (id: string) => {
      if (!isEmpty(id)) {
        const newTag = createTag(id);
        setValue([newTag, ...value]);
        setTagName('');
      }
    },
    [createTag, setValue, value]
  );

  const removeId = useCallback(
    (id: string) => {
      setValue(value.filter((v) => tagId(v) !== id));
    },
    [setValue, tagId, value]
  );

  return (
    <>
      <TextField
        label={label}
        placeholder={placeholder}
        value={tagName}
        variant={variant}
        size={size}
        fullWidth
        disabled={disabled}
        InputProps={{
          endAdornment: (
            <IconButton
              color="primary"
              disabled={isEmpty(tagName)}
              onClick={() => {
                addNewId(tagName);
              }}
            >
              <Plus size={18} />
            </IconButton>
          )
        }}
        onBlur={() => {
          addNewId(tagName);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            addNewId(tagName);
          }
        }}
        onChange={(e) => {
          setTagName(e.target.value);
        }}
      />
      {value.map((tag) => (
        <FlexContainer key={tagId(tag)}>
          {renderTag(tag)}
          <IconButton onClick={() => removeId(tagId(tag))} disabled={disabled}>
            <XCircle size={18} />
          </IconButton>
        </FlexContainer>
      ))}
    </>
  );
}
