import Typography from '@material-ui/core/Typography';
import { Skeleton } from '@material-ui/lab';
import { isEmpty } from 'lodash';
import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { ItemSorter, RowsRenderer } from '../../../../components/GroupableList';
import { Loader } from '../../../../components/Loader';
import { NoPermissions } from '../../../../components/NoPermissions';
import { ProfileLabel } from '../../../../components/ProfileLabel';
import { IColumn } from '../../../../components/Table/Column';
import { Dash } from '../../../../components/Table/CountCell';
import { Truncated } from '../../../../components/Truncated';
import { WithHoverIndicator } from '../../../../components/WithHoverIndicator';
import { SegmentCampaignStatus } from '../../../../domainTypes/campaigns';
import { Doc } from '../../../../domainTypes/document';
import { css } from '../../../../emotion';
import { Centered } from '../../../../layout/Centered';
import { FlexContainer, FlexContainerVertical } from '../../../../layout/Flex';
import { useRoutes } from '../../../../routes';
import { getCampaignLogEntry } from '../../../../services/campaigns';
import {
  useCurrentUser,
  useCurrentUserScopes
} from '../../../../services/currentUser';
import { useMappedLoadingValue } from '../../../../services/db';
import { useMarketplaceAdvertiserProfilesPublic } from '../../../../services/marketplaceAdvertisers';
import { toMoment } from '../../../../services/time';
import { CampaignsPageBody } from '../../components/CampaignsPageBody';
import { Managers } from '../../components/list/Managers';
import { TeamWithColorLazy } from '../../components/teams/TeamWithColor';
import { useSegmentCampaigns } from '../../service';
import { FirstLogTimestamp } from '../../service/log';
import {
  CampaignStatusIcon,
  campaignStatusTitle,
  groupCampaignsByStatus,
  SegmentCampaign
} from '../../service/segment-campaign';

type Column = 'name' | 'partner' | 'manager' | 'team' | 'createdAt';

const SORTER: ItemSorter<Doc<SegmentCampaign>> = {
  key: 'createdAt',
  items: {
    sort: (c) => {
      const entry = getCampaignLogEntry(c.data, 'save-draft');
      if (!entry) return 0;
      return toMoment(entry.timestamp).valueOf();
    },
    dir: 'desc'
  }
};

const COLUMNS: Array<IColumn<Doc<SegmentCampaign>, Column>> = [
  {
    key: 'name',
    head: () => 'Campaign name',
    align: 'left',
    cell: (c) => (
      <WithHoverIndicator>
        <Truncated title={c.data.name} />
      </WithHoverIndicator>
    ),
    width: 200,
    flexGrow: 1
  },
  {
    key: 'partner',
    head: () => 'Partner',
    align: 'left',
    cell: function Partner(c) {
      const { space } = useCurrentUser();
      const [
        partner,
        loading
      ] = useMappedLoadingValue(
        useMarketplaceAdvertiserProfilesPublic(space.id),
        (partners) => partners.find((p) => p.id === c.data.partner?.id)
      );
      if (loading) {
        return <Skeleton />;
      }
      if (!partner) {
        return <Dash />;
      }
      return <ProfileLabel profile={partner} />;
    },
    width: 200,
    flexGrow: 1
  },
  {
    key: 'team',
    head: () => 'Team',
    align: 'left',
    cell: (c) => <TeamWithColorLazy teamId={c.data.team} />,
    width: 200,
    flexGrow: 1
  },
  {
    key: 'createdAt',
    head: () => 'Created at',
    align: 'left',
    cell: (c) => <FirstLogTimestamp campaign={c.data} operation="save-draft" />,
    width: 200,
    flexGrow: 1
  },
  {
    key: 'manager',
    head: () => 'Managers',
    align: 'left',
    cell: (c) => <Managers userIds={c.data.managers} />,
    width: 100,
    flexGrow: 1
  }
];

const Header = ({ status }: { status: SegmentCampaignStatus }) => {
  return (
    <FlexContainerVertical spacing={1} fullWidth>
      <FlexContainer alignItems="center">
        <CampaignStatusIcon status={status} />
        <Typography variant="body1">{campaignStatusTitle(status)}</Typography>
      </FlexContainer>
    </FlexContainerVertical>
  );
};

const Table = ({
  data
}: {
  data: Array<Doc<SegmentCampaign>>;
  linkToDetails?: boolean;
}) => {
  const { ROUTES } = useRoutes();

  const rowToHref = useCallback(
    (c: Doc<SegmentCampaign>) => {
      switch (c.data.status) {
        case 'running':
        case 'scheduled':
        case 'completed':
          return ROUTES.campaigns.segment.report.url(c.data.id);
        default:
          return ROUTES.campaigns.segment.details.url(c.data.id, 'overview');
      }
    },
    [ROUTES.campaigns.segment.details, ROUTES.campaigns.segment.report]
  );
  return (
    <RowsRenderer
      rows={data}
      columns={COLUMNS}
      renderHead
      sorter={SORTER}
      otherProps={undefined}
      rowToKey={(c) => c.id}
      rowToHref={rowToHref}
    />
  );
};

const statuses = [
  'running',
  'scheduled',
  'approved',
  'proposed',
  'draft'
] as const;

const Body = () => {
  const [data, loading] = useMappedLoadingValue(
    useSegmentCampaigns(),
    (campaigns) => groupCampaignsByStatus(campaigns)
  );

  return (
    <div
      className={css((t) => ({
        marginTop: t.spacing(2),
        padding: t.spacing(2)
      }))}
    >
      {loading || !data ? (
        <Centered>
          <Loader size={32} />
        </Centered>
      ) : (
        <FlexContainerVertical spacing={3}>
          {statuses.map((status) => {
            if (isEmpty(data[status])) return null;
            return (
              <React.Fragment key={status}>
                <Header status={status} />
                <Table data={data[status]} />
              </React.Fragment>
            );
          })}
        </FlexContainerVertical>
      )}
    </div>
  );
};

export const SegmentCampaignActive: React.FC = () => {
  const scopes = useCurrentUserScopes();
  const canView = scopes.has('campaigns.view');
  return (
    <>
      <Helmet>
        <title>Campaigns | Affilimate</title>
      </Helmet>
      <CampaignsPageBody>
        {canView ? <Body /> : <NoPermissions />}
      </CampaignsPageBody>
    </>
  );
};
