import { useQueryParam } from '../../routes';
import { useCallback, useMemo } from 'react';
import { useCurrentUser } from '../../services/currentUser';
import { defaultTimeframe } from '../../components/TimeframePicker/service/options';
import { getTimeframeMoments, toMoments } from './toMoments';
import { parseTimeframe } from './parseTimeframe';
import { Timeframe } from '../../domainTypes/analytics';
import moment, { Moment } from 'moment-timezone';
import {
  printTimeframe,
  TimeframeDefinition,
  TimeframeIntervalDefinition
} from '../../domainTypes/timeframe';
import { AnalyticsRanges } from '../../components/analytics_v2/Timeframe';
import { AnalyticsInterval } from '../../domainTypes/analytics_v2';
import { compact } from 'lodash';
import { useDeepEqual } from '../useDeepEqual';
import { Query } from '../../domainTypes/routes/common';

export const DEFAULT_TIMEFRAME_URL_PARAM_NAME = 'timeframe';

export const useTimeframeDefinitionFromUrl = (
  paramName: string = DEFAULT_TIMEFRAME_URL_PARAM_NAME
) => {
  const [
    _timeframeDefinition,
    setTimeframeDefinition,
    toTimeframeQuery
  ] = useQueryParam<TimeframeDefinition>(
    paramName,
    (p) => parseTimeframe(p) || defaultTimeframe,
    printTimeframe
  );

  const {
    range: _range,
    comparison: _comparison,
    interval: _interval
  } = _timeframeDefinition;
  const range = useDeepEqual(_range);
  const comparison = useDeepEqual(_comparison);
  const interval = useDeepEqual(_interval);

  const timeframeDefinition = useMemo(
    () => ({
      range,
      comparison,
      interval
    }),
    [range, comparison, interval]
  );

  return [timeframeDefinition, setTimeframeDefinition, toTimeframeQuery] as [
    TimeframeDefinition,
    (tf: TimeframeDefinition) => void,
    (tf: TimeframeDefinition) => Query
  ];
};

export const toTimeRanges = (
  timeframe: Pick<TimeframeDefinition, 'range' | 'comparison'>,
  tz: string
): AnalyticsRanges => {
  const { start, end, compare } = toMoments(timeframe, tz);
  return {
    range: {
      start: start.toISOString(),
      end: end.toISOString()
    },
    compare: compare
      ? {
          range: {
            start: compare.start.toISOString(),
            end: compare.end.toISOString()
          }
        }
      : undefined
  };
};

export const useTimeRanges = (
  paramName: string = DEFAULT_TIMEFRAME_URL_PARAM_NAME
): AnalyticsRanges => {
  const [{ range, comparison }] = useTimeframeDefinitionFromUrl(paramName);
  const { tz } = useCurrentUser();
  return useMemo(() => toTimeRanges({ range, comparison }, tz), [
    range,
    comparison,
    tz
  ]);
};

export const useHasComparison = () => {
  const [timeframe] = useTimeframeDefinitionFromUrl();
  return useMemo(() => timeframe.comparison.kind !== 'disabled', [
    timeframe.comparison
  ]);
};

export const useAnalyticsInterval = (
  definition: TimeframeIntervalDefinition
): AnalyticsInterval => {
  const { tz } = useCurrentUser();
  return useMemo<AnalyticsInterval>(
    () => ({
      tz,
      ...definition
    }),
    [definition, tz]
  );
};

export const useBestIntervalForTimeframe = (
  paramName: string = DEFAULT_TIMEFRAME_URL_PARAM_NAME
): AnalyticsInterval => {
  const { tz } = useCurrentUser();
  const [timeframe] = useTimeframeDefinitionFromUrl(paramName);
  const definition = useMemo(() => {
    const { start, end } = getTimeframeMoments(timeframe.range, tz);
    return bestIntervalForTimeframe(start, end);
  }, [timeframe.range, tz]);
  return useAnalyticsInterval(definition);
};

export const YEARLY_INTERVAL: TimeframeIntervalDefinition = {
  unit: 'year',
  value: 1
};
export const QUARTERLY_INTERVAL: TimeframeIntervalDefinition = {
  unit: 'quarter',
  value: 1
};
export const MONTHLY_INTERVAL: TimeframeIntervalDefinition = {
  unit: 'month',
  value: 1
};
export const WEEKLY_INTERVAL: TimeframeIntervalDefinition = {
  unit: 'week',
  value: 1
};
export const DAILY_INTERVAL: TimeframeIntervalDefinition = {
  unit: 'day',
  value: 1
};
export const HOURLY_INTERVAL: TimeframeIntervalDefinition = {
  unit: 'hour',
  value: 1
};
export const FIFTEEN_MINUTES_INTERVAL: TimeframeIntervalDefinition = {
  unit: 'minute',
  value: 15
};

export const bestIntervalForTimeframe = (
  start: Moment,
  end: Moment
): TimeframeIntervalDefinition => {
  if (end.diff(start, 'months') > 24) {
    return YEARLY_INTERVAL;
  }
  if (end.diff(start, 'months') > 12) {
    return QUARTERLY_INTERVAL;
  }
  if (end.diff(start, 'months') >= 6) {
    return MONTHLY_INTERVAL;
  }
  if (end.diff(start, 'months') > 2) {
    return WEEKLY_INTERVAL;
  }
  if (end.diff(start, 'days') > 2) {
    return DAILY_INTERVAL;
  }
  if (end.diff(start, 'hours') > 6) {
    return HOURLY_INTERVAL;
  }
  return FIFTEEN_MINUTES_INTERVAL;
};

export const allIntervalsForTimeframe = (
  start: Moment,
  end: Moment
): TimeframeIntervalDefinition[] => {
  return compact([
    end.diff(start, 'day') < 2 && FIFTEEN_MINUTES_INTERVAL,
    end.diff(start, 'day') < 7 && HOURLY_INTERVAL,
    end.diff(start, 'hour') > 12 &&
      end.diff(start, 'months') < 13 &&
      DAILY_INTERVAL,
    WEEKLY_INTERVAL,
    MONTHLY_INTERVAL,
    QUARTERLY_INTERVAL,
    YEARLY_INTERVAL
  ]);
};

const fromLegacyTf = (tf: Timeframe): TimeframeDefinition => {
  const start = moment(tf.start).tz(tf.tz).startOf('day').toISOString();
  const end = moment(tf.end).tz(tf.tz).endOf('day').toISOString();
  return {
    range: {
      kind: 'custom',
      start: start,
      end: end
    },
    comparison: {
      kind: 'previous'
    }
  };
};

/*
 * This hook is used to convert the legacy timeframe object to the new timeframe object.
 */

export const useLegacyTimeframe = (
  paramName: string = DEFAULT_TIMEFRAME_URL_PARAM_NAME
): [Timeframe, (tf: Timeframe) => void, (tf: Timeframe) => Query] => {
  const { tz } = useCurrentUser();
  const [timeframe, setTimeframe] = useTimeframeDefinitionFromUrl(paramName);

  const legacyTimeframe = useMemo(() => {
    const moments = getTimeframeMoments(timeframe.range, tz);
    return {
      start: moments.start.format('YYYY-MM-DD'),
      end: moments.end.format('YYYY-MM-DD'),
      tz
    };
  }, [timeframe, tz]);

  const setCustomTimeframe = useCallback(
    (tf: Timeframe) => {
      setTimeframe(fromLegacyTf(tf));
    },
    [setTimeframe]
  );

  const toQueryParams = useCallback(
    (tf: Timeframe) => ({
      [paramName]: printTimeframe(fromLegacyTf(tf))
    }),
    [paramName]
  );

  return [legacyTimeframe, setCustomTimeframe, toQueryParams];
};
