import React from 'react';
import {
  ChartModeButton,
  StyledButtonGroup
} from '../../../../../components/Charts/EarningsChartCardV2/ChartModeSelector';
import { Tooltip as MaterialTooltip } from '@material-ui/core';
import { BarChart, BarChart2 } from 'react-feather';

export type CampaignReportAggregation = 'none' | 'cum';

export const CampaignReportAggregationModeSelector: React.FC<{
  value: CampaignReportAggregation;
  onChange: (nextValue: CampaignReportAggregation) => void;
}> = ({ value, onChange }) => {
  return (
    <StyledButtonGroup>
      <MaterialTooltip placement="top" title="Cumulative">
        <ChartModeButton
          color="default"
          variant={value === 'cum' ? 'contained' : 'text'}
          size="small"
          onClick={() => onChange('cum')}
          selected={value === 'cum'}
        >
          <BarChart size={16} />
        </ChartModeButton>
      </MaterialTooltip>
      <MaterialTooltip placement="top" title="Monthly">
        <ChartModeButton
          color="default"
          variant={value === 'none' ? 'contained' : 'text'}
          size="small"
          onClick={() => onChange('none')}
          selected={value === 'none'}
        >
          <BarChart2 size={16} />
        </ChartModeButton>
      </MaterialTooltip>
    </StyledButtonGroup>
  );
};
