import React from 'react';
import { Moment } from 'moment-timezone';
import { css } from '../../../../emotion';
import { Calendar } from 'react-feather';
import { prettifyDateRange } from '../../../../services/time';

export const CampaignTimeframe: React.FC<{
  start: Moment;
  end: Moment;
  timezone: string;
}> = ({ start, end, timezone }) => {
  return (
    <div
      className={css((t) => ({
        color: t.palette.grey[800],
        fontSize: 14,
        fontWeight: 500,
        backgroundColor: 'white',
        padding: '6px 10px',
        borderRadius: 4
      }))}
    >
      <Calendar
        size={16}
        className={css((t) => ({
          color: t.palette.grey[600],
          position: 'relative',
          top: 2,
          marginRight: t.spacing(1)
        }))}
      />
      {prettifyDateRange(start, end, timezone)}
    </div>
  );
};
