import { Checkbox, FormControlLabel } from '@material-ui/core';
import React from 'react';
import { styled } from '../../emotion';

export const StyledTermsAndConditions = styled('p')`
  color: ${(p) => p.theme.palette.grey[500]};
  font-size: 12px;
  text-align: left;
  line-height: 18px;

  a {
    border-bottom: 1px solid;
  }
`;

export const TermsCheckbox: React.FC<{
  checked: boolean;
  onChange: (nextChecked: boolean) => void;
}> = ({ checked, onChange, children }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          color="default"
          checked={checked}
          onChange={(ev) => onChange(ev.target.checked)}
        />
      }
      label={<StyledTermsAndConditions>{children}</StyledTermsAndConditions>}
    />
  );
};

export const TermsOfServiceAndPrivacyPolicyCheckbox = ({
  checked,
  onChange
}: {
  checked: boolean;
  onChange: (nextChecked: boolean) => void;
}) => {
  return (
    <TermsCheckbox checked={checked} onChange={onChange}>
      I agree to Affilimate's{' '}
      <a
        href="https://affilimate.com/qs/terms-of-service/"
        rel="noopener noreferrer"
        target="_blank"
      >
        terms of service
      </a>{' '}
      and{' '}
      <a
        href="https://affilimate.com/qs/privacy-policy/"
        rel="noopener noreferrer"
        target="_blank"
      >
        privacy policy
      </a>
      .
    </TermsCheckbox>
  );
};
