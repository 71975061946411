import { ButtonBase, Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import { ShoppingBag } from 'react-feather';
import { FiltersDrawer } from '../../../../components/analytics_v2/Filters/Drawer/FiltersDrawer';
import { FiltersList } from '../../../../components/analytics_v2/Filters/Drawer/filtersTree';
import { brandFilterUI } from '../../../../components/analytics_v2/Filters/FilterUI/product_catalog/BrandFilterUI';
import { catalogFilterUI } from '../../../../components/analytics_v2/Filters/FilterUI/product_catalog/CatalogFilterUI';
import { manufacturerFilterUI } from '../../../../components/analytics_v2/Filters/FilterUI/product_catalog/ManufacturerFilterUI';
import { productCatalogAvailabilityFilterUI } from '../../../../components/analytics_v2/Filters/FilterUI/product_catalog/ProductCatalogAvailabilityFilterUI';
import { retailerFilterUI } from '../../../../components/analytics_v2/Filters/FilterUI/product_catalog/RetailerFilterUI';
import { sellerFilterUI } from '../../../../components/analytics_v2/Filters/FilterUI/product_catalog/SellerFilterUI';
import { useProductCatalogFilters } from '../../../../components/analytics_v2/Filters/useProductCatalogFilters';
import { CustomPagination } from '../../../../components/CustomPagination';
import { EmptySearchState } from '../../../../components/EmptySearchState';
import { RowsRenderer } from '../../../../components/GroupableList';
import { Loader } from '../../../../components/Loader';
import {
  ProductCatalogTableSettings,
  useProductCatalogTable
} from '../../../../components/ProductCatalogTable';
import { SearchInput } from '../../../../components/SearchInput';
import { ColumnSelector } from '../../../../components/Table/ColumnSelector';
import { COLORS } from '../../../../domainTypes/colors';
import { EMPTY_OBJ } from '../../../../domainTypes/emptyConstants';
import { FilterDefinition } from '../../../../domainTypes/filters';
import {
  ProductCatalogField,
  ProductCatalogFilter,
  ProductCatalogOrderBy,
  ProductCatalogQuery
} from '../../../../domainTypes/productCatalog';
import { css } from '../../../../emotion';
import { Centered } from '../../../../layout/Centered';
import { FlexContainer } from '../../../../layout/Flex';
import {
  DEFAULT_OFFSET,
  PageToolbar,
  PageToolbarSection
} from '../../../../layout/PageToolbar';
import { useStringQueryParam } from '../../../../routes';
import { useMappedLoadingValue } from '../../../../services/db';
import { useSpaceId } from '../../../../services/use-space-id';
import { useProductCatalogQueryForPublisherApp } from '../../../ProductCatalog/service';
import { ProductCatalogDrawer } from '../ProductCatalogDrawer';

const UIS = [
  brandFilterUI,
  retailerFilterUI,
  sellerFilterUI,
  manufacturerFilterUI,
  catalogFilterUI,
  productCatalogAvailabilityFilterUI
];
const tree: FiltersList = [
  {
    label: 'Popular',
    options: [
      {
        dimension: 'product_catalog_brand',
        title: 'Brand',
        terms: []
      },
      {
        dimension: 'product_catalog_retailer',
        title: 'Retailer',
        terms: []
      },
      {
        dimension: 'product_catalog_manufacturer',
        title: 'Manufacturer',
        terms: []
      },
      {
        dimension: 'product_catalog_seller',
        title: 'Seller',
        terms: []
      },
      {
        dimension: 'product_catalog',
        title: 'Catalog',
        terms: []
      },
      {
        dimension: 'product_catalog_availability',
        title: 'Availability',
        terms: []
      }
    ]
  }
];

const columns = [
  'product',
  'retailer',
  'brand',
  'availability',
  'price'
] as const;
type Columns = typeof columns[number];
/*
 NOTE: This is a workaround for a TypeScript being overzealous.
 It is only necessary because we use ProductCatalogTableSettings type directly.
 Usually Columns type would be inferred correctly.
*/
const flatColumns = [...columns];

const PAGE_SIZE = 20;

type ProductCatalogTableProps = {
  fields: ProductCatalogField[];
  filters: ProductCatalogFilter[];
  orderBy: ProductCatalogOrderBy;
} & Pick<
  ProductCatalogTableSettings<Columns>,
  'tableProps' | 'pagination' | 'paginationSelectorProps'
>;

const ProductCatalogTable: React.FC<ProductCatalogTableProps> = ({
  fields,
  filters,
  pagination,
  orderBy,
  tableProps,
  paginationSelectorProps
}) => {
  const spaceId = useSpaceId();
  const query = useMemo<ProductCatalogQuery>(
    () => ({
      select: fields,
      filters,
      orderBy: [orderBy],
      limit: pagination
    }),
    [fields, filters, orderBy, pagination]
  );

  const [productCatalogUid, setProductCatalogUid] = useStringQueryParam(
    'productCatalogUid'
  );

  const countQuery = useMemo<ProductCatalogQuery>(
    () => ({
      select: ['count_uniq_uid'],
      filters,
      // TODO: make limit optional?
      limit: {
        page: 1,
        limit: 1
      }
    }),
    [filters]
  );
  const [data, loading] = useProductCatalogQueryForPublisherApp(spaceId, query);
  const [count = 0] = useMappedLoadingValue(
    useProductCatalogQueryForPublisherApp(spaceId, countQuery),
    (result) => result[0].count_uniq_uid
  );
  return (
    <>
      {loading || !data ? (
        <Centered height={300}>
          <Loader size={32} />
        </Centered>
      ) : (
        <>
          <RowsRenderer
            renderHead
            headProps={{
              sticky: true,
              offset: DEFAULT_OFFSET
            }}
            rows={data}
            {...tableProps}
            rowToKey={(r) => r.uid ?? ''}
            onRowClick={(r) => {
              setProductCatalogUid(r.uid ?? '');
            }}
          />
          <FlexContainer justifyContent="flex-end">
            <CustomPagination
              className={css(() => ({
                justifySelf: 'right'
              }))}
              {...paginationSelectorProps}
              siblingCount={0}
              count={Math.ceil(count / PAGE_SIZE)}
            />
          </FlexContainer>
        </>
      )}
      <ProductCatalogDrawer
        open={!!productCatalogUid}
        onClose={() => setProductCatalogUid('')}
        id={productCatalogUid}
      />
    </>
  );
};

export const ProductCatalog = () => {
  const [search, setSearch] = useStringQueryParam('q', '');
  const baseFilters = useMemo<ProductCatalogFilter[]>(() => {
    return !isEmpty(search)
      ? [
          {
            field: 'title',
            condition: 'ilike',
            pattern: `%${search}%`
          }
        ]
      : [];
  }, [search]);

  const queryParts = useMemo(
    () => ({
      filters: baseFilters
    }),
    [baseFilters]
  );

  const { filters, drawerProps } = useProductCatalogFilters(UIS, queryParts, {
    localStorageKey: 'product-catalog-filters',
    initialState: true
  });

  const allFilters = useMemo(() => [...filters, ...baseFilters], [
    filters,
    baseFilters
  ]);

  const {
    tableProps,
    orderBy,
    paginationSelectorProps,
    fields,
    pagination,
    columnSelectorProps
  } = useProductCatalogTable<never, Columns>(flatColumns, EMPTY_OBJ, {
    pageSize: PAGE_SIZE,
    showComparison: false,
    defaultSortColumn: 'product',
    defaultSortDirection: 'asc'
  });

  const { filters: filterDefinitions, addFilter } = drawerProps;

  return (
    <>
      <PageToolbar>
        <PageToolbarSection flex={2}>
          <Typography
            variant="h6"
            component="span"
            style={{
              marginRight: '9px',
              position: 'relative',
              fontWeight: 'bold',
              top: '-2px'
            }}
          >
            Product Catalog
          </Typography>
          <SearchInput
            value={search}
            onChange={setSearch}
            placeholder="Search products"
            autoFocus
            width={300}
          />
        </PageToolbarSection>
        <PageToolbarSection flex={1} justifyContent="flex-end">
          <ColumnSelector {...columnSelectorProps} />
        </PageToolbarSection>
      </PageToolbar>
      <FiltersDrawer
        {...drawerProps}
        isOpen={true}
        tree={tree}
        title="Filter products by"
      />

      <Paper className={css((t) => ({ marginTop: t.spacing(2) }))}>
        {isEmpty(filterDefinitions) && !search ? (
          <Prompt addFilter={addFilter} />
        ) : (
          <ProductCatalogTable
            fields={fields}
            filters={allFilters}
            pagination={pagination}
            orderBy={orderBy}
            tableProps={tableProps}
            paginationSelectorProps={paginationSelectorProps}
          />
        )}
      </Paper>
    </>
  );
};

const Action: React.FC<{ onClick: () => void }> = ({ onClick, children }) => {
  return (
    <ButtonBase onClick={onClick} style={{ verticalAlign: 'baseline' }}>
      <Typography variant="body1" color="primary">
        {children}
      </Typography>
    </ButtonBase>
  );
};

const Prompt: React.FC<{ addFilter: (def: FilterDefinition) => void }> = ({
  addFilter
}) => {
  return (
    <Centered height={600}>
      <EmptySearchState
        icon={ShoppingBag}
        title="Add filters to explore the product catalog"
        message={
          <>
            Try filtering for{' '}
            <Action
              onClick={() =>
                addFilter({
                  k: 'product_catalog_brand',
                  v: ['Apple']
                })
              }
            >
              Apple products
            </Action>
            , or{' '}
            <Action
              onClick={() =>
                addFilter({ k: 'product_catalog_brand', v: ['KitchenAid'] })
              }
            >
              KitchenAid products
            </Action>
            , or{' '}
            <Action
              onClick={() =>
                addFilter({ k: 'product_catalog_seller', v: ['Best Buy'] })
              }
            >
              products sold by Best Buy
            </Action>
            .
          </>
        }
        color={COLORS.blue.blue5}
        bgColor={COLORS.blue.blue1}
      />
    </Centered>
  );
};
