import React, { ReactNode } from 'react';
import { FlexContainer } from '../../../../layout/Flex';
import {
  AnalyticsFilter,
  AnalyticsOrderBy,
  AnalyticsResponseRowWithComparison,
  SelectableField
} from '../../../../domainTypes/analytics_v2';
import { useSpaceCurrency } from '../../../../services/useSpaceCurrency';
import pluralize from 'pluralize';
import { css, styled } from '../../../../emotion';
import { Card } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {
  isMetric,
  Metric,
  metricTitle
} from '../../../../services/analyticsV2/metrics';
import { Area, AreaChart, ResponsiveContainer } from 'recharts';
import { useColors } from '../../../../services/color';
import { LoadingValue } from '../../../../services/db';
import { Skeleton } from '@material-ui/lab';
import { formatNumber } from '../../../../components/Number';
import { useSearchSummary, useSearchTrend } from './service';
import { metricCell } from '../../../../components/analytics_v2/MetricCell';

interface SearchSummaryProps {
  filters: AnalyticsFilter[];
  metrics: Metric[];
  orderByField: AnalyticsOrderBy['field'];
}

const Cell: React.FC<{ title: ReactNode }> = ({ title, children }) => {
  return (
    <FlexContainer direction="column" className={css(() => ({ flexGrow: 1 }))}>
      <Typography color="textSecondary" variant="caption">
        {title}
      </Typography>
      <Typography variant="body2">{children}</Typography>
    </FlexContainer>
  );
};

const NumberSkeleton = styled(Skeleton)`
  width: 2rem;
  font-size: 1.5rem;
`;

const MetricCell: React.FC<{
  column: Metric;
  row: LoadingValue<AnalyticsResponseRowWithComparison>;
}> = ({ column, row }) => {
  const currency = useSpaceCurrency();
  const [data, loading] = row;
  return (
    <Cell title={metricTitle(column)}>
      {!data || loading ? (
        <NumberSkeleton />
      ) : (
        metricCell(column, data, { currency, showComparison: true })
      )}
    </Cell>
  );
};

const ResultsCell: React.FC<{
  row: LoadingValue<AnalyticsResponseRowWithComparison>;
}> = ({ row }) => {
  const [data, loading] = row;
  if (!data || loading) {
    return (
      <Cell title="Results">
        <NumberSkeleton />
      </Cell>
    );
  }
  const count = data.data.count_uniq_page_url?.curr ?? 0;
  return (
    <Cell title={pluralize('Result', count)}>{formatNumber({ n: count })}</Cell>
  );
};

const MetricTrend = ({
  metric,
  filters
}: {
  metric: Metric;
  filters: AnalyticsFilter[];
}) => {
  const { blue } = useColors();
  // TODO: check if sortByMetric is a metric and not a field like page_url
  const [data, loading] = useSearchTrend(filters, metric as SelectableField);
  if (!data || loading) return null;
  return (
    <ResponsiveContainer
      width="99%"
      height={60}
      className={css(() => ({ flexGrow: 1, flexBasis: 150 }))}
    >
      <AreaChart data={data}>
        <defs>
          <linearGradient id={`sparkline_gradient`} x1={0} x2={0} y1={0} y2={1}>
            <stop offset="5%" stopColor={blue.blue3} stopOpacity={0.8} />
            <stop offset="95%" stopColor={blue.blue3} stopOpacity={0} />
          </linearGradient>
        </defs>
        <Area
          dataKey="value"
          type="monotone"
          strokeOpacity={1}
          strokeWidth={1.5}
          stroke={blue.blue5}
          fill={`url(#sparkline_gradient)`}
          fillOpacity={1}
          activeDot={false}
          isAnimationActive={false}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

const SummaryCard = styled(Card)((p) => ({
  marginBottom: p.theme.spacing(2),
  paddingLeft: p.theme.spacing(4),
  paddingRight: p.theme.spacing(4),
  height: 85
}));

// NOTE: We hide Updated column for now. Probably we need better system for that, when we get more 'metrics' that are not strictly numeric
const SearchSummaryInner: React.FC<SearchSummaryProps> = ({
  filters,
  metrics,
  orderByField
}) => {
  const summary = useSearchSummary(filters, metrics as SelectableField[]);
  return (
    <SummaryCard>
      <FlexContainer spacing={2}>
        <ResultsCell row={summary} />
        {isMetric(orderByField) && orderByField !== 'page_last_modified' && (
          <MetricTrend filters={filters} metric={orderByField} />
        )}
        {metrics
          .filter((m) => m !== 'page_last_modified')
          .map((metric) => (
            <MetricCell key={metric} column={metric} row={summary} />
          ))}
      </FlexContainer>
    </SummaryCard>
  );
};

export const SearchSummary: React.FC<SearchSummaryProps> = ({
  filters,
  metrics,
  orderByField
}) => {
  // TODO: seems arbitrary
  if (filters.filter((f) => f.field !== 'page_url_origin').length === 0)
    return null;
  return (
    <SearchSummaryInner
      filters={filters}
      metrics={metrics}
      orderByField={orderByField}
    />
  );
};
