import { CampaignTeamFilterDefinition } from '../../../../../../domainTypes/filters';
import React, { useMemo, useState } from 'react';
import { BASIC_MODES, FilterMenu } from '../../Menus/FilterMenu';
import { useCurrentUser } from '../../../../../../services/currentUser';
import { useTeamsCollection } from '../../../../../../features/Campaigns/service/teams';
import { useMappedLoadingValue } from '../../../../../../services/db';
import {
  OptionsList,
  SelectorLoader,
  SelectorShell,
  useCollectionFilterState
} from '../../Menus/Selector';
import { ITeam } from '../../../../../../domainTypes/teams';
import pluralize from 'pluralize';
import { WithShape } from '../../../../../Charts/Util';
import { getStableRandomColor } from '../../../../../../services/color';
import { toSearchRegexp } from '../../../../../SearchInput';
import { CampaignFilterMenuComponent } from '../../../FilterUI';

const CampaignTeamPicker: React.FC<{
  onSave: (definition: CampaignTeamFilterDefinition) => void;
  teams: ITeam[];
  definition: CampaignTeamFilterDefinition;
}> = ({ definition, teams, onSave }) => {
  const {
    values,
    handleSave,
    handleToggle,
    handleFocus,
    isSaveEnabled
  } = useCollectionFilterState<string, CampaignTeamFilterDefinition>(
    definition,
    onSave
  );

  const [search, setSearch] = useState('');

  const options = useMemo(() => {
    const searchRe = toSearchRegexp(search);
    return teams
      .filter((team) => !searchRe || team.name.toLowerCase().match(searchRe))
      .map((team) => ({
        value: team.teamId,
        label: (
          <WithShape color={getStableRandomColor(team.teamId)}>
            {team.name}
          </WithShape>
        )
      }));
  }, [search, teams]);

  return (
    <>
      <FilterMenu.Body>
        <SelectorShell label="Teams" search={search} setSearch={setSearch}>
          <OptionsList
            options={options}
            selectedValues={values}
            onToggle={handleToggle}
            onFocus={handleFocus}
          />
        </SelectorShell>
      </FilterMenu.Body>
      <FilterMenu.Footer
        description={
          <>
            <strong>Campaign Team</strong> is the group of people working on
            campaigns together.
          </>
        }
      >
        <FilterMenu.SaveButton
          disabled={!isSaveEnabled}
          onSave={handleSave}
          label={`Filter by ${pluralize('team', values.length, true)}`}
        />
      </FilterMenu.Footer>
    </>
  );
};

const InnerCampaignTeamMenu = (props: {
  onSave: (definition: CampaignTeamFilterDefinition) => void;
  definition: CampaignTeamFilterDefinition;
}) => {
  const { space } = useCurrentUser();
  const [teams, loading] = useMappedLoadingValue(
    useTeamsCollection(space.id),
    (teams) => teams.map((team) => team.data)
  );

  if (!teams || loading) {
    return (
      <>
        <FilterMenu.Body>
          <SelectorLoader />
        </FilterMenu.Body>
        <FilterMenu.Footer
          description={
            <>
              <strong>Campaign Team</strong> is the group of people working on
              campaigns together.
            </>
          }
        >
          <FilterMenu.SaveButton
            disabled
            onSave={() => {}}
            label="Loading teams..."
          />
        </FilterMenu.Footer>
      </>
    );
  }

  return <CampaignTeamPicker {...props} teams={teams} />;
};

export const CampaignTeamMenu: CampaignFilterMenuComponent<CampaignTeamFilterDefinition> = ({
  definition,
  onSave,
  isFirst
}) => {
  const [mode, setMode] = useState(BASIC_MODES[0].value);

  return (
    <FilterMenu>
      <FilterMenu.Header name="campaign team" isFirst={isFirst}>
        <FilterMenu.ModeSelector
          modes={BASIC_MODES}
          mode={mode}
          setMode={setMode}
        />
      </FilterMenu.Header>
      <InnerCampaignTeamMenu definition={definition} onSave={onSave} />
    </FilterMenu>
  );
};
