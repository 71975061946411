import React, { useCallback, useState } from 'react';
import { useBoolean } from '../../../../../hooks/useBoolean';
import { FlexContainer } from '../../../../../layout/Flex';
import { IconButton, TextField } from '@material-ui/core';
import { Edit, Save } from 'react-feather';
import Typography from '@material-ui/core/Typography';

export const SegmentNameField: React.FC<{
  value: string;
  onChange: (value: string) => void;
}> = ({ value, onChange }) => {
  const [name, setName] = useState(value);
  const [isEditing, startEdit, endEdit] = useBoolean();
  const save = useCallback(() => {
    onChange(name);
    endEdit();
  }, [endEdit, name, onChange]);
  return (
    <FlexContainer>
      {isEditing ? (
        <>
          <TextField
            value={name}
            onChange={(e) => setName(e.target.value)}
            label="Name"
          />
          <IconButton onClick={save}>
            <Save size={16} />
          </IconButton>
        </>
      ) : (
        <>
          <Typography variant="body2">{name}</Typography>
          <IconButton onClick={startEdit}>
            <Edit size={16} />
          </IconButton>
        </>
      )}
    </FlexContainer>
  );
};
