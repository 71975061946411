import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { capitalize, maxBy, minBy, times } from 'lodash';
import moment from 'moment-timezone';
import React, { useMemo, useState } from 'react';
import {
  ChevronDown,
  ChevronUp,
  Clock,
  Code,
  ExternalLink,
  Image,
  X
} from 'react-feather';
import {
  Dot,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis
} from 'recharts';
import { useTimeframe } from '../../../components/analytics_v2/Timeframe';
import {
  CustomTooltipWithSinglePayload,
  TooltipHeading
} from '../../../components/Charts/CustomTooltip';
import {
  formatChartCurrency,
  WithShape
} from '../../../components/Charts/Util';
import { InternalId } from '../../../components/ConnectionId';
import {
  DrawerHeader,
  DrawerSection,
  List,
  SectionHeading
} from '../../../components/DrawerHeader';
import { LinkExternal } from '../../../components/LinkExternal';
import {
  clampCents,
  Currency,
  formatCurrency,
  Price
} from '../../../components/Number';
import { RetailerName } from '../../../components/ProductCatalogRetailer';
import { Dash } from '../../../components/Table/CountCell';
import { Truncated } from '../../../components/Truncated';
import {
  AnalyticsQuery,
  ISOTimeRange
} from '../../../domainTypes/analytics_v2';
import { COLORS } from '../../../domainTypes/colors';
import { CurrencyCode } from '../../../domainTypes/currency';
import {
  IProductCatalogHistoryRowInTimeseries,
  IProductCatalogRowV1,
  ProductCatalogAvailability,
  ProductCatalogHistoryInterval
} from '../../../domainTypes/productCatalog';
import { css, styled } from '../../../emotion';
import { FlexContainer, FlexContainerVertical } from '../../../layout/Flex';
import { useChannelIdGrouper } from '../../../services/analyticsV2/groups';
import { useAnalyticsQueryV2 } from '../../../services/analyticsV2/query';
import { useAdminOrImpersonatorClaim } from '../../../services/auth';
import { useCurrentUser } from '../../../services/currentUser';
import { fromMoment } from '../../../services/time';
import { getPathname } from '../../../services/url';
import { useSpaceCurrency } from '../../../services/useSpaceCurrency';
import {
  getAvailabilityColors,
  getAvailabilityTexts,
  IssueAvailability,
  IssueAvailabilityBadge
} from '../../Links/pages/Overview/components/ProductIssue';
import {
  useProductCatalogHistoryWithDefaults,
  useProductCatalogItem,
  useProductCatalogMatchingProductsById
} from '../../ProductCatalog/service';

const InnerWrapper = styled('div')`
  max-width: 400px;
`;

const IMAGE_SIZE = 112;

const ProductImageContainer = styled('div')`
  border: 1px solid ${(p) => p.theme.palette.grey[300]};
  box-sizing: border-box; /* Include padding in size */
  width: ${IMAGE_SIZE}px;
  height: ${IMAGE_SIZE}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: ${(p) => p.theme.palette.common.white};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);

  color: ${(p) => p.theme.palette.grey[400]}; /* for fallback icon */

  &:hover {
    cursor: pointer;
    border-color: ${(p) => p.theme.palette.common.black};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }

  img {
    display: block; /* Prevent inline element margins */
    object-fit: contain; /* Maintain aspect ratio */
    width: ${IMAGE_SIZE - 4}px;
    height: ${IMAGE_SIZE - 4}px;
  }
`;

const Grid = styled('div')`
  display: grid;
  grid-template-columns: ${IMAGE_SIZE}px 1fr;
  gap: 1rem;
`;

const AltRetailersGrid = styled('div')`
  display: grid;
  justify-content: space-between;
  width: 100%;
  grid-template-columns: 1fr 50px 50px;
`;

const LoadingProductSummary = () => {
  return (
    <>
      <Skeleton variant="text" height={36} width={72} />
      <Typography variant="h5" component="p">
        <Skeleton variant="text" height={41} />
        <Skeleton variant="text" height={41} />
        <Skeleton variant="text" height={41} width={300} />
      </Typography>
      <Grid>
        <Skeleton variant="rect" width={IMAGE_SIZE} height={IMAGE_SIZE} />
        <List>
          <dt>Retailer</dt>
          <dd>
            <Skeleton variant="text" height={24} width={80} />
          </dd>
          <dt>Price</dt>
          <dd>
            <Skeleton variant="text" height={24} width={80} />
          </dd>
          <dt>On sale</dt>
          <dd>
            <Skeleton variant="text" height={24} width={40} />
          </dd>
          <dt>Last updated</dt>
          <dd>
            <Skeleton variant="text" height={24} width={72} />
          </dd>
        </List>
      </Grid>
    </>
  );
};

const LastSeenLongTime = styled('span')`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const ClockIcon = styled(Clock)`
  color: ${(p) => p.theme.custom.colors.error.main};
`;

const LastSeenRecently = styled('span')`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const ProductLastUpdated = ({ seenAt }: { seenAt: string }) => {
  // If it was last seen more than 48 hours ago, show the date
  // Otherwise show the time

  const diffDays = useMemo(() => {
    const now = moment();
    const lastSeen = moment(seenAt);
    return now.diff(lastSeen, 'days');
  }, [seenAt]);
  const timeAgo = useMemo(() => {
    const lastSeen = moment(seenAt);
    return lastSeen.fromNow();
  }, [seenAt]);

  if (diffDays > 2) {
    return (
      <Tooltip
        title={`This product was last seen ${timeAgo}, on ${moment
          .utc(seenAt)
          .format('LLL')}.`}
        placement="top"
      >
        <LastSeenLongTime>
          {moment.utc(seenAt).format("MMM DD, 'YY")}
          <ClockIcon size={16} />
        </LastSeenLongTime>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      title={`This product was last seen ${timeAgo}, on ${moment
        .utc(seenAt)
        .format('LLL')}.`}
      placement="top"
    >
      <LastSeenRecently>
        {capitalize(moment.utc(seenAt).fromNow())}
      </LastSeenRecently>
    </Tooltip>
  );
};

const LoadingProductDetails = () => {
  return (
    <DrawerSection>
      <Skeleton variant="rect" height={172} />
    </DrawerSection>
  );
};

const ProductDetails = ({
  product,
  loading
}: {
  product: IProductCatalogRowV1 | null | void;
  loading: boolean;
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isAdminOrImpersonator] = useAdminOrImpersonatorClaim();
  const [expanded, setExpanded] = useState(false);

  if (!isAdminOrImpersonator) {
    return null;
  }

  if (loading || !product) {
    return <LoadingProductDetails />;
  }

  return (
    <>
      <Divider />
      <DrawerSection>
        <Accordion
          expanded={expanded}
          onChange={() => setExpanded(!expanded)}
          style={{ border: '1px solid #EEE' }}
        >
          <AccordionSummary>
            <FlexContainer justifyContent="space-between" fullWidth>
              <SectionHeading style={{ margin: 0 }}>
                Product data (Admin-only)
              </SectionHeading>
              {expanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
            </FlexContainer>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  justifyContent: 'space-between',
                  marginBottom: '8px'
                }}
              >
                <Typography variant="caption">
                  <strong>Product details</strong>
                </Typography>
                {isAdminOrImpersonator && (
                  <ButtonBase
                    onClick={() => setDialogOpen(true)}
                    style={{
                      color: COLORS.blue.blue5,
                      display: 'flex',
                      alignItems: 'center',
                      gap: '4px'
                    }}
                  >
                    <Code size={16} /> JSON
                  </ButtonBase>
                )}
              </div>
              <List>
                <dt>Uid</dt>
                <dd>
                  <InternalId>{product.uid}</InternalId>
                </dd>
                <dt>Catalog</dt>
                <dd>
                  <InternalId>{product.catalog}</InternalId>
                </dd>
                <dt>Catalog ID</dt>
                <dd>
                  <InternalId>{product.catalog_id}</InternalId>
                </dd>
                <dt>Source</dt>
                <dd>
                  <InternalId>{product.source}</InternalId>
                </dd>
                <dt>Retailer</dt>
                <dd>{product.retailer}</dd>
                <dt>Seller</dt>
                <dd>{product.seller}</dd>
                <dt>Brand</dt>
                <dd>{product.brand}</dd>
                <dt>Manufacturer</dt>
                <dd>{product.manufacturer}</dd>
                <dt>Title</dt>
                <dd>{product.title}</dd>
                <dt>Description</dt>
                <dd>{product.description}</dd>
                <dt>Link</dt>
                <dd>{product.link}</dd>
                <dt>Image link</dt>
                <dd>{product.image_link}</dd>
              </List>
              <Typography variant="caption" paragraph>
                <strong>Price and availability</strong>
              </Typography>
              <List>
                <dt>Price</dt>
                <dd>
                  {product.price} &nbsp;&nbsp;
                  <Typography variant="caption" color="textSecondary">
                    <Price
                      price={product.price}
                      orig_price={product.price}
                      currency={product.price_curr as CurrencyCode}
                    />
                  </Typography>
                </dd>
                <dt>Price currency</dt>
                <dd>
                  <InternalId>{product.price_curr}</InternalId>
                </dd>
                <dt>Price USD</dt>
                <dd>
                  {product.price_usd} &nbsp;&nbsp;
                  <Typography variant="caption" color="textSecondary">
                    <Price
                      price={product.price_usd}
                      orig_price={product.price_usd}
                      currency={'USD'}
                    />
                  </Typography>
                </dd>
                <dt>Original price</dt>
                <dd>
                  {product.orig_price} &nbsp;&nbsp;
                  <Typography variant="caption" color="textSecondary">
                    <Price
                      price={product.orig_price}
                      orig_price={product.orig_price}
                      currency={product.orig_price_curr as CurrencyCode}
                    />
                  </Typography>
                </dd>
                <dt>Original price currency</dt>
                <dd>
                  <InternalId>{product.orig_price_curr}</InternalId>
                </dd>
                <dt>Sale percent</dt>
                <dd>{product.sale_percent}</dd>
                <dt>Availability</dt>
                <dd>{product.availability}</dd>
                <dt>Availability date</dt>
                <dd>
                  {product.availability_date ? (
                    <InternalId>{product.availability_date}</InternalId>
                  ) : (
                    <Dash />
                  )}
                </dd>
                <dt>Availability change seen at</dt>
                <dd>
                  {product.availability_change_seen_at ? (
                    <InternalId>
                      {product.availability_change_seen_at}
                    </InternalId>
                  ) : (
                    <Dash />
                  )}
                </dd>
              </List>
              <Typography variant="caption" paragraph>
                <strong>Categories</strong>
              </Typography>
              <List>
                <dt>Category Amazon</dt>
                <dd>
                  {product.category_amazon ? (
                    <InternalId>{product.category_amazon}</InternalId>
                  ) : (
                    <Dash />
                  )}
                </dd>
                <dt>Category IAB</dt>
                <dd>
                  {product.category_iab ? (
                    <InternalId>{product.category_iab}</InternalId>
                  ) : (
                    <Dash />
                  )}
                </dd>
                <dt>Category GSF</dt>
                <dd>
                  {product.category_gsf ? (
                    <InternalId>{product.category_gsf}</InternalId>
                  ) : (
                    <Dash />
                  )}
                </dd>
                <dt>Category Merchant</dt>
                <dd>
                  {product.category_merchant ? (
                    <InternalId>{product.category_merchant}</InternalId>
                  ) : (
                    <Dash />
                  )}
                </dd>
              </List>
              <Typography variant="caption" paragraph>
                <strong>Timestamps</strong>
              </Typography>
              <List>
                <dt>Insertion process id</dt>
                <dd>
                  <InternalId>{product.insertion_process_id}</InternalId>
                </dd>
                <dt>Updated at</dt>
                <dd>
                  {product.updated_at ? (
                    <>
                      <InternalId>{product.updated_at}</InternalId>
                      <br />
                      <Tooltip
                        title={moment(product.updated_at).format('LLL')}
                        placement="top"
                      >
                        <div>{moment(product.updated_at).fromNow()}</div>
                      </Tooltip>
                    </>
                  ) : (
                    <Dash />
                  )}
                </dd>
                <dt>Seen at</dt>
                <dd>
                  {product.seen_at ? (
                    <>
                      <InternalId>{product.seen_at}</InternalId>
                      <br />
                      <Tooltip
                        title={moment(product.seen_at).format('LLL')}
                        placement="top"
                      >
                        <div>{moment(product.seen_at).fromNow()}</div>
                      </Tooltip>
                    </>
                  ) : (
                    <Dash />
                  )}
                </dd>
                <dt>Inserted at</dt>
                <dd>
                  {product.inserted_at ? (
                    <>
                      <InternalId>{product.inserted_at}</InternalId>
                      <br />
                      <Tooltip
                        title={moment(product.inserted_at).format('LLL')}
                        placement="top"
                      >
                        <div>{moment(product.inserted_at).fromNow()}</div>
                      </Tooltip>
                    </>
                  ) : (
                    <Dash />
                  )}
                </dd>
                <dt>Created at</dt>
                <dd>
                  {product.created_at ? (
                    <>
                      <InternalId>{product.created_at}</InternalId>
                      <br />
                      <Tooltip
                        title={moment(product.created_at).format('LLL')}
                        placement="top"
                      >
                        <div>{moment(product.created_at).fromNow()}</div>
                      </Tooltip>
                    </>
                  ) : (
                    <Dash />
                  )}
                </dd>
              </List>
              <Typography variant="caption" paragraph>
                <strong>Identifiers</strong>
              </Typography>
              <List>
                <dt>GTIN EAN UPC</dt>
                <dd>
                  <InternalId>{product.gtin_ean_upc}</InternalId>
                </dd>
                <dt>ASIN</dt>
                <dd>
                  {product.asin ? (
                    <InternalId>{product.asin}</InternalId>
                  ) : (
                    <Dash />
                  )}
                </dd>
                <dt>GTIN</dt>
                <dd>
                  {product.gtin ? (
                    <InternalId>{product.gtin}</InternalId>
                  ) : (
                    <Dash />
                  )}
                </dd>
                <dt>EAN</dt>
                <dd>
                  {product.ean ? (
                    <InternalId>{product.ean}</InternalId>
                  ) : (
                    <Dash />
                  )}
                </dd>
                <dt>ISBN</dt>
                <dd>
                  {product.isbn ? (
                    <InternalId>{product.isbn}</InternalId>
                  ) : (
                    <Dash />
                  )}
                </dd>
                <dt>MPN</dt>
                <dd>
                  {product.mpn ? (
                    <InternalId>{product.mpn}</InternalId>
                  ) : (
                    <Dash />
                  )}
                </dd>
                <dt>SKU</dt>
                <dd>
                  {product.sku ? (
                    <InternalId>{product.sku}</InternalId>
                  ) : (
                    <Dash />
                  )}
                </dd>
              </List>
            </div>{' '}
          </AccordionDetails>
        </Accordion>
      </DrawerSection>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="lg"
      >
        <DialogTitle>
          {product.title}
          <IconButton onClick={() => setDialogOpen(false)}>
            <X size={16} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <pre>{JSON.stringify(product, null, 2)}</pre>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDialogOpen(false)}
            color="primary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const ProductSummary = ({
  uid,
  product,
  loading
}: {
  uid: string;
  product: IProductCatalogRowV1 | null | void;
  loading: boolean;
}) => {
  const [imgDialogOpen, setImgDialogOpen] = useState(false);

  return (
    <>
      <DrawerSection>
        {loading && <LoadingProductSummary />}
        {!loading && product && (
          <>
            <IssueAvailabilityBadge
              availability={product.availability}
              // These are currently returned in a YYYY-MM-DD HH:mm:ss format.
              // We're gonna change this most likely to be true ISO formats,
              // which might require changes of this code.
              seenAt={fromMoment(moment.utc(product.seen_at))}
              availabilityChangeSeenAt={fromMoment(
                moment.utc(product.availability_change_seen_at)
              )}
            />
            <Typography variant="h5" paragraph style={{ marginTop: 8 }}>
              <strong>{product.title}</strong>{' '}
              <LinkExternal href={product.link}>
                <ExternalLink size={20} />
              </LinkExternal>
            </Typography>
            <Grid>
              <ProductImageContainer onClick={() => setImgDialogOpen(true)}>
                {product.image_link ? (
                  <img src={product.image_link} alt={product.title} />
                ) : (
                  <Image size={24} />
                )}
              </ProductImageContainer>
              <List>
                <dt>Retailer</dt>
                <dd>
                  <RetailerName retailer={product.retailer} />
                </dd>
                <dt>Price</dt>
                <dd>
                  <Price
                    price={product.price}
                    orig_price={product.orig_price}
                    currency={product.price_curr as CurrencyCode}
                  />
                </dd>
                <dt>On sale</dt>
                <dd>
                  {product.price !== product.orig_price ? (
                    <Typography color="error" variant="body2">
                      Yes
                    </Typography>
                  ) : (
                    'No'
                  )}
                </dd>
                <dt>Last updated</dt>
                <dd>
                  <ProductLastUpdated seenAt={product.seen_at} />
                </dd>
              </List>
            </Grid>
          </>
        )}
      </DrawerSection>
      {product && (
        <Dialog
          open={imgDialogOpen}
          onClose={() => setImgDialogOpen(false)}
          maxWidth="md"
        >
          <DialogContent>
            <Typography variant="h6" gutterBottom>
              <strong>{product.title}</strong>
            </Typography>
            <RetailerName retailer={product.retailer} />
            <div style={{ textAlign: 'center', marginTop: 16 }}>
              <img
                src={product.image_link}
                alt={product.title}
                style={{ maxWidth: '500px', height: 'auto' }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setImgDialogOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

const PageUrlGrid = styled('div')`
  display: grid;
  grid-template-columns: 175px 1fr 1fr 1fr;
  gap: 1rem;
`;

const PagePerformance = ({
  uid,
  product,
  loading
}: {
  uid: string;
  product: IProductCatalogRowV1 | null | void;
  loading: boolean;
}) => {
  const { space } = useCurrentUser();
  const currency = useSpaceCurrency();
  const { range, compare } = useTimeframe();
  const { columnTransformers } = useChannelIdGrouper();

  const pagePerformanceQuery = useMemo<AnalyticsQuery>(() => {
    return {
      select: ['c', 'epc_net', 'commission_sum_net'],
      range,
      compare,
      groupBy: ['page_url'],
      orderBy: [
        {
          field: 'c',
          direction: 'DESC'
        }
      ],
      filters: [
        {
          field: 'p_catalog_uid',
          condition: 'in',
          values: [uid]
        }
      ],
      paginate: {
        page: 1,
        limit: 10
      },
      columnTransformers: columnTransformers(space)
    };
  }, [range, columnTransformers, space, compare, uid]);

  const [data] = useAnalyticsQueryV2(space.id, pagePerformanceQuery, {
    logMode: 'full',
    logLabel: 'ProductCatalogDrawer'
  });

  return (
    <DrawerSection>
      <SectionHeading>Page performance</SectionHeading>
      {data && data.rows.length === 0 && (
        <Typography variant="caption">
          You haven't tracked any page performance for this product.
        </Typography>
      )}
      {data && data.rows.length > 0 && (
        <PageUrlGrid>
          <Typography variant="caption" color="textSecondary">
            Page URL
          </Typography>
          <Typography variant="caption" color="textSecondary">
            Clicks
          </Typography>
          <Typography variant="caption" color="textSecondary">
            EPC
          </Typography>
          <Typography variant="caption" color="textSecondary">
            Earnings
          </Typography>
          {!data &&
            times(4 * 6).map((i) => (
              <Skeleton key={i} variant="text" height={24} />
            ))}
          {data &&
            data.rows.length > 0 &&
            data.rows.map((row) => {
              const clicks = row.data.c?.curr ?? 0;
              const epc = row.data.epc_net?.curr ?? 0;
              const earnings = row.data.commission_sum_net?.curr ?? 0;

              return (
                <React.Fragment key={row.group.page_url}>
                  <Truncated title={row.group.page_url}>
                    {getPathname(row.group.page_url)}
                  </Truncated>
                  <div>{clicks.toLocaleString()}</div>
                  <div>
                    {epc !== 0 ? (
                      <Currency cents={epc} currency={currency} />
                    ) : (
                      <Dash size={14} />
                    )}
                  </div>
                  <div>
                    {earnings !== 0 ? (
                      <Currency cents={earnings} currency={currency} />
                    ) : (
                      <Dash size={14} />
                    )}
                  </div>
                </React.Fragment>
              );
            })}
        </PageUrlGrid>
      )}
    </DrawerSection>
  );
};

export const ProductHistoryChart = ({
  ds,
  range,
  interval
}: {
  ds: IProductCatalogHistoryRowInTimeseries[];
  range: ISOTimeRange;
  interval: ProductCatalogHistoryInterval;
}) => {
  // This currently only works correctly with daily
  // intervals. With weeks we need to make sure that
  // we're following the same mechanics as PGs date_bin does

  if (!ds.length) {
    // empty state
    return null;
  }
  const currency = ds[0].price_curr;
  const tz = interval.tz;

  console.log('HISTORY', ds);
  // Line chart
  // lines should be straight
  // Indicate via icon if on sale?
  // indicate via color availability status
  return (
    <ResponsiveContainer aspect={2}>
      <LineChart
        data={ds}
        margin={{
          left: -8,
          right: 4,
          bottom: 8
        }}
      >
        <XAxis
          // Need to make this number based, so that it's spaced out
          // correctly
          dataKey={(d) => moment(d.interval).valueOf()}
          tick={{
            fill: '#9b9b9b',
            fontSize: 12
          }}
          angle={-45}
          type="number"
          tickFormatter={(d) => moment.tz(d, tz).format('MM/DD')}
          domain={[
            moment(range.start).tz(tz).toISOString(),
            moment(range.end).tz(tz).toISOString()
          ]}
          textAnchor="end"
        />
        <YAxis
          tickFormatter={(d) =>
            formatChartCurrency(d, currency as CurrencyCode)
          }
          tick={{
            fill: '#9b9b9b',
            fontSize: 12
          }}
          domain={[
            Math.max(
              0,
              clampCents((minBy(ds, (d) => d.price)?.price || 0) - 1000, -1000)
            ),
            clampCents((maxBy(ds, (d) => d.price)?.price || 0) + 1000, 1000)
          ]}
        />
        <Line
          dataKey="price"
          stroke={'#9b9b9b'}
          isAnimationActive={false}
          dot={(d) => {
            const av = d.payload.availability as ProductCatalogAvailability;
            const avColors = getAvailabilityColors(av);
            return (
              <Dot
                r={d.r}
                cx={d.cx}
                cy={d.cy}
                className={css(() => ({
                  stroke: avColors.backgroundColor,
                  fill: avColors.backgroundColor
                }))}
              />
            );
          }}
        />
        <RechartsTooltip
          cursor={false}
          content={
            <CustomTooltipWithSinglePayload
              render={(d: IProductCatalogHistoryRowInTimeseries) => (
                <>
                  <TooltipHeading>
                    {moment(d.interval).tz(tz).format('MMM DD')}
                  </TooltipHeading>
                  <div>
                    <WithShape
                      color={
                        getAvailabilityColors(d.availability).backgroundColor
                      }
                    >
                      {getAvailabilityTexts(d.availability).label}
                    </WithShape>
                  </div>
                  <FlexContainer fullWidth spacing={1}>
                    <div>Price:</div>
                    <div>
                      <FlexContainer alignItems="flex-end">
                        <Price
                          price={d.price}
                          orig_price={d.orig_price}
                          currency={d.price_curr as CurrencyCode}
                          salePriceFirst={false}
                        />
                      </FlexContainer>
                    </div>
                  </FlexContainer>
                </>
              )}
            />
          }
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

const SemiOpaqueWrapper = styled('div')`
  position: relative;
`;

const OverlayText = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  text-align: center;
  width: 80%;
`;
const SemiOpaqueOverlay = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(2px);
  z-index: 1;
`;

const ProductHistoryChartNoChanges = ({
  lastSeen,
  currentPrice,
  currentAvailability
}: {
  lastSeen: string;
  currentPrice: number;
  currentAvailability: ProductCatalogAvailability;
}) => {
  const timeseries = useMemo(() => {
    if (moment(lastSeen).isBefore(moment().subtract(30, 'days'))) {
      return Array.from({ length: 30 }, (_, i) => ({
        pc_uid: '',
        seen_at: moment()
          .subtract(29 - i, 'days')
          .format(),
        interval: moment()
          .subtract(29 - i, 'days')
          .format(),
        price: currentPrice,
        price_curr: 'USD',
        price_usd: currentPrice,
        orig_price: currentPrice,
        orig_price_curr: 'USD',
        orig_price_usd: currentPrice,
        sale_percent: 0,
        availability: ProductCatalogAvailability.unknown
      }));
    }
    return Array.from({ length: 30 }, (_, i) => ({
      pc_uid: '',
      seen_at: moment()
        .subtract(29 - i, 'days')
        .format(),
      interval: moment()
        .subtract(29 - i, 'days')
        .format(),
      price: currentPrice,
      price_curr: 'USD',
      price_usd: currentPrice,
      orig_price: currentPrice,
      orig_price_curr: 'USD',
      orig_price_usd: currentPrice,
      sale_percent: 0,
      availability: currentAvailability
    }));
  }, [currentPrice, currentAvailability, lastSeen]);

  const range = useMemo(() => {
    return {
      start: moment().subtract(29, 'days').format(),
      end: moment().format()
    };
  }, []);

  const interval = useMemo(() => {
    return {
      tz: 'UTC',
      type: 'day' as const,
      unit: 'day' as const,
      value: 1
    };
  }, []);

  const text = useMemo(() => {
    const lastSeenDate = moment(lastSeen);
    const now = moment();
    const diff = now.diff(lastSeenDate, 'days');
    if (diff > 30) {
      return 'This product hasn\'t been checked for price or stock changes in the last 30 days.';
    }
    return 'No price or stock changes tracked in the last 30 days for this product.';
  }, [lastSeen]);

  return (
    <SemiOpaqueWrapper>
      <OverlayText>{text}</OverlayText>
      <SemiOpaqueOverlay />
      <ProductHistoryChart ds={timeseries} range={range} interval={interval} />
    </SemiOpaqueWrapper>
  );
};

export const ProductHistory = ({
  uid,
  tz,
  product,
  loading
}: {
  uid: string;
  tz: string;
  product: IProductCatalogRowV1 | null | void;
  loading: boolean;
}) => {
  const [history, loadingHistory] = useProductCatalogHistoryWithDefaults(
    uid,
    tz
  );

  return (
    <DrawerSection>
      <SectionHeading>Price &amp; Stock History</SectionHeading>
      <br />
      {loadingHistory || loading ? (
        <Skeleton variant="rect" height={172} />
      ) : (
        <>
          {history && history.timeseries.length > 0 ? (
            <>
              <ProductHistoryChart
                ds={history.timeseries}
                range={history.range}
                interval={history.interval}
              />
            </>
          ) : (
            product && (
              <ProductHistoryChartNoChanges
                lastSeen={product.seen_at}
                currentPrice={product.price}
                currentAvailability={product.availability}
              />
            )
          )}
        </>
      )}
    </DrawerSection>
  );
};

const ProductMatches = ({
  uid,
  product,
  loading
}: {
  uid: string;
  product: IProductCatalogRowV1 | null | void;
  loading: boolean;
}) => {
  const [d] = useProductCatalogMatchingProductsById(uid);
  return (
    <DrawerSection>
      <SectionHeading>Also available at</SectionHeading>
      <FlexContainerVertical fullWidth>
        {!d &&
          times(3).map((i) => (
            <FlexContainer fullWidth key={i}>
              <Skeleton variant="text" height={24} width="47%" />
              <Skeleton variant="text" height={24} width="47%" />
              <Skeleton variant="rect" height={12} width={12} />
            </FlexContainer>
          ))}
        {d && !!d.matches.length && (
          <AltRetailersGrid>
            <Typography variant="caption" color="textSecondary" gutterBottom>
              Retailer
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              gutterBottom
              style={{ textAlign: 'right' }}
            >
              Price
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              gutterBottom
              style={{ textAlign: 'right' }}
            >
              Stock
            </Typography>
            {d.matches.map(({ match: m }) => {
              return (
                <React.Fragment key={m.uid}>
                  <Tooltip
                    title={`Open "${m.link}" in a new tab`}
                    placement="top"
                  >
                    <LinkExternal
                      href={m.link}
                      color="text"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                        width: '100%'
                      }}
                    >
                      <RetailerName retailer={m.retailer} />
                      <ExternalLink size={16} />
                    </LinkExternal>
                  </Tooltip>
                  <div style={{ textAlign: 'right' }}>
                    {formatCurrency(m.price, m.price_curr as CurrencyCode)}
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IssueAvailability
                      availability={
                        m.availability as ProductCatalogAvailability
                      }
                      // These are currently returned in a YYYY-MM-DD HH:mm:ss format.
                      // We're gonna change this most likely to be true ISO formats,
                      // which might require changes of this code.
                      seenAt={fromMoment(moment.utc(m.seen_at))}
                      availabilityChangeSeenAt={fromMoment(
                        moment.utc(m.availability_change_seen_at)
                      )}
                    />
                  </div>
                </React.Fragment>
              );
            })}
          </AltRetailersGrid>
        )}
        {d && !d.matches.length && (
          <Typography variant="caption">
            No matches found in other supported catalogs.
          </Typography>
        )}
      </FlexContainerVertical>
    </DrawerSection>
  );
};

export const ProductCatalogDrawer = ({
  id: uid,
  open,
  onClose
}: {
  id: string;
  open: boolean;
  onClose: () => void;
}) => {
  const { space } = useCurrentUser();
  const [product, loadingProduct] = useProductCatalogItem(uid);
  const tz = space.config.tz;
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <DrawerHeader>
        <Typography variant="body1" component="span">
          Product catalog
        </Typography>
        <IconButton onClick={onClose}>
          <X size={16} />
        </IconButton>
      </DrawerHeader>
      <InnerWrapper>
        {uid && (
          <div>
            <ProductSummary
              uid={uid}
              product={product}
              loading={loadingProduct}
            />
            <ProductMatches
              uid={uid}
              product={product}
              loading={loadingProduct}
            />
            <ProductHistory
              uid={uid}
              tz={tz}
              product={product}
              loading={loadingProduct}
            />
            <PagePerformance
              uid={uid}
              product={product}
              loading={loadingProduct}
            />
            <ProductDetails product={product} loading={loadingProduct} />
          </div>
        )}
      </InnerWrapper>
    </Drawer>
  );
};
