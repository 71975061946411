import { compact } from 'lodash';
import { useMemo } from 'react';
import { AnalyticsFilter } from '../../../../domainTypes/analytics_v2';
import { toAnalyticsV2Search } from '../../../../services/analyticsV2/query';
import { EMPTY_ARR } from '../../../../domainTypes/emptyConstants';

export const toLinkSearch = (term: string) =>
  toAnalyticsV2Search(term, ['link_name', 'link_url', 'link_dest_url']);

export const toDomainLinkSearch = (term: string) =>
  toAnalyticsV2Search(term, ['l_advertiser_domain']);

export const toProductLinkSearch = (term: string) =>
  toAnalyticsV2Search(term, [
    'link_name',
    'p_title',
    'link_url',
    'link_dest_url'
  ]);

export const useLinksFilters = (
  baseFilters: AnalyticsFilter[],
  additionalFilters: AnalyticsFilter[] = EMPTY_ARR
): AnalyticsFilter[] => {
  return useMemo(() => {
    const finalFilters: AnalyticsFilter[] = compact([
      ...baseFilters,
      ...additionalFilters,
      {
        field: 'link_id',
        condition: 'not in',
        values: ['']
      }
    ]);
    return finalFilters;
  }, [baseFilters, additionalFilters]);
};
