import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography
} from '@material-ui/core';
import React, { useRef } from 'react';
import { AlertBox } from '../../../../../../components/AlertBox';
import { LinkExternal } from '../../../../../../components/LinkExternal';
import { Popover } from '../../../../../../components/Popover';
import { COLORS } from '../../../../../../domainTypes/colors';
import {
  IProductIssue,
  ISmartLabelIssue,
  ProductIssueType,
  SmartLabelIssueType
} from '../../../../../../domainTypes/product';
import { ProductCatalogAvailability } from '../../../../../../domainTypes/productCatalog';
import { styled } from '../../../../../../emotion';
import { withStoppedPropagation } from '../../../../../../helpers';
import { useDialogState } from '../../../../../../hooks/useDialogState';
import { useHover } from '../../../../../../hooks/useHover';
import { FlexContainerVertical } from '../../../../../../layout/Flex';
import {
  formatSmartRelativeDate,
  Timestamp
} from '../../../../../../services/time';

const StatusCircle = styled('div')`
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 100%;
`;

const StatusWrapper = styled('div')`
  position: relative;
`;

const StatusPopover = styled('div')`
  padding: ${(p) => p.theme.spacing(1)}px;
  width: 260px;
`;

const IssueDot = ({
  issue,
  explanation,
  circleStyle,
  onClick
}: {
  issue: IProductIssue;
  explanation: React.ReactNode;
  circleStyle: React.CSSProperties;
  onClick?: () => void;
}) => {
  const anchor = useRef<HTMLDivElement | null>(null);
  const popoverOpen = useHover(anchor);

  const firstDiscovered = formatSmartRelativeDate(issue.createdAt);
  const lastChecked = formatSmartRelativeDate(issue.updatedAt);
  const isSame = issue.createdAt.isEqual(issue.updatedAt);

  return (
    <StatusWrapper ref={anchor}>
      <StatusCircle
        style={circleStyle}
        onClick={onClick && withStoppedPropagation(onClick)}
        role={onClick && 'button'}
      />
      {popoverOpen && (
        <Popover>
          <StatusPopover>
            <Typography variant="body1" paragraph>
              {explanation}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {isSame
                ? `This issue was discovered ${firstDiscovered}.`
                : `This issue was first found ${firstDiscovered} and last confirmed ${lastChecked}.`}
            </Typography>
          </StatusPopover>
        </Popover>
      )}
    </StatusWrapper>
  );
};

const LinkEx = styled(LinkExternal)`
  text-decoration: underline;
`;

const SmartLabelIssue = ({ issue }: { issue: ISmartLabelIssue }) => {
  const { dialogOpen, openDialog, closeDialog } = useDialogState();
  const explanation = () => {
    if (issue.status === SmartLabelIssueType.NO_PASSTHROUGH_CONFIGURED) {
      return (
        <>Smart labels cannot be passed through. Click the dot to learn more.</>
      );
    }

    return '';
  };
  const css: {
    [key in SmartLabelIssueType]: {
      backgroundColor: string;
    };
  } = {
    NO_PASSTHROUGH_CONFIGURED: {
      backgroundColor: COLORS.purple.purple5
    },
    NO_PASSTHROUGH_POSSIBLE_BECAUSE_OF_SOURCE: {
      backgroundColor: COLORS.purple.purple4
    },
    NO_PASSTHROUGH_POSSIBLE_BECAUSE_OF_TARGET: {
      backgroundColor: COLORS.purple.purple6
    }
  };
  return (
    <>
      <IssueDot
        issue={issue}
        explanation={explanation()}
        circleStyle={css[issue.status]}
        onClick={openDialog}
      />
      <Dialog open={dialogOpen} onClose={closeDialog} maxWidth="md">
        <DialogTitle>How to enable smart labels for cloaked links</DialogTitle>
        <DialogContent>
          <p>
            Luckily it's pretty easy to enable this option for both Pretty Links
            and Thirsty Affiliates. If you're using a different link cloaker and
            aren't sure how to enable it, feel free to email us at{' '}
            <a href="mailto:support@affilimate.io">support@affilimate.io</a>.
          </p>

          <h3>Thirsty Affiliates</h3>

          <p>Open your Thirsty Affiliates settings. You have two options:</p>

          <h4>Option 1. Enable "query string passing globally"</h4>

          <p>
            In general,{' '}
            <strong>
              there's almost no reason not to enable this for your links
              globally
            </strong>
            . All it does is combine whatever parameters you have when you
            create a link in a post to your cloaked link with the query.
          </p>

          <img
            alt=""
            style={{ maxWidth: '100%', marginBottom: 24 }}
            src="/images/smart-labels/thirsty-affiliates-global.png"
          />

          <h4>
            Option 2. Go into every link and choose "query string passing" for
            individual links
          </h4>

          <p>
            While we don't necessarily recommend this option, it is possible to
            do. You should use this option if you have a specific reason why you
            wouldn't want those parameters to get appended.
          </p>

          <img
            alt=""
            style={{ maxWidth: '100%', marginBottom: 24 }}
            src="/images/smart-labels/thirsty-affiliates-per-link.png"
          />

          <h3>Pretty Links</h3>

          <p>
            Similarly, Pretty Links has a per-link setting that does the exact
            same thing. It's called "parameter forwarding".
          </p>

          <p>
            Open your Pretty Links settings for a specific link, and enable
            Parameter Forwarding.
          </p>

          <img
            alt=""
            style={{ maxWidth: '100%', marginBottom: 24 }}
            src="/images/smart-labels/pretty-links-parameter-forwarding.png"
          />

          <AlertBox variant="pending">
            <LinkEx href="https://help.affilimate.com/articles/smart-labels">
              Check out our help center
            </LinkEx>{' '}
            for more information on smart labels.
          </AlertBox>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
};

const Issue = ({ issue }: { issue: IProductIssue }) => {
  if (issue.type === ProductIssueType.SMART_LABEL) {
    return <SmartLabelIssue issue={issue} />;
  }

  return null;
};

export const ProductIssue = ({ issues }: { issues: IProductIssue[] }) => {
  return (
    <div>
      {issues.map((issue, i) => (
        <Issue key={i} issue={issue} />
      ))}
    </div>
  );
};

export type IIssueAvailibility = {
  type: 'availability';
  value: ProductCatalogAvailability;
  seenAt: Timestamp;
  availabilityChangeSeenAt: Timestamp;
};

export type IProductIssueV2 = IIssueAvailibility;

export const Dot = styled('div')<{ color: string }>((p) => ({
  backgroundColor: p.color,
  width: 16,
  height: 16,
  borderRadius: '3px'
}));

export const IssueAvailability = ({
  availability,
  seenAt,
  availabilityChangeSeenAt
}: {
  availability: ProductCatalogAvailability;
  seenAt: Timestamp;
  availabilityChangeSeenAt: Timestamp;
}) => {
  const dates = seenAt.isEqual(availabilityChangeSeenAt) ? (
    <div>
      The issue was discovered{' '}
      <strong>{formatSmartRelativeDate(seenAt)}</strong>.
    </div>
  ) : (
    <div>
      The issue was first found{' '}
      <strong>{formatSmartRelativeDate(availabilityChangeSeenAt)}</strong> and
      last confirmed <strong>{formatSmartRelativeDate(seenAt)}</strong>.
    </div>
  );
  switch (availability) {
    case ProductCatalogAvailability.out_of_stock:
      return (
        <Tooltip
          placement="top"
          title={
            <FlexContainerVertical spacing={0.5}>
              <div>
                Product is <strong>out of stock</strong>, meaning it cannot be
                purchased right now.
              </div>
              {dates}
            </FlexContainerVertical>
          }
        >
          <Dot color={COLORS.red.red5} />
        </Tooltip>
      );
    case ProductCatalogAvailability.backorder:
      return (
        <Tooltip
          placement="top"
          title={
            <FlexContainerVertical spacing={0.5}>
              <div>
                Product is <strong>temporarily out of stock</strong>, meaning it
                should be re-stocked in the near future.
              </div>
              {dates}
            </FlexContainerVertical>
          }
        >
          <Dot color={COLORS.gold.gold5} />
        </Tooltip>
      );
    case ProductCatalogAvailability.preorder:
      return (
        <Tooltip placement="top" title={<div>Pre-order</div>}>
          <Dot color={COLORS.gold.gold5} />
        </Tooltip>
      );
    case ProductCatalogAvailability.in_stock:
      return (
        <Tooltip placement="top" title="Available">
          <Dot color={COLORS.green.green5} />
        </Tooltip>
      );
    case ProductCatalogAvailability.unknown:
      return (
        <Tooltip placement="top" title="Unknown">
          <Dot color={'#AAA'} />
        </Tooltip>
      );

    default:
      return null;
  }
};

const Badge = styled('span')`
  display: inline-block;
  font-size: ${(p) => p.theme.typography.caption.fontSize};
  border-radius: 999px;
  padding: ${(p) => p.theme.spacing(0.5)}px ${(p) => p.theme.spacing(1.5)}px;
  font-weight: bold;
`;

export const AVAILABILITY_COLORS: {
  [availability in ProductCatalogAvailability]: {
    backgroundColor: string;
    color: string;
  };
} = {
  [ProductCatalogAvailability.backorder]: {
    backgroundColor: COLORS.gold.gold5,
    color: 'white'
  },
  [ProductCatalogAvailability.in_stock]: {
    backgroundColor: COLORS.green.green5,
    color: 'white'
  },
  [ProductCatalogAvailability.out_of_stock]: {
    backgroundColor: COLORS.red.red5,
    color: 'white'
  },
  [ProductCatalogAvailability.preorder]: {
    backgroundColor: COLORS.gold.gold5,
    color: 'white'
  },
  [ProductCatalogAvailability.unknown]: {
    backgroundColor: '#AAA',
    color: 'white'
  }
};

export const getAvailabilityColors = (av: ProductCatalogAvailability) =>
  AVAILABILITY_COLORS[av] || AVAILABILITY_COLORS.unknown;

export const AVAILABILITY_TEXTS: {
  [availability in ProductCatalogAvailability]: {
    label: string;
  };
} = {
  [ProductCatalogAvailability.backorder]: {
    label: 'Backorder'
  },
  [ProductCatalogAvailability.in_stock]: {
    label: 'In stock'
  },
  [ProductCatalogAvailability.out_of_stock]: {
    label: 'Out of stock'
  },
  [ProductCatalogAvailability.preorder]: {
    label: 'Preorder'
  },
  [ProductCatalogAvailability.unknown]: {
    label: 'Unknown'
  }
};

export const getAvailabilityTexts = (av: ProductCatalogAvailability) =>
  AVAILABILITY_TEXTS[av] || AVAILABILITY_TEXTS.unknown;

export const IssueAvailabilityBadge = ({
  availability,
  seenAt,
  availabilityChangeSeenAt
}: {
  availability: ProductCatalogAvailability;
  seenAt: Timestamp;
  availabilityChangeSeenAt: Timestamp;
}) => {
  const dates = seenAt.isEqual(availabilityChangeSeenAt) ? (
    <div>
      The issue was discovered{' '}
      <strong>{formatSmartRelativeDate(seenAt)}</strong>.
    </div>
  ) : (
    <div>
      The issue was first found{' '}
      <strong>{formatSmartRelativeDate(availabilityChangeSeenAt)}</strong> and
      last confirmed <strong>{formatSmartRelativeDate(seenAt)}</strong>.
    </div>
  );
  const badge = (
    <Badge style={getAvailabilityColors(availability)}>
      {getAvailabilityTexts(availability).label}
    </Badge>
  );
  switch (availability) {
    case ProductCatalogAvailability.out_of_stock:
      return (
        <Tooltip
          placement="top"
          title={
            <FlexContainerVertical spacing={0.5}>
              <div>
                Product is <strong>out of stock</strong>, meaning it cannot be
                purchased right now.
              </div>
              {dates}
            </FlexContainerVertical>
          }
        >
          {badge}
        </Tooltip>
      );
    case ProductCatalogAvailability.backorder:
      return (
        <Tooltip
          placement="top"
          title={
            <FlexContainerVertical spacing={0.5}>
              <div>
                Product is <strong>temporarily out of stock</strong>, meaning it
                should be re-stocked in the near future.
              </div>
              {dates}
            </FlexContainerVertical>
          }
        >
          {badge}
        </Tooltip>
      );
    case ProductCatalogAvailability.preorder:
      return (
        <Tooltip placement="top" title={<div>Pre-order</div>}>
          {badge}
        </Tooltip>
      );
    case ProductCatalogAvailability.in_stock:
      return (
        <Tooltip placement="top" title="Available">
          {badge}
        </Tooltip>
      );
    case ProductCatalogAvailability.unknown:
      return (
        <Tooltip placement="top" title="Unknown">
          {badge}
        </Tooltip>
      );

    default:
      return null;
  }
};

export const IssueV2 = ({ issue }: { issue: IProductIssueV2 }) => {
  switch (issue.type) {
    case 'availability':
      return (
        <IssueAvailability
          availability={issue.value}
          seenAt={issue.seenAt}
          availabilityChangeSeenAt={issue.availabilityChangeSeenAt}
        />
      );
    default:
      return null;
  }
};

export const ProductIssuesV2 = ({ issues }: { issues: IProductIssueV2[] }) => {
  return (
    <div>
      {issues.map((issue, i) => (
        <IssueV2 key={i} issue={issue} />
      ))}
    </div>
  );
};
