import { Card } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  AnalyticsColumnDefinitions,
  useAnalyticsTable
} from '../../../../../components/analytics_v2/Table';
import {
  TimeframePicker,
  useTimeframe
} from '../../../../../components/analytics_v2/Timeframe';
import { CustomPagination } from '../../../../../components/CustomPagination';
import { ExportQueryButton } from '../../../../../components/ExportQuery';
import { RowsRenderer } from '../../../../../components/GroupableList';
import { Loader } from '../../../../../components/Loader';
import { ColumnSelector } from '../../../../../components/Table/ColumnSelector';
import { WithHoverIndicator } from '../../../../../components/WithHoverIndicator';
import {
  AnalyticsFilter,
  AnalyticsOrderBy,
  AnalyticsQuery,
  AnalyticsResponseRowWithComparison
} from '../../../../../domainTypes/analytics_v2';
import { css } from '../../../../../emotion';
import { Centered } from '../../../../../layout/Centered';
import { FlexContainer } from '../../../../../layout/Flex';
import {
  DEFAULT_OFFSET,
  PageToolbar,
  PageToolbarSection,
  PageToolbarTitle
} from '../../../../../layout/PageToolbar';
import { Section } from '../../../../../layout/Section';
import { useRoutes } from '../../../../../routes';
import { Metric } from '../../../../../services/analyticsV2/metrics';
import { useAnalyticsQueryV2 } from '../../../../../services/analyticsV2/query';
import { useCurrentUser } from '../../../../../services/currentUser';
import { useMappedLoadingValue } from '../../../../../services/db';
import { availableMetrics } from '../service/columns';
import { Slot } from '../service/slot';
import { CustomDimensionTimeseries } from './CustomDimensionTimeseries';
import { useCustomDimensionTitle } from '../../../../../services/customDimensions';
import { CustomDimensionOptionLabel } from '../../../../../components/CustomDimensionOptionLabel';
import { useHasComparison } from '../../../../../hooks/timeframe';
import { useAnalyticsFilters } from '../../../../../components/analytics_v2/Filters/useAnalyticsFilters';
import { channelFilterUIDef } from '../../../../../components/analytics_v2/Filters/FilterUI/analytics/ChannelFilterUI';
import { FiltersToggleButton } from '../../../../../components/analytics_v2/Filters/Toggle';
import { FiltersDrawerWithDefaultTree } from '../../../../../components/analytics_v2/Filters/Drawer/FiltersDrawer';
import { useFeatureEnabled } from '../../../../../services/features';
import { useCustomDimensionsFilterUI } from '../../../../../components/analytics_v2/Filters/FilterUI/analytics/ClickDimensionFilterUI';
import { FILTER_STATE_LOCAL_STORAGE_KEYS } from '../../../../../components/analytics_v2/Filters/Drawer/keys';
import { AnalyticsFilterUI } from '../../../../../components/analytics_v2/Filters/FilterUI';
import { compact } from 'lodash';

const PAGE_SIZE = 10;
const customColumns = ['click_data'] as const;

type CustomColumns = typeof customColumns[number];
type Column = CustomColumns | Metric;

const availableColumns: Column[] = [...customColumns, ...availableMetrics];

const defaultVisibleColumns: Column[] = availableColumns;

const Value = ({ slot, value }: { slot: Slot; value: string }) => {
  const { ROUTES } = useRoutes();
  return (
    <Link to={ROUTES.content.customDimensions.details.url(slot, value)}>
      <WithHoverIndicator>
        <CustomDimensionOptionLabel slot={slot} value={value} large />
      </WithHoverIndicator>
    </Link>
  );
};

const columnDefinitions: (
  slot: Slot
) => AnalyticsColumnDefinitions<CustomColumns> = (slot: Slot) => ({
  click_data: {
    column: {
      key: 'click_data',
      head: () => 'Name',
      cell: (p) => {
        return <Value slot={slot} value={p.group[slot]} />;
      },
      align: 'left',
      sortable: true,
      width: 200,
      flexGrow: 4
    },
    sorter: {
      key: 'click_data',
      items: { sort: (p) => p.group[slot], dir: 'asc' }
    }
  }
});

const useCustomDimensionCount = (slot: Slot, filters: AnalyticsFilter[]) => {
  const { space } = useCurrentUser();
  const { range } = useTimeframe();

  const count = `count_uniq_${slot}` as const;

  const query = useMemo<AnalyticsQuery>(() => {
    return {
      select: [count],
      range,
      filters: [
        ...filters,
        {
          field: slot,
          condition: 'not in',
          values: ['']
        }
      ]
    };
  }, [count, range, slot, filters]);

  return useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    (resp) => resp.rows[0]?.data[count]?.curr || 0
  );
};

const useCustomDimensionData = (
  slot: Slot,
  filters: AnalyticsFilter[],
  metrics: Metric[],
  paginate: AnalyticsQuery['paginate'],
  orderBy: AnalyticsOrderBy
) => {
  const { space } = useCurrentUser();
  const { range, compare } = useTimeframe();
  const query = useMemo<AnalyticsQuery>(() => {
    return {
      select: metrics,
      range,
      compare,
      groupBy: [slot],
      orderBy: [orderBy],
      paginate,
      filters: [
        ...filters,
        {
          field: slot,
          condition: 'not in',
          values: ['']
        }
      ]
    };
  }, [metrics, range, compare, slot, orderBy, paginate, filters]);

  return useAnalyticsQueryV2(space.id, query);
};

const useCustomDimensionExportQuery = (
  slot: Slot,
  filters: AnalyticsFilter[],
  metrics: Metric[],
  orderBy: AnalyticsOrderBy
) => {
  const { range } = useTimeframe();
  return useMemo<AnalyticsQuery>(
    () => ({
      range,
      select: metrics,
      groupBy: [slot],
      orderBy: [orderBy],
      filters: [
        ...filters,
        {
          field: slot,
          condition: 'not in',
          values: ['']
        }
      ]
    }),
    [filters, metrics, orderBy, range, slot]
  );
};

const useAvailableFilterUIs = (): Array<AnalyticsFilterUI> => {
  const showCustomDimensions = useFeatureEnabled('CUSTOM_DIMENSIONS');
  const customDimensionUIs = useCustomDimensionsFilterUI();

  return useMemo(
    () =>
      compact([
        channelFilterUIDef,
        ...(showCustomDimensions ? customDimensionUIs : [])
      ]),
    [customDimensionUIs, showCustomDimensions]
  );
};

export const CustomDimensionsSlot = ({ slot }: { slot: Slot }) => {
  const columnDefs = useMemo(() => columnDefinitions(slot), [slot]);
  const rowToKey = useCallback(
    (p: AnalyticsResponseRowWithComparison) => p.group[slot],
    [slot]
  );
  const showComparison = useHasComparison();

  const {
    tableProps,
    columnSelectorProps,
    paginationSelectorProps,
    pagination,
    orderBy,
    metrics
  } = useAnalyticsTable(availableColumns, columnDefs, {
    pageSize: PAGE_SIZE,
    defaultSortColumn: 'commission_sum_net',
    defaultVisibleColumns,
    showComparison
  });

  const filterUIs = useAvailableFilterUIs();
  const { filters, drawerProps, toggleProps } = useAnalyticsFilters(
    filterUIs,
    {
      orderBy: orderBy.field as Metric
    },
    {
      localStorageKey: FILTER_STATE_LOCAL_STORAGE_KEYS.customDimensions,
      initialState: false
    }
  );

  const exportQuery = useCustomDimensionExportQuery(
    slot,
    filters,
    metrics,
    orderBy
  );

  const [count = 0] = useCustomDimensionCount(slot, filters);

  const [data, loading] = useCustomDimensionData(
    slot,
    filters,
    metrics,
    pagination,
    orderBy
  );

  const title = useCustomDimensionTitle(slot);

  return (
    <div>
      <PageToolbar
        className={css((t) => ({
          paddingTop: 0,
          paddingBottom: t.spacing(1)
        }))}
      >
        <PageToolbarTitle flex={1}>
          <FlexContainer>
            {title}
            <FiltersToggleButton {...toggleProps} />
          </FlexContainer>
        </PageToolbarTitle>

        <PageToolbarSection flex={2} justifyContent="flex-end">
          <ColumnSelector {...columnSelectorProps} />
          <CustomPagination
            {...paginationSelectorProps}
            count={Math.ceil(count / PAGE_SIZE)}
            siblingCount={0}
          />
          <ExportQueryButton
            title="Custom dimensions"
            reportType="custom_dimensions"
            query={exportQuery}
          />
          <TimeframePicker />
        </PageToolbarSection>
      </PageToolbar>

      <FiltersDrawerWithDefaultTree {...drawerProps} />

      <div
        className={css((t) => ({
          paddingTop: t.spacing(1),
          paddingBottom: t.spacing(1)
        }))}
      >
        <CustomDimensionTimeseries slot={slot} filters={filters} />
      </div>

      <Section>
        {loading ? (
          <Card>
            <Centered height={200}>
              <Loader />
            </Centered>
          </Card>
        ) : !data || data.rows.length === 0 ? (
          <Card>
            <Centered height={200}>
              <Typography variant="h6">No data</Typography>
            </Centered>
          </Card>
        ) : (
          <RowsRenderer
            {...tableProps}
            renderHead={true}
            headProps={{
              sticky: true,
              offset: DEFAULT_OFFSET
            }}
            rows={data.rows}
            rowToKey={rowToKey}
          />
        )}
      </Section>
    </div>
  );
};
