import React, { useCallback } from 'react';
import { removeCampaign } from '../service';
import { Trash2 } from 'react-feather';
import { ButtonWithPromise } from '../../../components/ButtonWithPromise';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { useRoutes } from '../../../routes';
import { useDialogState } from '../../../hooks/useDialogState';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { css } from '../../../emotion';
import { useMixpanel } from '../../../services/mixpanel';
import { ManualCampaign } from '../service/manual-campaign';

interface DeleteCampaignProps {
  campaign: Pick<ManualCampaign, 'id' | 'name'>;
}

export const DeleteCampaignButton: React.FC<DeleteCampaignProps> = ({
  campaign
}) => {
  const { ROUTES, goTo } = useRoutes();
  const { enqueueSnackbar } = useSnackbar();
  const { track } = useMixpanel();
  const { dialogOpen, openDialog, closeDialog } = useDialogState();
  const deleteCampaign = useCallback(async () => {
    try {
      track('delete_campaign', { id: campaign.id });
      await removeCampaign(campaign.id);

      enqueueSnackbar('Campaign deleted', { variant: 'success' });
      goTo(ROUTES.campaigns.manual.active.url());
    } catch (e) {
      enqueueSnackbar('Failed to delete campaign', { variant: 'error' });
    }
  }, [
    ROUTES.campaigns.manual.active,
    campaign.id,
    enqueueSnackbar,
    goTo,
    track
  ]);
  return (
    <>
      <Button color="default" onClick={openDialog} style={{ opacity: 0.5 }}>
        <Trash2 size={16} /> &nbsp; Delete campaign
      </Button>
      <Dialog open={dialogOpen} onClose={closeDialog} scroll="body">
        <DialogTitle
          className={css(() => ({
            paddingBottom: 0
          }))}
        >
          Permanently delete this campaign?
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" paragraph>
            Are you sure you want to delete the campaign{' '}
            <strong>"{campaign.name}"</strong>? You can't undo this action.
          </Typography>
          <DialogActions
            className={css((t) => ({ padding: 0, paddingTop: t.spacing(2) }))}
          >
            <Button onClick={closeDialog}>Cancel</Button>
            <ButtonWithPromise
              variant="contained"
              color="secondary"
              onClick={deleteCampaign}
              pending="Deleting..."
            >
              Delete campaign
            </ButtonWithPromise>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};
