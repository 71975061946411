import React, { useState } from 'react';
import { BASIC_MODES, FilterMenu } from '../../Menus/FilterMenu';
import { ProductCatalogAvailabilityFilterDef } from '../../../../../../domainTypes/filters';
import { useCollectionFilterState } from '../../Menus/Selector';
import { ProductCatalogFieldSelectorMenu } from '../../Menus/Selector';
import { ProductCatalogAvailability } from '../../../../../../domainTypes/productCatalog';
import { WithShape } from '../../../../../Charts/Util';
import { AVAILABILITY_COLORS } from '../../../../../../features/Links/pages/Overview/components/ProductIssue';
import { capitalize } from 'lodash';

export const PRODUCT_AVAILABILITY_ALL_STATUSES: ProductCatalogAvailability[] = [
  ProductCatalogAvailability.in_stock,
  ProductCatalogAvailability.out_of_stock,
  ProductCatalogAvailability.preorder,
  ProductCatalogAvailability.backorder,
  ProductCatalogAvailability.unknown
];

export interface ProductCatalogAvailabilityMenuProps {
  definition: ProductCatalogAvailabilityFilterDef;
  onSave: (filterDef: ProductCatalogAvailabilityFilterDef) => void;
  isFirst?: boolean;
  context: any;
}

export function ProductCatalogAvailabilityMenu({
  definition,
  onSave,
  isFirst = false,
  context
}: ProductCatalogAvailabilityMenuProps) {
  const [mode, setMode] = useState(BASIC_MODES[0].value);

  const {
    values,
    handleToggle,
    handleFocus,
    isSaveEnabled,
    handleSave
  } = useCollectionFilterState<
    ProductCatalogAvailability,
    ProductCatalogAvailabilityFilterDef
  >(definition, onSave);

  const renderOption = (value: ProductCatalogAvailability) => (
    <WithShape
      shape="square"
      color={AVAILABILITY_COLORS[value].backgroundColor}
    >
      {capitalize(value.replaceAll('_', ' '))}
    </WithShape>
  );

  return (
    <FilterMenu>
      <FilterMenu.Header name="product availability" isFirst={isFirst}>
        <FilterMenu.ModeSelector
          modes={BASIC_MODES}
          mode={mode}
          setMode={setMode}
        />
      </FilterMenu.Header>
      <FilterMenu.Body>
        <ProductCatalogFieldSelectorMenu
          label="Product Availability"
          field="availability"
          filters={[]}
          selectedValues={values}
          onToggle={handleToggle}
          onFocus={handleFocus}
          renderOption={renderOption}
        />
      </FilterMenu.Body>
      <FilterMenu.Footer
        description={
          <>
            <strong>Product availability</strong> is the current stock status of
            the product.
          </>
        }
      >
        <FilterMenu.SaveButton
          onSave={handleSave}
          disabled={!isSaveEnabled}
          label="Filter by availability"
        />
      </FilterMenu.Footer>
    </FilterMenu>
  );
}
