import { AnalyticsFilterUI } from '../../../FilterUI';
import {
  FilterDefinition,
  ProductAvailabilityFilterDef
} from '../../../../../../domainTypes/filters';
import { ProductAvailabilityMenu } from './ProductAvailabilityMenu';
import { ChipContent, filterModeSuffix, formatList } from '../../common';
import React from 'react';
import { WithShape } from '../../../../../Charts/Util';
import {
  AVAILABILITY_COLORS,
  AVAILABILITY_TEXTS
} from '../../../../../../features/Links/pages/Overview/components/ProductIssue';
import { ProductCatalogAvailability } from '../../../../../../domainTypes/productCatalog';
import { modeToCondition } from '../../../useAnalyticsFilters';

const ProductAvailabilityLabels: React.FC<{
  statuses: ProductCatalogAvailability[];
}> = ({ statuses }) => {
  return (
    <>
      {formatList(
        statuses.map((av) => (
          <WithShape
            color={AVAILABILITY_COLORS[av].backgroundColor}
            shape="square"
          >
            {AVAILABILITY_TEXTS[av].label}
          </WithShape>
        ))
      )}
    </>
  );
};

export const productAvailabilityFilterUIDef: AnalyticsFilterUI = {
  type: 'analytics',
  dimension: 'product_availability',
  chip: ({ definition }) => {
    if (!definition) return <>Product Availability</>;
    if (definition.k !== 'product_availability') {
      throw new Error('Invalid definition');
    }
    return (
      <ChipContent
        title={`Product availability ${filterModeSuffix(definition.mode)}`}
      >
        <ProductAvailabilityLabels statuses={definition.v} />
      </ChipContent>
    );
  },
  menu: function ProductAvailabilityFilterUIMenu({ definition, ...props }) {
    return (
      <ProductAvailabilityMenu
        definition={definition as ProductAvailabilityFilterDef}
        {...props}
      />
    );
  },
  init: () => ({
    k: 'product_availability',
    v: []
  }),
  toClauses: (definition: FilterDefinition) => {
    if (definition.k !== 'product_availability') {
      return [];
    }
    return [
      {
        field: 'p_availability',
        condition: modeToCondition(definition.mode),
        values: definition.v
      }
    ];
  }
};
