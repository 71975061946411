import { useSegmentCampaign } from '../../service';
import React from 'react';
import { Helmet } from 'react-helmet';
import { SegmentCampaignCreateForm } from '../../components/segment-campaign-form/CreateForm';
import { SegmentCampaignPage } from '../../components/SegmentCampaignPage';
import { SegmentCampaign } from '../../service/segment-campaign';
import { SegmentCampaignProposed } from '../../components/SegmentCampaignProposed';
import { SegmentCampaignScheduleForm } from '../../components/segment-campaign-form/ScheduleForm';
import { SegmentCampaignScheduled } from '../../components/SegmentCampaignScheduled';
import { SegmentCampaignRunning } from '../../components/SegmentCampaignRunning';

const SegmentCampaignDetailsInner: React.FC<{
  campaign: SegmentCampaign;
}> = ({ campaign }) => {
  switch (campaign.status) {
    case 'draft':
      return <SegmentCampaignCreateForm campaign={campaign} />;
    case 'proposed':
      return <SegmentCampaignProposed campaign={campaign} />;
    case 'approved':
      return <SegmentCampaignScheduleForm campaign={campaign} />;
    case 'scheduled':
      return <SegmentCampaignScheduled campaign={campaign} />;
    case 'running':
      return <SegmentCampaignRunning campaign={campaign} />;
    default:
      return <div>Unknown campaign status</div>;
  }
};

export const SegmentCampaignDetails: React.FC<{ campaignId: string }> = ({
  campaignId
}) => {
  // const [back] = useStringQueryParam('back', 'overview');
  const campaign = useSegmentCampaign(campaignId);
  return (
    <>
      <Helmet>
        <title>Campaign details | Affilimate</title>
      </Helmet>
      <SegmentCampaignPage campaign={campaign} scope="campaigns.edit">
        {({ campaign }) => <SegmentCampaignDetailsInner campaign={campaign} />}
      </SegmentCampaignPage>
    </>
  );
};
