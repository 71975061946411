import { AppBar, Button, Chip } from '@material-ui/core';
import React from 'react';
import { ChevronLeft, HelpCircle as IconHowTo, Icon } from 'react-feather';
import { Link } from 'react-router-dom';
import { ButtonSplit } from '../components/ButtonSplit';
import { HelpIcon } from '../components/HelpIcon';
import { COLORS } from '../domainTypes/colors';
import { css, styled } from '../emotion';
import { useDialogState } from '../hooks/useDialogState';
import { useRouter } from '../hooks/useRouter';
import { pathnameWithoutQueryParams } from '../services/url';

export const SIDE_NAV_WIDTH = 144;

const SIDE_NAV_COLORS = {
  groupLabel: '#6d6d6d',
  howTo: '#bdbdbd'
};

export type SideNavItem = {
  label: string;
  path?: string;
  onClick?: (...args: any) => void;
  articleId?: string;
  disabled?: boolean;
  badge?: 'BETA' | 'NEW' | 'UPGRADE' | 'PREVIEW' | 'STAFF';
};

export type SideNavGroup = {
  label: string;
  badge?: 'BETA' | 'NEW' | 'UPGRADE' | 'PREVIEW' | 'STAFF';
  count?: {
    color: string;
    backgroundColor: string;
    n: number;
  };
  items: SideNavItem[];
};

export type SideNavAction = {
  icon: Icon;
  label: string;

  // can have both, but needs at least one
  onClick?: () => void;
  href?: string;

  additionalActions?: {
    label: string;
    onClick: () => void;
  }[];
  // maybe allow other actions through a split button at some point
};

export type SideNavHowTo = React.ComponentType<{
  open: boolean;
  onClose: () => void;
}>;

export type SideNavBack = {
  path: string;
};

export type SideNavProps = {
  groups?: SideNavGroup[];
  action?: SideNavAction;
  howTo?: SideNavHowTo;
  back?: SideNavBack;
};

export const DISTANCE_TO_TOP_BAR = 3;

export const SideNavContainer = styled('div')`
  width: ${SIDE_NAV_WIDTH}px;
  top: ${(p) => p.theme.spacing(7 + DISTANCE_TO_TOP_BAR)}px;
  position: fixed;
  padding-right: ${(p) => p.theme.spacing(2)}px;
`;

export const SideNavGroupHeader = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: ${(p) => p.theme.spacing(1)}px;
  gap: ${(p) => p.theme.spacing(0.5)}px;
`;

export const SideNavGroupLabel = styled('div')`
  text-transform: uppercase;
  color: ${SIDE_NAV_COLORS.groupLabel};
  font-weight: ${(p) => p.theme.typography.fontWeightBold};
`;

export const SideNavGroupItemContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: ${(p) => p.theme.spacing(1)}px;

  gap: ${(p) => p.theme.spacing(0.5)}px;
`;

const SideNavGroupItem = styled<
  'div',
  { selected: boolean; disabled: boolean }
>('div')`
  display: flex;
  align-items: center;
  pointer-events: ${(p) => (p.disabled ? 'none' : 'auto')};
  color: ${(p) =>
    p.disabled ? p.theme.palette.grey[500] : p.theme.palette.primary.main};
  ${(p) =>
    p.selected ? `font-weight: ${p.theme.typography.fontWeightBold};` : ''}
`;

export const SideNavSection = styled('div')`
  margin-bottom: ${(p) => p.theme.spacing(3)}px;
`;

const SideNavHowToContainer = styled('div')`
  color: ${SIDE_NAV_COLORS.howTo};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${(p) => p.theme.spacing(0.5)}px;
`;

const BackContainer = styled('div')`
  display: flex;
  align-items: center;
  font-weight: ${(p) => p.theme.typography.fontWeightBold};
  gap: ${(p) => p.theme.spacing(0.5)}px;
`;

export const Badge = ({ type }: { type?: SideNavGroup['badge'] }) => {
  const toStyle = (
    color: string,
    backgroundColor: string
  ): React.CSSProperties => ({
    color,
    backgroundColor,
    fontSize: 10,
    fontWeight: 700,
    height: 18,
    textTransform: 'uppercase'
  });

  if (type === 'BETA') {
    return <Chip size="small" label="Beta" style={toStyle('#333', '#DDD')} />;
  }
  if (type === 'STAFF') {
    return null;
  }

  if (type === 'UPGRADE') {
    return (
      <Chip size="small" label="Upgrade" style={toStyle('#888', '#e5e4e4')} />
    );
  }
  if (type === 'PREVIEW') {
    return (
      <Chip size="small" label="Preview" style={toStyle('#EEE', '#353535')} />
    );
  }
  if (type === 'NEW') {
    return (
      <Chip
        size="small"
        label="New"
        style={toStyle(COLORS.orange.orange9, COLORS.orange.orange3)}
      />
    );
  }
  return null;
};

const CountContainer = styled<
  'div',
  { color: string; backgroundColor: string }
>('div')`
  color: ${(p) => p.color};
  background: ${(p) => p.backgroundColor};
  border-radius: 4px;
  padding: 2px 6px;
  font-weight: ${(p) => p.theme.typography.fontWeightBold};
`;

const Count = ({ d }: { d?: SideNavGroup['count'] }) => {
  if (!d) {
    return null;
  }
  const { color, backgroundColor, n } = d;
  return (
    <CountContainer color={color} backgroundColor={backgroundColor}>
      {n}
    </CountContainer>
  );
};

const Action = ({ action }: { action: SideNavAction }) => {
  if (!action.additionalActions || !action.additionalActions.length) {
    return (
      <Button
        startIcon={<action.icon size={18} />}
        onClick={action.onClick}
        href={action.href}
        variant="contained"
        color="primary"
      >
        {action.label}
      </Button>
    );
  }

  return (
    <ButtonSplit
      onClick={action.onClick}
      href={action.href}
      variant="contained"
      color="primary"
      otherActions={action.additionalActions}
    >
      {action.label}
    </ButtonSplit>
  );
};

const SideNav = ({ groups, action, howTo, back }: SideNavProps) => {
  const { location } = useRouter();
  const currentPath = location.pathname;
  const { dialogOpen, openDialog, closeDialog } = useDialogState();

  return (
    <SideNavContainer>
      {back && (
        <SideNavSection>
          <Link to={back.path}>
            <BackContainer>
              <ChevronLeft />
              <div>Back</div>
            </BackContainer>
          </Link>
        </SideNavSection>
      )}

      {groups?.map((g) => (
        <SideNavSection key={g.label}>
          <SideNavGroupHeader>
            <SideNavGroupLabel>{g.label}</SideNavGroupLabel>
            <Badge type={g.badge} />
            <Count d={g.count} />
          </SideNavGroupHeader>
          <div>
            {g.items.map((x) => (
              <SideNavGroupItemContainer key={x.label}>
                <SideNavGroupItem
                  disabled={!!x.disabled}
                  selected={
                    x.path !== undefined &&
                    currentPath === pathnameWithoutQueryParams(x.path)
                  }
                >
                  {x.path && (
                    <>
                      <Link
                        to={x.path}
                        style={{
                          color: x.badge === 'STAFF' ? 'gray' : 'inherit'
                        }}
                      >
                        {x.label}
                      </Link>
                    </>
                  )}
                  {x.onClick && (
                    <>
                      <Button
                        variant="text"
                        color="primary"
                        style={{
                          padding: 0,
                          margin: 0,
                          minWidth: 'fit-content'
                        }}
                        disabled={x.disabled}
                        onClick={x.onClick}
                      >
                        {x.label}
                      </Button>
                    </>
                  )}
                  {x.articleId && (
                    <>
                      &nbsp; <HelpIcon articleId={x.articleId} />
                    </>
                  )}
                </SideNavGroupItem>
                <Badge type={x.badge} />
              </SideNavGroupItemContainer>
            ))}
          </div>
        </SideNavSection>
      ))}
      {action && (
        <SideNavSection>
          <Action action={action} />
        </SideNavSection>
      )}

      {howTo && null && (
        <>
          <SideNavSection>
            <SideNavHowToContainer role="button" onClick={openDialog}>
              <IconHowTo size={16} />
              <div>How to use</div>
            </SideNavHowToContainer>

            {React.createElement(howTo, {
              open: dialogOpen,
              onClose: closeDialog
            })}
          </SideNavSection>
        </>
      )}
    </SideNavContainer>
  );
};

const PageBodyWrapperWithRightNav = styled<'div', { noTopPadding?: boolean }>(
  'div'
)`
  padding: ${(p) => p.theme.spacing(DISTANCE_TO_TOP_BAR)}px
    ${(p) => p.theme.spacing(5)}px ${(p) => p.theme.spacing(14)}px;
  ${(p) => (p.noTopPadding ? 'padding-top: 0;' : '')}
  display: grid;
  grid-template-columns: ${SIDE_NAV_WIDTH}px 0.9fr ${SIDE_NAV_WIDTH}px;
  width: 100%;
`;

export const PageBodyWrapper = styled<'div', { noTopPadding?: boolean }>('div')`
  display: grid;
  grid-template-columns: ${SIDE_NAV_WIDTH}px 1fr;
  width: 100%;
  padding: ${(p) => p.theme.spacing(DISTANCE_TO_TOP_BAR)}px
    ${(p) => p.theme.spacing(5)}px ${(p) => p.theme.spacing(14)}px;
  ${(p) => (p.noTopPadding ? 'padding-top: 0;' : '')}
`;

const PageBodyWrapperNoSideNav = styled<'div', { noTopPadding?: boolean }>(
  'div'
)`
  width: 100%;
  padding: ${(p) => p.theme.spacing(DISTANCE_TO_TOP_BAR)}px
    ${(p) => p.theme.spacing(5)}px ${(p) => p.theme.spacing(14)}px;
  ${(p) => (p.noTopPadding ? 'padding-top: 0;' : '')}
`;

const Main = styled('div')`
  width: 100%;
`;

export const MainWithSideNav = styled(Main)``;

export const PageBody: React.FC<{
  sideNav?: SideNavProps;
  rightNav?: React.ReactNode;
  noTopPadding?: boolean;
}> = ({ sideNav, noTopPadding, rightNav, children }) => {
  if (sideNav && !rightNav) {
    return (
      <PageBodyWrapper noTopPadding={noTopPadding}>
        <div>
          <SideNav {...sideNav} />
        </div>
        <MainWithSideNav>{children}</MainWithSideNav>
      </PageBodyWrapper>
    );
  }
  if (sideNav && rightNav) {
    return (
      <PageBodyWrapperWithRightNav noTopPadding={noTopPadding}>
        <div>
          <SideNav {...sideNav} />
        </div>
        <MainWithSideNav>{children}</MainWithSideNav>
        {rightNav}
      </PageBodyWrapperWithRightNav>
    );
  }

  return (
    <PageBodyWrapperNoSideNav noTopPadding={noTopPadding}>
      <Main>{children}</Main>
    </PageBodyWrapperNoSideNav>
  );
};

const LimitedWidthOuter = styled('div')`
  display: flex;
  justify-content: center;
`;

const LimitedWidthInner = styled<'div', { width: number }>('div')`
  flex: 1;
  max-width: ${(p) => p.width}px;
`;

export const LimitedWidth: React.FC<{
  width: number;
}> = ({ width, children }) => {
  return (
    <LimitedWidthOuter>
      <LimitedWidthInner width={width}>{children}</LimitedWidthInner>
    </LimitedWidthOuter>
  );
};

export const PageHero: React.FC<{
  backgroundColor: string;
  backgroundImage: string;
}> = ({ backgroundImage, backgroundColor, children }) => {
  return (
    <AppBar
      component="div"
      className={css((t) => ({
        backgroundColor,
        backgroundImage,
        position: 'relative',
        marginBottom: t.spacing(3),
        zIndex: 0
      }))}
      position="static"
      elevation={0}
    >
      {children}
    </AppBar>
  );
};
