import React, { useCallback, useMemo } from 'react';
import { CampaignsPageBody } from '../../components/CampaignsPageBody';
import { Helmet } from 'react-helmet';
import {
  useMixpanel,
  useTrackMixpanelView
} from '../../../../services/mixpanel';
import shortid from 'shortid';
import { useManualCampaignWithDefault } from '../../service';
import { CampaignWizard } from '../../components/manual-campaign-form/CampaignWizard';
import { useRoutes } from '../../../../routes';
import { useSnackbar } from 'notistack';
import { useCurrentUserScopes } from '../../../../services/currentUser';
import { NoPermissions } from '../../../../components/NoPermissions';

export const ManualCampaignCreator: React.FC = () => {
  const scopes = useCurrentUserScopes();
  const canCreate = scopes.has('campaigns.create');
  useTrackMixpanelView('view_campaigns_creator');
  const id = useMemo(() => shortid(), []);
  const campaign = useManualCampaignWithDefault(id);
  const [campaignData] = campaign;
  const { track } = useMixpanel();
  const { ROUTES, goTo } = useRoutes();
  const { enqueueSnackbar } = useSnackbar();
  const onSuccess = useCallback(() => {
    // NOTE: It's really 'campaign create complete' event
    track('create_campaign');
    if (campaignData) {
      enqueueSnackbar(`Campaign ${campaignData.name} created`, {
        variant: 'success'
      });
    }
    goTo(ROUTES.campaigns.manual.active.url());
  }, [
    ROUTES.campaigns.manual.active,
    campaignData,
    enqueueSnackbar,
    goTo,
    track
  ]);

  return (
    <>
      <Helmet>
        <title>Create campaign | Affilimate</title>
      </Helmet>
      <CampaignsPageBody>
        {canCreate ? (
          <CampaignWizard campaign={campaign} onSuccess={onSuccess} />
        ) : (
          <NoPermissions />
        )}
      </CampaignsPageBody>
    </>
  );
};
