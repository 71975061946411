import { filterDimensionsTree, FiltersList } from './filtersTree';
import React, { useMemo, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { isEmpty } from 'lodash';
import { List, ListItem, ListItemText, ListSubheader } from '@material-ui/core';
import { css } from '../../../../emotion';
import { SearchInput } from '../../../SearchInput';
import { FilterableDimension } from '../index';

interface DimensionListProps {
  tree: FiltersList;
  availableDimensions: FilterableDimension[];
  selectDimension: (f: FilterableDimension) => void;
  search: string;
}

export const DimensionList: React.FC<DimensionListProps> = ({
  selectDimension,
  availableDimensions,
  tree,
  search
}) => {
  const treeOfAvailableDimensions = useMemo<FiltersList>(() => {
    return filterDimensionsTree(tree, availableDimensions, search);
  }, [tree, availableDimensions, search]);

  const categories = Object.values(treeOfAvailableDimensions);

  if (categories.length === 0) {
    return (
      <Typography
        variant="caption"
        color="textSecondary"
        style={{ padding: 10, marginBottom: 12 }}
        component="p"
      >
        No filters found.
        {isEmpty(search) ? null : ' Try a different search term.'}
      </Typography>
    );
  }
  return (
    <List>
      {categories.map(({ options, label }) => {
        return (
          <React.Fragment key={label}>
            <ListSubheader
              className={css((t) => ({
                backgroundColor: t.palette.background.paper,
                fontWeight: 'bold',
                color: '#AAA'
              }))}
            >
              {label}
            </ListSubheader>
            {options.map((filter) => {
              return (
                <ListItem
                  key={filter.dimension}
                  button
                  onClick={() => selectDimension(filter.dimension)}
                >
                  <ListItemText>{filter.title}</ListItemText>
                </ListItem>
              );
            })}
          </React.Fragment>
        );
      })}
    </List>
  );
};

interface DimensionSelectorMenuProps {
  selectDimension: (d: FilterableDimension) => void;
  availableDimensions: FilterableDimension[];
  tree: FiltersList;
}

export const DimensionSelectorMenu: React.FC<DimensionSelectorMenuProps> = ({
  selectDimension,
  availableDimensions,
  tree
}) => {
  const [search, onSearchChange] = useState('');
  return (
    <div>
      <div
        className={css((t) => ({
          padding: t.spacing(2),
          paddingBottom: t.spacing(1)
        }))}
      >
        <SearchInput
          size="small"
          placeholder="Find a filter"
          value={search}
          onChange={onSearchChange}
          fullWidth
          autoFocus
        />
      </div>
      <div className={css(() => ({ maxHeight: 360, overflow: 'scroll' }))}>
        <DimensionList
          tree={tree}
          selectDimension={selectDimension}
          availableDimensions={availableDimensions}
          search={search}
        />
      </div>
    </div>
  );
};
