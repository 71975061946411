import { Typography } from '@material-ui/core';
import { isEqual } from 'lodash';
import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { AlertBox } from '../../../../components/AlertBox';
import {
  ALL_ANALYTICS_COLUMN_NAMES,
  AnalyticsTable,
  AnalyticsColumnDefinitions,
  useAnalyticsTable
} from '../../../../components/analytics_v2/Table';
import {
  TimeframePicker,
  useTimeframe
} from '../../../../components/analytics_v2/Timeframe';
import { ChartMode } from '../../../../components/Charts/EarningsChartCard/ChartModeSelector';
import {
  EarningsBarChartCardMetricV2,
  EarningsChartCardWithoutDataV2
} from '../../../../components/Charts/EarningsChartCardV2';
import { EarningsPieChartCardV2 } from '../../../../components/Charts/EarningsPieChartV2';
import { CustomPagination } from '../../../../components/CustomPagination';
import {
  ExportQueryButton,
  useExportQuery
} from '../../../../components/ExportQuery';
import { HelpIcon } from '../../../../components/HelpIcon';
import { useIntervalSelectorState } from '../../../../components/IntervalSelector';
import { NoPermissions } from '../../../../components/NoPermissions';
import { ColumnSelector } from '../../../../components/Table/ColumnSelector';
import { AnalyticsQuery } from '../../../../domainTypes/analytics_v2';
import { EMPTY_ARR } from '../../../../domainTypes/emptyConstants';
import { styled } from '../../../../emotion';
import { useHasComparison } from '../../../../hooks/timeframe';
import { FlexContainer } from '../../../../layout/Flex';
import {
  DEFAULT_OFFSET,
  PageToolbar,
  PageToolbarOtherRow,
  PageToolbarSection
} from '../../../../layout/PageToolbar';
import { useRoutes, useTypedStringQueryParam } from '../../../../routes';
import { allTime } from '../../../../services/analytics';
import { ANALYTICS_GROUPS } from '../../../../services/analyticsV2/groups';
import { Metric } from '../../../../services/analyticsV2/metrics';
import { useAnalyticsQueryV2 } from '../../../../services/analyticsV2/query';
import { ARTICLES } from '../../../../services/beacon';
import {
  useCurrentUser,
  useHasCurrentUserRequiredScopes
} from '../../../../services/currentUser';
import { useTrackMixpanelView } from '../../../../services/mixpanel';
import { timeframeToIsoRange, toMoment } from '../../../../services/time';
import { useSpaceCurrency } from '../../../../services/useSpaceCurrency';
import { PerformancePageBody } from '../../components/PerformancePageBody';
import { useKnownPartnersV2 } from '../../services/hooksV2';
import { useAnalyticsFilters } from '../../../../components/analytics_v2/Filters/useAnalyticsFilters';
import { FiltersToggleButton } from '../../../../components/analytics_v2/Filters/Toggle';
import { FiltersDrawerWithDefaultTree } from '../../../../components/analytics_v2/Filters/Drawer/FiltersDrawer';
import { usePerformancePagesFilterUIs } from '../../services/filters';
import { FILTER_STATE_LOCAL_STORAGE_KEYS } from '../../../../components/analytics_v2/Filters/Drawer/keys';

const Grid = styled('div')`
  display: grid;
  grid-column-gap: ${(p) => p.theme.spacing(3)}px;
  grid-row-gap: ${(p) => p.theme.spacing(6)}px;
  grid-template-columns: 3fr 1.75fr;
  margin-bottom: ${(p) => p.theme.spacing(6)}px;
  min-height: 520px;

  ${(p) => p.theme.breakpoints.down('md')} {
    grid-column-gap: ${(p) => p.theme.spacing(1)}px;
    grid-row-gap: ${(p) => p.theme.spacing(2)}px;
    grid-template-columns: 1fr 1fr;
  }

  ${(p) => p.theme.breakpoints.down('sm')} {
    grid-column-gap: ${(p) => p.theme.spacing(1)}px;
    grid-row-gap: ${(p) => p.theme.spacing(2)}px;
    grid-template-columns: 1fr;
  }
`;

const customColumns = ['pk'] as const;
type CustomColumns = typeof customColumns[number];
type Column = CustomColumns | Metric;
const availableColumns: Column[] = [
  ...customColumns,
  ...ALL_ANALYTICS_COLUMN_NAMES
];
const defaultVisibleColumns: Column[] = [
  ...customColumns,
  'c',
  'epc_net',
  'order_count_net',
  'avg_rate_net',
  'commission_sum_net',
  'gmv_sum_net'
];
const columnDefinitions: AnalyticsColumnDefinitions<CustomColumns> = {
  pk: {
    column: {
      key: 'pk',
      head: () => 'Platform',
      cell: (p) => ANALYTICS_GROUPS.pk.toCell(p),
      align: 'left',
      sortable: true,
      width: 180,
      flexGrow: 2
    },
    sorter: {
      key: 'pk',
      items: {
        sort: (p) => p.group['pk'] || '',
        dir: 'desc'
      }
    }
  }
};

const PAGE_SIZE = 25;

const PagePerformanceNetworksV2 = () => {
  const { ROUTES } = useRoutes();
  const { space } = useCurrentUser();
  const currency = useSpaceCurrency();

  const filterUIs = usePerformancePagesFilterUIs();
  const { filters, drawerProps, toggleProps } = useAnalyticsFilters(
    filterUIs,
    {
      orderBy: 'commission_sum_net'
    },
    {
      localStorageKey: FILTER_STATE_LOCAL_STORAGE_KEYS.performance
    }
  );

  const { compare, range } = useTimeframe();
  const hasComparison = useMemo(
    () => !isEqual(timeframeToIsoRange(allTime()), range),
    [range]
  );

  const intervalSelectorProps = useIntervalSelectorState();

  const [metric, setMetric] = useTypedStringQueryParam<
    EarningsBarChartCardMetricV2
  >('metric', 'c');

  const [partnerList] = useKnownPartnersV2(space.id, range, filters);
  const showComparison = useHasComparison();

  const {
    tableProps,
    metrics,
    paginationSelectorProps,
    pagination,
    orderBy,
    columnSelectorProps
  } = useAnalyticsTable(availableColumns, columnDefinitions, {
    pageSize: PAGE_SIZE,
    defaultSortColumn: 'commission_sum_net',
    defaultVisibleColumns,
    showComparison
  });

  const q = useMemo<AnalyticsQuery>(() => {
    return {
      range,
      compare: hasComparison ? compare : undefined,
      groupBy: ANALYTICS_GROUPS.pk.groupBy,
      filters,
      paginate: pagination,
      orderBy: [orderBy],
      select: metrics
    };
  }, [range, hasComparison, compare, filters, pagination, orderBy, metrics]);
  const exportQ = useExportQuery(q);
  const tableData = useAnalyticsQueryV2(space.id, q);

  const [chartMode, setChartMode] = useTypedStringQueryParam<ChartMode>(
    'chart_mode',
    'barChart'
  );

  const [canExportPlatforms] = useHasCurrentUserRequiredScopes([
    'reports.platforms.view' // should be export?
  ]);

  const user = useCurrentUser();
  const isNewSpace =
    moment().diff(toMoment(user.space.createdAt), 'hours') < 48;

  return (
    <PerformancePageBody noTopPadding>
      <PageToolbar sticky offset={DEFAULT_OFFSET} wrap>
        <PageToolbarSection flex={1}>
          <Typography
            variant="h6"
            component="span"
            style={{
              marginRight: '3px',
              position: 'relative',
              fontWeight: 'bold',
              top: '-2px'
            }}
          >
            Platforms
          </Typography>
          <HelpIcon
            color="gray"
            articleId={ARTICLES.networks.overview}
            style={{ marginRight: '16px' }}
          ></HelpIcon>
          <FiltersToggleButton {...toggleProps} />
        </PageToolbarSection>
        <PageToolbarSection flex={1} justifyContent="flex-end">
          <TimeframePicker />
        </PageToolbarSection>
        <PageToolbarOtherRow>
          <FiltersDrawerWithDefaultTree {...drawerProps} marginTop={2} />
        </PageToolbarOtherRow>
      </PageToolbar>

      {isNewSpace && (
        <AlertBox variant="success" style={{ marginBottom: '36px' }}>
          Your affiliate network and program-level analytics will begin
          collecting here.
          <br />
          <br />
          In the mean time, try{' '}
          <Link
            style={{ borderBottom: '1px solid black' }}
            to={ROUTES.performanceNew.transactions.url()}
          >
            setting up reporting
          </Link>{' '}
          with your affiliate networks and programs.
        </AlertBox>
      )}
      <Grid>
        <EarningsChartCardWithoutDataV2
          space={space}
          range={range}
          filters={filters}
          search={EMPTY_ARR}
          currency={currency}
          metric={metric}
          setMetric={setMetric}
          interval={intervalSelectorProps.interval}
          setInterval={intervalSelectorProps.setInterval}
          intervalOptions={intervalSelectorProps.options}
          selectableMetrics={['commission_sum_net', 'gmv_sum_net', 'c']}
          graphMode={'platform'}
          chartMode={chartMode}
          setChartMode={setChartMode}
        />

        <EarningsPieChartCardV2
          space={space}
          range={range}
          filters={filters}
          currency={currency}
          metric={'c'}
          grouper={ANALYTICS_GROUPS.pk}
          heading="Platforms"
          subheading="By share of outbound clicks"
          aspect={1.5}
        />
      </Grid>

      <FlexContainer justifyContent="space-between">
        <div>
          <Typography
            variant="body1"
            component="p"
            style={{ fontWeight: 'bold' }}
          >
            Platform metrics
          </Typography>
          <Typography
            variant="body2"
            component="p"
            color="textSecondary"
            paragraph
          >
            Compare platform performance during this period according to your
            key metrics
          </Typography>
        </div>
        <FlexContainer>
          <ColumnSelector {...columnSelectorProps} />
          <CustomPagination
            {...paginationSelectorProps}
            siblingCount={0}
            count={Math.ceil((partnerList || ['x']).length / PAGE_SIZE)}
          />
          {canExportPlatforms && (
            <ExportQueryButton
              query={exportQ}
              reportType="platforms"
              title="Export platforms to CSV"
            />
          )}
        </FlexContainer>
      </FlexContainer>

      <AnalyticsTable
        d={tableData}
        tableProps={tableProps}
        rowToKey={ANALYTICS_GROUPS.pk.toKey}
        rowToHref={(row) =>
          ROUTES.performanceNew.advertisers.overview.url({
            filters: [
              {
                k: 'platform',
                v: [row.group['pk']],
                mode: 'in'
              }
            ]
          })
        }
      />
    </PerformancePageBody>
  );
};

export const PagePerformanceNetworks = () => {
  useTrackMixpanelView('view_platforms');
  const [canView] = useHasCurrentUserRequiredScopes(['reports.platforms.view']);

  return (
    <>
      <Helmet>
        <title>Platforms | Affilimate</title>
      </Helmet>
      {canView ? (
        <PagePerformanceNetworksV2 />
      ) : (
        <PerformancePageBody>
          <NoPermissions />
        </PerformancePageBody>
      )}
    </>
  );
};
