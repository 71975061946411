import { compact, head, isEmpty, isNil, negate, uniq } from 'lodash';
import {
  beginsWithProtocol,
  notEmpty
} from '../../../../components/Form/validators';
import { IManualCampaign } from '../../../../domainTypes/campaigns';
import { CampaignContent } from './form-types';
import React, { useCallback } from 'react';
import { Field, Form } from 'react-final-form';
import { css } from '../../../../emotion';
import { Caption, Header } from './form-components';
import {
  Button,
  ButtonBase,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@material-ui/core';
import { AutocompleteSingleAsync } from '../../../../components/Autocomplete';
import { useCurrentUser } from '../../../../services/currentUser';
import { useTimeframe } from '../../../../components/analytics_v2/Timeframe';
import { AnalyticsQuery } from '../../../../domainTypes/analytics_v2';
import { queryAnalyticsV2 } from '../../../../services/analyticsV2/query';
import Typography from '@material-ui/core/Typography';
import { PlusCircle, X } from 'react-feather';
import { FlexContainer } from '../../../../layout/Flex';
import { useDialogState } from '../../../../hooks/useDialogState';

const validUrls = (value: string) => {
  const urls = value.split('\n');
  const results = urls
    .filter(negate(isEmpty))
    .map(beginsWithProtocol)
    .filter(negate(isNil));
  return head(results);
};

const ImportUrlsDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  onConfirm: (urls: string[]) => void;
}> = ({ open, onClose, onConfirm }) => {
  const onSubmit = useCallback(
    (values: { urls: string }) => {
      const urls = values.urls.split('\n').filter(negate(isEmpty));
      onConfirm(urls);
    },
    [onConfirm]
  );
  return (
    <Dialog open={open} onClose={onClose}>
      <Form
        onSubmit={onSubmit}
        initialValues={{ urls: '' }}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogTitle>Add bulk list of URLs</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Please enter a list of URLs, one per line, including protocol
                  (e.g. https://).
                </DialogContentText>

                <Field
                  name="urls"
                  validate={validUrls}
                  render={({ input, meta }) => {
                    const hasError = meta.error && meta.touched;

                    return (
                      <TextField
                        id="page-urls"
                        value={input.value}
                        onChange={input.onChange}
                        onBlur={input.onBlur}
                        onFocus={input.onFocus}
                        error={hasError}
                        helperText={hasError && meta.error}
                        multiline
                        minRows={4}
                        variant="outlined"
                        fullWidth
                        placeholder="One URL per line, including protocol (e.g. https://)"
                      />
                    );
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose} color="default" variant="contained">
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={submitting}
                  color="primary"
                  variant="contained"
                >
                  Confirm
                </Button>
              </DialogActions>
            </form>
          );
        }}
      />
    </Dialog>
  );
};

interface PageUrlSelectorProps {
  value: string[];
  onChange: (value: string[]) => void;
  onFocus: () => void;
  onBlur: () => void;
  hasError: boolean;
  helperText?: string;
  importDialogOpen: boolean;
  closeImportDialog: () => void;
}

const PageUrlSelector: React.FC<PageUrlSelectorProps> = ({
  value,
  onChange,
  onBlur,
  onFocus,
  hasError,
  helperText,
  importDialogOpen,
  closeImportDialog
}) => {
  const { space } = useCurrentUser();
  const { range } = useTimeframe();

  const getOptions = useCallback(
    async (filter: string) => {
      const q: AnalyticsQuery = {
        range,
        select: ['c'],
        groupBy: ['page_url'],
        orderBy: [{ field: 'c', direction: 'DESC' }],
        filters: compact([
          {
            field: 'page_url',
            condition: 'ilike',
            pattern: `%${filter}%`
          },
          value.length > 0 && {
            field: 'page_url',
            condition: 'not in',
            values: value
          }
        ]),
        paginate: {
          limit: 50,
          page: 1
        }
      };

      return queryAnalyticsV2(space.id, q).then((res) => {
        return res.rows.map((r) => r.group.page_url);
      });
    },
    [range, space.id, value]
  );

  return (
    <>
      <ImportUrlsDialog
        open={importDialogOpen}
        onClose={closeImportDialog}
        onConfirm={(urls) => {
          onChange(uniq([...urls, ...value]));
          closeImportDialog();
        }}
      />
      <AutocompleteSingleAsync
        onFocus={onFocus}
        onBlur={onBlur}
        fullWidth
        value={''}
        error={hasError}
        helperText={hasError ? helperText : ''}
        onChange={(nextValue) => {
          onChange([nextValue, ...value]);
        }}
        options={getOptions}
      />
      <br />
      <FlexContainer spacing={1} direction="column" alignItems="flex-start">
        {value.map((url) => (
          <FlexContainer key={url} spacing={0.5}>
            <Typography
              variant="body2"
              style={{ textDecorationLine: 'underline' }}
            >
              {url}
            </Typography>
            <ButtonBase
              onClick={() => onChange(value.filter((v) => v !== url))}
            >
              <X size={16} />
            </ButtonBase>
          </FlexContainer>
        ))}
      </FlexContainer>
    </>
  );
};

interface CampaignContentFormProps {
  campaign: IManualCampaign;
  proceed: (content: CampaignContent, campaign: IManualCampaign) => void;
  back: () => void;
}

export const CampaignContentForm: React.FC<CampaignContentFormProps> = ({
  campaign,
  proceed,
  back
}) => {
  const { dialogOpen, openDialog, closeDialog } = useDialogState(false);

  return (
    <Card>
      <Form
        onSubmit={(content) => proceed(content, campaign)}
        initialValues={{
          pageUrls: campaign.pageUrls
        }}
        render={({ handleSubmit, submitting }) => {
          return (
            <>
              <form
                className={css((t) => ({
                  display: 'flex',
                  flexDirection: 'column',
                  gap: t.spacing(2)
                }))}
                onSubmit={handleSubmit}
              >
                <CardContent
                  className={css(() => ({
                    height: 'calc(70vh)',
                    overflowY: 'auto',
                    marginBottom: 'auto'
                  }))}
                >
                  <Header>
                    CAMPAIGN CONTENT
                    <Button
                      color="primary"
                      style={{ marginLeft: 12 }}
                      onClick={openDialog}
                      startIcon={<PlusCircle size={16} />}
                    >
                      Add bulk list of URLs
                    </Button>
                  </Header>
                  <Caption>
                    Which articles should be counted towards this campaign's
                    performance?
                  </Caption>
                  <Field name="pageUrls" validate={notEmpty}>
                    {({ input, meta }) => {
                      const hasError = meta.error && meta.touched;

                      return (
                        <PageUrlSelector
                          value={input.value}
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                          onFocus={input.onFocus}
                          hasError={hasError}
                          helperText={hasError && meta.error}
                          importDialogOpen={dialogOpen}
                          closeImportDialog={closeDialog}
                        />
                      );
                    }}
                  </Field>
                  <br />
                  <br />
                  <Header>OFFSITE CONTENT</Header>
                  <Caption>
                    Simply filter the links you’d like to attribute to this
                    campaign by the channel you’re using to associate it.
                  </Caption>
                </CardContent>
                <CardActions>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={submitting}
                  >
                    Save and continue
                  </Button>
                  <Button color="default" onClick={back}>
                    Back to campaign details
                  </Button>
                </CardActions>
              </form>
            </>
          );
        }}
      />
    </Card>
  );
};
