import { FilterUI } from '../FilterUI';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Button, Popover } from '@material-ui/core';
import { PlusCircle } from 'react-feather';
import { FiltersList } from './filtersTree';
import { DimensionSelectorMenu } from './DimensionSelectorMenu';
import { FilterableDimension } from '../index';

interface AddFilterMenuProps {
  tree: FiltersList;
  selectDimension: (d: FilterableDimension) => void;
  selectedDimensions: FilterableDimension[];
  uis: Array<FilterUI>;
}

export const AddFilterMenu: React.FC<AddFilterMenuProps> = ({
  selectDimension,
  selectedDimensions,
  tree,
  uis
}) => {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);
  const toggle = useCallback(() => setOpen((x) => !x), []);
  const close = useCallback(() => setOpen(false), []);
  const _selectDimension = useCallback(
    (d: FilterableDimension) => {
      close();
      selectDimension(d);
    },
    [close, selectDimension]
  );
  const availableDimensions = useMemo(() => {
    return uis
      .map((ui) => ui.dimension)
      .filter((d) => !selectedDimensions.includes(d));
  }, [selectedDimensions, uis]);
  return (
    <>
      <Button
        onClick={toggle}
        ref={ref}
        color="primary"
        startIcon={<PlusCircle size={18} />}
      >
        Add a filter
      </Button>
      <Popover
        PaperProps={{ square: false }}
        open={isOpen}
        onClose={close}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <DimensionSelectorMenu
          tree={tree}
          selectDimension={_selectDimension}
          availableDimensions={availableDimensions}
        />
      </Popover>
    </>
  );
};
