import { isEqual } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import {
  AnalyticsFilter,
  ISOTimeRange
} from '../../../../../../domainTypes/analytics_v2';
import {
  coerceFilterMode,
  FilterMode,
  PlatformFilterDef
} from '../../../../../../domainTypes/filters';
import { useMappedLoadingValue } from '../../../../../../services/db';
import { PlatformWithColor } from '../../../../../PlatformWithColor';
import { toSearchRegexp } from '../../../../../SearchInput';
import {
  usePlatformsWithCommission,
  usePlatformsWithLinkCounts
} from '../../../../service/platform';
import {
  ADVANCED_MODES,
  FilterMenu,
  saveButtonLabel
} from '../../Menus/FilterMenu';
import {
  OptionsList,
  SelectorLoader,
  SelectorShell,
  useCollectionState
} from '../../Menus/Selector';
import { AnalyticsFilterMenuComponent } from '../../../FilterUI';

interface PlatformMenuBodyProps {
  onToggle: (value: string) => void;
  onFocus: (value: string) => void;
  filters: AnalyticsFilter[];
  value: Array<string>;
  range: ISOTimeRange;
}

const PlatformMenuBody: React.FC<PlatformMenuBodyProps> = ({
  onToggle,
  filters,
  range,
  onFocus,
  value
}) => {
  const [search, setSearch] = useState('');

  const [options, loading] = useMappedLoadingValue(
    usePlatformsWithLinkCounts(filters, range),
    (platforms) =>
      platforms.map((platform) => ({
        label: <PlatformWithColor partner={platform} />,
        value: platform.key,
        searchValue: platform.name
      }))
  );

  const filteredOptions = useMemo(() => {
    const searchRe = toSearchRegexp(search);
    if (!options) {
      return [];
    }
    if (!searchRe) {
      return options;
    }
    return options.filter((o) => o.searchValue.match(searchRe));
  }, [options, search]);

  return (
    <SelectorShell label="Platforms" search={search} setSearch={setSearch}>
      {!options || loading ? (
        <SelectorLoader />
      ) : (
        <OptionsList
          options={filteredOptions}
          selectedValues={value}
          onToggle={onToggle}
          onFocus={onFocus}
        />
      )}
    </SelectorShell>
  );
};

const PlatformMenuBodyForTransactions: React.FC<Omit<
  PlatformMenuBodyProps,
  'range'
>> = ({ onToggle, onFocus, filters, value }) => {
  const [search, setSearch] = useState('');

  const [options, loading] = useMappedLoadingValue(
    usePlatformsWithCommission(filters),
    (platforms) =>
      platforms.map((platform) => ({
        label: <PlatformWithColor partner={platform} />,
        value: platform.key,
        searchValue: platform.name
      }))
  );

  const filteredOptions = useMemo(() => {
    const searchRe = toSearchRegexp(search);
    if (!options) {
      return [];
    }
    if (!searchRe) {
      return options;
    }
    return options.filter((o) => o.searchValue.match(searchRe));
  }, [options, search]);

  return (
    <SelectorShell label="Platforms" search={search} setSearch={setSearch}>
      {!options || loading ? (
        <SelectorLoader />
      ) : (
        <OptionsList
          options={filteredOptions}
          selectedValues={value}
          onToggle={onToggle}
          onFocus={onFocus}
          caption={`Top platforms by earnings`}
        />
      )}
    </SelectorShell>
  );
};

const PlatformMenuInner: React.FC<{
  definition: PlatformFilterDef;
  onSave: (v: PlatformFilterDef) => void;
  filters: AnalyticsFilter[];
  range: ISOTimeRange;
  mode: FilterMode;
}> = ({ definition, onSave, filters, range, mode }) => {
  const [value, toggleValue] = useCollectionState(
    coerceFilterMode(definition.mode) === mode ? definition.v : []
  );

  const focus = useCallback(
    (platform: string) =>
      onSave({
        k: 'platform',
        v: [platform],
        mode
      }),
    [mode, onSave]
  );

  return (
    <>
      <FilterMenu.Body>
        <PlatformMenuBody
          filters={filters}
          onToggle={toggleValue}
          value={value}
          range={range}
          onFocus={focus}
        />
      </FilterMenu.Body>
      <PlatformMenuFooter
        initialDefinition={definition}
        value={value}
        onSave={onSave}
        mode={mode}
      />
    </>
  );
};

const PlatformForTransactionsMenuInner: React.FC<{
  definition: PlatformFilterDef;
  onSave: (v: PlatformFilterDef) => void;
  filters: AnalyticsFilter[];
  mode: FilterMode;
}> = ({ definition, onSave, filters, mode }) => {
  const [value, toggleValue] = useCollectionState(
    coerceFilterMode(definition.mode) === mode ? definition.v : []
  );

  const focus = useCallback(
    (platform: string) =>
      onSave({
        k: 'platform',
        v: [platform],
        mode
      }),
    [mode, onSave]
  );

  return (
    <>
      <FilterMenu.Body>
        <PlatformMenuBodyForTransactions
          filters={filters}
          onToggle={toggleValue}
          value={value}
          onFocus={focus}
        />
      </FilterMenu.Body>
      <PlatformMenuFooter
        initialDefinition={definition}
        value={value}
        onSave={onSave}
        mode={mode}
      />
    </>
  );
};

const PlatformMenuFooter: React.FC<{
  initialDefinition: PlatformFilterDef;
  value: string[];
  onSave: (v: PlatformFilterDef) => void;
  mode: FilterMode;
}> = ({ initialDefinition, value, onSave, mode }) => {
  const newDefinition = useMemo<PlatformFilterDef>(
    () => ({
      k: 'platform',
      v: value,
      mode
    }),
    [mode, value]
  );

  const enableSave = useMemo(
    () =>
      newDefinition.v.length > 0 && !isEqual(newDefinition, initialDefinition),
    [initialDefinition, newDefinition]
  );

  return (
    <FilterMenu.Footer
      description={
        <>
          <strong>Integration ID</strong> is the specific API connection to a
          platform. Use this to split revenue from multiple connections to the
          same platform.
        </>
      }
    >
      <FilterMenu.SaveButton
        disabled={!enableSave}
        onSave={() => onSave(newDefinition)}
        label={saveButtonLabel('platform', value.length, mode)}
      />
    </FilterMenu.Footer>
  );
};

const PlatformMenuHeader: React.FC<{
  isFirst: boolean;
  mode: FilterMode;
  setMode: (mode: FilterMode) => void;
}> = ({ isFirst, mode, setMode }) => {
  return (
    <FilterMenu.Header name={'platform'} isFirst={isFirst}>
      <FilterMenu.ModeSelector
        modes={ADVANCED_MODES}
        mode={mode}
        setMode={setMode}
      />
    </FilterMenu.Header>
  );
};

export const PlatformMenuForTransactions: AnalyticsFilterMenuComponent<PlatformFilterDef> = ({
  definition,
  onSave,
  context,
  isFirst
}) => {
  const [mode, setMode] = useState<FilterMode>(
    coerceFilterMode(definition.mode)
  );

  return (
    <FilterMenu>
      <PlatformMenuHeader isFirst={isFirst} mode={mode} setMode={setMode} />
      <PlatformForTransactionsMenuInner
        key={mode}
        definition={definition}
        onSave={onSave}
        filters={context.baseQuery.filters}
        mode={mode}
      />
    </FilterMenu>
  );
};

export const PlatformMenu: AnalyticsFilterMenuComponent<PlatformFilterDef> = ({
  definition,
  onSave,
  context,
  isFirst
}) => {
  const [mode, setMode] = useState<FilterMode>(
    coerceFilterMode(definition.mode)
  );

  return (
    <FilterMenu>
      <PlatformMenuHeader isFirst={isFirst} mode={mode} setMode={setMode} />
      <PlatformMenuInner
        key={mode}
        definition={definition}
        onSave={onSave}
        filters={context.baseQuery.filters}
        range={context.baseQuery.range}
        mode={mode}
      />
    </FilterMenu>
  );
};
