import { Card, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { Globe } from 'react-feather';
import { AlertBox } from '../../../../components/AlertBox';
import {
  AnalyticsColumnDefinitions,
  AnalyticsTableMetadata,
  useAnalyticsTable
} from '../../../../components/analytics_v2/Table';
import { LINKED_DOMAINS_REPORT_COLUMNS } from '../../../../components/analytics_v2/Table/columnSets';
import {
  TimeframePicker,
  useTimeframe
} from '../../../../components/analytics_v2/Timeframe';
import { CustomPagination } from '../../../../components/CustomPagination';
import { ExportQueryButton } from '../../../../components/ExportQuery';
import { RowsRenderer } from '../../../../components/GroupableList';
import { InlineLoader, Loader } from '../../../../components/Loader';
import { SearchInput } from '../../../../components/SearchInput';
import { ColumnSelector } from '../../../../components/Table/ColumnSelector';
import {
  AnalyticsFilter,
  AnalyticsOrderBy,
  AnalyticsQuery,
  AnalyticsResponseRowWithComparison
} from '../../../../domainTypes/analytics_v2';
import { css } from '../../../../emotion';
import { useHasComparison } from '../../../../hooks/timeframe';
import { Centered } from '../../../../layout/Centered';
import { FlexContainer } from '../../../../layout/Flex';
import {
  DEFAULT_OFFSET,
  PageToolbar,
  PageToolbarSection
} from '../../../../layout/PageToolbar';
import { useRoutes, useStringQueryParam } from '../../../../routes';
import { useChannelIdGrouper } from '../../../../services/analyticsV2/groups';
import { Metric } from '../../../../services/analyticsV2/metrics';
import {
  toStableAnalyticsV2Link,
  useAnalyticsQueryV2
} from '../../../../services/analyticsV2/query';
import { useCurrentUser } from '../../../../services/currentUser';
import { useMixpanel } from '../../../../services/mixpanel';
import { PageBodyLinks } from '../../components/PageBodyLinks';
import { toDomainLinkSearch } from '../OverviewV2/service';
import { useMappedLoadingValue } from '../../../../services/db';
import { useAnalyticsFilters } from '../../../../components/analytics_v2/Filters/useAnalyticsFilters';
import { FILTER_STATE_LOCAL_STORAGE_KEYS } from '../../../../components/analytics_v2/Filters/Drawer/keys';
import { FiltersDrawerWithDefaultTree } from '../../../../components/analytics_v2/Filters/Drawer/FiltersDrawer';
import { FiltersToggleButton } from '../../../../components/analytics_v2/Filters/Toggle';
import { FiltersDefinition } from '../../../../domainTypes/filters';
import { useLinkPagesFilterUIs } from '../../service/filters';

const customColumns = ['domain'] as const;
type CustomColumns = typeof customColumns[number];
type Column = CustomColumns | Metric;

const availableColumns: Column[] = ['domain', ...LINKED_DOMAINS_REPORT_COLUMNS];

const DEFAULT_VISIBLE_COLUMN_NAMES: Metric[] = [
  'c',
  'commission_sum_net',
  'quantity_net',
  'avg_rate_net',
  'epc_net',
  'gmv_sum_net',
  'aov_net'
];

const defaultVisibleColumns: Column[] = [
  ...customColumns,
  ...DEFAULT_VISIBLE_COLUMN_NAMES
];

const columnDefinitions: AnalyticsColumnDefinitions<CustomColumns> = {
  domain: {
    column: {
      key: 'domain',
      head: () => 'Deeplink domain',
      headInfo: () =>
        "The domain of the advertiser's deeplink, if available within the affiliate link.",
      cell: (
        p: AnalyticsResponseRowWithComparison,
        o: AnalyticsTableMetadata
      ) => {
        return (
          <Typography
            variant="body2"
            noWrap
            style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
          >
            <Globe size={14} style={{ color: '#AAA' }} />
            <span>{p.group.l_advertiser_domain}</span>
          </Typography>
        );
      },
      align: 'left',
      sortable: false,
      defaultDirection: 'asc',
      width: 100,
      flexGrow: 5
    },
    sorter: {
      key: 'l_advertiser_domain',
      items: {
        sort: (p: AnalyticsResponseRowWithComparison) =>
          p.group.l_advertiser_domain,
        dir: 'asc'
      }
    }
  }
};

const EXPORT_MAX_ROW_COUNT = 1000;
const PAGE_SIZE = 20;

const useLinksQuery = (
  filters: AnalyticsFilter[],
  metrics: Metric[],
  paginate: AnalyticsQuery['paginate'],
  orderBy: AnalyticsOrderBy,
  linkSearch = ''
) => {
  const { space } = useCurrentUser();
  const tf = useTimeframe();
  const { columnTransformers } = useChannelIdGrouper();
  const query = useMemo<AnalyticsQuery>(() => {
    return {
      groupBy: ['l_advertiser_domain'],
      select: metrics,
      ...tf,
      filters: [
        {
          field: 'l_advertiser_domain',
          condition: 'not in',
          values: ['']
        },
        ...filters
      ],
      search: toDomainLinkSearch(linkSearch),
      paginate,
      orderBy: [orderBy],
      columnTransformers: columnTransformers(space)
    };
  }, [
    metrics,
    tf,
    filters,
    paginate,
    orderBy,
    columnTransformers,
    space,
    linkSearch
  ]);

  return useAnalyticsQueryV2(space.id, query, {
    logMode: 'compact',
    logLabel: 'useLinksQuery'
  });
};

const useLinksExportQuery = (
  filters: AnalyticsFilter[],
  metrics: Metric[],
  orderBy: AnalyticsOrderBy,
  linkSearch = ''
) => {
  const { space } = useCurrentUser();
  const { columnTransformers } = useChannelIdGrouper();
  const { range } = useTimeframe();
  return useMemo<AnalyticsQuery>(() => {
    return {
      groupBy: ['l_advertiser_domain'],
      select: [...metrics, 'agg_uniq_page_url'],
      range,
      filters,
      search: toDomainLinkSearch(linkSearch),
      paginate: {
        page: 1,
        limit: EXPORT_MAX_ROW_COUNT
      },
      orderBy: [orderBy],
      columnTransformers: columnTransformers(space)
    };
  }, [metrics, range, filters, orderBy, columnTransformers, space, linkSearch]);
};

const useLinksCount = (filters: AnalyticsFilter[], linkSearch = '') => {
  const { space } = useCurrentUser();
  const { columnTransformers } = useChannelIdGrouper();
  const { range } = useTimeframe();
  const query = useMemo<AnalyticsQuery>(() => {
    return {
      select: ['count_uniq_link_id'],
      range,
      filters,
      search: toDomainLinkSearch(linkSearch),
      columnTransformers: columnTransformers(space)
    };
  }, [range, filters, columnTransformers, space, linkSearch]);

  return useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    (data) => data.rows[0]?.data.count_uniq_link_id?.curr ?? 0
  );
};

export const DomainsContent = () => {
  const { ROUTES } = useRoutes();
  const mixpanel = useMixpanel();
  const showComparison = useHasComparison();
  const filterUIs = useLinkPagesFilterUIs();
  const {
    tableProps,
    columnSelectorProps,
    paginationSelectorProps,
    pagination,
    orderBy,
    metrics
  } = useAnalyticsTable(availableColumns, columnDefinitions, {
    pageSize: PAGE_SIZE,
    defaultSortColumn: 'c',
    defaultVisibleColumns,
    showComparison,
    trackingEventNames: {
      onSortChange: 'links_issues_v2_table_sort_change',
      onPageChange: 'links_issues_v2_table_page_change'
    }
  });
  const { filters, drawerProps, toggleProps } = useAnalyticsFilters(
    filterUIs,
    {
      orderBy: orderBy.field as Metric
    },
    {
      localStorageKey: FILTER_STATE_LOCAL_STORAGE_KEYS.links
    }
  );

  const [linkSearch, setLinkSearch] = useStringQueryParam('q');

  const [data, loading] = useLinksQuery(
    filters,
    metrics,
    pagination,
    orderBy,
    linkSearch
  );
  const [count] = useLinksCount(filters, linkSearch);
  const exportQuery = useLinksExportQuery(
    filters,
    metrics,
    orderBy,
    linkSearch
  );
  const exportRowLimitReached = count && count >= EXPORT_MAX_ROW_COUNT;

  return (
    <PageBodyLinks noTopPadding>
      <PageToolbar>
        <PageToolbarSection flex={4}>
          <FlexContainer>
            <SearchInput
              size="small"
              width={360}
              placeholder="Search by deeplink domain"
              value={linkSearch}
              onChange={(nextLinkSearch) => {
                setLinkSearch(nextLinkSearch);
                if (nextLinkSearch) {
                  mixpanel.track('links_issues_v2_search', {
                    term: nextLinkSearch
                  });
                }
              }}
            />
            <FiltersToggleButton {...toggleProps} />
          </FlexContainer>
        </PageToolbarSection>
        <PageToolbarSection flex={4} justifyContent="flex-end">
          {data && loading && <InlineLoader />}
          <ColumnSelector {...columnSelectorProps} />
          <CustomPagination
            {...paginationSelectorProps}
            siblingCount={0}
            count={Math.ceil((count || 1) / PAGE_SIZE)}
          />
          <TimeframePicker />
          <ExportQueryButton
            title="Export links to CSV"
            reportType="links"
            query={exportQuery}
          >
            {exportRowLimitReached ? (
              <AlertBox variant="error">
                This export will include the first <strong>1000 rows</strong>{' '}
                matching your search criteria. Need more? Please contact the
                Customer Success team for support.
              </AlertBox>
            ) : null}
          </ExportQueryButton>
        </PageToolbarSection>
      </PageToolbar>
      <div
        className={css((t) => ({
          marginBottom: t.spacing(2),
          width: '100%'
        }))}
      >
        <FiltersDrawerWithDefaultTree
          {...drawerProps}
          title="Filter links by"
        />
      </div>
      {!data ? (
        <Card>
          <Centered height={350}>
            <Loader size={32} />
          </Centered>
        </Card>
      ) : (
        <RowsRenderer
          {...tableProps}
          renderHead={true}
          headProps={{
            sticky: true,
            offset: DEFAULT_OFFSET
          }}
          rows={data.rows}
          rowToKey={toStableAnalyticsV2Link}
          rowToHref={(row) => {
            const filterDefinition: FiltersDefinition = [
              {
                k: 'l_advertiser_domain',
                v: [row.group.l_advertiser_domain]
              }
            ];
            return ROUTES.links.overview_v2.url(filterDefinition);
          }}
        />
      )}
    </PageBodyLinks>
  );
};
