import { useRoutes } from '../../../../../routes';
import {
  Button,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { SectionHeading } from '../../../../../components/DrawerHeader';
import { css } from '../../../../../emotion';
import { LazyProfileLabel } from '../../../../../components/ProfileLabel';
import { TeamWithColorLazy } from '../../teams/TeamWithColor';
import { Managers } from '../../list/Managers';
import { CampaignGoalChip, CampaignIncentiveChip } from '../../chips';
import { Stack } from '../../manual-campaign-form/form-components';
import { FlexContainerVertical } from '../../../../../layout/Flex';
import { SegmentSummaryInner } from '../Segment/CampaignSegmentsPicker';
import { Uplift } from '../Uplift';
import { Edit, Send } from 'react-feather';
import { SegmentCampaign } from '../../../service/segment-campaign';

interface CampaignProposalFormProps {
  proceed: (campaign: SegmentCampaign) => void;
  campaign: SegmentCampaign;
  back: () => void;
}

export const CampaignProposalForm = ({
  campaign,
  proceed,
  back
}: CampaignProposalFormProps) => {
  const { ROUTES, goTo } = useRoutes();
  return (
    <>
      <CardContent>
        <Typography variant="h6" paragraph>
          <strong>Campaign summary</strong>
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          Review the details of your campaign and send a proposal to the
          partner.
        </Typography>
        <SectionHeading>Campaign details</SectionHeading>
        <Table
          className={css((t) => ({
            marginBottom: t.spacing(2)
          }))}
        >
          <TableBody>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>
                <strong>{campaign.name}</strong>
              </TableCell>
            </TableRow>
            {campaign.partner?.id ? (
              <TableRow>
                <TableCell>Partner</TableCell>
                <TableCell>
                  <LazyProfileLabel maId={campaign.partner?.id} />
                </TableCell>
              </TableRow>
            ) : null}
            <TableRow>
              <TableCell>Team</TableCell>
              <TableCell>
                <TeamWithColorLazy teamId={campaign.team} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Managers</TableCell>
              <TableCell>
                <Managers userIds={campaign.managers} />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Incentives</TableCell>
              <TableCell>
                {campaign.incentives.map((incentive) => (
                  <CampaignIncentiveChip incentive={incentive} />
                ))}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Goals</TableCell>
              <TableCell>
                <Stack>
                  {campaign.goals.map((goal) => (
                    <CampaignGoalChip goal={goal} />
                  ))}
                </Stack>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <br />

        {campaign.segments.length > 0 ? (
          <>
            <SectionHeading>Segment details</SectionHeading>

            <FlexContainerVertical>
              {campaign.segments.map((segment) => (
                <div>
                  <Typography variant="body1">{segment.name}</Typography>
                  <SegmentSummaryInner
                    segment={segment}
                    pageConstraints={campaign.pageConstraints}
                  />
                </div>
              ))}
              <Uplift
                incentives={campaign.incentives}
                segments={campaign.segments}
                pageConstraints={campaign.pageConstraints}
              />
            </FlexContainerVertical>
          </>
        ) : null}

        <br />
        <CardActions style={{ justifyContent: 'flex-end' }}>
          <Button
            onClick={() => goTo(ROUTES.campaigns?.segment.active?.url())}
            style={{ opacity: 0.5 }}
            variant="text"
            color="default"
          >
            Cancel
          </Button>
          <Button onClick={back}>
            <Edit size={16} /> &nbsp; Keep editing
          </Button>
          <Button
            onClick={() => proceed(campaign)}
            variant="contained"
            color="primary"
          >
            <Send size={16} /> &nbsp; Send proposal
          </Button>
        </CardActions>
      </CardContent>
    </>
  );
};
