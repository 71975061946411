import { ISegmentCampaign } from '../../../domainTypes/campaigns';
import { toSegmentCampaignDoc } from '../../../features/Campaigns/service';
import { usePromise } from '../../../hooks/usePromise';
import { store } from '../../../services/db';
import { callFirebaseFunction } from '../../../services/firebaseFunctions';
import {
  CollectionListener,
  createCollectionListenerStore,
  useCollectionListener
} from '../../../services/firecache/collectionListener';
import {
  createDocumentListenerGetter,
  useDocumentListener
} from '../../../services/firecache/documentListener';
import { FS } from '../../../versions';

const singleSegmentCampaignStore = createDocumentListenerGetter(
  (id: string) => store().collection(FS.campaigns).doc(id),
  toSegmentCampaignDoc
);

export const useSegmentCampaign = (campaignId: string) => {
  return useDocumentListener(singleSegmentCampaignStore(campaignId));
};

const segmentCampaignsCollection = () =>
  store().collection(FS.campaigns).where('type', '==', 'segment');

const segmentCampaignStore = createCollectionListenerStore<ISegmentCampaign>(
  () =>
    new CollectionListener(segmentCampaignsCollection(), toSegmentCampaignDoc)
);
export const useSegmentCampaigns = () => {
  return useCollectionListener(segmentCampaignStore(''));
};

export const getPgData = async (campaignId: string) => {
  return await callFirebaseFunction('campaigns-adm_getPgData', {
    campaignId
  });
};

export const usePgData = (campaignId: string) => {
  return usePromise(() => getPgData(campaignId), [campaignId]);
};

export const getRedisData = async (campaignId: string) => {
  return await callFirebaseFunction('campaigns-adm_getRedisData', {
    campaignId
  });
};

export const useRedisData = (campaignId: string) => {
  return usePromise(() => getRedisData(campaignId), [campaignId]);
};

export const forceSyncItemsToRedis = async (campaignId: string) => {
  return await callFirebaseFunction('campaigns-adm_forceSyncItemsToRedis', {
    campaignId
  });
};

export const getCampaignPages = async (spaceId: string, campaignId: string) => {
  return await callFirebaseFunction<string[]>(
    'campaigns-pub_getSegmentCampaignPages',
    {
      spaceId,
      campaignId
    }
  );
};
