import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import shortid from 'shortid';
import { useTrackMixpanelView } from '../../../../services/mixpanel';
import { SegmentCampaignCreateForm } from '../../components/segment-campaign-form/CreateForm';
import { useSegmentCampaignWithDefault } from '../../service';
import { SegmentCampaignPage } from '../../components/SegmentCampaignPage';

export const SegmentCampaignCreator: React.FC = () => {
  useTrackMixpanelView('view_campaigns_creator');
  const id = useMemo(() => shortid(), []);
  const campaign = useSegmentCampaignWithDefault(id);

  return (
    <>
      <Helmet>
        <title>Create campaign | Affilimate</title>
      </Helmet>
      <SegmentCampaignPage campaign={campaign} scope="campaigns.create">
        {({ campaign }) => <SegmentCampaignCreateForm campaign={campaign} />}
      </SegmentCampaignPage>
    </>
  );
};
