import React, { useMemo } from 'react';
import { ISOTimeRange } from '../../../../../domainTypes/analytics_v2';
import { useSpaceCurrency } from '../../../../../services/useSpaceCurrency';
import { sumBy } from 'lodash';
import { getStableRandomColor } from '../../../../../services/color';
import { Card } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { css } from '../../../../../emotion';
import { FlexContainer } from '../../../../../layout/Flex';
import { formatChartCurrency } from '../../../../../components/Charts/Util';
import { COLORS } from '../../../../../domainTypes/colors';
import {
  Cell,
  Label,
  Pie,
  PieChart,
  ResponsiveContainer,
  Text
} from 'recharts';
import { formatNumber } from '../../../../../components/Number';
import { TeamWithColor } from '../TeamWithColor';
import {
  teamCampaignPerformance,
  TeamWithCampaigns
} from '../../../service/teams-report';
import { Centered } from '../../../../../layout/Centered';

export const CampaignTeamsSummaryChart: React.FC<{
  timeframe: ISOTimeRange;
  data: Array<TeamWithCampaigns>;
}> = ({ data, timeframe }) => {
  const currency = useSpaceCurrency();
  const teamsPerformance = useMemo(() => {
    return data.map((d) => ({
      ...d.team,
      ...teamCampaignPerformance(d, { currency, timeframe })
    }));
  }, [currency, data, timeframe]);

  const chartData = useMemo(() => {
    const missing = sumBy(teamsPerformance, (t) =>
      Math.max(0, t.forecast - t.flatSpend)
    );

    return [
      ...teamsPerformance.map((t) => ({
        amount: Math.min(t.flatSpend, t.forecast),
        key: t.teamId,
        color: getStableRandomColor(t.teamId)
      })),
      {
        key: 'rest',
        color: '#F2F2F2',
        amount: missing
      }
    ];
  }, [teamsPerformance]);

  const summary = useMemo(
    () => ({
      forecast: sumBy(teamsPerformance, 'forecast'),
      flatSpend: sumBy(teamsPerformance, 'flatSpend'),
      teamsCount: teamsPerformance.filter(({ forecast }) => forecast > 0)
        .length,
      teamsOnTrack: teamsPerformance.filter(
        ({ flatSpend, forecast }) => forecast && flatSpend >= forecast
      ).length,
      teamsPerformance
    }),
    [teamsPerformance]
  );

  const noForecasts = teamsPerformance.every((t) => t.forecast === 0);

  if (noForecasts) {
    return (
      <Card>
        <Centered height={300}>
          <Typography
            variant="body1"
            className={css(() => ({
              fontWeight: 700
            }))}
          >
            No forecasts available
          </Typography>
        </Centered>
      </Card>
    );
  }

  return (
    <Card>
      <Typography
        variant="body1"
        className={css((t) => ({
          fontWeight: 700,
          marginBottom: t.spacing(2.5)
        }))}
      >
        Forecast progress
      </Typography>
      <FlexContainer justifyContent="space-between">
        <div>
          <span>
            <Typography
              variant="h5"
              component="span"
              className={css(() => ({
                fontWeight: 700,
                lineHeight: '24px'
              }))}
            >
              {formatChartCurrency(summary.flatSpend, currency, {
                excludeCents: true
              })}
            </Typography>
            <Typography
              component="span"
              className={css(() => ({
                fontWeight: 700,
                lineHeight: '24px',
                color: COLORS.blue.blue5
              }))}
            >
              {' '}
              /{' '}
              {formatChartCurrency(summary.forecast, currency, {
                excludeCents: true
              })}
            </Typography>
          </span>
          <Typography variant="body2" color="textSecondary">
            Flat spend obtained
          </Typography>
        </div>
        <div>
          <span>
            <Typography
              variant="h5"
              component="span"
              className={css(() => ({
                fontWeight: 700,
                lineHeight: '24px'
              }))}
            >
              {summary.teamsOnTrack}
            </Typography>
            <Typography
              component="span"
              className={css(() => ({
                fontWeight: 700,
                lineHeight: '24px',
                color: COLORS.blue.blue5
              }))}
            >
              {' '}
              / {summary.teamsCount}
            </Typography>
          </span>
          <Typography variant="body2" color="textSecondary">
            Teams on track
          </Typography>
        </div>
      </FlexContainer>
      <ResponsiveContainer width="99%" height={300}>
        <PieChart width={300} height={300}>
          <Pie
            isAnimationActive={false}
            startAngle={90}
            endAngle={-270}
            dataKey="amount"
            data={chartData}
            fill="#8884d8"
            innerRadius={75}
            outerRadius={90}
          >
            {chartData.map(({ color, key }) => (
              <Cell key={key} fill={color} stroke={color} />
            ))}
            <Label
              content={({ viewBox: { cx, cy } }) => {
                return (
                  <>
                    <Text
                      x={cx}
                      y={cy - 10}
                      textAnchor="middle"
                      verticalAnchor="middle"
                      style={{
                        fontSize: 20,
                        fontWeight: 700,
                        fill: '#000'
                      }}
                    >
                      {formatNumber({
                        format: 'percent',
                        n: summary.flatSpend / summary.forecast,
                        digits: 1
                      })}
                    </Text>
                    <Text
                      x={cx}
                      y={cy + 9}
                      textAnchor="middle"
                      verticalAnchor="middle"
                      style={{
                        fontSize: 12,
                        fontWeight: 400,
                        fill: '#828282'
                      }}
                    >
                      obtained
                    </Text>
                  </>
                );
              }}
            />
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <FlexContainer direction="column" spacing={1} alignItems="flex-start">
        {summary.teamsPerformance
          .filter(({ forecast }) => forecast > 0)
          .map((team) => (
            <FlexContainer>
              <TeamWithColor id={team.teamId} name={team.name} />
              <Typography variant="body2" color="textSecondary">
                {formatNumber({
                  format: 'percent',
                  n: team.flatSpend / team.forecast
                })}
              </Typography>
            </FlexContainer>
          ))}
      </FlexContainer>
    </Card>
  );
};
