import React from 'react';
import { Helmet } from 'react-helmet';
import { CanvasBar } from '../../../../layout/Canvas';
import { LimitedWidth } from '../../../../layout/PageBody';
import { Section } from '../../../../layout/Section';
import { useCurrentUser } from '../../../../services/currentUser';
import { SettingsLayout } from '../../SettingsLayout';
import { UserProfileAuthenticationProvidersSection } from './UserProfileAuthenticationProvidersSection';
import { UserProfileCard } from './UserProfileCard';
import { UserProfileLoginDetailsCard } from './UserProfileLoginDetailsCard';

export const PagesSettingsNewProfile = () => {
  const currentUser = useCurrentUser();
  return (
    <>
      <SettingsLayout>
        <Helmet>
          <title>Profile Settings | Affilimate</title>
        </Helmet>
        <LimitedWidth width={800}>
          <Section>
            <CanvasBar>User profile</CanvasBar>
            <UserProfileCard currentUser={currentUser} />{' '}
          </Section>
          <Section>
            <CanvasBar>Login details</CanvasBar>
            <UserProfileLoginDetailsCard currentUser={currentUser} />
          </Section>
          <UserProfileAuthenticationProvidersSection
            currentUser={currentUser}
          />
        </LimitedWidth>
      </SettingsLayout>
    </>
  );
};
