import {
  ProductCatalogFilterMenuComponent,
  ProductCatalogFilterUI
} from '../../../FilterUI';
import {
  FilterDefinition,
  ProductCatalogSellerFilterDef
} from '../../../../../../domainTypes/filters';
import { ChipContent, formatList } from '../../common';
import React, { useState } from 'react';
import { BASIC_MODES, FilterMenu } from '../../Menus/FilterMenu';
import {
  ProductCatalogFieldSelectorMenu,
  useCollectionFilterState
} from '../../Menus/Selector';
import pluralize from 'pluralize';

const SellerMenu: ProductCatalogFilterMenuComponent<ProductCatalogSellerFilterDef> = ({
  definition,
  onSave,
  isFirst,
  context
}) => {
  const [mode, setMode] = useState(BASIC_MODES[0].value);
  const {
    values,
    isSaveEnabled,
    handleSave,
    handleToggle,
    handleFocus
  } = useCollectionFilterState<string, ProductCatalogSellerFilterDef>(
    definition,
    onSave
  );

  return (
    <FilterMenu>
      <FilterMenu.Header name={'seller'} isFirst={isFirst}>
        <FilterMenu.ModeSelector
          modes={BASIC_MODES}
          mode={mode}
          setMode={setMode}
        />
      </FilterMenu.Header>
      <FilterMenu.Body>
        <ProductCatalogFieldSelectorMenu
          label={'seller'}
          selectedValues={values}
          filters={context.baseQuery.filters}
          onToggle={handleToggle}
          onFocus={handleFocus}
          field="seller"
        />
      </FilterMenu.Body>
      <FilterMenu.Footer
        description={
          <>
            <strong>Seller</strong> is the company that lists and sells the
            product!
          </>
        }
      >
        <FilterMenu.SaveButton
          disabled={!isSaveEnabled}
          onSave={handleSave}
          label={`Filter by ${pluralize('seller', values.length, true)}`}
        />
      </FilterMenu.Footer>
    </FilterMenu>
  );
};

export const sellerFilterUI: ProductCatalogFilterUI = {
  type: 'product_catalog',
  chip: ({ definition }) => {
    if (!definition) return <>Seller</>;
    if (definition.k !== 'product_catalog_seller') {
      throw new Error('Invalid definition');
    }
    return (
      <ChipContent title={`Seller is`}>{formatList(definition.v)}</ChipContent>
    );
  },
  dimension: 'product_catalog_seller',
  init: () => ({
    k: 'product_catalog_seller',
    v: []
  }),
  menu: function SellerFilterUIMenu({ definition, ...props }) {
    return (
      <SellerMenu
        definition={definition as ProductCatalogSellerFilterDef}
        {...props}
      />
    );
  },
  toClauses: (definition: FilterDefinition) => {
    if (definition.k !== 'product_catalog_seller') {
      return [];
    }
    return [
      {
        field: 'seller',
        condition: 'in',
        values: definition.v
      }
    ];
  }
};
