import { Button, Card } from '@material-ui/core';
import { compact } from 'lodash';
import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import { AlertOctagon } from 'react-feather';
import { Link } from 'react-router-dom';
import { AlertBox } from '../../../../components/AlertBox';
import {
  AnalyticsColumnDefinitions,
  AnalyticsTableMetadata,
  useAnalyticsTable
} from '../../../../components/analytics_v2/Table';
import { LINKS_ISSUES_COLUMNS } from '../../../../components/analytics_v2/Table/columnSets';
import {
  TimeframePicker,
  useTimeframe
} from '../../../../components/analytics_v2/Timeframe';
import { CustomPagination } from '../../../../components/CustomPagination';
import { EmptySearchState } from '../../../../components/EmptySearchState';
import { ExportQueryButton } from '../../../../components/ExportQuery';
import { RowsRenderer } from '../../../../components/GroupableList';
import { InlineLoader, Loader } from '../../../../components/Loader';
import { SearchInput } from '../../../../components/SearchInput';
import { ColumnSelector } from '../../../../components/Table/ColumnSelector';
import {
  AnalyticsFilter,
  AnalyticsOrderBy,
  AnalyticsQuery,
  AnalyticsResponseRowWithComparison
} from '../../../../domainTypes/analytics_v2';
import { COLORS } from '../../../../domainTypes/colors';
import { ProductCatalogAvailability } from '../../../../domainTypes/productCatalog';
import { css } from '../../../../emotion';
import { useHasComparison } from '../../../../hooks/timeframe';
import { Centered } from '../../../../layout/Centered';
import { FlexContainer } from '../../../../layout/Flex';
import {
  DEFAULT_OFFSET,
  PageToolbar,
  PageToolbarSection
} from '../../../../layout/PageToolbar';
import { useRoutes, useStringQueryParam } from '../../../../routes';
import { useApiConfigs } from '../../../../services/amazonApiKeys';
import { useChannelIdGrouper } from '../../../../services/analyticsV2/groups';
import { Metric } from '../../../../services/analyticsV2/metrics';
import {
  toStableAnalyticsV2Link,
  useAnalyticsQueryV2
} from '../../../../services/analyticsV2/query';
import { useCurrentUser } from '../../../../services/currentUser';
import { useMappedLoadingValue } from '../../../../services/db';
import { useMixpanel } from '../../../../services/mixpanel';
import { fromMoment } from '../../../../services/time';
import { LinkActionsMenu } from '../../components/LinkActionsMenu';
import { PageBodyLinks } from '../../components/PageBodyLinks';
import { LinkCellWithProductCatalogData } from './components/ProductLinkCell';
import { LinkDetailsModal } from '../OverviewV2/LinkDetailsModal';
import { StockStats } from '../OverviewV2/StockStats';
import { toProductLinkSearch } from '../OverviewV2/service';
import { IssueAvailability } from './components/ProductIssue';
import { useAnalyticsFilters } from '../../../../components/analytics_v2/Filters/useAnalyticsFilters';
import { useCustomDimensionsFilterUI } from '../../../../components/analytics_v2/Filters/FilterUI/analytics/ClickDimensionFilterUI';
import { platformFilterUIDef } from '../../../../components/analytics_v2/Filters/FilterUI/analytics/PlatformFilterUI';
import { channelFilterUIDef } from '../../../../components/analytics_v2/Filters/FilterUI/analytics/ChannelFilterUI';
import { productAvailabilityFilterUIDef } from '../../../../components/analytics_v2/Filters/FilterUI/analytics/ProductAvailabilityFilterUI';
import { deviceFilterUIDef } from '../../../../components/analytics_v2/Filters/FilterUI/analytics/DeviceFilterUI';
import { FiltersDrawerWithDefaultTree } from '../../../../components/analytics_v2/Filters/Drawer/FiltersDrawer';
import { ProductAvailabilityMenu } from '../../../../components/analytics_v2/Filters/FilterUI/analytics/ProductAvailabilityFilterUI/ProductAvailabilityMenu';
import { AnalyticsFilterMenuComponentProps } from '../../../../components/analytics_v2/Filters/FilterUI';
import { ProductAvailabilityFilterDef } from '../../../../domainTypes/filters';
import { tagFilterUIDef } from '../../../../components/analytics_v2/Filters/FilterUI/analytics/TagFilterUI';
import { referrerFilterUIDef } from '../../../../components/analytics_v2/Filters/FilterUI/analytics/ReferrerFilterUI';
import { ALL_UTM_FILTER_UIS } from '../../../../components/analytics_v2/Filters/FilterUI/analytics/UTMFilterUI';
import { FiltersToggleButton } from '../../../../components/analytics_v2/Filters/Toggle';
import { FILTER_STATE_LOCAL_STORAGE_KEYS } from '../../../../components/analytics_v2/Filters/Drawer/keys';

const customColumns = ['link_id', 'availability', 'actions'] as const;
type CustomColumns = typeof customColumns[number];
type Column = CustomColumns | Metric;

const availableColumns: Column[] = [
  'link_id',
  ...LINKS_ISSUES_COLUMNS,
  'availability',
  'actions'
];

const DEFAULT_VISIBLE_COLUMN_NAMES: Metric[] = ['p', 'v', 'c'];

const defaultVisibleColumns: Column[] = [
  ...customColumns,
  ...DEFAULT_VISIBLE_COLUMN_NAMES
];

const columnDefinitions: AnalyticsColumnDefinitions<CustomColumns> = {
  link_id: {
    column: {
      key: 'link_id',
      head: () => 'Product name or deeplink',
      cell: (p: AnalyticsResponseRowWithComparison) => {
        return (
          <LinkCellWithProductCatalogData
            link_name={p.group.p_title}
            link_dest_url={p.group.link_url}
            p_title={p.group.p_title}
            p_image_url={p.group.p_image_url}
          />
        );
      },
      align: 'left',
      sortable: false,
      defaultDirection: 'asc',
      width: 400,
      flexGrow: 5
    },
    sorter: {
      key: 'link_id',
      items: {
        sort: (p: AnalyticsResponseRowWithComparison) => p.group.link_id,
        dir: 'asc'
      }
    }
  },
  availability: {
    column: {
      key: 'availability',
      head: () => 'Stock',
      headInfo: () =>
        'Whether the product this link is pointing to is in stock or not. Applies only to Amazon links.',
      cell: (p: AnalyticsResponseRowWithComparison) =>
        p.group.p_availability && (
          <FlexContainer fullWidth justifyContent="center">
            <IssueAvailability
              availability={
                p.group.p_availability as ProductCatalogAvailability
              }
              // These are currently returned in a YYYY-MM-DD HH:mm:ss format.
              // We're gonna change this most likely to be true ISO formats,
              // which might require changes of this code.
              seenAt={fromMoment(moment.utc(p.group.p_seen_at))}
              availabilityChangeSeenAt={fromMoment(
                moment.utc(p.group.p_availability_change_seen_at)
              )}
            />
          </FlexContainer>
        ),
      align: 'center',
      width: 80,
      flexGrow: 1,
      sortable: false
    },
    sorter: {
      key: 'availability',
      items: {
        sort: (p: AnalyticsResponseRowWithComparison) => p.group.p_availibility,
        dir: 'asc'
      }
    }
  },
  actions: {
    column: {
      key: 'actions',
      head: () => 'Actions',
      cell: (
        p: AnalyticsResponseRowWithComparison,
        o: AnalyticsTableMetadata
      ) => <LinkActionsMenu linkId={p.group.link_id} spaceId={o.spaceId} />,
      align: 'center',
      sortable: false,
      width: 55
    },
    sorter: {
      key: 'actions',
      items: {
        sort: () => '',
        dir: 'asc'
      }
    }
  }
};

const EXPORT_MAX_ROW_COUNT = 1000;
const PAGE_SIZE = 20;

const OUT_OF_STOCK_FILTER: AnalyticsFilter = {
  field: 'p_availability',
  condition: 'in',
  values: [
    ProductCatalogAvailability.out_of_stock,
    ProductCatalogAvailability.backorder
  ]
};

const PRODUCT_AVAILABILITY_OUT_OF_STOCK_STATUSES: ProductCatalogAvailability[] = [
  ProductCatalogAvailability.out_of_stock,
  ProductCatalogAvailability.backorder
];

const useFilterMenus = () => {
  const customDimensions = useCustomDimensionsFilterUI();
  return useMemo(
    () => [
      ...customDimensions,
      platformFilterUIDef,
      channelFilterUIDef,
      deviceFilterUIDef,
      tagFilterUIDef,
      referrerFilterUIDef,
      ...ALL_UTM_FILTER_UIS,
      {
        ...productAvailabilityFilterUIDef,
        menu: ({ definition, ...props }: AnalyticsFilterMenuComponentProps) => (
          <ProductAvailabilityMenu
            {...props}
            definition={definition as ProductAvailabilityFilterDef}
            options={PRODUCT_AVAILABILITY_OUT_OF_STOCK_STATUSES}
          />
        )
      }
    ],
    [customDimensions]
  );
};

const useAmazonIssuesReportFilters = (filters: AnalyticsFilter[]) => {
  return useMemo(() => {
    const hasAvailabilityFilter = filters.some(
      (f) => f.field === 'p_availability'
    );
    const finalFilters: AnalyticsFilter[] = compact([
      ...filters,
      !hasAvailabilityFilter && OUT_OF_STOCK_FILTER,
      {
        field: 'link_id',
        condition: 'not in',
        values: ['']
      },
      {
        field: 'l_advertiser_id',
        condition: 'in',
        values: ['amazon']
      }
    ]);
    return finalFilters;
  }, [filters]);
};

const useLinksCount = (filters: AnalyticsFilter[], linkSearch = '') => {
  const { space } = useCurrentUser();
  const { columnTransformers } = useChannelIdGrouper();
  const { range } = useTimeframe();
  const query = useMemo<AnalyticsQuery>(() => {
    return {
      select: ['count_uniq_link_id'],
      range,
      filters,
      search: toProductLinkSearch(linkSearch),
      columnTransformers: columnTransformers(space)
    };
  }, [range, filters, columnTransformers, space, linkSearch]);

  return useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    (data) => data.rows[0]?.data.count_uniq_link_id?.curr ?? 0
  );
};

const useLinksQuery = (
  filters: AnalyticsFilter[],
  metrics: Metric[],
  paginate: AnalyticsQuery['paginate'],
  orderBy: AnalyticsOrderBy,
  linkSearch = ''
) => {
  const { space } = useCurrentUser();
  const tf = useTimeframe();
  const { columnTransformers } = useChannelIdGrouper();
  const query = useMemo<AnalyticsQuery>(() => {
    return {
      groupBy: [
        'link_id',
        'link_url',
        'link_dest_url',
        'p_title',
        'p_image_url',
        'p_availability',
        'p_seen_at',
        'p_availability_change_seen_at'
      ], // maybe add prices?
      select: metrics,
      ...tf,
      filters,
      search: toProductLinkSearch(linkSearch),
      paginate,
      orderBy: [orderBy],
      columnTransformers: columnTransformers(space)
    };
  }, [
    metrics,
    tf,
    filters,
    paginate,
    orderBy,
    columnTransformers,
    space,
    linkSearch
  ]);

  return useAnalyticsQueryV2(space.id, query, {
    logMode: 'compact',
    logLabel: 'useLinksQuery'
  });
};

const useLinksExportQuery = (
  filters: AnalyticsFilter[],
  metrics: Metric[],
  orderBy: AnalyticsOrderBy,
  linkSearch = ''
) => {
  const { space } = useCurrentUser();
  const { columnTransformers } = useChannelIdGrouper();
  const { range } = useTimeframe();
  return useMemo<AnalyticsQuery>(() => {
    return {
      groupBy: [
        'link_id',
        'link_url',
        'link_dest_url',
        'pk',
        'p_title',
        'p_availability',
        'p_seen_at',
        'p_availability_change_seen_at'
      ],
      select: [...metrics, 'agg_uniq_page_url'],
      range,
      filters,
      search: toProductLinkSearch(linkSearch),
      paginate: {
        page: 1,
        limit: EXPORT_MAX_ROW_COUNT
      },
      orderBy: [orderBy],
      columnTransformers: columnTransformers(space)
    };
  }, [metrics, range, filters, orderBy, columnTransformers, space, linkSearch]);
};

export const AmazonIssuesReportContent = () => {
  const mixpanel = useMixpanel();
  const { space } = useCurrentUser();
  const { ROUTES } = useRoutes();
  const [linkId, setLinkId] = useStringQueryParam('link_id', '');
  const showComparison = useHasComparison();
  const [apiConfigs] = useApiConfigs(space?.id);
  const {
    tableProps,
    columnSelectorProps,
    paginationSelectorProps,
    pagination,
    orderBy,
    metrics
  } = useAnalyticsTable(availableColumns, columnDefinitions, {
    pageSize: PAGE_SIZE,
    defaultSortColumn: 'c',
    defaultVisibleColumns,
    showComparison,
    trackingEventNames: {
      onSortChange: 'links_issues_v2_table_sort_change',
      onPageChange: 'links_issues_v2_table_page_change'
    }
  });

  const menus = useFilterMenus();
  const { filters, drawerProps, toggleProps } = useAnalyticsFilters(
    menus,
    {},
    {
      initialState: false,
      localStorageKey: FILTER_STATE_LOCAL_STORAGE_KEYS.links
    }
  );
  const analyticsFilters = useAmazonIssuesReportFilters(filters);

  const askForApiKeys = useMemo(() => apiConfigs && apiConfigs.length === 0, [
    apiConfigs
  ]);

  const [linkSearch, setLinkSearch] = useStringQueryParam('q');

  const [data, loading] = useLinksQuery(
    analyticsFilters,
    metrics,
    pagination,
    orderBy,
    linkSearch
  );
  const [count] = useLinksCount(analyticsFilters, linkSearch);
  const exportQuery = useLinksExportQuery(
    analyticsFilters,
    metrics,
    orderBy,
    linkSearch
  );
  const exportRowLimitReached = count && count >= EXPORT_MAX_ROW_COUNT;

  if (askForApiKeys) {
    return (
      <PageBodyLinks noTopPadding>
        <EmptySearchState
          icon={AlertOctagon}
          title="Please provide your Amazon Product Advertising API keys"
          message={
            <>
              To check your Amazon links for being broken or out of stock, you
              need to provide your Amazon Product Advertising API keys first.
              <br />
              <br />
              <Link to={ROUTES.links.amazonSettings.url()}>
                <Button variant="contained" color="primary">
                  Update Amazon Settings
                </Button>
              </Link>
            </>
          }
          color={COLORS.blue.blue6}
          bgColor={COLORS.blue.blue2}
        />
      </PageBodyLinks>
    );
  }

  return (
    <PageBodyLinks noTopPadding>
      <PageToolbar>
        <PageToolbarSection flex={4}>
          <FlexContainer>
            <SearchInput
              size="small"
              width={360}
              placeholder="Filter by product name or URL"
              value={linkSearch}
              onChange={(nextLinkSearch) => {
                setLinkSearch(nextLinkSearch);
                if (nextLinkSearch) {
                  mixpanel.track('links_issues_v2_search', {
                    term: nextLinkSearch
                  });
                }
              }}
            />
            <FiltersToggleButton {...toggleProps} />
          </FlexContainer>
        </PageToolbarSection>
        <PageToolbarSection flex={4} justifyContent="flex-end">
          {data && loading && <InlineLoader />}
          <ColumnSelector {...columnSelectorProps} />
          <CustomPagination
            {...paginationSelectorProps}
            siblingCount={0}
            count={Math.ceil((count || 1) / PAGE_SIZE)}
          />
          <TimeframePicker />
          <ExportQueryButton
            title="Export links to CSV"
            reportType="links"
            query={exportQuery}
          >
            {exportRowLimitReached ? (
              <AlertBox variant="error">
                This export will include the first <strong>1000 rows</strong>{' '}
                matching your search criteria. Need more? Please contact the
                Customer Success team for support.
              </AlertBox>
            ) : null}
          </ExportQueryButton>
        </PageToolbarSection>
      </PageToolbar>
      <div
        className={css((t) => ({
          marginBottom: t.spacing(2),
          width: '100%'
        }))}
      >
        <FiltersDrawerWithDefaultTree
          {...drawerProps}
          title="Filter links by"
        />
      </div>
      <StockStats
        linkSearch={linkSearch}
        mode="products"
        filters={analyticsFilters}
      />
      {!data ? (
        <Card>
          <Centered height={350}>
            <Loader size={32} />
          </Centered>
        </Card>
      ) : (
        <RowsRenderer
          {...tableProps}
          renderHead={true}
          headProps={{
            sticky: true,
            offset: DEFAULT_OFFSET
          }}
          rows={data.rows}
          onRowClick={(d) => {
            const nextLinkId = d.group.link_id;
            setLinkId(nextLinkId);
            mixpanel.track('view_links_details_modal', { linkId: nextLinkId });
          }}
          rowToKey={toStableAnalyticsV2Link}
        />
      )}
      <LinkDetailsModal
        initialPageSearch={''}
        open={linkId !== ''}
        onClose={() => setLinkId('')}
        linkId={linkId}
      />
    </PageBodyLinks>
  );
};
