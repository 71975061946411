import React, { useCallback, useMemo } from 'react';
import {
  useCurrentUser,
  useCurrentUserScopes
} from '../../../services/currentUser';
import { Helmet } from 'react-helmet';
import { CampaignsPageBody } from '../components/CampaignsPageBody';
import { css, styled } from '../../../emotion';
import { NoPermissions } from '../../../components/NoPermissions';
import { FlexContainer } from '../../../layout/Flex';
import Typography from '@material-ui/core/Typography';
import {
  useCombineLoadingValues,
  useMappedLoadingValue
} from '../../../services/db';
import { useTeamsCollection } from '../service/teams';
import { useManualCampaigns } from '../service';
import { Loader } from '../../../components/Loader';
import { entries, groupBy, range, sumBy } from 'lodash';
import moment, { Moment } from 'moment-timezone';
import { ISOTimeRange } from '../../../domainTypes/analytics_v2';
import { Calendar, ChevronDown } from 'react-feather';
import { useNumberQueryParam, useTypedStringQueryParam } from '../../../routes';
import { SingleSelector } from '../../../components/SingleSelector';
import { CampaignsTeamsTable } from '../components/teams/report/CampaignsTeamsTable';
import { CampaignTeamsTimeseries } from '../components/teams/report/CampaignTeamsTimeseries';
import { CampaignTeamsSummaryChart } from '../components/teams/report/CampaignTeamsSummaryChart';
import {
  CampaignWithPerformance,
  TeamWithCampaigns
} from '../service/teams-report';
import { ITeam } from '../../../domainTypes/teams';
import {
  CampaignReportAttribution,
  CampaignReportGranularity
} from '../service/reports';
import { CampaignReportAttributionSelector } from '../components/CampaignReportAttributionSelector';
import { Message } from '../../../components/Message';
import { generateRangeOfMoments } from '../../../services/time';
import {
  ManualCampaign,
  CompletedManualCampaign,
  isCampaignAtLeastScheduled,
  RunningManualCampaign,
  ScheduledManualCampaign
} from '../service/manual-campaign';
import { getFlatSpendAmount } from '../service/goals-and-incentives';

const PageWrapper = styled('div')((p) => ({
  margin: '0 auto',
  maxWidth: 1200,
  marginTop: p.theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  gap: p.theme.spacing(2)
}));

const YearPicker: React.FC<{
  value: number;
  onChange: (newValue: number) => void;
}> = ({ value, onChange }) => {
  const start = moment().year();
  const years = range(start + 2, start - 2, -1);
  return (
    <SingleSelector
      value={value}
      onChange={onChange}
      options={years.map((year) => ({
        label: year.toString(),
        value: year,
        searchValue: year.toString()
      }))}
      noSearch
    >
      <Typography
        variant="body2"
        color="textSecondary"
        component="span"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '6px'
        }}
      >
        <Calendar size={18} /> Year <strong>{value}</strong>
        <ChevronDown size={18} />
      </Typography>
    </SingleSelector>
  );
};

const hasNoCampaigns = (data: Array<TeamWithCampaigns>): boolean => {
  return data.every((team) => team.campaigns.length === 0);
};

const hasNoTeams = (data: Array<TeamWithCampaigns>): boolean => {
  return data.length === 0;
};

const CampaignsTeamsReportBody: React.FC<{
  data: Array<TeamWithCampaigns>;
  timeframe: ISOTimeRange;
  granularity: CampaignReportGranularity;
}> = ({ data, timeframe, granularity }) => {
  if (hasNoTeams(data)) {
    return <Message message="No teams found in your space." />;
  }
  if (hasNoCampaigns(data)) {
    return <Message message="No campaigns found for this timeframe." />;
  }

  return (
    <FlexContainer direction="column" alignItems="stretch" spacing={3}>
      <div
        className={css((t) => ({
          display: 'grid',
          gridTemplateColumns: '316px 1fr',
          gap: t.spacing(3)
        }))}
      >
        <CampaignTeamsSummaryChart data={data} timeframe={timeframe} />
        <CampaignTeamsTimeseries
          data={data}
          timeframe={timeframe}
          granularity={granularity}
        />
      </div>
      <CampaignsTeamsTable data={data} timeframe={timeframe} />
    </FlexContainer>
  );
};

export const mergeTeamsAndCampaigns = (
  teams: ITeam[],
  campaigns: ManualCampaign[],
  granularity: CampaignReportGranularity,
  attribution: CampaignReportAttribution,
  { start, end }: ISOTimeRange
): Array<TeamWithCampaigns> => {
  const startMoment = moment(start);
  const endMoment = moment(end);

  const timestampWithGranularity = (moment: Moment) =>
    moment.clone().startOf(granularity).toISOString();

  const campaignWithZeroPerformance = (
    c: ManualCampaign
  ): CampaignWithPerformance => ({
    ...c,
    total: 0,
    series: []
  });

  const campaignWithSinglePerformanceDataPoint = (
    c: ManualCampaign,
    moment: Moment,
    amount: number
  ): CampaignWithPerformance => ({
    ...c,
    total: amount,
    series: [{ timestamp: timestampWithGranularity(moment), amount }]
  });

  const validCampaigns = campaigns.filter((c): c is
    | ScheduledManualCampaign
    | RunningManualCampaign
    | CompletedManualCampaign => {
    // TODO: lift up to useCampaigns hook.
    if (!isCampaignAtLeastScheduled(c)) return false;
    const campaignStart = moment(c.timeframe.start);
    const campaignEnd = moment(c.timeframe.end);
    return (
      campaignStart.isSameOrBefore(endMoment) &&
      campaignEnd.isSameOrAfter(startMoment)
    );
  });

  const aggregatedCampaigns = validCampaigns.map((c) => {
    const campaignStart = moment(c.timeframe.start);
    const campaignEnd = moment(c.timeframe.end);
    const flatSpend = getFlatSpendAmount(c);
    if (!flatSpend || flatSpend === 0) {
      return campaignWithZeroPerformance(c);
    }
    if (attribution === 'start') {
      if (campaignStart.isBefore(startMoment)) {
        return campaignWithZeroPerformance(c);
      }
      return campaignWithSinglePerformanceDataPoint(
        c,
        campaignStart,
        flatSpend
      );
    }
    if (attribution === 'end') {
      if (campaignEnd.isAfter(endMoment)) {
        return campaignWithZeroPerformance(c);
      }
      return campaignWithSinglePerformanceDataPoint(c, campaignEnd, flatSpend);
    }

    const campaignLengthInDays = campaignEnd.diff(campaignStart, 'days');
    const performancePerDay = flatSpend / campaignLengthInDays;
    const startOfSeries = campaignStart.isBefore(startMoment)
      ? startMoment
      : campaignStart;
    const endOfSeries = campaignEnd.isAfter(endMoment)
      ? endMoment
      : campaignEnd;
    const days = generateRangeOfMoments(startOfSeries, endOfSeries, 'days');
    const byDay = days.map((day) => ({ day, amount: performancePerDay }));
    const byGranularity = groupBy(byDay, (d) =>
      timestampWithGranularity(d.day)
    );
    const series = entries(byGranularity).map(([timestamp, amounts]) => ({
      timestamp,
      amount: sumBy(amounts, 'amount')
    }));

    return {
      ...c,
      total: sumBy(series, 'amount'),
      series
    };
  });

  return teams.map((team) => {
    return {
      team,
      campaigns: aggregatedCampaigns.filter((c) => c.team === team.teamId)
    };
  });
};

export const CampaignsTeamsReport: React.FC = () => {
  const { space } = useCurrentUser();
  const scopes = useCurrentUserScopes();
  const canView = scopes.has('teams.view') && scopes.has('campaigns.view');
  const [attribution, setAttribution] = useTypedStringQueryParam<
    CampaignReportAttribution
  >('attribution', 'spread');
  const granularity: CampaignReportGranularity = 'month';
  const [year, setYear] = useNumberQueryParam('year', moment().year());
  const timeframe = useMemo(() => {
    const start = moment().year(year).startOf('year').toISOString();
    const end = moment().year(year).endOf('year').toISOString();
    return { start, end };
  }, [year]);
  const [data, loading, error] = useMappedLoadingValue(
    useCombineLoadingValues(useTeamsCollection(space.id), useManualCampaigns()),
    useCallback(
      ([teams, campaigns]) =>
        mergeTeamsAndCampaigns(
          teams.map((t) => t.data),
          campaigns,
          granularity,
          attribution,
          timeframe
        ),
      [attribution, timeframe]
    ),
    true
  );

  return (
    <>
      <Helmet>Campaigns Teams | Affilimate</Helmet>
      <CampaignsPageBody>
        <PageWrapper>
          {!canView ? (
            <NoPermissions />
          ) : (
            <>
              <FlexContainer justifyContent="space-between">
                <Typography component="h1" variant="h6">
                  <strong>Campaigns by Team</strong>
                </Typography>
                {!loading && !error ? (
                  <FlexContainer>
                    <CampaignReportAttributionSelector
                      value={attribution}
                      onChange={setAttribution}
                    />
                    <YearPicker value={year} onChange={setYear} />
                  </FlexContainer>
                ) : null}
              </FlexContainer>
              {error ? (
                <>
                  <Typography variant="h5" component="p" paragraph>
                    <strong>Couldn't load teams data</strong>
                  </Typography>
                  <Typography variant="body1" component="p" paragraph>
                    Try refreshing the page. If you expect to see data here and
                    the error persists, please contact Support with the URL to
                    this page.
                  </Typography>
                </>
              ) : loading || !data ? (
                <Loader size={36} height={500} />
              ) : (
                <CampaignsTeamsReportBody
                  data={data}
                  timeframe={timeframe}
                  granularity={granularity}
                />
              )}
            </>
          )}
        </PageWrapper>
      </CampaignsPageBody>
    </>
  );
};
