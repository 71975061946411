import React from 'react';
import { Card } from '@material-ui/core';
import { css } from '../../../emotion';
import Typography from '@material-ui/core/Typography';
import { Skeleton } from '@material-ui/lab';
import { FlexContainer } from '../../../layout/Flex';
import { metricTitle } from '../../../services/analyticsV2/metrics';
import { useSpaceCurrency } from '../../../services/useSpaceCurrency';
import {
  CampaignTotals,
  CampaignTotal,
  CampaignTotalsObject,
  hasGoal
} from '../service/totals';
import {
  formatChartCurrency,
  formatChartNumber
} from '../../../components/Charts/Util';
import { CampaignTargetStatus } from './CampaignTargetStatus';
import { formatNumber } from '../../../components/Number';
import { ManualCampaign } from '../service/manual-campaign';
import { SegmentCampaign } from '../service/segment-campaign';
import { isCampaignRunning } from '../service/lifecycle';

const BaseCard: React.FC<{
  totals: CampaignTotals;
  title: string;
  children: (values: CampaignTotalsObject) => React.ReactNode;
}> = ({ totals, title, children }) => {
  const [values, loading] = totals;
  return (
    <Card
      className={css(() => ({
        flex: 1
      }))}
    >
      <Typography variant="body2" gutterBottom>
        {title}
      </Typography>
      {loading || !values ? (
        <Skeleton
          className={css(() => ({
            width: '60%',
            fontSize: '1.5rem'
          }))}
        />
      ) : (
        <FlexContainer alignItems="baseline" spacing={0.5}>
          {children(values)}
        </FlexContainer>
      )}
    </Card>
  );
};

const CurrencyCard: React.FC<{
  title: string;
  totals: CampaignTotals;
  field: CampaignTotal;
  includeCents?: boolean;
}> = ({ title, totals, field, includeCents = false }) => {
  const currency = useSpaceCurrency();
  return (
    <BaseCard totals={totals} title={title}>
      {(values) => (
        <Typography variant="h5">
          {formatChartCurrency(values[field], currency, {
            excludeCents: !includeCents
          })}
        </Typography>
      )}
    </BaseCard>
  );
};

const NumberCard: React.FC<{
  title: string;
  totals: CampaignTotals;
  field: CampaignTotal;
}> = ({ title, totals, field }) => {
  return (
    <BaseCard totals={totals} title={title}>
      {(values) => (
        <Typography variant="h5">{formatChartNumber(values[field])}</Typography>
      )}
    </BaseCard>
  );
};

// TODO: TrafficGoalCard and GMVGoalCard are very similar, consider refactoring if we go with those components
const TrafficGoalCard: React.FC<{
  campaign: SegmentCampaign | ManualCampaign;
  totals: CampaignTotals;
  field: 'c' | 'p';
}> = ({ totals, campaign, field }) => {
  return (
    <BaseCard totals={totals} title={metricTitle(field)}>
      {(values) => {
        const metric = values[field];
        const value = (
          <Typography variant="h5">
            {formatChartNumber(metric.value)}
          </Typography>
        );
        if (!hasGoal(metric)) {
          return value;
        }
        return (
          <>
            {value}
            <Typography variant="body1" color="textSecondary">
              (
              {formatNumber({
                n: metric.goal.percentage,
                format: 'percent',
                digits: 1
              })}
              )
            </Typography>
            {isCampaignRunning(campaign) ? (
              <CampaignTargetStatus
                metric={metric}
                campaign={campaign}
                field={field}
              />
            ) : null}
          </>
        );
      }}
    </BaseCard>
  );
};

const GmvGoalCard: React.FC<{
  campaign: SegmentCampaign | ManualCampaign;
  totals: CampaignTotals;
}> = ({ totals, campaign }) => {
  const currency = useSpaceCurrency();
  return (
    <BaseCard totals={totals} title={metricTitle('gmv_sum_net')}>
      {(values) => {
        const metric = values.gmv;
        const value = (
          <Typography variant="h5">
            {formatChartCurrency(metric.value, currency, {
              excludeCents: true
            })}
          </Typography>
        );
        if (!hasGoal(metric)) {
          return value;
        }
        return (
          <>
            {value}
            <Typography variant="body1" color="textSecondary">
              (
              {formatNumber({
                n: metric.goal.percentage,
                format: 'percent',
                digits: 1
              })}
              )
            </Typography>
            {isCampaignRunning(campaign) ? (
              <CampaignTargetStatus
                metric={metric}
                campaign={campaign}
                field={'gmv_sum_net'}
              />
            ) : null}
          </>
        );
      }}
    </BaseCard>
  );
};

interface TotalsCardProps {
  campaign: SegmentCampaign | ManualCampaign;
  totals: CampaignTotals;
}

export const CampaignClicksCard: React.FC<TotalsCardProps> = (props) => (
  <TrafficGoalCard field="c" {...props} />
);

export const CampaignPageviewsCard: React.FC<TotalsCardProps> = (props) => (
  <TrafficGoalCard field="p" {...props} />
);

export const CampaignImpressionsCard: React.FC<TotalsCardProps> = (props) => (
  <NumberCard field="v" title={metricTitle('v')} {...props} />
);

export const CampaignGmvCard: React.FC<TotalsCardProps> = (props) => (
  <GmvGoalCard {...props} />
);

export const CampaignCPCCard: React.FC<TotalsCardProps> = (props) => (
  <CurrencyCard title="CPC" field="cpc" includeCents {...props} />
);

export const CampaignBudgetCard: React.FC<TotalsCardProps> = (props) => (
  <CurrencyCard title="Budget" field="budget" {...props} />
);

export const CampaignEstimatedCPCCard: React.FC<
  TotalsCardProps & { title?: string }
> = ({ title = 'Est. CPC earnings', totals }) => {
  const currency = useSpaceCurrency();
  return (
    <BaseCard totals={totals} title={title}>
      {(values) => (
        <Typography variant="h5">
          {formatChartCurrency(values.estimatedCPC.value, currency, {
            excludeCents: true
          })}
        </Typography>
      )}
    </BaseCard>
  );
};

export const CampaignSpentCard: React.FC<TotalsCardProps> = (props) => (
  <CampaignEstimatedCPCCard {...props} title="Spent so far" />
);

export const CampaignROASCard: React.FC<TotalsCardProps> = ({ totals }) => (
  <BaseCard totals={totals} title="Est. ROAS">
    {(values) => {
      const roas = values.gmv.value / values.estimatedCPC.value;
      return (
        <Typography variant="h5">
          {formatNumber({ n: roas, digits: 1 })}x
        </Typography>
      );
    }}
  </BaseCard>
);

export const CampaignEarningsCard: React.FC<TotalsCardProps> = (props) => (
  <CurrencyCard
    title={metricTitle('commission_sum_net')}
    field="commission"
    {...props}
  />
);

export const CampaignFlatSpendCard: React.FC<TotalsCardProps> = (props) => (
  <CurrencyCard title="Flat spend" field="flatSpend" {...props} />
);

export const CampaignOrdersCard: React.FC<TotalsCardProps> = (props) => (
  <NumberCard
    title={metricTitle('order_count_net')}
    field="orderCount"
    {...props}
  />
);

export const CampaignAovCard: React.FC<TotalsCardProps> = (props) => (
  <CurrencyCard title={metricTitle('aov_net')} field="aov" {...props} />
);

export const CampaignTotalCardsContainer: React.FC = ({ children }) => {
  return <FlexContainer spacing={2}>{children}</FlexContainer>;
};
