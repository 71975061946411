import { styled } from '../../emotion';

export const FieldContainer = styled('div')<{ fullWidth?: boolean }>`
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  gap: ${(t) => t.theme.spacing(1)}px;
  ${(p) =>
    p.fullWidth === false
      ? ''
      : 'width: 100%;' /* this to say that the default is true */}
`;
