import { MenuItem, Select } from '@material-ui/core';
import MuiBreadcrumbs from '@material-ui/core/Breadcrumbs';
import Hidden from '@material-ui/core/Hidden';
import MuiTab from '@material-ui/core/Tab';
import MuiTabs from '@material-ui/core/Tabs';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { AffilimateLogoWide } from '../../components/AffilimateLogo';
import { TopNavBarSection } from '../../components/TopNavBarSection';
import { css, styled } from '../../emotion';
import { useHotKeys } from '../../hooks/useHotkeys';
import { useRoutes } from '../../routes';
import { pathnameWithoutQueryParams } from '../../services/url';
import * as tracking from '../../tracking';

export type IProps = {
  title?: string | React.ReactNode;
  children?: React.ReactNode;
  colors?: {
    fg: string;
    bg: string;
  };
  stickyIndex?: number;
};

export type TabLinkProps = {
  to: string;
  label: string;
};

export const Breadcrumbs = (props: any) => (
  <MuiBreadcrumbs
    className={css((t) => ({
      color: 'inherit',
      marginLeft: t.spacing(3),
      padding: t.spacing(2, 0)
    }))}
    {...props}
  />
);

const MobileNavWrapper = styled('div')`
  .MuiInput-root {
    color: white;
  }
`;

export const Tabs = ({ tabs }: { tabs: TabLinkProps[] }) => {
  const { getPath, goTo } = useRoutes();
  const currentPath = getPath();
  const activeI = Math.max(
    tabs.findIndex((t) => t.to.split('?')[0] === currentPath),
    0
  );

  const goToPrevTab = () => activeI > 0 && goTo(tabs[activeI - 1].to);
  const goToNextTab = () =>
    tabs.length > 1 &&
    activeI !== tabs.length - 1 &&
    goTo(tabs[activeI + 1].to);

  useHotKeys(
    [
      {
        keys: 'shift+left',
        handler: goToPrevTab,
        label: 'Go to previous tab'
      },
      {
        keys: 'shift+right',
        handler: goToNextTab,
        label: 'Go to next tab'
      }
    ],
    [activeI, tabs]
  );

  return (
    <MuiTabs
      value={activeI}
      color="inherit"
      classes={{
        indicator: css((t) => ({ backgroundColor: t.palette.common.white }))
      }}
    >
      {tabs.map((t, i) => (
        <MuiTab
          key={i}
          label={
            <Link
              to={t.to}
              onClick={() => {
                tracking.sendEvent({
                  category: tracking.toAppCategory(),
                  action: 'Click',
                  label: `${t.label} tab`
                });
              }}
              className={css((t) => ({
                button: { opacity: activeI === i ? 1 : 0.7 }
              }))}
            >
              {t.label}
            </Link>
          }
        ></MuiTab>
      ))}
    </MuiTabs>
  );
};

export const TOP_NAV = {
  COLORS: {
    light: '#111',
    lightDimmed: '#444',
    dark: '#FFF'
  }
};

const TopNavBar = styled('div')<{
  rightFr?: number;
}>`
  top: 0;
  position: sticky;
  display: grid;
  grid-template-columns: minmax(100px, 144px) minmax(100px, 3fr) ${(p) =>
      p.rightFr || 0.5}fr;
  align-items: center;
  padding: 0px ${(p) => p.theme.spacing(3)}px;
  min-height: ${(p) => p.theme.spacing(7)}px;
  color: ${TOP_NAV.COLORS.light};
  background-color: ${TOP_NAV.COLORS.dark};
  border-bottom: 1px solid ${(p) => p.theme.palette.grey[300]};
  z-index: 2;

  ${(p) => p.theme.breakpoints.down('xs')} {
    padding: 0px ${(p) => p.theme.spacing(1)}px;
  }
`;

const TopNavLink = styled<'span', { selected: boolean }>('span')`
  :not(:last-child) {
    padding-right: ${(p) => p.theme.spacing(2)}px;
  }
  ${(p) =>
    p.selected ? `font-weight: ${p.theme.typography.fontWeightBold};` : ''}

  ${(p) => (!p.selected ? `color: ${TOP_NAV.COLORS.lightDimmed};` : '')}
`;

// Minor recentering
const LOGO_STYLE: React.CSSProperties = {
  position: 'relative',
  top: '3px'
};

export const TopNavCommon: React.FC<{
  navItems: { label: string; path: string }[];
  homePath: string;
  currentPath: string;
  demoButton?: React.ReactNode;
  rightFr?: number;
}> = ({ navItems, homePath, currentPath, demoButton, rightFr, children }) => {
  const selected = useMemo(() => {
    return navItems.find((x) => {
      const pathname = pathnameWithoutQueryParams(x.path);
      const p = pathname.split('/')[1];
      const c = currentPath.split('/')[1];
      return p === c;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath, navItems]);
  return (
    <TopNavBar rightFr={rightFr}>
      <TopNavBarSection>
        <Link to={homePath}>
          <AffilimateLogoWide style={LOGO_STYLE} />
        </Link>
      </TopNavBarSection>
      <TopNavBarSection>
        <Hidden smDown>
          {navItems.map((x) => (
            <TopNavLink key={x.label} selected={x === selected}>
              <Link to={x.path}>{x.label}</Link>
            </TopNavLink>
          ))}
          {demoButton && <TopNavLink selected={false}>{demoButton}</TopNavLink>}
        </Hidden>
        <MobileNavWrapper>
          <Hidden mdUp>
            <Select
              disableUnderline={true}
              value={selected?.label || 'Dashboard'}
              classes={{
                root: css((t) => ({
                  color: TOP_NAV.COLORS.light,
                  fontSize: t.custom.fontSize.m
                }))
              }}
            >
              {navItems.map((x) => (
                <MenuItem key={x.label} value={x.label}>
                  <Link to={x.path}>{x.label}</Link>
                </MenuItem>
              ))}
            </Select>
          </Hidden>
        </MobileNavWrapper>
      </TopNavBarSection>

      {children}
    </TopNavBar>
  );
};
