import { AnalyticsFilterUI } from '../../../FilterUI';
import React from 'react';
import { ChipContent, formatList } from '../../common';
import {
  FilterDefinition,
  LinkAdvertiserFilterDefinition
} from '../../../../../../domainTypes/filters';
import { LinkAdvertiserDomainMenu } from './LinkAdvertiserDomainMenu';

export const linkAdvertiserDomainFilterUIDef: AnalyticsFilterUI = {
  type: 'analytics',
  dimension: 'l_advertiser_domain',
  chip: ({ definition }) => {
    if (!definition) return <>Deeplink domain</>;
    if (definition.k !== 'l_advertiser_domain') {
      throw new Error('Invalid definition');
    }
    return (
      <ChipContent title="Deeplink domain is">
        {formatList(definition.v)}
      </ChipContent>
    );
  },
  menu: function ({ definition, ...props }) {
    return (
      <LinkAdvertiserDomainMenu
        definition={definition as LinkAdvertiserFilterDefinition}
        {...props}
      />
    );
  },
  init: () => ({
    k: 'l_advertiser_domain',
    v: []
  }),
  toClauses: (definition: FilterDefinition) => {
    if (definition.k !== 'l_advertiser_domain') {
      return [];
    }
    return [
      {
        field: 'l_advertiser_domain',
        condition: 'in',
        values: definition.v
      }
    ];
  }
};
