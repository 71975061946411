import { LinkGeneratorOption, LinkGeneratorOptionGroup } from './types';

export const LINK_GENERATOR_BASIC_OPTIONS: LinkGeneratorOption[] = [
  {
    value: 'subid',
    label: 'SubID',
    labelString: 'subid',
    group: LinkGeneratorOptionGroup.Standard,
    inputType: 'text'
  }
];

export const LINK_WRAPPER_ADVANCED_OPTIONS: LinkGeneratorOption[] = [
  {
    value: 'page_url',
    label: 'Page URL',
    labelString: 'Page URL',
    group: LinkGeneratorOptionGroup.Offsite,
    inputType: 'autocomplete',
    field: 'page_url'
  },
  {
    value: 'utm_campaign',
    label: 'UTM Campaign',
    labelString: 'UTM Campaign',
    group: LinkGeneratorOptionGroup.Offsite,
    inputType: 'autocomplete',
    field: 'utm_campaign'
  },
  {
    value: 'utm_campaign',
    label: 'UTM Campaign',
    labelString: 'UTM Campaign',
    group: LinkGeneratorOptionGroup.Offsite,
    inputType: 'text'
  },
  {
    value: 'utm_source',
    label: 'UTM Source',
    labelString: 'UTM Source',
    group: LinkGeneratorOptionGroup.Offsite,
    inputType: 'text'
  },
  {
    value: 'utm_medium',
    label: 'UTM Medium',
    labelString: 'UTM Medium',
    group: LinkGeneratorOptionGroup.Offsite,
    inputType: 'text'
  }
];
