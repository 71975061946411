import React, { useMemo } from 'react';
import {
  createNameColumn,
  TopFooter,
  TopHeader,
  TopPaper,
  TopTable
} from '../../../../../components/analytics_v2/Table/TopTable/TopTable';
import { Truncated } from '../../../../../components/Truncated';
import { WithHoverIndicator } from '../../../../../components/WithHoverIndicator';
import {
  AnalyticsFilter,
  AnalyticsResponseRowWithComparison,
  ISOTimeRange
} from '../../../../../domainTypes/analytics_v2';
import { useRoutes } from '../../../../../routes';
import { useMixpanel } from '../../../../../services/mixpanel';
import { getPathName } from '../../../../../services/pages';
import { useRealtimeColumnTransformers, useRealtimeMetric } from '../service';
import { usePeriodRangeToTimeframe } from './PeriodSelector';

const cell = (p: AnalyticsResponseRowWithComparison) => {
  const href = p.group.page_url;
  const pathName = getPathName(href);
  return (
    <WithHoverIndicator>
      <Truncated title={href}>{pathName}</Truncated>
    </WithHoverIndicator>
  );
};

const metrics = [
  'p',
  'c',
  'ctr',
  'rpm_net',
  'epc_net',
  'commission_sum_net',
  'gmv_sum_net'
] as const;

export const TopEarningsTable = ({
  range,
  compare,
  filters,
  color
}: {
  range: ISOTimeRange;
  compare?: ISOTimeRange;
  filters: AnalyticsFilter[];
  color: string;
}) => {
  const mixpanel = useMixpanel();
  const [metric] = useRealtimeMetric();
  const { ROUTES } = useRoutes();
  const timeframe = usePeriodRangeToTimeframe();
  const columnTransformers = useRealtimeColumnTransformers(filters);
  const contentColumn = useMemo(() => {
    return createNameColumn(cell, 'Page URL', color, metric);
  }, [color, metric]);
  return (
    <TopPaper>
      <TopHeader
        title="Top content"
        description="Your top content based on the selected sorting metric. You can filter this using the search box."
      ></TopHeader>
      <TopTable
        availableMetrics={metrics}
        metric={metric}
        range={range}
        compareRange={compare}
        groupField={'page_url'}
        nameColumn={contentColumn}
        filters={filters}
        columnTransformers={columnTransformers}
        rowToHref={(d) =>
          ROUTES.content.details.heatmap.url(d.group.page_url, { timeframe })
        }
      />
      <TopFooter
        route={ROUTES.content.overview_v2.url(undefined, timeframe)}
        onClick={() =>
          mixpanel.track('realtime_report_view_all', {
            widget: 'content'
          })
        }
      />
    </TopPaper>
  );
};
