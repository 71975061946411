import React from 'react';
import { CampaignsPageBody } from '../../components/CampaignsPageBody';
import { useManualCampaign } from '../../service';
import { Loader } from '../../../../components/Loader';
import { Message } from '../../../../components/Message';
import { Helmet } from 'react-helmet';
import { useTrackMixpanelView } from '../../../../services/mixpanel';
import { CompletedCampaignReport } from '../../components/report/CompletedCampaignReport';
import { RunningCampaignReport } from '../../components/report/RunningCampaignReport';
import { useCurrentUserScopes } from '../../../../services/currentUser';
import { NoPermissions } from '../../../../components/NoPermissions';
import { isCampaignComplete } from '../../service/manual-campaign';
import { isCampaignRunning } from '../../service/lifecycle';

export const ManualCampaignReport: React.FC<{ campaignId: string }> = ({
  campaignId
}) => {
  const scopes = useCurrentUserScopes();
  const canView = scopes.has('campaigns.view');
  const [campaign, loading] = useManualCampaign(campaignId);
  useTrackMixpanelView('view_campaigns_report', { id: campaignId });
  return (
    <>
      <Helmet>
        <title>Campaign report | Affilimate</title>
      </Helmet>
      <CampaignsPageBody>
        {!canView ? (
          <NoPermissions />
        ) : loading || !campaign ? (
          <Loader size={36} height={500} />
        ) : isCampaignComplete(campaign) ? (
          <CompletedCampaignReport campaign={campaign} />
        ) : isCampaignRunning(campaign) ? (
          <RunningCampaignReport campaign={campaign} />
        ) : (
          <Message message="Campaign is not ready yet!" />
        )}
      </CampaignsPageBody>
    </>
  );
};
