import { Button, IconButton, Typography } from '@material-ui/core';
import firebase from 'firebase/app';
import { compact, uniqBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { ChevronLeft, ChevronRight, RefreshCw, X } from 'react-feather';
import { Link } from 'react-router-dom';
import { AlertBox } from '../../../../components/AlertBox';
import { useSortQueryParam } from '../../../../components/GroupableList';
import { Loader } from '../../../../components/Loader';
import { PlatformWithColor } from '../../../../components/PlatformWithColor';
import { SearchInput } from '../../../../components/SearchInput';
import {
  SingleSelector,
  SingleSelectorChip
} from '../../../../components/SingleSelector';
import { CachedAdvertiser } from '../../../../domainTypes/advertiser';
import { Doc } from '../../../../domainTypes/document';
import { IPartner } from '../../../../domainTypes/partners';
import {
  HANDLERS_WITH_RATES,
  SECRET_CATEGORY
} from '../../../../domainTypes/reporting';
import { styled } from '../../../../emotion';
import { useDialogState } from '../../../../hooks/useDialogState';
import {
  DEFAULT_OFFSET,
  PageToolbar,
  PageToolbarSection
} from '../../../../layout/PageToolbar';
import { useRoutes, useStringQueryParam } from '../../../../routes';
import { toAdvertiserDoc } from '../../../../services/advertisers';
import { ARTICLES } from '../../../../services/beacon';
import { useCurrentUser } from '../../../../services/currentUser';
import { store } from '../../../../services/db';
import { getKnownPartnerForKey } from '../../../../services/partner';
import { useSecrets } from '../../../../services/secret';
import { FS } from '../../../../versions';
import { PerformancePageBody } from '../../components/PerformancePageBody';
import { RatesTable, SORTERS } from '../../components/RatesTable';
import { TriggerJobDialog } from '../../components/TriggerJobDialog';
import { API_REPORT_HANDLERS } from '../../services/handlers';

type AdvertiserDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot<
  CachedAdvertiser
>;

const PAGE_SIZE = 12;
const PAGE_SIZE_WITH_SEARCH = 12;

const StyledLink = styled(Link)({
  borderBottom: `1px solid`
});

export const Rates = () => {
  const { space } = useCurrentUser();
  const [advertisers, setAdvertisers] = useState<Doc<CachedAdvertiser>[]>([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useStringQueryParam('q', '');
  const [[sorter, direction], setSort] = useSortQueryParam('sort', SORTERS);
  const [showTroubleshootingMessage, setShowTroubleshootingMessage] = useState(
    window.location.search.includes('show_troubleshooting')
  );
  const [secrets, loadingSecrets] = useSecrets(space.id, SECRET_CATEGORY);
  const [selectedPartnerKey, setSelectedPartnerKey] = useStringQueryParam(
    'partner_key',
    ''
  );
  const { dialogOpen, setDialogOpen } = useDialogState();
  const { ROUTES } = useRoutes();

  const activePartners = useMemo<IPartner[]>(() => {
    if (!secrets) {
      return [];
    }
    const aps = secrets.filter(
      (s) =>
        s.data.status === 'active' && HANDLERS_WITH_RATES.includes(s.data.name)
    );
    const allPks = compact(
      aps.map((s) => {
        const handler = API_REPORT_HANDLERS.find(
          (h) => h.configName === s.data.name
        );
        if (!handler) {
          return null;
        }
        return getKnownPartnerForKey(handler.partnerKey);
      })
    );
    return uniqBy(allPks, 'key').sort((a, b) => a.name.localeCompare(b.name));
  }, [secrets]);

  const [
    lastSnapshot,
    setLastSnapshot
  ] = useState<AdvertiserDocumentSnapshot | null>(null);
  const [
    firstSnapshot,
    setFirstSnapshot
  ] = useState<AdvertiserDocumentSnapshot | null>(null);

  useEffect(() => {
    setPage(1); // Reset page to zero when searching
  }, [search]);

  const handleSnapshots = (
    snap: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  ) => {
    const snapDocs = snap.docs as AdvertiserDocumentSnapshot[];
    const docs = snap.docs.map((d) => toAdvertiserDoc(d));
    setLastSnapshot(snapDocs[snapDocs.length - 1]);
    setFirstSnapshot(snapDocs[0]);
    setAdvertisers(docs);
    setLoading(false);
  };

  const fetchData = async () => {
    const orderBy = sorter ? sorter.key : SORTERS.name.key;
    const dir = direction || SORTERS.name.items.dir;

    let query = store()
      .collection(FS.advertisers)
      .where('spaceId', '==', space.id);

    if (selectedPartnerKey) {
      query = query.where('partnerKey', '==', selectedPartnerKey);
    }

    if (!search) {
      query
        .orderBy(orderBy, dir)
        .limit(PAGE_SIZE)
        .get()
        .then(handleSnapshots)
        .catch((e) => {
          console.error(e);
          setLoading(false);
        });
    } else {
      const s = search.toLowerCase();
      query
        .where('query', '>=', s)
        .where('query', '<=', s + '\uf8ff')
        .orderBy('query', 'asc')
        .orderBy(orderBy, dir) // It's required this orderBy comes after query
        .limit(PAGE_SIZE_WITH_SEARCH) // Higher limit if searching
        .get()
        .then(handleSnapshots)
        .catch((e) => {
          console.error(e);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [space.id, search, selectedPartnerKey, sorter, direction]); // eslint-disable-line react-hooks/exhaustive-deps

  const showNext = () => {
    if (advertisers.length === 0) {
      return;
    }

    const fetchNext = async () => {
      setLoading(true);
      let query = firebase
        .firestore()
        .collection(FS.advertisers)
        .where('spaceId', '==', space.id);

      if (selectedPartnerKey) {
        query = query.where('partnerKey', '==', selectedPartnerKey);
      }

      const orderBy = sorter ? sorter.key : SORTERS.name.key;
      const dir = direction || SORTERS.name.items.dir;

      if (!search) {
        query
          .orderBy(orderBy, dir)
          .limit(PAGE_SIZE)
          .startAfter(lastSnapshot)
          .get()
          .then((snap) => {
            handleSnapshots(snap);
            setPage(page + 1);
          })
          .catch((err) => {
            console.error(err);
            setLoading(false);
          });
      } else {
        const s = search.toLowerCase();
        query
          .where('query', '>=', s)
          .where('query', '<=', s + '\uf8ff')
          .orderBy('query', 'asc')
          .orderBy(orderBy, dir) // It's required this orderBy comes after query
          .limit(PAGE_SIZE_WITH_SEARCH) // Higher limit if searching
          .startAfter(lastSnapshot)
          .get()
          .then((snap) => {
            handleSnapshots(snap);
            setPage(page + 1);
          })
          .catch((err) => {
            console.error(err);
            setLoading(false);
          });
      }
    };
    fetchNext();
  };

  const showPrev = () => {
    setLoading(true);

    const fetchPrev = async () => {
      const orderBy = sorter ? sorter.key : SORTERS.name.key;
      const dir = direction || SORTERS.name.items.dir;
      let query = firebase
        .firestore()
        .collection(FS.advertisers)
        .where('spaceId', '==', space.id);

      if (selectedPartnerKey) {
        query = query.where('partnerKey', '==', selectedPartnerKey);
      }

      if (!search) {
        query
          .orderBy(orderBy, dir)
          .endBefore(firstSnapshot)
          .limitToLast(PAGE_SIZE)
          .get()
          .then((snap) => {
            handleSnapshots(snap);
            setPage(page - 1);
          })
          .catch((err) => {
            console.error(err);
            setLoading(false);
          });
      } else {
        const s = search.toLowerCase();
        query
          .where('query', '>=', s)
          .where('query', '<=', s + '\uf8ff')
          .orderBy('query', 'asc')
          .orderBy(orderBy, dir) // It's required this orderBy comes after query
          .endBefore(firstSnapshot)
          .limitToLast(PAGE_SIZE_WITH_SEARCH)
          .get()
          .then((snap) => {
            handleSnapshots(snap);
            setPage(page - 1);
          })
          .catch((err) => {
            console.error(err);
            setLoading(false);
          });
      }
    };
    fetchPrev();
  };

  return (
    <PerformancePageBody noTopPadding>
      <PageToolbar sticky offset={DEFAULT_OFFSET}>
        <PageToolbarSection flex={3}>
          <Typography
            variant="h6"
            component="span"
            style={{
              marginRight: '9px',
              position: 'relative',
              fontWeight: 'bold',
              top: '-2px'
            }}
          >
            Rates
          </Typography>
          <SearchInput
            value={search}
            onChange={setSearch}
            width={350}
            placeholder="Search by advertiser name"
          />
          {secrets && !loadingSecrets ? (
            <SingleSelector
              value={selectedPartnerKey}
              legend="Platform"
              onChange={setSelectedPartnerKey}
              options={activePartners.map((partner) => {
                return {
                  label: <PlatformWithColor partner={partner!} />,
                  value: partner!.key,
                  searchValue: partner?.name || ''
                };
              })}
            >
              <SingleSelectorChip
                isApplied={selectedPartnerKey !== ''}
                label={
                  selectedPartnerKey === ''
                    ? 'All platforms'
                    : getKnownPartnerForKey(selectedPartnerKey)!.name
                }
                appliedLabel={
                  selectedPartnerKey !== ''
                    ? `For ${getKnownPartnerForKey(selectedPartnerKey)!.name}`
                    : 'All platforms'
                }
                onDelete={() => setSelectedPartnerKey('')}
              />
            </SingleSelector>
          ) : (
            <Loader />
          )}
        </PageToolbarSection>
        <PageToolbarSection flex={2} justifyContent="flex-end">
          <div>
            {page > 1 && (
              <IconButton
                onClick={() => showPrev()}
                disabled={!firstSnapshot || loading}
              >
                <ChevronLeft size={16} />
              </IconButton>
            )}
            <Typography
              variant="body2"
              component="span"
              style={{ display: 'inline-block', margin: '0 6px' }}
            >
              Page {page}
            </Typography>
            {advertisers.length < PAGE_SIZE ? null : (
              <IconButton
                onClick={() => showNext()}
                disabled={!lastSnapshot || loading}
              >
                <ChevronRight size={16} />
              </IconButton>
            )}
          </div>
          <Button
            variant="contained"
            color="default"
            onClick={() => {
              setDialogOpen(true);
            }}
          >
            <RefreshCw size={12} /> &nbsp;&nbsp; Sync rates
          </Button>
        </PageToolbarSection>
      </PageToolbar>
      {showTroubleshootingMessage && !search && (
        <AlertBox
          variant="pending"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            gap: 16
          }}
        >
          <div>
            <Typography variant="body1" gutterBottom>
              <strong>Having trouble creating a link?</strong>
            </Typography>
            <Typography variant="body2" gutterBottom>
              You may not be joined to the program, the advertiser may have
              switched networks, or the rates haven't updated yet (most networks
              sync daily).
            </Typography>
            <Typography variant="body2" gutterBottom>
              Try clicking the &nbsp;
              <strong>
                <RefreshCw size={12} /> &nbsp;Sync rates
              </strong>{' '}
              button in the top right corner to refresh the rates, wait a few
              minutes, and then search to make sure the advertiser is now listed
              as "Active".
            </Typography>
            <Typography variant="body2">
              For more help, check this{' '}
              <StyledLink
                to={ROUTES.docs.knowledgeBase.url(
                  ARTICLES.tools.linkGeneratorTroubleshooting
                )}
              >
                this style-by-step guide
              </StyledLink>
              .
            </Typography>
          </div>
          <IconButton onClick={() => setShowTroubleshootingMessage(false)}>
            <X size={18} />
          </IconButton>
        </AlertBox>
      )}
      {loading ? (
        <Loader height={400} />
      ) : advertisers ? (
        <RatesTable
          advertisers={advertisers}
          sorter={sorter}
          direction={direction || SORTERS.name.items.dir}
          setSort={setSort}
        />
      ) : (
        <div>No advertisers found.</div>
      )}
      {dialogOpen && (
        <TriggerJobDialog
          jobType="advertiser"
          availableHandlers={HANDLERS_WITH_RATES}
          dialogTitle="Sync your rates"
          showDates={false}
          dialogContent={
            <>
              <Typography variant="body1" gutterBottom>
                Once you've performed an initial import of your rates, they'll
                update automatically on a daily basis. You can also manually
                import your rates using the form below:
              </Typography>
              <br />
              <br />
              <Typography variant="body1" color="textSecondary" gutterBottom>
                Which platform do you want to sync rates for?
              </Typography>
            </>
          }
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
        />
      )}
    </PerformancePageBody>
  );
};
