import { compact, take } from 'lodash';
import pluralize from 'pluralize';
import React, { useMemo, useState } from 'react';
import {
  AnalyticsFilter,
  AnalyticsQuery,
  ISOTimeRange
} from '../../../../../../domainTypes/analytics_v2';
import { COUNTRY_ABBREVIATIONS } from '../../../../../../domainTypes/country';
import { useCurrentUser } from '../../../../../../services/currentUser';
import { useMappedLoadingValue } from '../../../../../../services/db';
import { CountryFilterDef } from '../../../../../../domainTypes/filters';
import {
  Metric,
  metricName
} from '../../../../../../services/analyticsV2/metrics';
import { useAnalyticsQueryV2 } from '../../../../../../services/analyticsV2/query';
import { FlagWithLabel } from '../../../../../Flag';
import { BASIC_MODES, FilterMenu } from '../../Menus/FilterMenu';
import {
  OptionsList,
  SelectorLoader,
  SelectorShell,
  TOP_N,
  useCollectionFilterState
} from '../../Menus/Selector';
import { AnalyticsFilterMenuComponent } from '../../../FilterUI';

const CODES = COUNTRY_ABBREVIATIONS;

interface CountriesListProps {
  onToggle: (country: string) => void;
  onFocus: (country: string) => void;
  orderBy: Metric;
  range: ISOTimeRange;
  filters: AnalyticsFilter[];
  value: Array<string>;
}

const CountriesList: React.FC<CountriesListProps> = ({
  range,
  filters,
  orderBy,
  value,
  onToggle,
  onFocus
}) => {
  const { space } = useCurrentUser();
  const [search, setSearch] = useState('');

  const query = useMemo<AnalyticsQuery>(
    () => ({
      range,
      select: [orderBy],
      groupBy: ['country'],
      orderBy: [
        {
          field: orderBy,
          direction: 'DESC'
        }
      ],
      filters: compact([
        ...filters,
        {
          field: 'country',
          condition: 'not in',
          values: ['']
        }
      ])
    }),
    [range, orderBy, filters]
  );

  const [
    countryCodes = [],
    loading
  ] = useMappedLoadingValue(useAnalyticsQueryV2(space.id, query), (response) =>
    response.rows.map((row) => row.group.country)
  );

  const matchingCodes = useMemo(() => {
    const searchString = search.trim().toLowerCase();
    return countryCodes.filter((code) => {
      const name = CODES[code];
      return (
        (name && name.toLowerCase().includes(searchString)) ||
        code.toLowerCase().includes(searchString)
      );
    });
  }, [countryCodes, search]);

  const topCodes = useMemo(() => take(matchingCodes, TOP_N), [matchingCodes]);

  const hasMoreOptions = useMemo(() => matchingCodes.length > TOP_N, [
    matchingCodes
  ]);

  const options = useMemo(() => {
    const promoted = value.filter((code) => !topCodes.includes(code));
    return [...promoted, ...topCodes].map((countryCode) => ({
      value: countryCode,
      label: <FlagWithLabel code={countryCode} />
    }));
  }, [topCodes, value]);

  return (
    <SelectorShell label="Click country" search={search} setSearch={setSearch}>
      {loading ? (
        <SelectorLoader />
      ) : (
        <OptionsList
          options={options}
          selectedValues={value}
          onToggle={onToggle}
          onFocus={onFocus}
          hasMore={hasMoreOptions}
          caption={`Top ${pluralize('click country')} by ${metricName(
            orderBy
          )}`}
        />
      )}
    </SelectorShell>
  );
};

export const CountryMenu: AnalyticsFilterMenuComponent<CountryFilterDef> = ({
  definition,
  onSave,
  context,
  isFirst
}) => {
  const [mode, setMode] = useState(BASIC_MODES[0].value);
  const {
    values,
    isSaveEnabled,
    handleSave,
    handleToggle,
    handleFocus
  } = useCollectionFilterState<string, CountryFilterDef>(definition, onSave);

  return (
    <FilterMenu>
      <FilterMenu.Header name={'click country'} isFirst={isFirst}>
        <FilterMenu.ModeSelector
          modes={BASIC_MODES}
          mode={mode}
          setMode={setMode}
        />
      </FilterMenu.Header>
      <FilterMenu.Body>
        <CountriesList
          filters={context.baseQuery.filters}
          orderBy={context.baseQuery.orderBy}
          range={context.baseQuery.range}
          onToggle={handleToggle}
          onFocus={handleFocus}
          value={values}
        />
      </FilterMenu.Body>
      <FilterMenu.Footer
        description={
          <>
            <strong>Click country</strong> is the country where the user was
            located when they clicked on your links. Unless you have Amazon
            subtags, Amazon earnings will be omitted.
          </>
        }
      >
        <FilterMenu.SaveButton
          disabled={!isSaveEnabled}
          onSave={handleSave}
          label={`Filter by ${pluralize('country', values.length, true)}`}
        />
      </FilterMenu.Footer>
    </FilterMenu>
  );
};
