import React, { useMemo } from 'react';
import {
  ComposedChart,
  ComposedChartProps,
  ResponsiveContainer,
  ResponsiveContainerProps
} from 'recharts';
import {
  AnalyticsField,
  AnalyticsResponseRowWithComparison
} from '../../../domainTypes/analytics_v2';
import { Metric } from '../../../services/analyticsV2/metrics';
import { entries, fromPairs, groupBy, mapValues, sumBy, uniq } from 'lodash';
import { ChartContext, useChartContext } from './chart-context';

const SINGLETON_KEY = '__value';

type GroupingFn = (row: AnalyticsResponseRowWithComparison) => string;

const formatTimeseriesData = (
  data: AnalyticsResponseRowWithComparison[],
  metrics: Array<Metric>,
  groupingFn: GroupingFn
) => {
  const byTimestamp = groupBy(data, (row) => row.group.interval);
  return entries(byTimestamp).map(([timestamp, dataForInterval]) => {
    const byField = groupBy(dataForInterval, groupingFn);
    const variables = fromPairs(
      metrics.map((metric) => {
        const value = mapValues(byField, (dataForFieldValue) =>
          sumBy(dataForFieldValue, (row) => row.data[metric]?.curr ?? 0)
        );
        return [metric, value];
      })
    );

    return {
      timestamp,
      ...variables
    };
  });
};

const allFieldValues = (
  data: AnalyticsResponseRowWithComparison[],
  groupingFn: GroupingFn
) => uniq(data.map(groupingFn));

interface TimeseriesChartProps {
  data: AnalyticsResponseRowWithComparison[];
  field?: AnalyticsField;
  metrics: Metric[];
  children: (value: {
    fieldValues: string[];
    context: ChartContext;
  }) => React.ReactNode;
  ComposedChartProps?: Partial<ComposedChartProps>;
  ResponsiveContainerProps?: Partial<ResponsiveContainerProps>;
}

export const TimeseriesChart: React.FC<TimeseriesChartProps> = ({
  children,
  ...props
}) => {
  const context = useChartContext();

  return (
    <TimeseriesChartWithContext
      {...props}
      context={context}
      children={children} // Be explicit for ts-react
    />
  );
};

export const TimeseriesChartWithContext: React.FC<
  TimeseriesChartProps & { context: ChartContext }
> = ({
  data,
  children,
  field,
  metrics,
  ComposedChartProps = {},
  ResponsiveContainerProps = {},
  context
}) => {
  const groupingFn = useMemo(
    () =>
      !field
        ? () => SINGLETON_KEY
        : (row: AnalyticsResponseRowWithComparison) => row.group[field],
    [field]
  );
  const timeseriesData = useMemo(
    () => formatTimeseriesData(data, metrics, groupingFn),
    [data, groupingFn, metrics]
  );

  const fieldValues = useMemo(() => allFieldValues(data, groupingFn), [
    data,
    groupingFn
  ]);

  return (
    <ResponsiveContainer width="99%" {...ResponsiveContainerProps}>
      <ComposedChart data={timeseriesData} {...ComposedChartProps}>
        {children({ fieldValues, context })}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
