import { groupBy, sortBy, sumBy, uniq, uniqBy } from 'lodash';
import moment from 'moment-timezone';
import React, { useCallback, useMemo } from 'react';
import { AdvertiserWithColor } from '../../../../../components/AdvertiserWithColor';
import {
  AnalyticsColumnDefinitions,
  useAnalyticsTable
} from '../../../../../components/analytics_v2/Table';
import {
  TimeframePicker,
  useTimeframe
} from '../../../../../components/analytics_v2/Timeframe';
import { ChartCard } from '../../../../../components/Charts/ChartCard';
import { RowsRenderer } from '../../../../../components/GroupableList';
import { Loader, PaperLoader } from '../../../../../components/Loader';
import { ColumnSelector } from '../../../../../components/Table/ColumnSelector';
import { SubHeading } from '../../../../../components/Typography';
import {
  AnalyticsFilter,
  AnalyticsInterval,
  AnalyticsOrderBy,
  AnalyticsQuery,
  AnalyticsResponseRowWithComparison,
  ISOTimeRange
} from '../../../../../domainTypes/analytics_v2';
import { styled } from '../../../../../emotion';
import {
  useAnalyticsInterval,
  useHasComparison
} from '../../../../../hooks/timeframe';
import { CanvasBar } from '../../../../../layout/Canvas';
import { PageBody } from '../../../../../layout/PageBody';
import {
  DEFAULT_OFFSET,
  PageToolbar,
  PageToolbarOtherRow,
  PageToolbarSection,
  PageToolbarTitle
} from '../../../../../layout/PageToolbar';
import { Section } from '../../../../../layout/Section';
import { useComboQueryParam } from '../../../../../routes';
import { Metric } from '../../../../../services/analyticsV2/metrics';
import { useAnalyticsQueryV2 } from '../../../../../services/analyticsV2/query';
import { useCurrentUser } from '../../../../../services/currentUser';
import {
  useCombineLoadingValues3,
  useMappedLoadingValue
} from '../../../../../services/db';
import { usePage } from '../../../../../services/page';
import { getKnownPartnerForKey } from '../../../../../services/partner';
import {
  INTERVALS_TO_TK,
  intervalToTk,
  toMoment
} from '../../../../../services/time';
import { useSpaceCurrency } from '../../../../../services/useSpaceCurrency';
import { DetailsPageTitle } from '../../../components/DetailsPageTitle';
import { useSideNavProps } from '../../../services/detailsSideNav';
import { Chart, ChartData, LeftMode, RightMode } from './Chart/index2';
import { CHART_HEIGHT } from './constants';
import { Summary } from './Summary/index2';
import {
  IntervalSelector,
  useIntervalSelectorState
} from '../../../../../components/IntervalSelector';
import { FlexContainer } from '../../../../../layout/Flex';
import { useContentPagesFilterUIs } from '../../../services/filters';
import { IPageRevision } from '../../../../../domainTypes/page';
import { useAnalyticsFilters } from '../../../../../components/analytics_v2/Filters/useAnalyticsFilters';
import { FiltersToggleButton } from '../../../../../components/analytics_v2/Filters/Toggle';
import { FiltersDrawerWithDefaultTree } from '../../../../../components/analytics_v2/Filters/Drawer/FiltersDrawer';
import { FILTER_STATE_LOCAL_STORAGE_KEYS } from '../../../../../components/analytics_v2/Filters/Drawer/keys';

const Grid = styled('div')`
  display: grid;
  grid-column-gap: ${(p) => p.theme.spacing(3)}px;
  grid-row-gap: ${(p) => p.theme.spacing(4)}px;
  max-width: 100%;
  grid-template-columns: 1fr 2fr;

  ${(p) => p.theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
    grid-column-gap: ${(p) => p.theme.spacing(1)}px;
    grid-row-gap: ${(p) => p.theme.spacing(1)}px;
  }
`;

// right mode is constant, probably we can simplify that
const useModeQueryParam = (
  param: string,
  defaultValue: [LeftMode, RightMode]
) => {
  return useComboQueryParam<[LeftMode, RightMode]>(
    param,
    ([left, right]) => [
      (left as LeftMode) || defaultValue[0],
      (right as RightMode) || defaultValue[1]
    ],
    ([left, right]) =>
      left === defaultValue[0] && right === defaultValue[1]
        ? [undefined, undefined]
        : [left, right],
    '---'
  );
};

const customColumns = ['advertiser', 'platform'] as const;
type CustomColumns = typeof customColumns[number];
type Column = CustomColumns | Metric;

export const availableColumns: Column[] = [
  ...customColumns,
  'order_count_net',
  'commission_count_net',
  'order_count_gross',
  'aov_net',
  'avg_rate_net',
  'commission_sum_net',
  'gmv_sum_net'
];

export const defaultVisibleColumns: Column[] = [
  ...customColumns,
  'commission_sum_net',
  'commission_count_net',
  'aov_net',
  'gmv_sum_net',
  'order_count_net',
  'avg_rate_net'
];

const columnDefinitions: AnalyticsColumnDefinitions<CustomColumns> = {
  platform: {
    column: {
      key: 'platform',
      head: () => 'Platform',
      cell: (p: AnalyticsResponseRowWithComparison) => {
        const partner = getKnownPartnerForKey(p.group.pk);
        return partner?.name ?? 'Not provided';
      },
      align: 'left',
      sortable: true,
      defaultDirection: 'asc',
      width: 200,
      flexGrow: 2
    },
    sorter: {
      key: 'platform',
      items: {
        sort: (p: AnalyticsResponseRowWithComparison) => p.group.pk,
        dir: 'asc'
      }
    }
  },
  advertiser: {
    column: {
      key: 'advertiser',
      head: () => 'Advertiser',
      cell: (p: AnalyticsResponseRowWithComparison) => (
        <AdvertiserWithColor
          advertiserName={p.group.advertiser_name}
          partnerKey={p.group.pk}
        />
      ),
      align: 'left',
      sortable: true,
      defaultDirection: 'asc',
      width: 300,
      flexGrow: 4
    },
    sorter: {
      key: 'advertiser',
      items: {
        sort: (p: AnalyticsResponseRowWithComparison) =>
          p.group.advertiser_name,
        dir: 'asc'
      }
    }
  }
};

const useAdvertisersEarnings = (
  filters: AnalyticsFilter[],
  metrics: Metric[],
  orderBy: AnalyticsOrderBy
) => {
  const { space } = useCurrentUser();
  const { range, compare } = useTimeframe();
  const query = useMemo<AnalyticsQuery>(
    () => ({
      range,
      compare,
      select: metrics,
      groupBy: ['advertiser_name', 'pk'],
      orderBy: [orderBy],
      filters
    }),
    [compare, metrics, orderBy, range, filters]
  );
  return useAnalyticsQueryV2(space.id, query);
};

const PAGE_SIZE = 20;

const useSummaryData = (filters: AnalyticsFilter[]) => {
  const { space } = useCurrentUser();
  const { range, compare } = useTimeframe();
  const query = useMemo<AnalyticsQuery>(() => {
    return {
      range,
      compare,
      select: [
        'c',
        'p',
        'rpm_net',
        'ctr',
        'commission_count_net',
        'commission_sum_net',
        'aov_net'
      ],
      filters
    };
  }, [compare, range, filters]);

  return useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    (response) =>
      response.rows[0]?.data || {
        c: { prev: 0, curr: 0 },
        p: { prev: 0, curr: 0 },
        rpm_net: { prev: 0, curr: 0 },
        commission_count_net: { prev: 0, curr: 0 },
        aov_net: { prev: 0, curr: 0 }
      }
  );
};

const useEarningsTimeseries = (
  filters: AnalyticsFilter[],
  range: ISOTimeRange,
  interval: AnalyticsInterval
) => {
  const { space } = useCurrentUser();
  const query = useMemo<AnalyticsQuery>(
    () => ({
      range,
      select: ['commission_sum_net'],
      groupBy: ['advertiser_name', 'pk'],
      interval,
      filters
    }),
    [interval, range, filters]
  );
  return useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    (response) => response.rows
  );
};

const useTrafficTimeseries = (
  filters: AnalyticsFilter[],
  range: ISOTimeRange,
  compare: AnalyticsQuery['compare'],
  interval: AnalyticsInterval
) => {
  const { space } = useCurrentUser();
  const query = useMemo<AnalyticsQuery>(
    () => ({
      range,
      compare,
      select: ['c', 'p', 'ctr'],
      interval,
      filters
    }),
    [compare, interval, range, filters]
  );
  return useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    (response) => response.rows
  );
};

// const KEY_FORMAT = 'x';
// const rowToTk = (row: AnalyticsResponseRowWithComparison) =>
//   moment(row.group.interval).format(KEY_FORMAT);

const advertiserKey = (row: AnalyticsResponseRowWithComparison) =>
  `${row.group.advertiser_name}---${row.group.pk}`;

const formatEarnings = (
  rows: AnalyticsResponseRowWithComparison[] = []
): Record<
  string,
  { advertiserName: string; partnerKey: string; earnings: number }
> => {
  return Object.fromEntries(
    rows.map((row) => {
      const advertiserName = row.group.advertiser_name;
      const partnerKey = row.group.pk;
      const key = advertiserKey(row);
      return [
        key,
        {
          advertiserName,
          partnerKey,
          earnings: row.data.commission_sum_net?.curr ?? 0
        }
      ];
    })
  );
};

const formatTraffic = (rows: AnalyticsResponseRowWithComparison[] = []) => {
  return {
    clicks: {
      curr: sumBy(rows, (d) => d.data.c?.curr ?? 0),
      prev: sumBy(rows, (d) => d.data.c?.prev ?? 0)
    },
    pageviews: {
      curr: sumBy(rows, (d) => d.data.p?.curr ?? 0),
      prev: sumBy(rows, (d) => d.data.p?.prev ?? 0)
    },
    ctr: {
      curr: sumBy(rows, (d) => d.data.ctr?.curr ?? 0),
      prev: sumBy(rows, (d) => d.data.ctr?.prev ?? 0)
    }
  };
};

export const DetailsTrendsBody = ({ url }: { url: string }) => {
  const { space, tz } = useCurrentUser();
  const currency = useSpaceCurrency();
  const { range, compare } = useTimeframe();
  const { interval, setInterval, options } = useIntervalSelectorState();
  const analyticsInterval = useAnalyticsInterval(interval);

  const sideNav = useSideNavProps(url);

  const baseFilters = useMemo<AnalyticsFilter[]>(
    () => [
      {
        field: 'page_url',
        condition: 'in',
        values: [url]
      }
    ],
    [url]
  );
  const uis = useContentPagesFilterUIs();
  const { filters, toggleProps, drawerProps } = useAnalyticsFilters(
    uis,
    {
      filters: baseFilters
    },
    {
      localStorageKey: FILTER_STATE_LOCAL_STORAGE_KEYS.content
    }
  );

  const allFilters = useMemo<AnalyticsFilter[]>(
    () => [...baseFilters, ...filters],
    [baseFilters, filters]
  );

  const [mode, setMode] = useModeQueryParam('chart-mode', [
    'clicks',
    'earnings'
  ]);

  const showComparison = useHasComparison();

  const {
    tableProps,
    columnSelectorProps,
    metrics,
    orderBy
  } = useAnalyticsTable(availableColumns, columnDefinitions, {
    pageSize: PAGE_SIZE,
    defaultSortColumn: 'commission_sum_net',
    defaultVisibleColumns,
    showComparison
  });

  const revisions = useMappedLoadingValue(
    usePage(space.id, url),
    (metadata) => {
      return metadata?.data.revisions ?? [];
    }
  );

  const [lastRevision = null] = useMappedLoadingValue(
    revisions,
    (rs) => rs[rs.length - 1] ?? null
  );

  const startMoment = moment(range.start);
  const endMoment = moment(range.end);

  const revisionFn = useCallback(
    (rs: IPageRevision[]) =>
      rs
        .filter((r) => !r.ignore)
        .map((r, index) => ({
          note: r.note,
          timestamp: toMoment(r.lastModifiedAt).tz(tz),
          index: index + 1
        }))
        .filter((r) => r.timestamp.isBetween(startMoment, endMoment)),
    [tz, startMoment, endMoment]
  );

  const validRevisions = useMappedLoadingValue(revisions, revisionFn, true);

  const [data, loadingData] = useMappedLoadingValue(
    useCombineLoadingValues3(
      useEarningsTimeseries(allFilters, range, analyticsInterval),
      useTrafficTimeseries(allFilters, range, compare, analyticsInterval),
      validRevisions
    ),
    ([earnings, traffic, revisions]) => {
      const advertisers = uniqBy(earnings, advertiserKey)
        .map(advertiserKey)
        .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));
      const earningsByTk = groupBy(earnings, (d) =>
        intervalToTk(d.group.interval, tz, interval.unit)
      );
      const trafficByTk = groupBy(traffic, (d) =>
        intervalToTk(d.group.interval, tz, interval.unit)
      );
      const revisionsByTk = groupBy(revisions, (r) =>
        r.timestamp.clone().format(INTERVALS_TO_TK[interval.unit])
      );

      const tks = sortBy(
        uniq([
          ...Object.keys(earningsByTk),
          ...Object.keys(trafficByTk),
          ...Object.keys(revisionsByTk)
        ])
      );
      const timeseries = tks.map(
        (tk): ChartData => {
          const earnings = formatEarnings(earningsByTk[tk]);
          const traffic = formatTraffic(trafficByTk[tk]);
          const revisions = revisionsByTk[tk] ?? [];
          return {
            tk,
            earnings,
            ...traffic,
            revisions
          };
        }
      );
      return { timeseries, advertisers };
    }
  );

  const [summaryData, loadingSummaryData] = useSummaryData(allFilters);

  const [byAdvertiser, loadingByAdvertiser] = useAdvertisersEarnings(
    allFilters,
    metrics,
    orderBy
  );

  return (
    <PageBody sideNav={sideNav} noTopPadding>
      <PageToolbar wrap>
        <FlexContainer>
          <PageToolbarTitle flex={2}>
            <DetailsPageTitle url={url} />
          </PageToolbarTitle>
          <FiltersToggleButton {...toggleProps} />
        </FlexContainer>
        <PageToolbarSection flex={2} justifyContent="flex-end">
          <TimeframePicker />
        </PageToolbarSection>
        <PageToolbarOtherRow>
          <FiltersDrawerWithDefaultTree {...drawerProps} marginTop={2} />
        </PageToolbarOtherRow>
      </PageToolbar>
      <Section>
        <Grid>
          <Summary
            summary={summaryData}
            advertisers={(byAdvertiser && byAdvertiser.rows) ?? []}
            rev={lastRevision}
            loading={loadingSummaryData || loadingByAdvertiser}
          />
          {!data || loadingData ? (
            <ChartCard
              heading="Performance over time"
              size="small"
              padding="dense"
            >
              <Loader height={CHART_HEIGHT + 42} />
            </ChartCard>
          ) : (
            <ChartCard
              heading="Performance over time"
              size="small"
              padding="dense"
              topRight={
                <IntervalSelector
                  value={interval}
                  onChange={setInterval}
                  options={options}
                />
              }
              maximizedContent={() => (
                <Chart
                  timeseries={data.timeseries}
                  advertisers={data.advertisers}
                  timeRange={range}
                  currency={currency}
                  mode={mode}
                  onModeChange={setMode}
                  height="auto"
                  unit={interval.unit}
                />
              )}
            >
              <Chart
                timeseries={data.timeseries}
                advertisers={data.advertisers}
                timeRange={range}
                currency={currency}
                mode={mode}
                onModeChange={setMode}
                height={CHART_HEIGHT}
                unit={interval.unit}
              />
            </ChartCard>
          )}
        </Grid>
      </Section>
      <Section>
        <CanvasBar>
          <SubHeading>Advertiser performance</SubHeading>
          <ColumnSelector {...columnSelectorProps} />
        </CanvasBar>
        {!byAdvertiser && loadingByAdvertiser && <PaperLoader height={300} />}
        {byAdvertiser && (
          <>
            <RowsRenderer
              {...tableProps}
              renderHead
              headProps={{
                sticky: true,
                offset: DEFAULT_OFFSET
              }}
              rows={byAdvertiser.rows}
              rowToKey={(d) => d.group.advertiser_name}
            />
          </>
        )}
      </Section>
    </PageBody>
  );
};
