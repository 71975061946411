import { Metric } from '../../../services/analyticsV2/metrics';

export const LINKED_DOMAINS_REPORT_COLUMNS: Metric[] = [
  'p',
  'v',
  'c',
  's',
  'ctr',
  'epc_net',
  'avg_rate_net',
  'view_ratio',
  'order_count_net',
  'order_count_gross',
  'quantity_net',
  'aov_net',
  'rpm_net',
  'commission_sum_net',
  'gmv_sum_net'
];

export const LINKS_REPORT_COLUMNS: Metric[] = [
  'count_uniq_link_occ',
  'p',
  'v',
  'c',
  's',
  'ctr',
  'epc_net',
  'avg_rate_net',
  'view_ratio',
  'order_count_net',
  'order_count_gross',
  'quantity_net',
  'aov_net',
  'rpm_net',
  'commission_sum_net',
  'gmv_sum_net'
];

export const LINKS_ISSUES_COLUMNS: Metric[] = [
  'count_uniq_link_occ',
  'p',
  'v',
  'c',
  's',
  'ctr',
  'epc_net',
  'avg_rate_net',
  'view_ratio',
  'order_count_net',
  'order_count_gross',
  'quantity_net',
  'aov_net',
  'rpm_net',
  'commission_sum_net',
  'gmv_sum_net'
];
