import {
  Card,
  Step,
  StepButton,
  StepContent,
  StepLabel,
  Stepper
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { default as React, useCallback } from 'react';
import { styled } from '../../../../../emotion';
import { useSnackbar } from '../../../../../hooks/useSnackbar';
import { Centered } from '../../../../../layout/Centered';
import { FlexContainer } from '../../../../../layout/Flex';
import { LimitedWidth } from '../../../../../layout/PageBody';
import { useNumberQueryParam, useRoutes } from '../../../../../routes';
import { useCurrentUser } from '../../../../../services/currentUser';
import { CampaignDraftForm } from './CampaignDraftForm';
import { CampaignProposalForm } from './CampaignProposalForm';
import {
  proposeSegmentCampaign,
  saveSegmentCampaignDraft,
  SegmentCampaignDraft
} from '../../../service/segment-campaign-form';
import { SegmentCampaign } from '../../../service/segment-campaign';

const CreatorBody = styled(Card)`
  display: grid;
  grid-template-columns: 230px 1fr;
  grid-column-gap: ${({ theme }) => theme.spacing(4)}px;
  margin-top: ${({ theme }) => theme.spacing(4)}px;
`;

const steps = [
  {
    label: 'Draft a campaign',
    description:
      'Define your campaign and invite your advertiser to participate'
  },
  {
    label: 'Review and propose',
    description: 'Review your campaign and make a proposal to selected partner.'
  }
];

interface SegmentCampaignFormStepsProps {
  step: number;
  back: () => void;
  campaign: SegmentCampaign;
  saveDraft: (
    initialValues: SegmentCampaignDraft,
    campaign: SegmentCampaign
  ) => void;
  proposeDraft: (campaign: SegmentCampaign) => void;
}

const SegmentCampaignFormSteps: React.FC<SegmentCampaignFormStepsProps> = ({
  step,
  back,
  campaign,
  saveDraft,
  proposeDraft
}) => {
  switch (step) {
    case 0:
      return <CampaignDraftForm campaign={campaign} proceed={saveDraft} />;
    case 1:
      return (
        <CampaignProposalForm
          campaign={campaign}
          proceed={proposeDraft}
          back={back}
        />
      );
    default:
      return <Centered>Unknown step</Centered>;
  }
};

interface SegmentCampaignFormProps {
  campaign: SegmentCampaign;
}

export const SegmentCampaignCreateForm: React.FC<SegmentCampaignFormProps> = ({
  campaign
}) => {
  const { goTo, ROUTES } = useRoutes();
  const { enqueueSnackbar } = useSnackbar();
  const { space, id: userId } = useCurrentUser();
  const [activeStep, setActiveStep] = useNumberQueryParam('step', 0);

  const back = useCallback(() => {
    setActiveStep(Math.max(activeStep - 1, 0));
  }, [setActiveStep, activeStep]);

  const saveDraft = useCallback(
    async (initialValues: SegmentCampaignDraft, campaign: SegmentCampaign) => {
      await saveSegmentCampaignDraft(
        space,
        campaign.id,
        userId,
        initialValues,
        campaign
      );
      setActiveStep(1);
    },
    [setActiveStep, space, userId]
  );

  const proposeDraft = useCallback(
    async (campaign: SegmentCampaign) => {
      await proposeSegmentCampaign(campaign.id, userId);
      enqueueSnackbar(`Campaign "${campaign.name}" proposed`, {
        variant: 'success'
      });
      goTo(ROUTES.campaigns?.segment.active?.url());
    },
    [ROUTES.campaigns?.segment.active, enqueueSnackbar, goTo, userId]
  );

  return (
    <LimitedWidth width={1000}>
      <CreatorBody>
        <FlexContainer
          direction="column"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <div>
            <Typography variant="h6">
              <strong>Create your campaign</strong>
            </Typography>
            <Stepper
              activeStep={activeStep}
              orientation="vertical"
              style={{
                backgroundColor: 'transparent',
                padding: `12px 0`
              }}
            >
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepButton onClick={() => setActiveStep(index)}>
                    <StepLabel>{step.label}</StepLabel>
                  </StepButton>
                  <StepContent>
                    <Typography variant="caption" color="textSecondary">
                      {step.description}
                    </Typography>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </div>
        </FlexContainer>
        <SegmentCampaignFormSteps
          step={activeStep}
          back={back}
          campaign={campaign}
          saveDraft={saveDraft}
          proposeDraft={proposeDraft}
        />
      </CreatorBody>
    </LimitedWidth>
  );
};
