import {
  Button,
  IconButton,
  ListSubheader,
  MenuItem,
  Paper,
  Select,
  TextField
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { capitalize, groupBy, sortBy } from 'lodash';
import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import { Plus, X } from 'react-feather';
import { IChannel } from '../../../../../domainTypes/channels';
import { Doc } from '../../../../../domainTypes/document';
import { IDomain } from '../../../../../domainTypes/space';
import {
  AutocompleteSingleFreeSolo,
  ConnectionId,
  WithShape
} from '../components';
import { LINK_GENERATOR_BASIC_OPTIONS } from '../constants';
import {
  getStableRandomColor,
  styled,
  useChannels,
  useCurrentUser,
  useCustomDimensionGeneratorOptions,
  useFeatureEnabled,
  useSendAnalyticsQuery
} from '../service';
import {
  AnalyticsQuery,
  LinkGeneratorChannelOption,
  LinkGeneratorOption,
  LinkGeneratorOptionGroup,
  LinkGeneratorParameter,
  LinkParameter
} from '../types';

const withoutProtocol = (url: string) => {
  return url.replace(/^https?:\/\//, '');
};

const LinkParametersGrid = styled<'div', { showActionsColumn: boolean }>('div')`
  display: grid;
  width: 100%;
  grid-template-columns: ${(p) =>
    p.showActionsColumn ? '165px 1fr 18px' : '165px 1fr'};
  grid-column-gap: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

const AddParameterWrapper = styled('div')`
  display: flex;
  justify-content: flex-start;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;

const toChannels = (domains: IDomain[], channels: Doc<IChannel>[]) => {
  const siteOptions: LinkGeneratorChannelOption[] = domains
    .filter((d) => d.active)
    .map((domain) => ({
      value: domain.channelId,
      labelString: domain.url,
      label: (
        <WithShape
          large
          color={getStableRandomColor(withoutProtocol(domain.url))}
        >
          <span
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {withoutProtocol(domain.url)}{' '}
            {domain.subIdDefaultPrefix && (
              <ConnectionId>{domain.subIdDefaultPrefix}</ConnectionId>
            )}
          </span>
        </WithShape>
      ),
      group: 'sites'
    }));

  const channelOptions: LinkGeneratorChannelOption[] = channels.map(
    (channel) => ({
      value: channel.id,
      labelString: channel.data.name,
      label: (
        <WithShape large color={getStableRandomColor(channel.data.channelId)}>
          <span
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {channel.data.name}{' '}
            <ConnectionId>{channel.data.subIdDefaultPrefix}</ConnectionId>
          </span>
        </WithShape>
      ),
      group: channel.data.type
    })
  );

  const allOptions = [...siteOptions, ...channelOptions];

  const optionGroup: LinkGeneratorOption = {
    value: 'channel',
    label: 'Channel',
    inputType: 'channel',
    labelString: 'Channel',
    group: LinkGeneratorOptionGroup.Standard,
    options: allOptions
  };

  return optionGroup;
};

const useLinkGeneratorOptions = () => {
  const hasChannels = useFeatureEnabled('CHANNELS');
  const { space } = useCurrentUser();
  const [channels = []] = useChannels(space.id);
  const customDimensionsOptions = useCustomDimensionGeneratorOptions();

  return useMemo(() => {
    if (!space) {
      return LINK_GENERATOR_BASIC_OPTIONS;
    }

    let options: LinkGeneratorOption[] = [];
    const channelOptions = toChannels(space.domains, channels);

    if (hasChannels) {
      options = options.concat(channelOptions);
    }

    options = options.concat(customDimensionsOptions);

    const allOptions = [...options, ...LINK_GENERATOR_BASIC_OPTIONS];

    return allOptions;
  }, [space, channels, hasChannels, customDimensionsOptions]);
};

export const LinkParametersForm = ({
  loading,
  parameters,
  setParameters
}: {
  loading: boolean;
  parameters: LinkParameter[];
  setParameters: (parameters: LinkParameter[]) => void;
}) => {
  const sendAnalyticsQuery = useSendAnalyticsQuery();
  const options = useLinkGeneratorOptions();
  const hasChannels = useFeatureEnabled('CHANNELS');
  const hasLinkWrapper = useFeatureEnabled('LINK_WRAPPER');

  const unusedParameters = options.filter(
    (option) => !parameters.some((p) => p.key === option.value)
  );

  return (
    <div style={{ width: '100%' }}>
      {parameters.map((parameter, index) => {
        const selectedKey = parameter.key;
        const config = options.find((o) => o.value === selectedKey);

        if (!config) {
          return null;
        }

        const shouldHideAndIsChannel =
          config.inputType === 'channel' && !hasChannels;
        const hideX =
          !hasLinkWrapper ||
          ['subid', 'channel'].includes(parameter.key) ||
          shouldHideAndIsChannel;

        return (
          <LinkParametersGrid
            key={index}
            showActionsColumn={hasLinkWrapper && !hideX}
            style={{ display: shouldHideAndIsChannel ? 'none' : 'grid' }}
          >
            <Select
              style={{ padding: 0 }}
              value={parameter.key}
              variant="outlined"
              disabled={
                loading ||
                !hasChannels ||
                !hasLinkWrapper ||
                parameter.key === 'channel' ||
                parameter.key === 'subid'
              }
              label="Parameter"
              onChange={(e) => {
                const newParameters = parameters.slice();
                newParameters[index].key = e.target
                  .value as LinkGeneratorParameter;

                // Also reset the value when changed
                if (e.target.value !== selectedKey) {
                  newParameters[index].value = '';
                }

                setParameters(newParameters);
              }}
            >
              {Object.keys(groupBy(options, 'group')).map((group, index) => [
                <ListSubheader
                  key={index}
                  style={{
                    backgroundColor: 'white',
                    pointerEvents: 'none'
                  }}
                >
                  {group}
                </ListSubheader>,
                options
                  .filter((option) => {
                    return option.group === group;
                  })
                  .map((option, index) => (
                    <MenuItem
                      key={index}
                      value={option.value}
                      disabled={
                        option.value === 'channel' || option.value === 'subid'
                      }
                    >
                      {option.label}
                    </MenuItem>
                  ))
              ])}
            </Select>
            {config.inputType === 'select' ? (
              <Select
                value={parameter.value}
                variant="outlined"
                disabled={loading}
                label={config.label}
                onChange={(e) => {
                  const newParams = [...parameters];
                  newParams[index] = {
                    ...parameter,
                    value: e.target.value as string
                  };
                  setParameters(newParams);
                }}
              >
                {sortBy(config.options, (o) => o.label).map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    <WithShape large color={getStableRandomColor(option.value)}>
                      {option.label}
                    </WithShape>
                  </MenuItem>
                ))}
              </Select>
            ) : config.inputType === 'channel' ? (
              <Autocomplete
                options={config.options}
                value={config.options.find((o) => o.value === parameter.value)}
                disabled={loading}
                onChange={(_, newValue) => {
                  const newParams = [...parameters];
                  newParams[index] = {
                    ...parameter,
                    value: newValue?.value || ''
                  };
                  setParameters(newParams);
                }}
                size="medium"
                getOptionSelected={(
                  option: LinkGeneratorChannelOption,
                  value
                ) => option.value === value.value}
                getOptionLabel={(option) => option.labelString}
                renderOption={(props, option) => (
                  <div {...props}>{props.label}</div>
                )}
                PaperComponent={(props) => <Paper {...props} elevation={6} />}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select site or channel"
                    label={config.label}
                    variant="outlined"
                  />
                )}
                groupBy={(option) => capitalize(option.group)}
              />
            ) : config.inputType === 'autocomplete' ? (
              <AutocompleteSingleFreeSolo
                label={config.label}
                type="text"
                value={parameter.value}
                disabled={loading}
                fullWidth
                onChange={(newValue) => {
                  const newParams = [...parameters];
                  newParams[index] = {
                    ...parameter,
                    value: newValue
                  };
                  setParameters(newParams);
                }}
                options={async () => {
                  const q: AnalyticsQuery = {
                    range: {
                      start: moment().subtract(30, 'days').format('YYYY-MM-DD'),
                      end: moment().format('YYYY-MM-DD')
                    },
                    select: ['c'],
                    groupBy: [config.field],
                    orderBy: [{ field: 'c', direction: 'ASC' }],
                    paginate: {
                      page: 1,
                      limit: 100
                    }
                  };
                  return sendAnalyticsQuery(q).then((r) => {
                    return r.rows
                      .map((row) => row.group[config.field] || '')
                      .sort();
                  });
                }}
              />
            ) : (
              <TextField
                type="text"
                value={parameter.value}
                label={config.label}
                autoFocus={index > 1}
                disabled={loading}
                placeholder="spring-newsletter"
                fullWidth
                onChange={(e) => {
                  const newParams = [...parameters];
                  newParams[index] = {
                    ...parameter,
                    value: e.target.value
                  };
                  setParameters(newParams);
                }}
                variant="outlined"
              />
            )}
            {hasLinkWrapper && !hideX && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'right'
                }}
              >
                <IconButton
                  size="small"
                  disabled={loading}
                  style={{ opacity: 0.5 }}
                  onClick={() => {
                    const newParams = [...parameters];
                    newParams.splice(index, 1);
                    setParameters(newParams);
                  }}
                >
                  <X size={18} />
                </IconButton>
              </div>
            )}
          </LinkParametersGrid>
        );
      })}

      {hasLinkWrapper && unusedParameters.length > 0 ? (
        <AddParameterWrapper>
          <Button
            style={{ padding: '0' }}
            onClick={() => {
              const newParams = [...parameters];

              // Find the first option which
              // isn't already used in the params
              const option = options.find(
                (o) => !newParams.some((p) => p.key === o.value)
              );

              if (!option) {
                return;
              }

              newParams.push({ key: option.value, value: '' });
              setParameters(newParams);
            }}
          >
            <Plus size={18} /> &nbsp; Add parameter
          </Button>
        </AddParameterWrapper>
      ) : (
        <div style={{ height: 32 }} />
      )}
    </div>
  );
};
