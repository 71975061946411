import { TransactionStatusFilterDefinition } from '../../../../../../domainTypes/filters';
import { BASIC_MODES, FilterMenu } from '../../Menus/FilterMenu';
import React, { useCallback, useState } from 'react';
import {
  EnumSelectorMenu,
  useCollectionFilterState
} from '../../Menus/Selector';
import {
  TRANSACTION_STATUSES,
  TRANSACTION_UI_CONFIG,
  TransactionStatus
} from '../../../../../../domainTypes/performance';
import pluralize from 'pluralize';
import { css } from '../../../../../../emotion';
import { AnalyticsFilterMenuComponent } from '../../../FilterUI';

// NOTE: to get rid of `readonly` modifier.
const options = [...TRANSACTION_STATUSES];

export const TransactionStatusMenu: AnalyticsFilterMenuComponent<TransactionStatusFilterDefinition> = ({
  definition,
  onSave,
  context,
  isFirst
}) => {
  const [mode, setMode] = useState(BASIC_MODES[0].value);
  const {
    values,
    handleSave,
    handleFocus,
    handleToggle,
    isSaveEnabled
  } = useCollectionFilterState<
    TransactionStatus,
    TransactionStatusFilterDefinition
  >(definition, onSave);

  const toLabel = useCallback((status: TransactionStatus) => {
    const config = TRANSACTION_UI_CONFIG[status];
    return (
      <span
        className={css((t) => ({
          display: 'inline-block',
          fontSize: t.typography.caption.fontSize,
          borderRadius: '999px',
          padding: `${t.spacing(0.5)}px ${t.spacing(1.5)}px`,
          backgroundColor: config.color,
          color: config.text
        }))}
      >
        {config.label}
      </span>
    );
  }, []);

  return (
    <FilterMenu>
      <FilterMenu.Header name={'transaction status'} isFirst={isFirst}>
        <FilterMenu.ModeSelector
          modes={BASIC_MODES}
          mode={mode}
          setMode={setMode}
        />
      </FilterMenu.Header>
      <FilterMenu.Body>
        <EnumSelectorMenu
          label="transaction status"
          options={options}
          selectedValues={values}
          onToggle={handleToggle}
          onFocus={handleFocus}
          toLabel={toLabel}
          range={context.baseQuery.range}
          analyticsField={'sale_status'}
          queryFilters={context.baseQuery.filters}
          metric={'commission_sum_net'}
        />
      </FilterMenu.Body>
      <FilterMenu.Footer
        description={
          <>
            <strong>Transaction status</strong> of the commission. Disabled
            options means no commissions of that status in the current view.
          </>
        }
      >
        <FilterMenu.SaveButton
          disabled={!isSaveEnabled}
          onSave={handleSave}
          label={`Filter by ${pluralize(
            'transaction status',
            values.length,
            true
          )}`}
        />
      </FilterMenu.Footer>
    </FilterMenu>
  );
};
