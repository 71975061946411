import { ManualCampaign, RunningManualCampaign } from './manual-campaign';
import { RunningSegmentCampaign, SegmentCampaign } from './segment-campaign';
import { Moment } from 'moment-timezone';

export interface CampaignTimeframe {
  start: Moment;
  end: Moment;
}

export function isCampaignRunning(
  campaign: ManualCampaign
): campaign is RunningManualCampaign;
export function isCampaignRunning(
  campaign: SegmentCampaign
): campaign is RunningSegmentCampaign;
export function isCampaignRunning(
  campaign: SegmentCampaign | ManualCampaign
): campaign is RunningManualCampaign | RunningSegmentCampaign;
export function isCampaignRunning(
  campaign: ManualCampaign | SegmentCampaign
): boolean {
  switch (campaign.type) {
    case 'segment':
      return campaign.status === 'running';
    case 'manual':
      return campaign.status === 'running';
  }
}
