import {
  AnalyticsColumnDefinitions,
  useAnalyticsTable
} from '../../../../components/analytics_v2/Table';
import { Centered } from '../../../../layout/Centered';
import { Loader } from '../../../../components/Loader';
import { RowsRenderer } from '../../../../components/GroupableList';
import React, { useMemo } from 'react';
import { useAnalyticsQueryV2 } from '../../../../services/analyticsV2/query';
import { useCurrentUser } from '../../../../services/currentUser';
import {
  AnalyticsFilter,
  AnalyticsOrderBy,
  AnalyticsQuery,
  AnalyticsResponseRowWithComparison,
  ISOTimeRange
} from '../../../../domainTypes/analytics_v2';
import { Paper } from '@material-ui/core';
import { DeviceIconWithLabel } from '../../../../components/DeviceIcon';
import { Device } from '../../../../domainTypes/tracking';
import { Metric } from '../../../../services/analyticsV2/metrics';

const customColumns = ['device'] as const;
const metrics = [
  'p',
  'c',
  'ctr',
  'epc_net',
  'quantity_net',
  'commission_sum_net',
  'gmv_sum_net'
] as const;
type CustomColumns = typeof customColumns[number];
type Column = CustomColumns | Metric;

const columnDefinitions: AnalyticsColumnDefinitions<CustomColumns> = {
  device: {
    column: {
      key: 'device',
      head: () => 'Device',
      cell: (p: AnalyticsResponseRowWithComparison) => {
        return <DeviceIconWithLabel device={p.group.device as Device} />;
      },
      align: 'left',
      sortable: true,
      width: 200,
      flexGrow: 2
    },
    sorter: {
      key: 'device',
      items: {
        sort: (p: AnalyticsResponseRowWithComparison) => p.group.device,
        dir: 'asc'
      }
    }
  }
};

const availableColumns: Column[] = [...customColumns, ...metrics];

const PAGE_SIZE = 10;

const useDevicesMetrics = (
  range: ISOTimeRange,
  compare: AnalyticsQuery['compare'],
  filters: AnalyticsFilter[],
  metrics: Metric[],
  orderBy: AnalyticsOrderBy
) => {
  const { space } = useCurrentUser();
  const query = useMemo<AnalyticsQuery>(() => {
    return {
      range,
      compare,
      select: metrics,
      groupBy: ['device'],
      orderBy: [orderBy],
      filters,
      paginate: {
        page: 1,
        limit: PAGE_SIZE
      }
    };
  }, [range, compare, metrics, orderBy, filters]);

  return useAnalyticsQueryV2(space.id, query);
};

export const DevicesTable = ({
  filters,
  compare,
  range
}: {
  filters: AnalyticsFilter[];
  range: ISOTimeRange;
  compare: AnalyticsQuery['compare'];
}) => {
  const { tableProps, orderBy, metrics } = useAnalyticsTable(
    availableColumns,
    columnDefinitions,
    {
      pageSize: PAGE_SIZE,
      defaultSortColumn: 'c',
      sortQueryParamName: 'sort-devices',
      showComparison: !!compare
    }
  );
  const [data, loading] = useDevicesMetrics(
    range,
    compare,
    filters,
    metrics,
    orderBy
  );
  if (!data || loading) {
    return (
      <Paper>
        <Centered height={350}>
          <Loader size={16} />
        </Centered>
      </Paper>
    );
  }

  return (
    <RowsRenderer
      {...tableProps}
      rows={data.rows}
      renderHead
      rowToKey={(d) => d.group.country}
    />
  );
};
