import { useCampaignTotals } from '../../service/totals';
import { CampaignReportToolbar } from './CampaignReportToolbar';
import { css } from '../../../../emotion';
import {
  CampaignClicksCard,
  CampaignEarningsCard,
  CampaignFlatSpendCard,
  CampaignGmvCard,
  CampaignImpressionsCard,
  CampaignPageviewsCard,
  CampaignTotalCardsContainer
} from '../CampaignTotalCards';
import { ManualCampaignTables } from '../CampaignTables';
import React from 'react';
import { RunningCampaignProgress } from '../RunningCampaignProgress';
import { CampaignSummaryLayout } from '../CampaignSummaryLayout';
import { useCampaignMetricSelectorState } from './CampaignMetricSelector';
import { RunningManualCampaign } from '../../service/manual-campaign';
import { ManualCampaignTimeseries } from '../timeseries/ManualCampaignTimeseries';

export const RunningCampaignReport: React.FC<{
  campaign: RunningManualCampaign;
}> = ({ campaign }) => {
  const totals = useCampaignTotals(campaign);
  const [metric, setMetric] = useCampaignMetricSelectorState(campaign);

  return (
    <>
      <CampaignReportToolbar
        campaign={campaign}
        metric={metric}
        setMetric={setMetric}
      />
      <div
        className={css((t) => ({
          display: 'flex',
          flexDirection: 'column',
          rowGap: t.spacing(4)
        }))}
      >
        <CampaignTotalCardsContainer>
          <CampaignFlatSpendCard campaign={campaign} totals={totals} />
          <CampaignGmvCard campaign={campaign} totals={totals} />
          <CampaignEarningsCard campaign={campaign} totals={totals} />
          <CampaignClicksCard campaign={campaign} totals={totals} />
          <CampaignPageviewsCard campaign={campaign} totals={totals} />
          <CampaignImpressionsCard campaign={campaign} totals={totals} />
        </CampaignTotalCardsContainer>
        <ManualCampaignTimeseries
          campaign={campaign}
          metric={metric}
          summary={
            <CampaignSummaryLayout totals={totals}>
              {(totalValues) => {
                return (
                  <RunningCampaignProgress
                    campaign={campaign}
                    totalValues={totalValues}
                  />
                );
              }}
            </CampaignSummaryLayout>
          }
        />
        <ManualCampaignTables campaign={campaign} metric={metric} />
      </div>
    </>
  );
};
