import { SegmentCampaign } from '../service/segment-campaign';
import React from 'react';
import { Centered } from '../../../layout/Centered';
import { Paper, Typography } from '@material-ui/core';
import { LazyProfileLabel } from '../../../components/ProfileLabel';
import { FlexContainer } from '../../../layout/Flex';

export const SegmentCampaignProposed: React.FC<{
  campaign: SegmentCampaign;
}> = ({ campaign }) => {
  const maId = campaign.partner?.id;
  return (
    <Paper style={{ width: '80%', margin: '0 auto' }}>
      <Centered height={300}>
        <Typography variant="body1" color="textSecondary">
          <FlexContainer>
            Campaign <strong>{campaign.name}</strong> awaits{' '}
            {maId ? <LazyProfileLabel maId={maId} nameVariant="body1" /> : null}{' '}
            approval
          </FlexContainer>
        </Typography>
      </Centered>
    </Paper>
  );
};
