import DateFnsUtils from '@date-io/moment'; // choose your lib
import { CssBaseline, Dialog, DialogContent } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SnackbarProvider } from 'notistack';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import './00_init';
import { AddDomainModal } from './components/AddDomainModal';
import { QuickstartDialog } from './components/QuickstartDialog';
import { Redirector } from './components/Redirector';
import { hasSubscriptionExpired, hasTrialExpired } from './domainTypes/billing';
import { IDomainCreateParams } from './domainTypes/space';
import { ICurrentUser } from './domainTypes/user';
import { styled } from './emotion';
import {
  CheckoutForm,
  CHECKOUT_FORM_MIN_WIDTH
} from './features/Billing/components/CheckoutForm';
import { CheckoutSuccess } from './features/Billing/components/CheckoutSuccess';
import { StripeProvider } from './features/Billing/components/StripeProvider';
import { PageSubscriptionExpired } from './features/Billing/pages/SubscriptionExpired';
import { InitializingAccount, SigningOut } from './features/CreatingNewAccount';
import { Login } from './features/Login';
import { PageMaintenance } from './features/Maintenance/pages';
import { useMaintenanceSettings } from './features/Maintenance/service';
import { NotificationsDrawer } from './features/Notifications/components/Drawer';
import { NotificationsDrawerProvider } from './features/Notifications/hooks/useNotificationsDrawer';
import { useAffiliateCookieObserver } from './features/Settings/pages/AffiliateProgram/service';
import { SubscriptionCenter } from './features/SubscriptionCenter';
import { PageAcceptInvitation } from './features/Users/pages/AcceptInvitation';
import { BackgroundJobsProvider } from './hooks/useBackgroundJobs';
import { useDialogState } from './hooks/useDialogState';
import { HotkeysProvider } from './hooks/useHotkeys';
import { useIdentifyUser } from './hooks/useIdentifyUser';
import { SideNavProvider } from './hooks/useSideNav';
import { useSpaceSwitcher } from './hooks/useSpaceSwitcher';
import { TopNav } from './layout/TopNav';
import './polyfills';
import { useRoutes } from './routes';
import { AnalyticsV2CacheProvider } from './services/analyticsV2/cache';
import { signOut, useLogin } from './services/auth';
import { getCurrentSpace } from './services/currentUser';
import { ExperimentalProvider } from './services/experimental';
import { callFirebaseFunction } from './services/firebaseFunctions';
import { PgProductsCacheProvider } from './services/products/cache';
import { SalesCacheProvider } from './services/sales/cache';
import { createScan } from './services/scan';
import { TagsCacheProvider } from './services/tags/cache';
import { getPostLoginRedirect } from './services/user';
import { IPage, PAGES } from './sitemap';
import { AppThemeProvider } from './themes';
import { CF } from './versions';

const Root = styled('div')`
  display: flex;
  min-height: 100vh;
`;

const AppContent = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${(p) => p.theme.custom.colors.background.main};
`;

const NoAccessForExternalSupport = () => {
  return (
    <div style={{ padding: '24px', textAlign: 'center', margin: '100px auto' }}>
      <strong>No access.</strong> This account primarily receives support via
      Slack.
      <br />
      Remove the Space ID from the URL and try again.
    </div>
  );
};

const hasTrueQueryParam = (param: string) =>
  !!window.location.search.match(`${param}=true`);

const INITITAL_ONBOARDING_STATE = {
  onboarded: false,
  onboardingDialogOpen: false
};
const useOnboarding = (
  currentUser: null | ICurrentUser
): [boolean, () => void] => {
  const [{ onboarded, onboardingDialogOpen }, setState] = useState(
    INITITAL_ONBOARDING_STATE
  );

  useEffect(() => {
    if (!currentUser) {
      setState(INITITAL_ONBOARDING_STATE);
      return;
    }

    const hasVerifiedSites = currentUser.space.domains.find((d) => d.verified);

    if (currentUser && !hasVerifiedSites && !onboarded) {
      setState({ onboarded: true, onboardingDialogOpen: false });
    }

    // eslint-disable-next-line
  }, [currentUser]);

  return [
    onboardingDialogOpen,
    () => {
      setState({ onboardingDialogOpen: false, onboarded });
    }
  ];
};

const mapPagesToRoutes = (page: IPage) => {
  return (
    <React.Fragment key={page.path}>
      {page.redirectFrom &&
        page.redirectFrom.map((p) => (
          <Route
            path={p}
            key={p}
            exact
            render={() => <Redirect to={page.path} />}
          />
        ))}
      {page.redirect && (
        <Route
          key={page.path}
          path={page.path}
          exact
          render={(props) => (
            <Redirector toPath={(routes) => page.redirect!(props, routes)} />
          )}
        />
      )}

      {page.render && (
        <Route
          key={page.path}
          path={page.path}
          exact
          render={(props) => page.render!(props)}
        />
      )}
    </React.Fragment>
  );
};

/*
const JobListener = () => {
  const currentUser = useCurrentUser();
  const { listenForJobChanges } = useJobsListener();
  const { defaultOption } = useStandardOptions();
  const [timeframe] = useTimeframeFromUrl(defaultOption.value);
  const flushCache = useFlushAnalyticsV2Cache();

  useEffect(() => {
    return listenForJobChanges({
      timeframe,
      onChange: () => {
        flushSalesCacheForSpace(currentUser.space.id);
        flushCache(currentUser.space.id);
      }
    });
  }, [listenForJobChanges, timeframe, currentUser, flushCache]);
  return null;
};
*/

const MainApp = () => {
  useAffiliateCookieObserver();
  const [
    maintenanceSettings,
    maintenanceSettingsLoading
  ] = useMaintenanceSettings();
  const { getPath, goTo, ROUTES } = useRoutes();
  const { currentlySwitchedSpaceId } = useSpaceSwitcher();
  const { currentUser, initialising, creatingNewAccount, error } = useLogin(
    currentlySwitchedSpaceId
  );

  const [onboardingDialogOpen, onCloseOnboardingDialog] = useOnboarding(
    currentUser
  );

  const {
    dialogOpen: checkoutSuccessDialogOpen,
    closeDialog: closeCheckoutSuccessDialog
  } = useDialogState(hasTrueQueryParam('checkout_success'));

  const [checkout, setCheckout] = useState(
    hasTrueQueryParam('checkout') && !checkoutSuccessDialogOpen
  );

  const [ignoreSubscriptionExpired, setIgnoreSubscriptionExpired] = useState(
    false
  );

  const onVerifyDomain = (domain: string) => {
    return callFirebaseFunction(CF.space.addDomain, {
      spaceId: getCurrentSpace().id,
      url: domain
    } as IDomainCreateParams);
  };

  const onScan = (domains: string[]): Promise<void> => {
    return createScan(domains, 'DOMAIN').then((doc) => {
      goTo(`/scans/${doc.id}`);
    });
  };

  // Use identify user for third-party scripts like Helpscout and Customer.io
  useIdentifyUser({
    currentUser,
    ready: !initialising && !creatingNewAccount && !checkout
  });

  const currentPath = getPath();

  if (currentPath.indexOf('/logout') === 0) {
    signOut();
    return <SigningOut />;
  }

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (initialising || maintenanceSettingsLoading) {
    return <InitializingAccount />;
  }

  if (currentPath.indexOf('/subscription-center') !== -1) {
    // If person is logged in, send them to the notifications page
    if (currentUser) {
      goTo(ROUTES.settings.notifications.url());
      return null;
    }
    return <SubscriptionCenter />;
  }

  // access by going to /accept-invitation/INVIATION_ID
  if (currentPath.startsWith('/accept-invitation')) {
    return (
      <PageAcceptInvitation
        currentUser={currentUser}
        invitationId={currentPath.replace('/accept-invitation', '')}
      />
    );
  }

  if (currentPath.indexOf('/signup') !== -1 || !currentUser) {
    return <Login creatingNewAccount={creatingNewAccount} />;
  }

  const { space } = currentUser;
  const { billing } = space;

  const now = Date.now();
  const showSubscriptionModal = hasTrialExpired(billing, now) || checkout;

  if (
    !ignoreSubscriptionExpired &&
    hasSubscriptionExpired(billing, now) &&
    !checkout
  ) {
    return (
      <PageSubscriptionExpired
        space={space}
        onIgnore={() => setIgnoreSubscriptionExpired(true)}
        onGoToCheckout={() => setCheckout(true)}
      />
    );
  }

  if (
    maintenanceSettings &&
    maintenanceSettings.data.enabled &&
    !hasTrueQueryParam('ignore_maintenance_page')
  ) {
    return <PageMaintenance settings={maintenanceSettings.data} />;
  }

  // Slight adjustment to limit support to
  // just creator-level and midmarket accounts
  const EVERGREEN_USERS = [
    'X3exbxDieVRQzSnSiEjciUOoHDw2', // glory
    'PSBTw2odqPOpzuD6yRmS3jPgzSg2', // emma
    'd5huFhBJgkVw8ZUUA3jLeXV2Lii2' // denise
  ];

  const RESTRICTED_SPACES = [
    'WOO2CVcgO',
    '9pArtqPZ4',
    'UoEcF-b67',
    'YAyO7UsW3',
    'ddM7Qq1gR',
    'redVmDGfB',
    'sKa82EYZ0',
    '6UAIvKcsv',
    'In51Dh00U',
    'ouT4h-sRrm',
    'myG01fOUe',
    'OptZUFHIA',
    'taBO0Dut5',
    'Rl6phtQg5',
    'ReCuaaQp9',
    'Nw5uK0IhJ',
    'ma1LBIjvH',
    'uSaT04rDR',
    'F0Rb4z5os',
    'Ba4C0h-8u',
    'ApTolg1UT'
  ];

  if (
    EVERGREEN_USERS.indexOf(currentUser.id) !== -1 &&
    RESTRICTED_SPACES.indexOf(currentlySwitchedSpaceId) !== -1
  ) {
    return <NoAccessForExternalSupport />;
  }

  const homePath = getPostLoginRedirect(ROUTES, currentUser);

  return (
    <Root>
      <CssBaseline />
      <AppContent>
        <TopNav />
        <Route path="/" exact render={() => <Redirect to={homePath} />} />
        {PAGES.map(mapPagesToRoutes)}
      </AppContent>
      <Dialog
        open={showSubscriptionModal}
        maxWidth="xl"
        scroll="body"
        style={{ backdropFilter: 'blur(8px)' }}
      >
        <DialogContent style={{ width: CHECKOUT_FORM_MIN_WIDTH }}>
          <CheckoutForm onSuccess={() => setCheckout(false)} />
        </DialogContent>
      </Dialog>
      <Dialog
        maxWidth="xl"
        open={checkoutSuccessDialogOpen}
        PaperProps={{
          elevation: 1
        }}
        onClose={() => {
          goTo('/');
          closeCheckoutSuccessDialog();
        }}
      >
        <CheckoutSuccess onClose={closeCheckoutSuccessDialog} />
      </Dialog>
      <AddDomainModal
        open={onboardingDialogOpen}
        onClose={onCloseOnboardingDialog}
        onVerifyDomain={onVerifyDomain}
        onScan={onScan}
      />
      <NotificationsDrawer userId={currentUser.id} />
      <QuickstartDialog
        otherModalsOpen={checkoutSuccessDialogOpen || showSubscriptionModal}
      />
    </Root>
  );
};

const App = () => {
  return (
    <StripeProvider>
      <SnackbarProvider maxSnack={3}>
        <HotkeysProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <PgProductsCacheProvider>
              <SalesCacheProvider>
                <TagsCacheProvider>
                  <AnalyticsV2CacheProvider>
                    <ExperimentalProvider>
                      <BackgroundJobsProvider>
                        <SideNavProvider>
                          <NotificationsDrawerProvider>
                            <AppThemeProvider>
                              <Router>
                                <MainApp />
                              </Router>
                            </AppThemeProvider>
                          </NotificationsDrawerProvider>
                        </SideNavProvider>
                      </BackgroundJobsProvider>
                    </ExperimentalProvider>
                  </AnalyticsV2CacheProvider>
                </TagsCacheProvider>
              </SalesCacheProvider>
            </PgProductsCacheProvider>
          </MuiPickersUtilsProvider>
        </HotkeysProvider>
      </SnackbarProvider>
    </StripeProvider>
  );
};

export default App;
