import { ProductCatalogMatchQueryResponse } from '../../../../domainTypes/productCatalog';

const AMAZON_EPC = 10;
const AMAZON_GMV_PER_CLICK = 144;

export const injectAmazonEPC = (
  response: ProductCatalogMatchQueryResponse
) => ({
  ...response,
  totals: {
    ...response.totals,
    analytics: {
      ...response.totals.analytics,
      epc_net: {
        curr: AMAZON_EPC
      },
      commission_sum_net: {
        curr: (response.totals.analytics?.c?.curr ?? 0) * AMAZON_EPC
      },
      gmv_sum_net: {
        curr: (response.totals.analytics?.c?.curr ?? 0) * AMAZON_GMV_PER_CLICK
      }
    }
  }
});
