import { TooltipProps } from 'recharts';
import { ITeam } from '../../../../../domainTypes/teams';
import { useSpaceCurrency } from '../../../../../services/useSpaceCurrency';
import { TeamWithColor } from '../TeamWithColor';
import moment from 'moment-timezone';
import {
  TooltipHeading,
  TooltipPaper
} from '../../../../../components/Charts/CustomTooltip';
import React from 'react';
import { styled } from '../../../../../emotion';
import { formatChartCurrency } from '../../../../../components/Charts/Util';
import { isNil } from 'lodash';

const TooltipTable = styled<'div'>('div')`
  margin-top: ${(t) => t.theme.spacing(1)}px;
  display: grid;
  grid-template-columns: auto repeat(2, min-content);
  column-gap: ${(t) => t.theme.spacing(1)}px;
`;

const PerformanceLabel = styled('span')`
  font-weight: 700;
  text-align: right;
`;

const ForecastLabel = styled('span')`
  opacity: 0.8;
  text-align: right;
`;

export const TeamsReportTooltip = ({
  payload,
  label,
  teamsById
}: TooltipProps & { teamsById: Record<string, ITeam> }) => {
  const currency = useSpaceCurrency();

  const title = moment(label).format('MMM YYYY');
  const data = (payload ?? [])
    .filter((p) => p.value > 0 && !isNil(teamsById[p.name]))
    .map((p) => {
      const teamId = p.name;
      const team = teamsById[teamId];
      return {
        key: teamId,
        label: <TeamWithColor id={teamId} name={team.name} />,
        ...p.payload.byTeam[teamId]
      };
    });

  return (
    <TooltipPaper>
      <TooltipHeading>{title}</TooltipHeading>
      <PerformanceLabel>Actual</PerformanceLabel>
      <ForecastLabel> (vs. Forecast)</ForecastLabel>
      <TooltipTable>
        {data.map(({ key, label, flatSpend, forecast }) => {
          return (
            <React.Fragment key={key}>
              {label}
              <PerformanceLabel>
                {formatChartCurrency(flatSpend, currency, {
                  excludeCents: true
                })}
              </PerformanceLabel>
              {forecast ? (
                <ForecastLabel>
                  (
                  {formatChartCurrency(forecast, currency, {
                    excludeCents: true
                  })}
                  )
                </ForecastLabel>
              ) : (
                '—'
              )}
            </React.Fragment>
          );
        })}
      </TooltipTable>
    </TooltipPaper>
  );
};
