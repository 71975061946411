import isPropValid from '@emotion/is-prop-valid';
import { ButtonBase, Dialog, DialogContent } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { compact } from 'lodash';
import React, { useMemo } from 'react';
import {
  Calendar,
  Menu as IconMenu,
  Send,
  Video as VideoIcon
} from 'react-feather';
import { Link } from 'react-router-dom';
import { Video } from '../../components/Video';
import { isInTrialIncludingGracePeriod } from '../../domainTypes/billing';
import { css, styled } from '../../emotion';
import * as ENV from '../../env.json';
import { useDialogState } from '../../hooks/useDialogState';
import { usePromise } from '../../hooks/usePromise';
import { useSideNav } from '../../hooks/useSideNav';
import { useRoutes } from '../../routes';
import { checkAdminClaim } from '../../services/auth';
import { ARTICLES, Beacon } from '../../services/beacon';
import {
  useCurrentUser,
  useCurrentUserScopes
} from '../../services/currentUser';
import { isFeatureEnabled } from '../../services/features';
import { NAV } from '../../testIds';
import * as tracking from '../../tracking';
import { AccountUpgradePrompt } from '../AccountUpgradePrompt';
import { TopNavRight } from '../TopNavRight/TopNavRight';
import { IProps, TopNavCommon, TOP_NAV } from './common';

const GoToAdminApp = () => {
  const currentUser = useCurrentUser();
  const [isAdmin] = usePromise(
    async () => currentUser.authUser && checkAdminClaim(currentUser.authUser),
    [currentUser.authUser]
  );
  if (!isAdmin) {
    return null;
  }

  const p = ENV.domains.adminApp;
  const domain = process.env.NODE_ENV === 'development' ? p.local : p.public;

  return (
    <a
      rel="noopener noreferrer"
      target="_blank"
      href={`${domain}/spaces/${currentUser.space.id}`}
    >
      Go to Admin App
    </a>
  );
};

const SupportButton = styled(ButtonBase)`
  font-size: 16px;
  color: ${(p) => p.theme.palette.primary.main} !important;
  position: relative;
  top: -2px;
`;

const VideoButton = styled<typeof ButtonBase, { inTrial: boolean }>(
  ButtonBase,
  {
    shouldForwardProp: (props) => isPropValid(props)
  }
)`
  background-color: ${(p) =>
    p.inTrial
      ? p.theme.palette.primary.main
      : p.theme.palette.grey[200]} !important;
  color: ${(p) => (p.inTrial ? 'white' : p.theme.palette.grey[800])} !important;
  padding: 2px 10px !important;
  border-radius: 10px !important;
  position: relative;
  top: -2px;

  svg {
    margin-right: 6px;
  }
`;

const DialogLinkInternal = styled(Link)`
  color: ${(p) => p.theme.palette.primary.main};

  svg {
    position: relative;
    top: 2px;
  }
`;

const DialogLink = styled('a')`
  color: ${(p) => p.theme.palette.primary.main};

  svg {
    position: relative;
    top: 2px;
  }
`;

const DemoButton = () => {
  const { space } = useCurrentUser();
  const { dialogOpen, openDialog, closeDialog } = useDialogState(false);
  const { trialUntil } = space.billing;
  const { ROUTES } = useRoutes();
  const now = Date.now();
  const inTrial = Boolean(
    trialUntil || isInTrialIncludingGracePeriod(space.billing, now)
  );

  return (
    <>
      <VideoButton
        inTrial={inTrial}
        onClick={() => {
          tracking.sendOnboardingEvent({
            event: 'Onboarding: Click demo',
            value: 0
          });
          openDialog();
        }}
      >
        <VideoIcon size={12} /> Video
      </VideoButton>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        maxWidth="xl"
        scroll="body"
      >
        <DialogContent>
          <br />
          <Video
            url="https://player.vimeo.com/video/880102867?h=1f474b2f19"
            width={800}
            height={418}
          />
          <Typography
            variant="body1"
            color="textSecondary"
            style={{ textAlign: 'center', maxWidth: '600px', margin: '0 auto' }}
          >
            <strong>Questions?</strong> Start with our{' '}
            <DialogLinkInternal
              onClick={() => closeDialog()}
              to={ROUTES.docs.knowledgeBase.url(ARTICLES.setup.quickstart)}
            >
              Quickstart Guide
            </DialogLinkInternal>{' '}
            and{' '}
            <DialogLinkInternal
              to={ROUTES.docs.knowledgeBase.url()}
              onClick={() => closeDialog()}
              target="_blank"
            >
              Knowledge Base
            </DialogLinkInternal>
            .
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            style={{
              marginTop: '9px',
              textAlign: 'center',
              maxWidth: '600px',
              margin: '0 auto'
            }}
          >
            You can also{' '}
            <SupportButton
              onClick={(e) => {
                e.preventDefault();
                Beacon('open');
              }}
            >
              <Send size={16} style={{ marginRight: '3px' }} /> talk to Customer
              Success
            </SupportButton>{' '}
            or{' '}
            <DialogLink
              href="https://savvycal.com/affilimate/intro"
              style={{ marginLeft: '3px' }}
              target="_blank"
            >
              <Calendar
                size={16}
                style={{
                  marginRight: '1px'
                }}
              />{' '}
              request a demo
            </DialogLink>
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

// TODO - this can probably be removed
export const TopNavOld = ({
  title,
  children,
  stickyIndex = 0,
  colors = {
    fg: TOP_NAV.COLORS.dark,
    bg: 'white'
  }
}: IProps) => {
  const { open, setOpen } = useSideNav();
  const { fg: color, bg: backgroundColor } = colors;

  return (
    <React.Fragment>
      <AppBar
        color="primary"
        position={stickyIndex === 0 ? 'sticky' : 'static'}
        elevation={0}
        classes={{
          root: css(() => ({
            backgroundColor,
            color
          }))
        }}
      >
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Hidden smUp>
              <Grid item>
                <IconButton
                  aria-label="Open menu"
                  onClick={() => {
                    setOpen(!open);
                    tracking.sendEvent({
                      category: 'Navigator',
                      action: 'Click',
                      label: 'Mobile menu'
                    });
                  }}
                  className={css((t) => ({ marginLeft: -t.spacing() }))}
                >
                  <IconMenu />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item>
              <GoToAdminApp />
            </Grid>
            <Grid item />
            <Grid item></Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {title && (
        <AppBar
          component="div"
          classes={{
            root: css(() => ({
              backgroundColor,
              color,
              zIndex: 0
            }))
          }}
          color="primary"
          position={stickyIndex === 1 ? 'sticky' : 'static'}
          elevation={0}
        >
          <Toolbar>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs>
                <Typography color="inherit" variant="h5" data-cy={NAV.title}>
                  {title}
                </Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      )}
      <AppBar
        component="div"
        className={css((t) => ({
          zIndex: stickyIndex === 2 ? 2 : 0,
          backgroundColor,
          color
        }))}
        color="primary"
        position={stickyIndex === 2 ? 'sticky' : 'static'}
        elevation={1}
      >
        {children}
      </AppBar>
    </React.Fragment>
  );
};

export const TopNav = React.memo(() => {
  const { ROUTES, getPath } = useRoutes();
  const hasScanlessTracking = isFeatureEnabled('SCANLESS_TRACKING');
  const hasCampaigns = isFeatureEnabled('CAMPAIGNS');
  const scopes = useCurrentUserScopes();
  const canViewDashboard = scopes.has('dashboard.view');
  const canViewTransactions = scopes.has('reports.transactions.view');
  const canViewRules = scopes.has('rules.view');
  const canViewIntegrations = scopes.has('integrations.view');
  const canViewScans = scopes.has('scans.view') && !hasScanlessTracking;
  const canViewContent =
    scopes.has('reports.content.view') || scopes.has('reports.tags.view');
  const canViewLinksOverview = scopes.has('reports.links.view');
  const canViewCampaigns = hasCampaigns && scopes.has('campaigns.view');
  const canUseLinkGenerator = scopes.has('link_generator.use');
  const canViewDocs = scopes.has('docs.view');

  const items: {
    label: string;
    path: string;
  }[] = useMemo(
    () =>
      compact([
        canViewDashboard && {
          label: 'Dashboard',
          path: ROUTES.dashboard.overview.url()
        },
        (canViewTransactions || canViewRules || canViewIntegrations) && {
          label: 'Performance',
          path: ROUTES.performanceNew.transactions.url()
        },
        canViewContent && {
          label: 'Content',
          path: ROUTES.content.overview_v2.url()
        },
        canViewLinksOverview && {
          label: 'Links',
          path: ROUTES.links.overview_v2.url()
        },
        canViewCampaigns && {
          label: 'Campaigns',
          path: ROUTES.campaigns.segment.active.url()
        },
        canViewScans && {
          label: 'Scan',
          path: ROUTES.links.scans.history.url()
        },
        canUseLinkGenerator && {
          label: 'Tools',
          path: ROUTES.tools.linkGenerator.url()
        },
        canViewDocs && {
          label: 'Docs',
          path: ROUTES.docs.knowledgeBase.url()
        }
      ]),
    [
      canViewDashboard,
      ROUTES.dashboard.overview,
      ROUTES.performanceNew.transactions,
      ROUTES.content.overview_v2,
      ROUTES.links.scans.history,
      ROUTES.campaigns.segment.active,
      ROUTES.tools.linkGenerator,
      ROUTES.docs.knowledgeBase,
      ROUTES.links.overview_v2,
      canViewTransactions,
      canViewRules,
      canViewIntegrations,
      canViewContent,
      canViewLinksOverview,
      canViewCampaigns,
      canViewScans,
      canUseLinkGenerator,
      canViewDocs
    ]
  );

  return (
    <>
      <TopNavCommon
        homePath={ROUTES.dashboard.overview.url()}
        navItems={items}
        currentPath={getPath()}
        demoButton={<DemoButton />}
      >
        <TopNavRight noBorder />
      </TopNavCommon>
      <AccountUpgradePrompt />
    </>
  );
});
