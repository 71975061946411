import { Typography } from '@material-ui/core';
import { compact } from 'lodash';
import pluralize from 'pluralize';
import React, { useMemo, useState } from 'react';
import {
  AnalyticsFilter,
  ISOTimeRange
} from '../../../../../../domainTypes/analytics_v2';
import { Doc } from '../../../../../../domainTypes/document';
import { IntegrationIdFilterDefinition } from '../../../../../../domainTypes/filters';
import { SECRET_CATEGORY } from '../../../../../../domainTypes/reporting';
import { ISecretWithTs } from '../../../../../../domainTypes/secret';
import { useApiReportHandlers } from '../../../../../../features/PerformanceNew/services/handlers';
import { IApiReportHandler } from '../../../../../../features/PerformanceNew/services/handlers/types';
import { FlexContainer } from '../../../../../../layout/Flex';
import { useCurrentUser } from '../../../../../../services/currentUser';
import {
  LoadingValue,
  useMappedLoadingValue
} from '../../../../../../services/db';
import { getKnownPartnerForKey } from '../../../../../../services/partner';
import { useSecrets } from '../../../../../../services/secret';
import { InternalId } from '../../../../../ConnectionId';
import { PlatformWithColor } from '../../../../../PlatformWithColor';
import { BASIC_MODES, FilterMenu } from '../../Menus/FilterMenu';
import {
  OptionsList,
  SelectorLoader,
  SelectorShell,
  useCollectionFilterState
} from '../../Menus/Selector';
import { AnalyticsFilterMenuComponent } from '../../../FilterUI';

const IntegrationDescription = () => (
  <>
    <strong>Integration ID</strong> is the specific API connection to a
    platform. Use this to split revenue from multiple connections to the same
    platform.
  </>
);

type Integration = {
  secret: Doc<ISecretWithTs>;
  handler: IApiReportHandler;
};

interface IntegrationIdMenuBodyProps {
  integrations: Integration[];
  definition: IntegrationIdFilterDefinition;
  onSave: (d: IntegrationIdFilterDefinition) => void;
}

// Maybe show items hierarchical - one for each PK, then whatever's inside

const IntegrationIdMenuBody: React.FC<IntegrationIdMenuBodyProps> = ({
  integrations,
  definition,
  onSave
}) => {
  const [search, setSearch] = useState('');
  const {
    values,
    handleSave,
    handleToggle,
    handleFocus,
    isSaveEnabled
  } = useCollectionFilterState<string, IntegrationIdFilterDefinition>(
    definition,
    onSave
  );

  const options = useMemo(() => {
    return compact(
      integrations
        .sort((a, b) =>
          a.handler.partnerKey.localeCompare(b.handler.partnerKey)
        )
        .filter((integration) => {
          const { secret, handler } = integration;
          const partner = getKnownPartnerForKey(handler.partnerKey);

          if (!partner) {
            return false;
          }

          // This one doesn't have any commissions come through
          if (handler.configName === 'amazonV2') {
            return false;
          }

          return (
            search === '' ||
            handler.partnerKey.toLowerCase().includes(search.toLowerCase()) ||
            secret.data.nickname
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            secret.data.instanceId.toLowerCase().includes(search.toLowerCase())
          );
        })
        .map((integration) => {
          const { secret, handler } = integration;
          const partner = getKnownPartnerForKey(handler.partnerKey);
          if (!partner) {
            return null;
          }
          return {
            value: secret.data.instanceId,
            label: (
              <div style={{ padding: '8px 4px' }}>
                <FlexContainer alignItems="center" justifyContent="flex-start">
                  <PlatformWithColor partner={partner} />
                  <InternalId>{secret.data.instanceId}</InternalId>
                </FlexContainer>
                <Typography variant="caption" color="textSecondary">
                  {secret.data.nickname
                    ? secret.data.nickname
                    : handler.additionalName
                    ? handler.additionalName(partner, secret)
                    : null}
                </Typography>
              </div>
            )
          };
        })
    );
  }, [integrations, search]);

  return (
    <>
      <FilterMenu.Body>
        <SelectorShell
          label="Integrations"
          search={search}
          setSearch={setSearch}
        >
          <OptionsList
            options={options}
            selectedValues={values}
            onToggle={handleToggle}
            onFocus={handleFocus}
          />
        </SelectorShell>
      </FilterMenu.Body>
      <FilterMenu.Footer description={<IntegrationDescription />}>
        <FilterMenu.SaveButton
          disabled={!isSaveEnabled}
          onSave={handleSave}
          label={`Filter by ${pluralize('integration', values.length, true)}`}
        />
      </FilterMenu.Footer>
    </>
  );
};

const IntegrationIdMenuLoading = () => (
  <>
    <FilterMenu.Body>
      <SelectorLoader />
    </FilterMenu.Body>
    <FilterMenu.Footer description={<IntegrationDescription />}>
      <FilterMenu.SaveButton
        disabled={true}
        onSave={() => {}}
        label="Loading integrations..."
      />
    </FilterMenu.Footer>
  </>
);

const useIntegrations = (
  filters: Array<AnalyticsFilter>,
  range: ISOTimeRange
): LoadingValue<
  {
    secret: Doc<ISecretWithTs>;
    handler: IApiReportHandler;
  }[]
> => {
  const { space } = useCurrentUser();
  const handlers = useApiReportHandlers();
  return useMappedLoadingValue(
    useSecrets(space.id, SECRET_CATEGORY),
    (secrets) => {
      return compact(
        secrets.map((s) => {
          const handler = handlers.find((h) => h.configName === s.data.name);
          return handler
            ? {
                secret: s,
                handler
              }
            : null;
        })
      );
    }
  );
};

const InnerIntegrationIdMenu: React.FC<{
  definition: IntegrationIdFilterDefinition;
  onSave: (d: IntegrationIdFilterDefinition) => void;
  filters: AnalyticsFilter[];
  range: ISOTimeRange;
}> = ({ definition, onSave, filters, range }) => {
  const [integrations, loading] = useIntegrations(filters, range);
  if (!integrations || loading) {
    return <IntegrationIdMenuLoading />;
  }
  return (
    <IntegrationIdMenuBody
      integrations={integrations}
      definition={definition}
      onSave={onSave}
    />
  );
};

export const IntegrationIdMenu: AnalyticsFilterMenuComponent<IntegrationIdFilterDefinition> = ({
  definition,
  onSave,
  context,
  isFirst
}) => {
  const [mode, setMode] = useState(BASIC_MODES[0].value);

  return (
    <FilterMenu>
      <FilterMenu.Header name={'integration ID'} isFirst={isFirst}>
        <FilterMenu.ModeSelector
          modes={BASIC_MODES}
          mode={mode}
          setMode={setMode}
        />
      </FilterMenu.Header>
      <InnerIntegrationIdMenu
        filters={context.baseQuery.filters}
        range={context.baseQuery.range}
        definition={definition}
        onSave={onSave}
      />
    </FilterMenu>
  );
};
