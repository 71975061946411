import { compact, isEmpty, Omit } from 'lodash';
import { IManualCampaign, isOffsiteLink } from '../../../domainTypes/campaigns';
import { Moment } from 'moment-timezone';
import {
  AnalyticsFilter,
  ISOTimeRange
} from '../../../domainTypes/analytics_v2';
import { removeTrailingSlash } from '../../../services/url';
import { toMoment } from '../../../services/time';

export const campaignStatuses = [
  'planning',
  'scheduled',
  'running',
  'completed'
] as const;

export const activeCampaignStatuses = [
  'planning',
  'scheduled',
  'running'
] as const;

export const campaignStatusTitle = (status: CampaignStatus) => {
  switch (status) {
    case 'planning':
      return 'Planning';
    case 'scheduled':
      return 'Scheduled';
    case 'running':
      return 'Running';
    case 'completed':
      return 'Completed';
  }
};

export type CampaignStatus = typeof campaignStatuses[number];

interface BaseCampaign extends Omit<IManualCampaign, 'timeframe'> {}

export type PlannedManualCampaign = BaseCampaign & {
  status: 'planning';
  timeframe?: {
    start: Moment;
    end: Moment;
  };
};

export type ScheduledManualCampaign = BaseCampaign & {
  status: 'scheduled';
  timeframe: {
    start: Moment;
    end: Moment;
  };
};

export type RunningManualCampaign = BaseCampaign & {
  status: 'running';
  timeframe: {
    start: Moment;
    end: Moment;
  };
};

export type CompletedManualCampaign = BaseCampaign & {
  status: 'completed';
  timeframe: {
    start: Moment;
    end: Moment;
  };
};

export type ManualCampaign =
  | PlannedManualCampaign
  | ScheduledManualCampaign
  | RunningManualCampaign
  | CompletedManualCampaign;

export type ReadyManualCampaign =
  | RunningManualCampaign
  | CompletedManualCampaign;

export const isCampaignComplete = (
  campaign: ManualCampaign
): campaign is CompletedManualCampaign => campaign.status === 'completed';

export const isCampaignActive = (
  campaign: ManualCampaign
): campaign is
  | ScheduledManualCampaign
  | RunningManualCampaign
  | PlannedManualCampaign => {
  return (
    campaign.status === 'planning' ||
    campaign.status === 'scheduled' ||
    campaign.status === 'running'
  );
};

export const isCampaignAtLeastScheduled = (
  campaign: ManualCampaign
): campaign is
  | ScheduledManualCampaign
  | RunningManualCampaign
  | CompletedManualCampaign =>
  campaign.status === 'scheduled' ||
  campaign.status === 'running' ||
  campaign.status === 'completed';

export const campaignQueryBase = ({
  timeframe,
  links,
  pageUrls
}: ReadyManualCampaign): {
  filters: AnalyticsFilter[];
  range: ISOTimeRange;
} => ({
  range: {
    start: timeframe.start.toISOString(),
    end: timeframe.end.toISOString()
  },
  filters: compact([
    links.length > 0 && {
      field: 'link_id',
      condition: 'in',
      values: links.map((l) => l.id)
    },
    pageUrls.length > 0 && {
      field: 'page_url',
      condition: 'in',
      values: [
        ...pageUrls.map(removeTrailingSlash),
        // can't use lodash.compact, because "''" is falsy
        ...(links.some(isOffsiteLink) ? [''] : [])
      ]
    }
  ])
});

// Type is used to model changing schema of ICampaign
export type PartialICampaign = Pick<IManualCampaign, 'spaceId' | 'id'> &
  Partial<Omit<IManualCampaign, 'spaceId' | 'id'>>;

export const readManualCampaign = (
  partialCampaign: PartialICampaign
): ManualCampaign => {
  const campaign: IManualCampaign = {
    ...DEFAULT_MANUAL_CAMPAIGN,
    ...partialCampaign
  };

  const { timeframe: rawTimeframe, pageUrls, links } = campaign;
  const timeframe = rawTimeframe
    ? {
        start: toMoment(rawTimeframe.start),
        end: toMoment(rawTimeframe.end)
      }
    : undefined;

  if (!rawTimeframe || !timeframe || isEmpty(pageUrls) || isEmpty(links)) {
    return {
      ...campaign,
      timeframe,
      status: 'planning'
    };
  }
  const now = Date.now();
  if (now < rawTimeframe.start.toMillis()) {
    return {
      ...campaign,
      status: 'scheduled',
      timeframe
    };
  }
  if (now < rawTimeframe.end.toMillis()) {
    return {
      ...campaign,
      status: 'running',
      timeframe
    };
  }
  return {
    ...campaign,
    status: 'completed',
    timeframe
  };
};

const DEFAULT_MANUAL_CAMPAIGN: Omit<IManualCampaign, 'spaceId' | 'id'> = {
  name: '',
  type: 'manual',
  advertisers: [],
  managers: [],
  team: '',
  incentives: [],
  goals: [],
  timeframe: null,
  pageUrls: [],
  links: []
};

export const EMPTY_MANUAL_CAMPAIGN = (
  spaceId: string,
  id: string
): IManualCampaign => ({
  spaceId,
  id,
  ...DEFAULT_MANUAL_CAMPAIGN
});
